import React from 'react'
import moment from 'moment'
import { withTranslate } from 'react-redux-multilingual'
import { Box, Button, CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { SavedIcon } from '../ui/Icons'

import 'moment/locale/en-gb'
import 'moment/locale/nl'
import 'moment/locale/de'
import 'moment/locale/fr'
import 'moment/locale/es'
import 'moment/locale/it'
import 'moment/locale/pt'

const styles = theme => ({
    root: {
        height: '100%',
        width: '180px',
        display: 'flex',
        alignItems: 'center',
        background: '#005A50',
        color: '#fff',
        userSelect: 'none',
    },
    btnSaveAction: {
        display: 'flex',
        color: 'white',
        height: '100%',
        width: '180px',
        padding: 0,
        margin: 0,
    },
    saveGroup: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        fontSize: `14px !important`,
        alignItems: 'center',
        justifyContent: 'center',
    },
    saveText: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        paddingRight: 8,
        paddingLeft: 8,
    },
    icon: {
        color: 'white',
        fontSize: 28,
    },
    lastSaved: {
        display: 'flex',
        justifyContent: 'flexStart',
        fontWeight: 600,
        fontSize: 12,
    },
    saveError: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
});

const UPDATE_AUTO_SAVED_INTERVAL = 2 * 1000; // 2 seconds

class AutoSave extends React.Component {

    constructor(props) {
        super(props);

        this.date = new Date();

        // Set language
        moment.locale(this.props.currentLanguage);
        this.state = {
            fromNow: moment(this.date).fromNow()
        }
    }

    componentDidMount() {
        // Update the value
        setInterval(() => {
            this.setState({
                fromNow: moment(this.date).fromNow()
            });
        }, UPDATE_AUTO_SAVED_INTERVAL);
    }

    componentDidUpdate(prevProps) {
        if (this.props.saving && !prevProps.saving) {
            this.date = new Date();
            this.setState({
                fromNow: moment(this.date).fromNow()
            });
        }
    }

    render() {
        const { translate, classes, saving, saved, saveError, saveTranscript } = this.props;

        function getBackgroundColor() {
            if (saveError) {
                return '#E36A57'; // RED
            } else {
                return '#005A50'; // AMBER_GREEN
            }
        }

        return (
            <Box className={classes.root} fontSize={{ xs: 12, sm: 12, md: 12, lg: 14, xl: 14 }}>
                {!saving && (saved || saveError) &&
                    <Button color="primary" variant="contained" className={classes.btnSaveAction} onClick={saveTranscript} style={{ backgroundColor: getBackgroundColor() }}>
                        {
                            saved ?
                                <div className={classes.saveGroup}>
                                    <div className={classes.saveText}>
                                        <Box marginRight={1}>
                                            <SavedIcon className={classes.icon} />
                                        </Box>
                                        <span className={classes.lastSaved}>{translate('EDITOR.autoSaveSaved')} {this.state.fromNow}</span>
                                    </div>
                                </div> :
                                <div className={classes.saveError}>
                                    <span>{translate('EDITOR.autoSaveError')}</span>
                                    <span><b>{translate('EDITOR.autoSaveTryAgain')}</b></span>
                                </div>
                        }
                    </Button>
                }
                {saving && !saved &&
                    <Box display="flex" width="100%" height="100%" justifyContent="flex-start" alignItems="center">
                        <Box marginLeft={1} marginRight={1}>
                            <CircularProgress className={classes.icon} size={24} />
                        </Box>
                        <span>{translate('EDITOR.autosavePending')}</span>
                    </Box>
                }
            </Box>
        );
    }
}

export default withStyles(styles)(withTranslate(AutoSave));
