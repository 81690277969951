import { call, put, race, take } from "redux-saga/effects";

import {
  AUTH_LOGIN,
  AUTH_LOGIN_FIREBASE,
  AUTH_LOGIN_FAIL,
  AUTH_LOGIN_OK,
  AUTH_LOGOUT,
  AUTH_LOGOUT_OK,
  EMAIL_VALIDATION_SENT,
  GET_ACCOUNT,
  GET_ACCOUNT_FAIL,
  GET_ACCOUNT_OK,
  SEND_EMAIL_VALIDATION,
} from "./constants";

import { STORAGE_API_KEY } from "../settings";
import { apiClient, apiLoginClient } from "../apiClient";
import { signInWithEmailPassword } from "../helpers/firebaseAuthentication";

// ****************** Functions ******************

export function* authorizeWorker(data) {
  try {
    return yield call(apiLoginClient.post, "token", data);
  } catch (error) {
    yield put({ type: AUTH_LOGIN_FAIL, sending: false });
  }
}

export function* authorizeFirebaseWorker(data) {
  try {
    yield call(signInWithEmailPassword, data.username, data.password);
    return yield call(apiClient.get, "user/get");
  } catch (error) {
    yield put({ type: AUTH_LOGIN_FAIL, sending: false });
  }
}

export function* authorize() {
  while (true) {
    const request = yield take(AUTH_LOGIN);
    const result = yield race({
      ok: call(authorizeWorker, request.data),
      no: take(AUTH_LOGOUT),
    });
    if (result.ok) {
      yield put({ type: AUTH_LOGIN_OK, payload: result.ok.data });
    }
  }
}

export function* authorizeFirebase() {
  while (true) {
    const request = yield take(AUTH_LOGIN_FIREBASE);
    const result = yield race({
      ok: call(authorizeFirebaseWorker, request.data),
      no: take(AUTH_LOGOUT),
    });
    if (result.ok) {
      yield put({ type: AUTH_LOGIN_OK, payload: result.ok.data });
    }
  }
}

export function* deAuthorize() {
  while (true) {
    yield take(AUTH_LOGOUT);
    localStorage.removeItem(STORAGE_API_KEY);
    yield put({ type: AUTH_LOGOUT_OK });
  }
}

export function* getAccountDataWorker() {
  try {
    return yield call(apiClient.get, "user/get");
  } catch (error) {
    yield put({ type: GET_ACCOUNT_FAIL, payload: error.response.data.message });
  }
}

export function* getAccountData() {
  while (true) {
    yield take(GET_ACCOUNT);

    const result = yield race({
      ok: call(getAccountDataWorker),
      no: take(AUTH_LOGOUT),
    });

    if (result.ok) {
      yield put({ type: GET_ACCOUNT_OK, payload: result.ok.data });
    }
  }
}

export function* sendEmailValidationWorker() {
  try {
    return yield call(apiClient.post, "user/resendwelcomeemail");
  } catch (error) {
    yield put({ type: GET_ACCOUNT_FAIL });
  }
}

export function* sendEmailValidation() {
  while (true) {
    yield take(SEND_EMAIL_VALIDATION);

    const result = yield race({
      ok: call(sendEmailValidationWorker),
      no: take(AUTH_LOGOUT),
    });

    if (result.ok) {
      yield put({ type: EMAIL_VALIDATION_SENT });
    }
  }
}
