import React from 'react'
import { withTranslate } from 'react-redux-multilingual'
import { Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    button: {
        color: '#707273',
    },
    [theme.breakpoints.down('sm')]: {
        button: {
            display: 'none',
        },
    },
});

function EditorTourButton(props) {
    const { translate, classes, onClick } = props;

    return (
        <React.Fragment>
            <Button
                className={classes.button}
                color="default"
                onClick={onClick}
            >
                <span>{translate('EDITOR.replayTour')}</span>
            </Button>
        </React.Fragment>
    )
}

export default withStyles(styles)(withTranslate(EditorTourButton));
