import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslate } from 'react-redux-multilingual'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText,
    Input,
    InputLabel,
    Slide,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles';
import {
    SAVE_TRANSCRIPT_STATUS,
    SET_TRANSCRIPT_TITLE
} from '../../sagas/constants'
import Loading from '../ui/Loading'

function Transition(props) {
    return <Slide direction="down" {...props} />;
}

const styles = theme => ({
    actions: {
        justifyContent: 'space-between',
        margin: 20,
    }
});

class EditInfo extends Component {

    state = {
        title: ''
    }

    onChangeName = (event) => {
        this.setState({ title: event.target.value });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.props.dispatch({
            type: SET_TRANSCRIPT_TITLE, payload: this.state.title
        });
        // TODO: probably move this to somewhere nicer
        this.props.dispatch({
            type: SAVE_TRANSCRIPT_STATUS,
            transcriptStatus: this.props.transcriptStatus,
        });
        this.props.onClose();
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            title: nextProps.transcriptStatus.data.audioDisplayFileName,
        });
    }

    render() {
        const { loaded } = this.props.transcriptStatus;
        const { title } = this.state;
        const { translate, classes, open, onClose } = this.props;
        const errors = {
            name: false, // @todo-rense
        };

        if (!loaded) {
            return (<Loading />)
        }

        // TODO: probably move this to somewhere nicer
        document.title = this.state.title;

        return (
            <Dialog
                className={classes.root}
                open={open}
                onClose={onClose}
                transition={Transition}
                maxWidth="sm"
                fullWidth
                aria-labelledby="EditInfo-dialog-title"
            >
                <form onSubmit={this.handleSubmit}>
                    <DialogTitle id="EditInfo-dialog-title">
                        {translate('EDITOR.infoTitle')}
                    </DialogTitle>
                    <DialogContent>
                        <FormControl fullWidth>
                            <InputLabel error={errors.name} htmlFor="title}-input">
                                {translate('EDITOR.infoNameLabel')}
                            </InputLabel>
                            <Input
                                id="title-input"
                                type="text"
                                name="title"
                                autoFocus
                                required
                                value={title}
                                onChange={this.onChangeName}
                                error={errors.name}
                                inputProps={{ maxLength: 64 }}
                            />
                            {errors.name &&
                                <FormHelperText error={errors.name} id="name-error-text">Name is invalid</FormHelperText>
                            }
                        </FormControl>
                    </DialogContent>
                    <DialogActions className={classes.actions}>
                        <Button
                            color="default"
                            variant="contained"
                            onClick={onClose}
                            fullWidth
                        >
                            {translate('EDITOR.infoCancel')}
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            type="submit"
                            disabled={!title}
                            fullWidth
                        >
                            {translate('EDITOR.infoSave')}
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        );
    }
}

const mapStateToProps = ({ transcriptStatus }) => ({
    transcriptStatus
});

export default connect(mapStateToProps)(withStyles(styles)(withTranslate(EditInfo)))
