// Editor (draft.js)
export const HIGHLIGHT = 'HIGHLIGHT';
export const REWIND_INTERVAL = 5;

// GTM (Google tag manager)
export const GTM_ID = 'GTM-TLJGV9T';
export const GTM_AUTH_STAGING = 'c5NN4RkutjxQig2dZdGtDw';
export const GTM_PREVIEW_STAGING = 'env-115';

// Sentry
export const RAVEN_DNS =
	'https://28bb53e26a254716a14184160301df4b@o135058.ingest.sentry.io/336763';

// Redux
export const DEBUG_REDUX = false;

// Local Storage
export const STORAGE_API_KEY = 'amber.key';
export const STORAGE_ANALYTICS_DATALAYER = 'amber.analyticsDL';
export const STORAGE_IE_NOTICE = 'amber.ienotice';
export const STORAGE_USER_DATA = 'amber.user';

// ENV Vars
export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT_EDITOR;
export const DEBUG = ENVIRONMENT === 'staging';

export const DASHBOARD_URL = process.env.REACT_APP_DASHBOARD_URL;
export const TRANSCRIBER_DASHBOARD = process.env.REACT_APP_PERFECT_PLATFORM_URL;
export const LOGIN_PAGE = process.env.REACT_APP_DASHBOARD_URL + '/login';
export const RECORDS_PAGE = process.env.REACT_APP_DASHBOARD_URL + '/records';

export const API_URL = process.env.REACT_APP_BE_BASE_URL;
export const WORD_EXPORT_ENDPOINT = process.env.REACT_APP_WORD_EXPORT_ENDPOINT;

export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET;
