import React, { useEffect, useRef } from "react";
import { updateJobActiveSeconds } from "./activityTrackerApi";

function UserActivityTracker(props) {
  const { audioPlaying, jobId } = props;
  const { playing } = audioPlaying;
  const userIsInactive = useRef(false);
  const activityIntervalTracker = useRef(0);
  const activeContainer = useRef(0);
  const activeStartTime = useRef(Date.now());
  const timer = useRef(null);

  const handleKeyboardEventTracker = () => {
    handleUserPostActivity();
  };

  const handleKeyboardEventTrackerInterval = () => {
    // This is to only to reset the timer on every 50th event since mouse movement fires a lot.
    if (shouldUpdateUserActivity()) {
      handleUserPostActivity();
    }
  };

  const restartTimer = () => {
    clearTimeout(timer.current);
    timer.current = setTimeout(checkIfAudioIsPlayingOrSetInactive, 20000);
  };

  const handleUserPostActivity = () => {
    if (userIsInactive.current) {
      activeStartTime.current = Date.now();
      userIsInactive.current = false;
    }
    restartTimer();
  };

  const shouldUpdateUserActivity = () => {
    activityIntervalTracker.current += 1;

    return activityIntervalTracker.current % 50 === 0 ? true : false;
  };

  const checkIfAudioIsPlayingOrSetInactive = () => {
    playing ? restartTimer() : setUserAsInactive();
  };

  const setUserAsInactive = () => {
    userIsInactive.current = true;
    sendActiveTimeToBackEnd();
  };

  const sendActiveTimeToBackEnd = () => {
    activeContainer.current += (Date.now() - activeStartTime.current) / 1000;

    updateJobActiveSeconds(jobId, Math.round(activeContainer.current))
      .then((resp) => {
        activeContainer.current = 0;
      });
  };

  const handleBrowserCloseAndRefresh = (e) => {
    e.preventDefault();

    clearTimeout(timer.current);
    setUserAsInactive();

    return (e.returnValue = "Are you sure you want to exit?");
  };

  const addEventListeners = () => {
    document.addEventListener("keydown", handleKeyboardEventTracker);
    document.addEventListener("click", handleKeyboardEventTracker);
    document.addEventListener("mousemove", handleKeyboardEventTrackerInterval);
    document.addEventListener("wheel", handleKeyboardEventTrackerInterval);
    window.addEventListener("beforeunload", handleBrowserCloseAndRefresh);
  };

  const removeEventListeners = () => {
    document.removeEventListener("keydown", handleKeyboardEventTracker);
    document.removeEventListener("click", handleKeyboardEventTracker);
    document.removeEventListener(
      "mousemove",
      handleKeyboardEventTrackerInterval
    );
    document.removeEventListener("wheel", handleKeyboardEventTrackerInterval);
    window.removeEventListener("beforeunload", handleBrowserCloseAndRefresh);
  };

  useEffect(() => {
    handleUserPostActivity();
  }, [audioPlaying]);

  useEffect(() => {
    addEventListeners();

    return () => {
      clearTimeout(timer.current);
      setUserAsInactive();
      removeEventListeners();
    };
  }, []);

  return <></>;
}

export default UserActivityTracker;
