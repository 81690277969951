import * as Sentry from '@sentry/react';

// VAR(s)
const TAG_DATA_NAME = 'data';
const SECTION = 'section';

// Functions
/**
 * Initialize Sentry for this user. Setting the generic data.
 *
 * @param {string} email Email of the user (user.email).
 * @param {string} userName Username of the user (user.userName).
 * @param {string} countryCode Country_code of the user (user.countryCode).
 * @param {string} userType User_type of the user (user.userType).
 */
export function informSentryOfCurrentAuthenticatedUser(
	email,
	userName,
	countryCode,
	userType,
) {
	if (email) {
		Sentry.configureScope((scope) => {
			scope.setUser({
				email,
				userName,
				countryCode,
				userType,
			});
		});
	}
}

/**
 * Send error log to Sentry for scrambled error
 *
 * @param {String} section Transcript editor or Subtitle editor.
 * @param {Object} user The user object.
 * @param {String} lang The language of the file.
 * @param {String} recordId
 * @param {Array<String>} lastKeyPressedList
 * @param {String} reason
 * @param {String} word
 */
export function logScrambledErrorOnSentry(
	section,
	user,
	lang,
	recordId,
	lastKeyPressedList,
	reason,
	word,
) {
	const ERROR_TITLE = `Scrambled error occurred!`;
	const { userType } = user.data;

	Sentry.captureException(new Error(ERROR_TITLE), (scope) => {
		for (let index = 0; index < lastKeyPressedList.length; index += 1) {
			scope.setTag(
				`key_${String(index + 1).padStart(2, '0')}`,
				lastKeyPressedList[index].key_pressed,
			);
		}
		scope.setTag(SECTION, 'scrambled_error');
		scope.setTag('editor', section);
		scope.setTag('userType', userType);
		scope.setTag('fileLanguage', lang);
		scope.setTag('recordId', recordId);
		reason && scope.setTag('reason', reason);
		word && scope.setTag('word', word);
		return scope;
	});
}

/**
 * Send error log to Sentry for saving issues
 *
 * @param {object} data The data need to invsetigate the error.
 */
export function logSavingIssueOnSentry(data) {
	const ERROR_TITLE = `Saving issue`;

	Sentry.captureException(new Error(ERROR_TITLE), (scope) => {
		scope.setTag(TAG_DATA_NAME, JSON.stringify(data));
		scope.setTag(SECTION, ERROR_TITLE);
		return scope;
	});
}

/**
 * Send error log to Sentry for scrambled error
 *
 * @param {String} title
 */
export function storeFeedbackBySentry(title) {
	const ERROR_TITLE = 'Scrambled error feedback';

	Sentry.captureException(new Error(title), (scope) => {
		scope.setTag(SECTION, ERROR_TITLE);
		return scope;
	});
}

/**
 * Send error log to Sentry for logouts
 *
 * @param {Error} error
 * @param {String} url
 * @param {String} type
 */
export function trackAxiosErrors(error, url, type) {
	const ERROR_TITLE = 'axios';

	Sentry.captureException(error, (scope) => {
		scope.setTag(SECTION, ERROR_TITLE);
		scope.setTag('url', url);
		scope.setTag('type', type);
		return scope;
	});
}

/**
 * Send error log to Sentry for create subtitles function
 * @param {Error} error
 */
export function trackCreateSubtitleErrors(error) {
	const ERROR_TITLE = 'create_sub';

	Sentry.captureException(error, (scope) => {
		scope.setTag(SECTION, ERROR_TITLE);

		return scope;
	});
}

/**
 * Send the error to Sentry logs passing a tag
 * @param {error} error
 * @param {string} tag
 */
 export function trackSentryErrorByTag(error, tag) {
  Sentry.captureException(error, (scope) => {
    // Scope tags
    scope.setTag(SECTION, tag);
    return scope;
  });
}