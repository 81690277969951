import React from "react";
import PropTypes from "prop-types";
import { DialogActions, styled } from "@material-ui/core";

import AmberOutlinedButton from "../buttons/AmberOutlinedButton";
import AmberPrimaryButton from "../buttons/AmberPrimaryButton";

const CustomDialogActions = styled(DialogActions)(({ theme }) => ({
  padding: theme.spacing(3),
}));

export default function PreQCDialogActions(props) {
  const {
    translate,
    handleViewAndFix,
    confirmation,
    setConfirmation,
    handleSubmit,
  } = props;

  const handleGoToConfirmation = () => {
    setConfirmation(true);
  };

  const handleGoBack = () => {
    setConfirmation(false);
  };

  function SubmitOrView() {
    return (
      <>
        <AmberOutlinedButton
          text={translate("EDITOR.PRE_QC.submitAnyway")}
          onClick={handleGoToConfirmation}
        />
        <AmberPrimaryButton
          text={translate("EDITOR.PRE_QC.viewFix")}
          onClick={handleViewAndFix}
        />
      </>
    );
  }

  function SubmitConfirmation() {
    return (
      <>
        <AmberOutlinedButton
          text={translate("EDITOR.PRE_QC.cancel")}
          onClick={handleGoBack}
        />
        <AmberPrimaryButton
          text={translate("EDITOR.PRE_QC.submit")}
          onClick={handleSubmit}
        />
      </>
    );
  }

  return (
    <CustomDialogActions>
      {confirmation ? <SubmitConfirmation /> : <SubmitOrView />}
    </CustomDialogActions>
  );
}

PreQCDialogActions.propTypes = {
  translate: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleViewAndFix: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setConfirmation: PropTypes.func.isRequired,
  confirmation: PropTypes.bool.isRequired,
};
