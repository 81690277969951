import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { DialogContent } from '@material-ui/core';
import AssignmentIcon from '@material-ui/icons/Assignment';

import AmberOutlinedButton from '../../buttons/AmberOutlinedButton';
import AmberDialog from '../AmberDialog';
import AmberDialogActions from '../AmberDialogActions';
import TabularContent from './tabularContents';
import DetailsTabPanelContents from './detailsTabPanelContents';
import HistoryNotesTabPanelContents from './historyNotesTabPanelContents';
import CircularIndeterminate from '../../loading/indeterminateSpinner';
import QualityCheckContents from './qualityCheckPanel';

import { UPDATE_JOB_DETAILS_DIALOG } from '../../../sagas/constants';

const getTabPanels = (job, translate) => {
	const { historyLog, notes, client, displayFileName, ...rest } = job.data;
	return [
		<DetailsTabPanelContents jobDetails={rest} translate={translate} />,
		<HistoryNotesTabPanelContents
			history={historyLog}
			notes={notes}
			client={client}
			translate={translate}
		/>,
		<QualityCheckContents translate={translate} jobId={job.data.jobId} />,
	];
};

const useStyles = makeStyles((theme) => ({
	dialogContents: {
		padding: '1em',
	},
	buttonRed: {
		color: theme.palette.custom.pinkishRed,
		border: `2px solid ${theme.palette.custom.pinkishRed}`,
		'&:hover': {
			border: `1px solid ${theme.palette.custom.pinkishRed}`,
			backgroundColor: 'rgba(50, 150, 120, 0.04)',
		},
	},
	dialogActions: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	buttonsRight: {
		display: 'flex',
		flexDirection: 'row',
	},
	assignmentIcon: {
		color: theme.palette.custom.mango,
	},
	circularIndeterminate: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		margin: '-1.5em 0 0 -1.5em',
	},
}));
function JobItemDialog(props) {
	const classes = useStyles();
	const { job, translate } = props;

	const getTabs = useCallback(() => [
		{ label: translate('EDITOR.jobItemDialog.detailsTab'), icon: null },
		{
			label: translate('EDITOR.jobItemDialog.historyNotesTab'),
			icon: <AssignmentIcon classes={{ root: classes.assignmentIcon }} />,
		},
		{ label: translate('EDITOR.jobItemDialog.qualityCheckTab'), icon: null },
	]);

	const handleJobDetailsDialogClose = () => {
		props.dispatch({
			type: UPDATE_JOB_DETAILS_DIALOG,
			isDetailsDialogOpen: false,
		});
	};

	if (!job.data) {
		return null;
	}
	return (
		<AmberDialog
			open={job.isDetailsDialogOpen}
			title={translate('EDITOR.jobItemDialog.title')}
			fullWidth
			handleClose={handleJobDetailsDialogClose}
			maxWidth="sm"
		>
			<DialogContent>
				{job.loading && (
					<CircularIndeterminate
						size="3.6rem"
						thickness={3.6}
						className={classes.circularIndeterminate}
					/>
				)}
				{!job.loading && (
					<TabularContent
						tabs={getTabs(translate)}
						tabPanelContents={getTabPanels(job, translate)}
					/>
				)}
			</DialogContent>
			<AmberDialogActions classes={{ root: classes.dialogActions }}>
				<div className={classes.buttonsRight}>
					<AmberOutlinedButton
						text={translate('MISC.closeDialogBtn')}
						onClick={handleJobDetailsDialogClose}
					/>
				</div>
			</AmberDialogActions>
		</AmberDialog>
	);
}
JobItemDialog.propTypes = {
	job: PropTypes.shape({
		isDetailsDialogOpen: PropTypes.bool,
		loading: PropTypes.bool,
		data: PropTypes.shape({
			_id: PropTypes.string,
			displayFileName: PropTypes.string,
			transcriptionType: PropTypes.string,
			qualityControlDeadline: PropTypes.oneOfType([
				PropTypes.string,
				PropTypes.number,
			]),
			nrAudioSeconds: PropTypes.number,
			language: PropTypes.string,
			numberOfSpeakers: PropTypes.number,
			workflowStyle: PropTypes.string,
			jobOptions: PropTypes.shape({
				transcriptionStyle: PropTypes.string,
			}),
		}),
	}),
	translate: PropTypes.func.isRequired,
};

JobItemDialog.defaultProps = {
	shouldDialogLoad: true,
	job: {
		isDetailsDialogOpen: false,
		loading: true,
		data: {
			jobOptions: {},
		},
	},
	media: {},
	DialogActions: null,
	extraTabs: [],
	extraTabPanels: [],
	qualityCheck: {},
};

const mapStateToProps = ({ job }) => ({
	job,
});

export default connect(mapStateToProps)(JobItemDialog);
