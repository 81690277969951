import React, { Component } from 'react'
import { withTranslate } from 'react-redux-multilingual'
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    Input,
    InputLabel,
    Slide,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles';

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

const styles = theme => ({
    button: {
        fontSize: 18,
    },
});

class EditSpeaker extends Component {

    state = {
        id: '',
        name: '',
    }

    handleChange = (event) => {
        const target = event.target;
        const name = target.name;
        this.setState({ [name]: target.value });
    }

    handleSubmit = (event) => {
        event.preventDefault();

        const data = {
            id: this.state.id,
            name: this.state.name
        };

        this.props.onSubmit(data);
        this.props.onClose();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.speaker !== null) {
            const { id, name } = nextProps.speaker;
            this.setState({ id, name });
        } else {
            this.setState({
                id: '',
                name: ''
            });
        }
    }

    render() {
        const state = this.state;
        const { translate, classes, open, onClose, speaker } = this.props;
        const isAddingNew = !!!speaker;

        return (
            <Dialog
                className={classes.root}
                open={open}
                onClose={onClose}
                transition={Transition}
                maxWidth="xs"
                aria-labelledby="EditSpeaker-dialog-title"
            >
                <form onSubmit={this.handleSubmit}>
                    <DialogTitle id="EditSpeaker-dialog-title">
                        {isAddingNew ? translate('EDITOR.speakerAddTitle') : translate('EDITOR.speakerEditTitle')}
                    </DialogTitle>
                    <DialogContent>
                        {/*<FormControl fullWidth>
                            <InputLabel htmlFor="id-input">ID</InputLabel>
                            <Input
                                id="id-input"
                                type="text"
                                name="id"
                                value={state.id}
                                readOnly
                            />
                        </FormControl>*/}
                        <FormControl fullWidth>
                            <InputLabel htmlFor="name-input">Name</InputLabel>
                            <Input
                                id="name-input"
                                type="name"
                                name="name"
                                autoFocus
                                required
                                value={state.name}
                                onChange={this.handleChange}
                                onFocus={function (e) {
                                    var val = e.target.value;
                                    e.target.value = '';
                                    e.target.value = val;
                                }}
                            />
                        </FormControl>
                        <Button
                            fullWidth
                            className={classes.button}
                            color="primary"
                            variant="contained"
                            type="submit"
                            disabled={!state.name}
                        >
                            {!!isAddingNew ? 'Add' : 'Save'}
                        </Button>
                    </DialogContent>
                </form>
            </Dialog>
        );
    }
}

export default withStyles(styles)(withTranslate(EditSpeaker));
