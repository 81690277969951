import React, { useCallback } from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { DialogContent } from '@material-ui/core';

import AmberOutlinedButton from '../../buttons/AmberOutlinedButton';
import AmberLoadingButton from '../../buttons/AmberLoadingButton';

import AmberDialog from '../AmberDialog';
import AmberDialogActions from '../AmberDialogActions';
import {
  UPDATE_CAPTION_JOB_SUBMIT_DIALOG,
  SUBMIT_CAPTION_JOB,
  SET_JOB_MESSAGE
} from '../../../sagas/constants';
import { throttle } from 'lodash';

const useStyles = makeStyles((theme) => ({
  dialogActions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  actionButtons: {
    display: 'flex',
    flexDirection: 'row',
  },
  errorMessage: {
    color: theme.palette.error.main,
    paddingLeft: '24px',
    paddingBottom: '24px'
  },
}));

function JobCaptionSubmitDialog(props) {
  const {
    job,
    translate
  } = props;

  const classes = useStyles();

  const handleDialogClose = () => {
    props.dispatch({ type: UPDATE_CAPTION_JOB_SUBMIT_DIALOG, isCaptionSubmitDialogOpen: false, message: null });
  }

  const handleSubmit = () => {
    try {
      props.dispatch({ type: SUBMIT_CAPTION_JOB, jobId: job.data.jobId });
    } catch (error) {
      props.dispatch({ type: SET_JOB_MESSAGE, message: error.message });
    }
  };

  const handleSubmitThrottled = useCallback(
    throttle(handleSubmit, 5000)
  , []);
  
  return (
      <AmberDialog
        open={job.isCaptionSubmitDialogOpen}
        title={translate('EDITOR.dialogCaptionJobSubmit.title')}
        fullWidth
        maxWidth="sm"
        handleClose={handleDialogClose}
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogContent>
          {translate('EDITOR.dialogCaptionJobSubmit.informOfsIssues')}
          <br/>
          <br/>
          {translate('EDITOR.dialogCaptionJobSubmit.afterSubmitting')}
        </DialogContent>

        <AmberDialogActions classes={{ root: classes.dialogActions }}>
          
          <AmberOutlinedButton
            text={translate('MISC.closeDialogBtn')}
            onClick={handleDialogClose}
          />
          <div className={classes.actionButtons}>
            <AmberLoadingButton
              text={translate('EDITOR.dialogCaptionJobSubmit.btnSubmitJob')}
              onClick={handleSubmitThrottled}
              loading={job.loading}
              disabled={job.loading}
            />
          </div>
          
        </AmberDialogActions>

        {job.message && <div className={classes.errorMessage}>{job.message}</div>}

      </AmberDialog>
  );
}

JobCaptionSubmitDialog.propTypes = {
  translate: PropTypes.func.isRequired,
};

const mapStateToProps = ({ job }) => ({
  job
});

export default connect(mapStateToProps)(JobCaptionSubmitDialog);