import {
    GET_AUDIO,
    GET_AUDIO_FAIL,
    GET_AUDIO_OK,
    SET_AUDIO_PAUSE,
    SET_AUDIO_PLAY,
    SET_AUDIO_SPEED,
    SET_AUDIO_TIME, SET_AUDIO_TOGGLE,
    RESIZE_VIDEO
} from "../sagas/constants";


const initialState = {
    data: null,

    loading: false,
    loaded: false,

    error: false,
    errorMessage: null,
    playing: false,

    currentTime: 0,
    speed: 1,
    isTimeChangedOnSeek: false,
    expanded: true
};

export default function reducer(state=initialState, action) {
    switch(action.type) {

        case GET_AUDIO: {
            return {...state, loading: true, error: false}
        }
        case GET_AUDIO_FAIL: {
            return {
                ...state,
                loading: false,
                error: true,
                errorMessage: action.payload
            }
        }
        case GET_AUDIO_OK: {
            return {
                ...state,
                loading: false,
                loaded: true,
                error: false,
                data: action.payload
            }
        }
        case SET_AUDIO_TOGGLE: {
            return {
                ...state,
                playing: !state.playing
            }
        }
        case SET_AUDIO_PLAY: {
            return {
                ...state,
                playing: true
            }
        }
        case SET_AUDIO_PAUSE: {
            return {
                ...state,
                playing: false
            }
        }

        case SET_AUDIO_TIME: {
            // coming from audio-wave
            return {
                ...state,
                currentTime: action.currentTime,
                isTimeChangedOnSeek: action.onSeek
            }
        }
        case SET_AUDIO_SPEED: {
            // coming from speed-slider
            return {
                ...state,
                speed: action.speed
            }
        }
        case RESIZE_VIDEO: {
            return {
                ...state,
                expanded: action.expanded
            }
        }
        default: {
            return state;
        }
    }
}
