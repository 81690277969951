import { Modifier } from "@amberscript/amberscript-draftjs/lib/Draft";
import { List } from "immutable";
import TranscriptWord from "../models/TranscriptWord";
import { getWordsBefore, getWordsAfter } from "./wordsUtils";
import uuidV4 from "uuid/v4";

const numberOfSpaces = text => {
  const spaces = text.match(/\s/gi);
  return spaces != null ? spaces.length : 0;
};

const findReplaceText = (content, text, selection) => {
  const key = selection.getStartKey();

  const startOffset = selection.getStartOffset();
  const endOffset = selection.getEndOffset();

  const block = content.getBlockForKey(key);

  const blockText = block.getText();

  const blockWords = block.getIn(["data", "words"]);

  const startOffsetWithoutSpaces =
    startOffset - numberOfSpaces(blockText.slice(0, startOffset));
  const endOffsetWithoutSpaces =
    endOffset - numberOfSpaces(blockText.slice(0, endOffset));

  let words = List();

  // get the words before and after the selection
  const wordsBefore = getWordsBefore(blockWords, startOffsetWithoutSpaces);
  const wordsAfter = getWordsAfter(blockWords, endOffsetWithoutSpaces);
  const wordsBetween = getWordsBetween(
    blockWords,
    startOffsetWithoutSpaces,
    endOffsetWithoutSpaces
  ).toArray();

  const replaceWords = text.split(" ");

  if (replaceWords.length > wordsBetween.length) {
    const lastEnd = wordsBetween[wordsBetween.length - 1].end;
    for (let index = 0; index < replaceWords.length; index++) {
      words = words.push(
        TranscriptWord({
          id: uuidV4(),
          text: replaceWords[index],
          start: wordsBetween[index] ? wordsBetween[index].start : lastEnd,
          end: wordsBetween[index] ? wordsBetween[index].end : lastEnd,
          pristine: false,
          conf: null
        })
      );
    }
  } else if (replaceWords.length === wordsBetween.length) {
    for (let index = 0; index < wordsBetween.length; index++) {
      words = words.push(
        TranscriptWord({
          id: uuidV4(),
          text: replaceWords[index],
          start: wordsBetween[index].start,
          end: wordsBetween[index].end,
          pristine: false,
          conf: null
        })
      );
    }
  } else {
    for (let index = 0; index < replaceWords.length - 1; index++) {
      words = words.push(
        TranscriptWord({
          id: uuidV4(),
          text: replaceWords[index],
          start: wordsBetween[index].start,
          end: wordsBetween[index].end,
          pristine: false,
          conf: null
        })
      );
    }
    words = words.push(
      TranscriptWord({
        id: uuidV4(),
        text: replaceWords[replaceWords.length - 1],
        start: wordsBetween[replaceWords.length - 1].start,
        end: wordsBetween[wordsBetween.length - 1].end,
        pristine: false,
        conf: null
      })
    );
  }

  words = wordsBefore.concat(words).concat(wordsAfter);

  content = Modifier.replaceText(content, selection, text);

  content = Modifier.mergeBlockData(content, selection, {
    words: words
  });

  return content;
};

const getWordsBetween = (blockWords, startOffset, endOffset) => {
  return blockWords.reduce(
    (acc, word) => {
      let { i } = acc;
      const text = word.get("text");
      let newWords = acc.words;

      if (i >= startOffset && i < endOffset) {
        newWords = newWords.push(word);
      }

      i += text.length;
      return {
        words: newWords,
        i
      };
    },
    {
      words: List(),
      i: 0
    }
  ).words;
};

export default findReplaceText;