import {combineReducers} from 'redux'
import {reducer as formReducer} from 'redux-form'
import {IntlReducer as Intl} from 'react-redux-multilingual'
import auth from './authReducer'
import account from './accountReducer'
import analytics from './analyticsReducer'
import asrModels from './asrModelsReducer'
import transcriptList from './transcriptListReducer'
import transcriptAudio from './transcriptAudioReducer'
import transactionBalance from './transactionBalanceReducer'
import transcriptStatus from './transcriptStatusReducer'
import transcript from './transcriptReducer'
import {modal, snackbar} from './modalReducer'
import { findText } from './findTextReducer'
import {referralInvitation, referralCredit, referralDetails} from './referralReducer'
import uiSettings from './uiSettingsReducer'
import job from './jobReducer'
import qualityCheck from './qualityCheckReducer'

import {AUTH_LOGOUT} from '../sagas/constants'

const rootReducer = combineReducers({
    auth,
    account,
    analytics,
    asrModels,
    transcriptList,
    transactionBalance,
    transcriptStatus,
    transcriptAudio,
    transcript,
    Intl,
    form: formReducer,
    modal,
    snackbar,
    referralInvitation,
    referralCredit,
    referralDetails,
    uiSettings,
    job,
    qualityCheck,
    findText,
});


export default (state, action) => {
    if (action.type === AUTH_LOGOUT) {
        state = undefined;
    }
    return rootReducer(state, action)
};

