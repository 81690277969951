import moment from "moment";

/**
 * Get date
 *
 * @see  https://stackoverflow.com/a/3605248/1938970
 * @param  {Number} timestamp
 * @return {String}
 */
 export function getDate(timestamp) {
  const d = new Date(timestamp);

  if (d && d instanceof Date && !isNaN(d.getTime())) {
      return ('0' + d.getDate()).slice(-2) + '-'
          + ('0' + (d.getMonth() + 1)).slice(-2) + '-'
          + d.getFullYear();
  }
  return '';
}

/**
* Get time
*
* @param  {Number} timestamp
* @return {String}
*/
export function getTime(timestamp) {
  try {
      const d = new Date(timestamp);
      return d.toISOString().substr(11, 8);
  } catch (e) {
      return '';
  }
}

/**
* Get time from seconds
*
* @param  {Number} seconds
* @return {String}
*/
export function getTimeFromSec(seconds) {
  return getTime(seconds * 1000);
}

/**
* Seconds to hours, minutes and seconds
* @link(http://stackoverflow.com/a/6313008/1938970, source)
* @param  {number}
* @return {string}
*/
export function toHHMMSS (givenSeconds) {
const secondsNumber = parseInt(givenSeconds, 10);
let hours = Math.floor(secondsNumber / 3600);
let minutes = Math.floor((secondsNumber - (hours * 3600)) / 60);
let seconds = secondsNumber - (hours * 3600) - (minutes * 60);

if (hours   < 10) { hours   = '0' + hours; }
if (minutes < 10) { minutes = '0' + minutes; }
if (seconds < 10) { seconds = '0' + seconds; }

return hours + ':' + minutes + ':' + seconds;
}

/**
* Seconds to hours, minutes and seconds
* @link(http://stackoverflow.com/a/6313008/1938970, source)
* @param  {number}
* @return {string}
*/
export function toMMSS (givenSeconds) {
const secondsNumber = parseInt(givenSeconds, 10);
let hours = Math.floor(secondsNumber / 3600);
let minutes = Math.floor((secondsNumber - (hours * 3600)) / 60);
let seconds = secondsNumber - (hours * 3600) - (minutes * 60);

if (hours   < 10) { hours   = '0' + hours; }
if (minutes < 10) { minutes = '0' + minutes; }
if (seconds < 10) { seconds = '0' + seconds; }

return minutes + ':' + seconds;
}

/**
* seconds to hours, minutes seconds and milliseconds
* @param {*} givenSeconds 
*/
export function toHHMMSSMS(givenSeconds, msDelimiter) {
  let negative = false;
  if (givenSeconds < 0) {
    givenSeconds = givenSeconds * -1;
    negative = true;
  }
  const secondsNumber = parseInt(givenSeconds, 10);
  let hours = Math.floor(secondsNumber / 3600);
  let minutes = Math.floor((secondsNumber - (hours * 3600)) / 60);
  let seconds = secondsNumber - (hours * 3600) - (minutes * 60);
  let milliSeconds = Math.round((givenSeconds % 1) * 1000);

  if (hours < 10) {
    hours = '0' + hours;
  }
  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  if (seconds < 10) {
    seconds = '0' + seconds;
  } 
  if (!milliSeconds) {
    milliSeconds = '000';
  } else if (milliSeconds < 10) {
    milliSeconds = '00' + milliSeconds;
  } else if (milliSeconds < 100) {
    milliSeconds = '0' + milliSeconds;
  }

  if (!givenSeconds) {
    return `00:00:00${msDelimiter}000`;
  }

  return (negative ? '-' : '') + hours + ':' + minutes + ':' + seconds + msDelimiter + milliSeconds;
}

export const secToMs = (seconds) => {
  return Math.ceil(seconds * 1000);
}
 
/**
 * Timestamps to DD-MM-YYYY
 * @param  {number}
 * @return {string}
 */
 export function convertSecondsToDDMMYYYY(timestamps) {
  if (Number.isInteger(timestamps)) {
    return moment(new Date(timestamps)).format('DD-MM-YYYY');
  }
  return '';
}