import { secToMs, toHHMMSSMS } from './timeUtils'
import TranscriptSegment from '../models/TranscriptSegment'
import Transcript from '../models/Transcript'
import Immutable from 'immutable'
import uuidV4 from 'uuid/v4'

const MAX_CHARS_PER_SUBTITLE = 84;
export const MAX_CHARS_PER_LINE = MAX_CHARS_PER_SUBTITLE / 2;
const CHECK_WORDS_AFTER_PUNCTUATION = 2;

const wordsDuration = (words) => {
	let start = words[0].start;
	let end = words[words.length - 1].end;
	return secToMs(end - start);
}

const punctuationMarks = ['.', ',', '!', '?', ':', ';'];

function ifEndOfSentence (wordsArray, minWordsInSentence) {
	let lastWordInSentenceIndex = -1;
	if (minWordsInSentence === 0 || wordsArray.length - minWordsInSentence < 2)
		return lastWordInSentenceIndex;

	for (let i = wordsArray.length - 2; i >= wordsArray.length - minWordsInSentence - 2; i--) {
		let wordText = wordsArray[i].text.trim();
		if (wordText.length === 0)
			continue;
		let lastChar = wordText.charAt(wordText.length - 1);
		if (punctuationMarks.includes(lastChar))
			return i;
	}
	return lastWordInSentenceIndex;
}

const convertSegments = (segments, durationMax, createNewEntityCallback) => {
	let subtitleSegments = [];
	let speaker;
	let currentWords = [];

	segments.forEach(segment => {
		//if the letfover words of the last line HAS NOT the same speaker as current one
		//we make a new segments, only for that leftover words.
		if (speaker && speaker !== segment.speaker) {
			if (currentWords.length > 0) {
				subtitleSegments.push(createNewEntityCallback(currentWords, speaker));
				currentWords = [];
			}
		}
		speaker = segment.speaker;
		segment.words.forEach((word) => {
			const nextCurrentWords = [...currentWords, word];
			const currentDuration = wordsDuration(nextCurrentWords);
			if ((nextCurrentWords.map(w => w.text).join(" ")).length <= MAX_CHARS_PER_SUBTITLE
							&& currentDuration <= durationMax) {
				currentWords.push(word);
			} else {
				if (currentWords.length > 0) {
					const lastWordInSentenceWithPunctuation = ifEndOfSentence(currentWords, CHECK_WORDS_AFTER_PUNCTUATION);
					//we found words after a punctuation
					if (lastWordInSentenceWithPunctuation > -1) {
						let temp = currentWords;
						//let's make a frame with these words
						subtitleSegments.push(createNewEntityCallback(temp.slice(0, lastWordInSentenceWithPunctuation + 1), speaker));
						currentWords = [];
						//we save the leftover words for another frame
						for(let xyz of temp.slice(lastWordInSentenceWithPunctuation + 1)) {
							currentWords.push(xyz);
						}
						currentWords.push(word);
					}
					//we didn't find enough words after a punctuation
					else {
						//make a frame
						subtitleSegments.push(createNewEntityCallback(currentWords, speaker));
						currentWords = [];
						//save the leftover word for another frame
						currentWords.push(word);
					}
				}
			}
		});
	});	
	
	if (currentWords.length > 0) {
		subtitleSegments.push(createNewEntityCallback(currentWords, speaker));
	}

	return subtitleSegments;
}

export class Subtitle {

	constructor(start, end, lines, speaker) {
		this.start = start;
		this.end = end;
		this.lines = lines;
		this.speaker = speaker;
	}

	getSrtSubtitle() {
		return `${this.startFormatted(',')} --> ${this.endFormatted(',')}\r\n${this.lines.join("\r\n")}`;
	}

	getVttSubtitle() {
		return `${this.startFormatted('.')} --> ${this.endFormatted('.')}\r\n${this.lines.join("\r\n")}`;
	}

	startFormatted(msDelimiter) {
		return toHHMMSSMS(this.start, msDelimiter);
	}

	endFormatted(msDelimiter) {
		return toHHMMSSMS(this.end, msDelimiter);
	}
}


export const convertSegmentsToSubtitleSegments = (transcript, durationMax) => {
	const segments = convertSegments(transcript.segments, durationMax, 
		(words, speaker) => new TranscriptSegment({id: uuidV4(), speaker, words: new Immutable.List(words)}));

		return new Transcript({
			startTimeOffset: transcript.startTimeOffset,
			isConvertedToSubtitles: true,
			speakers: transcript.speakers,
			segments: new Immutable.List(segments),
			highlights: transcript.highlights,
		});
}

export const convertSegmentsToSubtitles = (segments, durationMax) => {
	return convertSegments(segments, durationMax, createSubtitleFromWords);
}

export function createSubtitleFromWords(words, speaker) {
  const text = words.map(w => w.text).join(" ");
	const lines = [];
	if (text.length > MAX_CHARS_PER_LINE) {
		let textSplitterIndex = Math.ceil(text.length / 2);
		let isSpace = false;
		while (!isSpace && textSplitterIndex <= text.length) {
			isSpace = /\s/.test(text.charAt(textSplitterIndex));
			textSplitterIndex++;
		}
		lines.push(text.substr(0, textSplitterIndex - 1).trim());
		//add the second line if it actually has some text
		if(text.substr(textSplitterIndex - 1).trim().length > 0) {
			lines.push(text.substr(textSplitterIndex - 1).trim());
		}
	} else {
		lines.push(text.trim());
	}

  let start = words[0].start;
  let end = words[words.length - 1].end;
  return new Subtitle(start, end, lines, speaker);
}

export function createSubtitlesFromSegments(segments) {
	return segments.map(s => createSubtitleFromWords(s.words.toArray(), s.speaker));
}