export const FORMAT_WORD = "Word";
export const FORMAT_JSON = "JSON";
export const FORMAT_TEXT = "Text";
export const FORMAT_CSV = 'CSV';
export const FORMATS = [FORMAT_WORD, FORMAT_JSON, FORMAT_TEXT, FORMAT_CSV];
export const CSV_FPS = 'FPS';
export const CSV_MILLISECONDS = 'Millisecond';
export const CSV_FORMATS = [CSV_FPS, CSV_MILLISECONDS];
export const DEFAULT_FPS = '24';
export const CSV_FRAME_RATES = [
  '24',
  '25',
  '30',
  '48',
  '50',
  '60',
  '120',
  '240',
  '300',
];

export const availableOptionsByFormat = [
  { format: FORMAT_WORD, options: [1, 2, 3, 4] },
  { format: FORMAT_JSON, options: [] },
  { format: FORMAT_TEXT, options: [1, 2, 4] },
  { format: FORMAT_CSV, options: [] },
];
export const OPTIONS = [
  {
    key: 1,
    name: "includeTimestamps",
    translateKey: "optionsTimestamps",
  },
  {
    key: 2,
    name: "includeSpeakers",
    translateKey: "optionsSpeakers",
  },
  {
    key: 3,
    name: "includeHighlights",
    translateKey: "optionsIncludeHighlights",
  },
  {
    key: 4,
    name: "exportOnlyHighlights",
    translateKey: "optionsHighlights",
  },
  {
    key: 5,
    name: "includeTimestamps",
    translateKey: "optionsTimestamps",
  },
];
export const MIN_VALUE_FOR_CHARS_PER_ROW = 10;
export const MAX_VALUE_FOR_CHARS_PER_ROW = 10000000;
export const DEFAULT_VALUE_FOR_CHARS_PER_ROW = 80;
