import React, { Component } from 'react'
import { withTranslate } from 'react-redux-multilingual'
import { connect } from 'react-redux'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles';

import SubtitleSlider from '../slider/SubtitleSlider';

import SubtitlesRightPanelImage from '../../assets/images/ui/convert-to-subtitles-right-panel.png'
import SubtitlesNoteImage from '../../assets/images/ui/convert-to-subtitles-note.svg'

import './SubtitleDialog.scss'

const styles = theme => ({
  paper: {
    maxWidth: 'none',
    height: 492,
    maxHeight: '100vh',
    overflowY: 'unset',
  },
  titleRoot: {
    paddingBottom: 0,
  },
  button: {
    fontSize: 16,
  },
  actions: {
    justifyContent: 'space-between',
    margin: 20,
  },
  loadingIcon: {
    position: 'absolute',
    left: 230,
    display: 'flex'
  }
});


class SubtitleDialog extends Component {
  state = {
    subtitleLength: 3500,
    hideAdvancedOptions: true
  }

  onSliderChange = (value) => {
    this.setState({ subtitleLength: value });
  }

  showAdvancedOptions = () => this.setState({ hideAdvancedOptions: false })

  componentDidUpdate(prevProps, prevState) {

    if (prevProps.isConverting && !this.props.isConverting) {
      this.props.onClose();
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { onConvertToSubtitles } = this.props;
    const { subtitleLength } = this.state;
    onConvertToSubtitles(subtitleLength);
  }


  render() {
    const { open, onClose, translate, classes, isConverting } = this.props;
    const { hideAdvancedOptions } = this.state;
    const submitText = isConverting ? 'subtitlesDialogSubmitLoading' : 'subtitlesDialogSubmit';

    return (
      <Dialog
        open={open}
        onClose={onClose}
        classes={{ paper: classes.paper }}
        aria-labelledby="Subtitle-dialog"
      >
        <div className="SubtitleDialog">
          <form onSubmit={this.handleSubmit} className="SubtitleDialog__form">
            <DialogTitle classes={{ root: classes.titleRoot }}>
              {translate('EDITOR.subtitlesDialogHeader')}
            </DialogTitle>
            <DialogContent>
              <p className="description">{translate('EDITOR.subtitlesDialogDescription')}</p>
              <p className="note">{translate('EDITOR.subtitlesDialogNote')}</p>
              <img src={SubtitlesNoteImage} className="noteImage" alt="" />
              {hideAdvancedOptions &&
                <div className="advancedButton"
                  onClick={this.showAdvancedOptions}>
                  {translate('EDITOR.subtitlesDialogAdvancedOptions')}
                </div>}
              {!hideAdvancedOptions &&
                <div className="slider">
                  <SubtitleSlider
                    onSliderChange={this.onSliderChange}
                    defaultValue={this.state.subtitleLength}
                    sliderStyle={{ width: 300, margin: 'auto' }} />
                </div>}
            </DialogContent>
            <DialogActions className={classes.actions}>
              <Button
                className={classes.button}
                fullWidth
                variant="contained"
                disabled={isConverting}
                onClick={onClose}
              >{translate('EDITOR.subtitlesDialogCancel')}</Button>
              <Button
                className={classes.button}
                fullWidth
                color="primary"
                variant="contained"
                disabled={isConverting}
                type="submit"
              >{translate(`EDITOR.${submitText}`)}</Button>
              {isConverting &&
                <div className={classes.loadingIcon}>
                  <CircularProgress
                    color='primary'
                    size={24}
                  />
                </div>}
            </DialogActions>
          </form>
          <img src={SubtitlesRightPanelImage} className="SubtitleDialog__rightPanel" alt="" />
        </div>
      </Dialog>
    );
  }
}

const mapStateToProps = ({ transcript }) => ({
  isConverting: transcript.isConvertingToSubtitles,
});

export default connect(mapStateToProps)(withStyles(styles)(withTranslate(SubtitleDialog)));