import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Box, makeStyles, Tab, Tabs } from "@material-ui/core";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    minWidth: "10px",
  },
  tabs: {
    borderLeft: `1px solid ${theme.palette.divider}`,
    "& .MuiTabs-indicator": {
      display: "none",
    },
  },
  tab: {
    backgroundColor: theme.background.appBar,
    minWidth: "unset",
    padding: "0px !important",
    borderBottom: `1px solid ${theme.palette.divider}`,
    "& svg": {
      fill: theme.palette.custom.purplishGray,
    },
  },
  selected: {
    backgroundColor: theme.palette.primary.main,
    "& svg": {
      fill: "white",
    },
  },
}));

export default function AmberVerticalTabs(props) {
  const { tabHeadings, tabPanels, openTabValue, resetOpenTabValue } = props;

  const classes = useStyles();
  const [value, setValue] = React.useState(false);

  const handleChange = (event, newValue) => {
    if (newValue === value) {
      setValue(false);
    } else {
      setValue(newValue);
    }
    // Reset the open tab value to null
    resetOpenTabValue();
  };

  // Opens a tab given by the editor
  const handleTabOpeningFromEditor = () => {
    if (openTabValue && value !== openTabValue) {
      setValue(openTabValue);
    }
  };

  useEffect(() => {
    handleTabOpeningFromEditor();
  }, [openTabValue]);

  return (
    <>
      <Box>
        {tabPanels &&
          value !== -1 &&
          tabPanels.map((panel, index) => (
            <TabPanel
              value={value}
              index={index}
              data-testid={panel.key}
              key={panel.key}
            >
              <Box display="flex" width={{ md: 225, lg: 275, xl: 400 }}>
                {panel.component}
              </Box>
            </TabPanel>
          ))}
      </Box>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        className={classes.tabs}
      >
        {tabHeadings &&
          tabHeadings.map((heading) => (
            <Tab
              key={heading.key}
              label={heading.label}
              className={classes.tab}
              classes={{ selected: classes.selected }}
            />
          ))}
      </Tabs>
    </>
  );
}

AmberVerticalTabs.propTypes = {
  tabHeadings: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.node.isRequired || PropTypes.string.isRequired,
    })
  ).isRequired,
  tabPanels: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      component: PropTypes.node.isRequired || PropTypes.string.isRequired,
    })
  ).isRequired,
  openTabValue: PropTypes.number,
  resetOpenTabValue: PropTypes.func,
};

AmberVerticalTabs.defaultProps = {
  openTabValue: null,
  resetOpenTabValue: () => {},
};
