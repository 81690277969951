import axios from 'axios';
import { exportJSON, exportWordDoc, exportToTxtFile } from '../../../helpers/exportTranscript'
import { exportSrt, exportVtt } from '../../../helpers/exportSrtVtt'
import exportStl from '../../../helpers/exportStl'
import { apiClient } from "../../../apiClient";
import { WORD_EXPORT_ENDPOINT, STORAGE_API_KEY, DEBUG } from "../../../settings";
import { MAX_CHARS_PER_LINE } from '../../../helpers/subtitleUtil';
import { convertToJSON } from "../../../helpers/exportTranscript";
import { saveAs } from 'file-saver';
import { CSV_FPS } from './exportText/constants';
import { FORMAT_AVID, FORMAT_AVID_EXTENSION, FORMAT_PREMIERE, FORMAT_PREMIERE_EXTENSION } from './exportSubtitles/constants';

/**
 * Returns the export file name
 * @param {string} title 
 * @param {string} ext 
 * @returns {string}
 */
export function getExportFilename(title, ext) {
  let extension = ext;
  if (ext === FORMAT_AVID) {
    extension = FORMAT_AVID_EXTENSION;
  }
  else if (ext === FORMAT_PREMIERE) {
    extension = FORMAT_PREMIERE_EXTENSION;
  }
  return `${title.replace('.mp3', '').replace('.wav', '').replace('.m4a', '')}--edited.${extension}`;
}

// ********* JSON ********* \\

// Export the transcript as DOC, use the FE version if BE threw error
export function exportAsWord(transcript, transcriptStatus, options, footerText) {
    const transcriptJsonFormat = convertToJSON(transcript, options, false);
    const { includeTimestamps, includeSpeakers, includeHighlights, languageCode, filename } = options;
    axios.post(WORD_EXPORT_ENDPOINT, transcriptJsonFormat, {
        params: {
            apiKey: localStorage.getItem(STORAGE_API_KEY),
            recordId: transcriptStatus.data.recordId,
            includeTimestamps,
            includeSpeakers,
            includeHighlights,
            // highlightsOnly,
            footerText,
            languageCode,
            filename,
        },
        responseType: 'arraybuffer',
    }).then(res => {
        const blob = new Blob([res.data], { type: "application/octet-stream" });
        saveAs(blob, getExportFilename(transcriptStatus.data.audioDisplayFileName, 'docx'));
    }).catch(err => {
        let errorMessage = 'Error in exportAsWord';
        if (err.response && err.response.data) {
            if (err.response.data.constructor === ArrayBuffer) {
                const decoder = new TextDecoder();
                const dataDecoded = JSON.parse(decoder.decode(err.response.data));
                errorMessage = dataDecoded.message;
            } else {
                errorMessage = err.response.data.message
            }
        }
        apiClient.post('senderroremail', `Error in exportAsWord, recordId: ${transcriptStatus.data.recordId}, errorMessage: ${errorMessage}`, {
            params: {
                errorType: 'exportAsWord',
            }
        });
        if (DEBUG) {
            console.log("Error in exportAsWord function: ", `recordId: ${transcriptStatus.data.recordId}, errorMessage: ${errorMessage}`);
        }
        exportWordDoc(transcript, transcriptStatus, options);
    });
}

// ********* TEXT ********* \\

// Export the transcript as txt, use the FE version if BE threw error
export function exportAsTxt(transcript, transcriptStatus, options, maxCharsPerRow) {
    const { includeTimestamps, includeSpeakers, highlightsOnly } = options;
    const URL = `/export/${transcriptStatus.data.job.id}/txt`;

    apiClient.get(URL, {
        params: {
            recordId: transcriptStatus.data.recordId,
            maxCharsPerRow: (maxCharsPerRow.active) ? maxCharsPerRow.value : undefined,
            includeTimestamps,
            includeSpeakers,
            highlightsOnly,
        }
    }).then(res => {
        const blob = new Blob([res.data], { type: "text/html;charset=utf-8" });
        saveAs(blob, getExportFilename(transcriptStatus.data.audioDisplayFileName, 'txt'));
    }).catch(err => {
        let errorMessage = 'Error in exportAsTxt';
        if (err.response && err.response.data) {
            if (err.response.data.constructor === ArrayBuffer) {
                const decoder = new TextDecoder();
                const dataDecoded = JSON.parse(decoder.decode(err.response.data));
                errorMessage = dataDecoded.message;
            } else {
                errorMessage = err.response.data.message
            }
        }
        apiClient.post('senderroremail', `Error in exportAsTxt, recordId: ${transcriptStatus.data.recordId}, errorMessage: ${errorMessage}`, {
            params: {
                errorType: 'subtitleScriptError',
            }
        });
        if (DEBUG) {
            console.log("Error in exportAsTxt function: ", `recordId: ${transcriptStatus.data.recordId}, errorMessage: ${errorMessage}`);
        }
        exportToTxtFile(transcript, transcriptStatus, options);
    });
}

// ********* JSON ********* \\

// Export the transcript as json, use the FE version if BE threw error
export function exportAsJson(transcript, transcriptStatus, options, isTranscription) {
    const { includeTimestamps, includeSpeakers, highlightsOnly } = options;
    const URL = `/export/${transcriptStatus.data.job.id}/json`;

    apiClient.get(URL, {
        params: {
            recordId: transcriptStatus.data.recordId,
            maxCharsPerRow: MAX_CHARS_PER_LINE,
            includeTimestamps,
            includeSpeakers,
            highlightsOnly,
        }
    }).then(res => {
        const blob = new Blob([JSON.stringify(res.data, null, 4)], { type: "text/html;charset=utf-8" });
        saveAs(blob, getExportFilename(transcriptStatus.data.audioDisplayFileName, 'json'));
    }).catch(err => {
        let errorMessage = 'Error in exportAsJson';
        if (err.response && err.response.data) {
            if (err.response.data.constructor === ArrayBuffer) {
                const decoder = new TextDecoder();
                const dataDecoded = JSON.parse(decoder.decode(err.response.data));
                errorMessage = dataDecoded.message;
            } else {
                errorMessage = err.response.data.message
            }
        }
        apiClient.post('senderroremail', `Error in exportAsJson, recordId: ${transcriptStatus.data.recordId}, errorMessage: ${errorMessage}`, {
            params: {
                errorType: 'subtitleScriptError',
            }
        });
        if (DEBUG) {
            console.log("Error in exportAsJson function: ", `recordId: ${transcriptStatus.data.recordId}, errorMessage: ${errorMessage}`);
        }
        exportJSON(transcript, transcriptStatus, options);
    });
}

// ********* CSV ********* \\

export function exportAsCsv(transcriptStatus, options, maxCharsPerRow) {
    const { frameRate, timeFormat } = options;
    const URL = `/export/${transcriptStatus.data.job.id}/csv`;
  
    const params = {
      timeFormat: timeFormat.toLowerCase(),
    };
  
    if (timeFormat === CSV_FPS) {
      params.frameRate = frameRate;
    }
  
    if (maxCharsPerRow.active) {
      params.maxCharsPerRow = maxCharsPerRow.value;
    }
  
    apiClient
      .get(URL, {
        params,
      })
      .then((res) => {
        const blob = new Blob([res.data], { type: 'text/html;charset=utf-8' });
        saveAs(
          blob,
          getExportFilename(transcriptStatus.data.audioDisplayFileName, 'csv'),
        );
      })
      .catch((err) => {
        let errorMessage = 'Error in exportAsCsv';
        if (err.response && err.response.data) {
          if (err.response.data.constructor === ArrayBuffer) {
            const decoder = new TextDecoder();
            const dataDecoded = JSON.parse(decoder.decode(err.response.data));
            errorMessage = dataDecoded.message;
          } else {
            errorMessage = err.response.data.message;
          }
        }
        apiClient.post(
          'senderroremail',
          `Error in exportAsCsv, recordId: ${transcriptStatus.data.recordId}, errorMessage: ${errorMessage}`,
          {
            params: {
              errorType: 'subtitleScriptError',
            },
          },
        );
      });
  }

// ********* SUBTITLE SUBTITLES ********* \\

export function exportSubtitles(
    transcript,
    transcriptStatus,
    options,
    isTranscription,
    recordIdOrJobId,
    isTranscriber,
    subtitleFormat,
  ) {
    const { subtitleDurationMax, maxNumberOfRows, maxCharsPerRow } = options;
    const URL = `/export/${transcriptStatus.data.job.id}/${subtitleFormat.toLowerCase()}`;
  
    return apiClient
      .get(URL, {
        params: {
          maxCharsPerRow,
          maxNumberOfRows,
          maxScreenTimePerRowSeconds: subtitleDurationMax,
        },
      })
      .then((res) => {
        if (subtitleFormat === 'stl') {
            window.open(res.data, '_self');
        }else{
          // console.log('res', res);
          const blob = new Blob([res.data], { type: 'text/html;charset=utf-8' });
          // console.log('blob', blob);

          const fileName =
          isTranscriber && !isTranscription
          ? recordIdOrJobId
          : transcriptStatus.data.audioDisplayFileName;

          const exportFileName = getExportFilename(fileName, subtitleFormat);
          // console.log('exportFileName', exportFileName);
          saveAs(blob, exportFileName);
          // console.log('called!!!!!!');
        }
      })
      .catch((err) => {
        let errorMessage = 'Error in exportAsSrt';
        if (err.response && err.response.data) {
          if (err.response.data.constructor === ArrayBuffer) {
            const decoder = new TextDecoder();
            const dataDecoded = JSON.parse(decoder.decode(err.response.data));
            errorMessage = dataDecoded.message;
          } else {
            errorMessage = err.response.data.message;
          }
        }
        apiClient.post(
          'senderroremail',
          `Error in exportAsSrt, recordId: ${transcriptStatus.data.recordId}, errorMessage: ${errorMessage}`,
          {
            params: {
              errorType: 'subtitleScriptError',
            },
          },
        );
        if (DEBUG) {
            console.log("Error in exportSubtitles function: ", `recordId: ${transcriptStatus.data.recordId}, errorMessage: ${errorMessage}`);
        }
        if (subtitleFormat === 'srt') {
          exportSrt(
            transcript.data.segments.valueSeq().toArray(),
            transcriptStatus,
            subtitleDurationMax,
          );
        } else if (subtitleFormat === 'vtt') {
          exportVtt(
            transcript.data.segments.valueSeq().toArray(),
            transcriptStatus,
            subtitleDurationMax,
          );
        } else if (subtitleFormat === 'stl') {
          exportStl(transcript, transcriptStatus, subtitleDurationMax);
        }
      });
  }

// ********* MEDIA ********* \\
export function exportMedia(transcriptStatus) {
    const { recordId } = transcriptStatus.data;
    apiClient.get(`userrecord/${recordId}/original-media-link`).then(res => {
        window.open(res.data.downloadUrl, "_blank");
    });
}

// ********* TRACKING ********* \\
export function trackExport(format, transcriptStatus) {
    apiClient.post('analytics/trackEvent', {}, {
        params: {
            type: 'export',
            recordId: transcriptStatus.data.recordId,
            format
        }
    })
}

export async function getOriginalText(jobId) {
  const URL = `/document/transcription/${jobId}`;
  try {
      const response = await apiClient.get(URL);

      // Return the JSON data directly
      return response.data;

  } catch (error) {
      console.error('Error in getOriginalText:', error);

  }
}