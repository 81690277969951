import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslate } from "react-redux-multilingual";
import { Dialog, CircularProgress } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import ExportSubtitles from "./exportSubtitles/ExportSubtitles";
import {
  TRANSCRIPTION_TYPE_CAPTIONS,
  TRANSCRIPTION_TYPE_TRANSCRIPTION,
} from "../../../sagas/constants";
import ExportText from "./exportText/ExportText";

const styles = (theme) => ({
  dialogPaper: {
    minHeight: 200,
  },
  root: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: `#e0e0e0ee`,
    zIndex: 100,
  },
  loading: {
    color: "#005A50",
    marginBottom: 16,
  },
});

class Export extends Component {
  state = {
    step: 1,
    selectedType: null,
    showLoadingBar: true,
  };

  componentDidUpdate(prevProps, prevStates) {
    if (prevProps.open !== this.props.open && this.props.open) {
      this.props.saveTranscript();
    }

    if (prevProps.transcript.saving && this.props.transcript.saved) {
      this.setState({
        showLoadingBar: false,
      });
    }
  }

  onBack = () => {
    this.setState({
      selectedType: null,
      step: 1,
    });
  };

  setSelectedType = (selectedType) => {
    this.setState({
      selectedType,
      step: 2,
    });
  };

  closeExportDialog = () => {
    this.setState(
      {
        step: 1,
        selectedType: null,
      },
      () => {
        this.props.onClose();
      }
    );
  };

  componentDidMount() {
    if (this.props.shouldOpenBasedOnParam) {
      this.props.saveTranscript();
      this.setState({
        showLoadingBar: false,
      });
    }
  }

  render() {
    const {
      translate,
      classes,
      open,
      shouldOpenBasedOnParam,
    } = this.props;

    return (
      <Dialog
        classes={{ paper: classes.dialogPaper }}
        open={open || shouldOpenBasedOnParam}
        onClose={this.closeExportDialog}
        maxWidth="sm"
        fullWidth
        aria-labelledby="Export-dialog-title"
      >
        {/* Loading bar */}
        {this.state.showLoadingBar && (
          <div className={classes.root}>
            <CircularProgress className={classes.loading} />
            <span>{translate("EDITOR.subtitlesDialogSubmitLoading")}</span>
          </div>
        )}

        {this.props.isTranscriptionFile() && (
          <ExportText
            onClose={this.closeExportDialog}
            isTranscriptionFile={this.props.isTranscriptionFile}
            transcriptId={this.props.transcriptId}
            transcript={this.props.transcript}
            transcriptStatus={this.props.transcriptStatus}
            openJobSatisfactionDialog={this.props.openJobSatisfactionDialog}
          />
        )}
        {this.props.isCaptionFile() && (
          <ExportSubtitles
            onClose={this.closeExportDialog}
            isTranscriptionFile={this.props.isTranscriptionFile}
            transcriptId={this.props.transcriptId}
            isTranscriber={this.props.isTranscriber}
            transcript={this.props.transcript}
            transcriptStatus={this.props.transcriptStatus}
            language={this.props.language}
            openJobSatisfactionDialog={this.props.openJobSatisfactionDialog}
          />
        )}
      </Dialog>
    );
  }
}

const mapStateToProps = ({ transcript, transcriptStatus, account }) => ({
  transcript,
  transcriptStatus,
  isUserSubscriptionActive: account.isUserSubscriptionActive,
  language: account.data.language,
});

export default connect(mapStateToProps)(
  withStyles(styles)(withTranslate(Export))
);
