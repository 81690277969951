import {
    REFERRAL_SEND_INVITATION,
    REFERRAL_SEND_INVITATION_OK,
    REFERRAL_SEND_INVITATION_FAIL,

    REFERRAL_GET_CREDIT,
    REFERRAL_GET_CREDIT_OK,
    REFERRAL_GET_CREDIT_FAIL,

    REFERRAL_GET_DETAILS,
    REFERRAL_GET_DETAILS_OK,
    REFERRAL_GET_DETAILS_FAIL
} from '../sagas/constants';

const initialState = {
    data: null,

    loading: false,
    loaded: false,

    error: false,
    errorMessage: null,
};

export function referralInvitation(state = initialState, action) {
    switch (action.type) {
        case REFERRAL_SEND_INVITATION: {
            return {
                ...state,
                loading: true,
                error: false
            }
        }
        case REFERRAL_SEND_INVITATION_FAIL: {
            return {
                ...state,
                loading: false,
                error: true,
                errorMessage: action.payload
            }
        }
        case REFERRAL_SEND_INVITATION_OK: {
            return {
                ...state,
                loading: false,
                loaded: true,
                error: false,
                data: action.payload
            }
        }
        
        default: {
            return state;
        }
    }
}

export function referralCredit(state = initialState, action) {
    switch (action.type) {
        case REFERRAL_GET_CREDIT: {
            return {
                ...state,
                loading: false,
                error: true,
                errorMessage: action.payload
            }
        }        
        case REFERRAL_GET_CREDIT_FAIL: {
            return {
                ...state,
                loading: false,
                error: true,
                errorMessage: action.payload
            }
        }
        case REFERRAL_GET_CREDIT_OK: {
            return {
                ...state,
                loading: false,
                loaded: true,
                error: false,
                data: action.payload
            }
        }
        default: {
            return state;
        }
    }
}

export function referralDetails(state = initialState, action) {
    switch (action.type) {
        case REFERRAL_GET_DETAILS: {
            return {
                ...state,
                loading: false,
                error: false
            }
        }
        case REFERRAL_GET_DETAILS_FAIL: {
            return {
                ...state,
                loading: false,
                error: true,
                errorMessage: action.payload
            }
        }
        case REFERRAL_GET_DETAILS_OK: {
            return {
                ...state,
                loading: false,
                loaded: true,
                error: false,
                data: action.payload
            }
        }
        default: {
            return state;
        }
    }
}
