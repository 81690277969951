import React from "react";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";
import Title from "./title";
import Name from "./name";
import ItemTooltip from "./itemTooltip";

export default function ItemsPanel(props) {
  const { items, translate } = props;

  // Returns true if it's the last element of the array
  const isLast = (index, arr) => {
    return index !== arr.length - 1;
  };

  return (
    <>
      <Box display="flex" alignItems="center" paddingLeft={2.75} marginTop={3}>
        <Title text={translate("GLOSSARY.terms")} />
      </Box>
      <Box
        paddingLeft={2.75}
        paddingRight={2.75}
        color="#2B292D"
        lineHeight="2"
        marginTop={0.75}
      >
        {items.length !== 0 &&
          items.map((item, index, arr) => (
            <Box
              key={`item-${index}`}
              display="inline-flex"
              fontSize={{ xs: 10, sm: 12, md: 12, lg: 14, xl: 14 }}
            >
              {/* Description is not blank */}
              {item.description !== "" && (
                <ItemTooltip name={item.name} description={item.description} />
              )}

              {/* Description is blank */}
              {item.description === "" && <Name name={item.name} />}

              {/* Comma after each element except last one */}
              {isLast(index, arr) && <>,&nbsp;</>}
            </Box>
          ))}
      </Box>
    </>
  );
}

ItemsPanel.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
    })
  ),
};
