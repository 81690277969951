import React, { useEffect, Component } from "react";
import { withTranslate } from "react-redux-multilingual";
import { connect } from "react-redux";
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  Input,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import { DASHBOARD_URL } from "../settings";
import {
  AUTH_LOGIN_FIREBASE,
  GET_ACCOUNT,
  GET_ANALYTICS_DATALAYER,
} from "../sagas/constants";

import { setupFirebaseAuthentication } from "../helpers/firebaseAuthentication";

const styles = (theme) => ({
  main: {
    padding: 24,
  },
  title: {
    marginBottom: 8,
  },
  description: {
    maxWidth: 340,
    fontSize: 14,
    marginBottom: 24,
  },
  content: {
    display: "flex",
    flexDirection: "column",
  },
  errorMessage: {
    fontSize: 14,
    color: "red",
  },
  action: {
    display: "flex",
    flexDirection: "column",
    margin: 16,
  },
  wrapper: {
    width: "100%",
    margin: 8,
    position: "relative",
  },
  button: {
    width: "100%",
    height: 45,
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  forgotPassword: {
    marginTop: 8,
    marginBottom: 8,
  },
});

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: true,
      loading: false,
      error: false,
      username: "",
      password: "",
    };
  }

  updateUsername = (e) => {
    this.setState({
      username: e.target.value,
    });
  };

  updatePassword = (e) => {
    this.setState({
      password: e.target.value,
    });
  };

  onLogin = (event) => {
    event.preventDefault();

    this.setState({ loading: true, error: false });

    setTimeout(() => {
      const data = {
        username: this.state.username.trim(),
        password: this.state.password,
      };
      this.props.dispatch({ type: AUTH_LOGIN_FIREBASE, data });
    }, 500);
  };

  componentDidUpdate(prevProps, prevStates) {
    if (prevProps.auth.loading !== this.props.auth.loading) {
      if (!prevProps.auth.authenticated && this.props.auth.authenticated) {
        this.props.dispatch({ type: GET_ACCOUNT });
        this.props.dispatch({ type: GET_ANALYTICS_DATALAYER });
        // Save transcript if provided
        if (this.props.saveTranscript) {
          this.props.saveTranscript();
        }
      } else if (
        !prevProps.auth.authenticated &&
        !this.props.auth.authenticated &&
        !this.props.auth.loading
      ) {
        this.setState({ loading: false, error: true });
      }
    }
  }

  async componentDidMount() {
    await setupFirebaseAuthentication();
  }

  render() {
    const { loading, error, username, password } = this.state;
    const { classes, translate } = this.props;

    return (
      <Dialog
        className={classes.main}
        open={this.state.open}
        aria-labelledby="Login-editor"
        maxWidth="xs"
      >
        <form onSubmit={this.onLogin}>
          <DialogTitle className={classes.title}>
            {translate("LOGIN.btn")}
          </DialogTitle>
          <DialogContent>
            {/* Description */}
            <div className={classes.description}>
              <span>{translate("LOGIN.loginFormDescription")}</span>
            </div>
            {/* Username */}
            <FormControl fullWidth>
              <InputLabel htmlFor="name-input">
                {translate("LOGIN.emailOrUsername")}
              </InputLabel>
              <Input
                id="username"
                type="text"
                autoFocus
                placeholder={translate("LOGIN.emailOrUsername")}
                required
                value={this.state.username}
                onChange={this.updateUsername}
                disabled={loading}
              />
            </FormControl>
            {/* Password */}
            <FormControl fullWidth>
              <InputLabel htmlFor="name-input">
                {translate("ACCOUNT.pwd")}
              </InputLabel>
              <Input
                id="password"
                type="password"
                placeholder={translate("ACCOUNT.pwd")}
                required
                value={this.state.password}
                onChange={this.updatePassword}
                disabled={loading}
              />
            </FormControl>
            {error && (
              <span className={classes.errorMessage}>
                {translate("LOGIN.errorLogin")}
              </span>
            )}
          </DialogContent>
          <DialogActions className={classes.action}>
            <div className={classes.wrapper}>
              <Button
                className={classes.button}
                color="primary"
                variant="contained"
                type="submit"
                fullWidth
                disabled={
                  loading || username.trim() === "" || password.trim() === ""
                }
              >
                {translate("LOGIN.btn")}
              </Button>
              {loading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
            <div className={classes.forgotPassword}>
              <a target="_blank" href={`${DASHBOARD_URL}/password-reset`}>
                <small>{translate("LOGIN.forgotPassword")}</small>
              </a>
            </div>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

const mapStateToProps = ({ auth, account }) => ({
  auth,
  account,
});

export default connect(mapStateToProps)(
  withStyles(styles)(withTranslate(Login))
);
