import {
	AUTH_LOGOUT,
	GET_JOB,
	GET_JOB_FAIL,
	GET_JOB_OK,
	SAVE_JOB_REVIEW,
	SAVE_JOB_REVIEW_FAIL,
	SAVE_JOB_REVIEW_OK,
	SUBMIT_CAPTION_JOB,
	SUBMIT_CAPTION_JOB_OK,
	SUBMIT_CAPTION_JOB_FAIL,
	SET_JOB_MESSAGE,
	UPDATE_JOB_REVIEW_DIALOG,
	UPDATE_JOB_DETAILS_DIALOG,
	UPDATE_CAPTION_JOB_SUBMIT_DIALOG,
	RATING_PERFECT_DONE_STATE,
	RATING_PERFECT_REVIEW_NOT_PASSED_STATE,
} from '../sagas/constants';

import { TRANSCRIBER_DASHBOARD } from '../settings';

const initialState = {
	data: null,

	loading: false,
	loaded: false,
	approving: false,
	rejecting: false,

	isError: false,
	message: null,

	redirectTo: null,
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case GET_JOB: {
			return { ...state, loading: true, error: false };
		}
		case GET_JOB_FAIL: {
			return {
				...state,
				loading: false,
				isError: true,
				message: action.payload,
			};
		}
		case GET_JOB_OK: {
			return {
				...state,
				loading: false,
				loaded: true,
				isError: false,
				data: action.payload,
			};
		}

		case SAVE_JOB_REVIEW: {
			return {
				...state,
				loading: true,
				approving: action.status === RATING_PERFECT_DONE_STATE,
				rejecting: action.status === RATING_PERFECT_REVIEW_NOT_PASSED_STATE,
				loaded: false,
				isError: false,
			};
		}

		case SAVE_JOB_REVIEW_OK: {
			return {
				...state,
				loading: false,
				approving: false,
				rejecting: false,
				loaded: true,
				isError: false,
				redirectTo: TRANSCRIBER_DASHBOARD,
			};
		}

		case SAVE_JOB_REVIEW_FAIL: {
			return {
				...state,
				loading: false,
				approving: false,
				rejecting: false,
				loaded: false,
				isError: true,
				message: action.message,
			};
		}

		case SUBMIT_CAPTION_JOB: {
			return {
				...state,
				loading: true,
				loaded: false,
				isError: false,
			};
		}

		case SUBMIT_CAPTION_JOB_OK: {
			return {
				...state,
				loading: false,
				loaded: true,
				isError: false,
				redirectTo: TRANSCRIBER_DASHBOARD,
			};
		}

		case SUBMIT_CAPTION_JOB_FAIL: {
			return {
				...state,
				loading: false,
				loaded: false,
				isError: true,
				message: action.message,
			};
		}
		case SET_JOB_MESSAGE: {
			return {
				...state,
				message: action.message,
			};
		}

		case UPDATE_JOB_REVIEW_DIALOG: {
			return {
				...state,
				isRatingDialogOpen: action.isRatingDialogOpen,
				message: action.message,
			};
		}

		case UPDATE_JOB_DETAILS_DIALOG: {
			return {
				...state,
				isDetailsDialogOpen: action.isDetailsDialogOpen,
				message: action.message,
			};
		}

		case UPDATE_CAPTION_JOB_SUBMIT_DIALOG: {
			return {
				...state,
				isCaptionSubmitDialogOpen: action.isCaptionSubmitDialogOpen,
				message: action.message,
			};
		}

		case AUTH_LOGOUT: {
			return {
				...state,
				companyKey: null,
			};
		}
		default: {
			return state;
		}
	}
}
