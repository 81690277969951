import { Box, withStyles } from "@material-ui/core";
import React from "react";
import { AlarmOff } from "@material-ui/icons";
import { AMBER_SECONDARY } from "../ui/Theme";
import AmberPrimaryButton from "../buttons/AmberPrimaryButton";
import { withTranslate } from "react-redux-multilingual";
import { redirectToTranscriberDashboard } from "../../helpers/redirectHelper";

const styles = (theme) => ({
  icon: {
    height: 75,
    width: 75,
    color: AMBER_SECONDARY,
  },
});

function TranscriptStatusError({ translate, classes }) {
  const handleRedirectClick = () => {
    redirectToTranscriberDashboard();
  };

  return (
    <Box
      height="100vh"
      display="flex"
      justifyContent="center"
      width="100%"
      alignItems="center"
      flexDirection="column"
      data-testid="transcript-error-wrapper"
    >
      <Box mb={3}>
        <AlarmOff
          className={classes.icon}
          style={{}}
          data-testid="transcript-error-icon"
        />
      </Box>
      <Box
        mb={3}
        maxWidth={450}
        textAlign="center"
        data-testid="transcript-error-message"
      >
        {translate("TRANSCRIPT_ERROR.message")}
      </Box>
      <AmberPrimaryButton
        data-testid="transcript-error-button"
        text={translate("TRANSCRIPT_ERROR.button")}
        onClick={handleRedirectClick}
      />
    </Box>
  );
}

export default withStyles(styles)(withTranslate(TranscriptStatusError));
