import {
  GET_ASR_MODELS,
  GET_ASR_MODELS_OK,
  GET_ASR_MODELS_FAIL
} from "../sagas/constants";

const initialState = {
  data: null,

  loading: false,
  loaded: false,

  error: false,
  errorMessage: null,
};

export default function reducer(state=initialState, action) {
  switch(action.type) {

      case GET_ASR_MODELS: {
          return {...state, loading: true, error: false}
      }
      case GET_ASR_MODELS_FAIL: {
          return {
              ...state,
              loading: false,
              error: true,
              errorMessage: action.payload
          }
      }
      case GET_ASR_MODELS_OK: {
          return {
              ...state,
              loading: false,
              loaded: true,
              error: false,
              data: action.payload
          }
      }
      default: {
          return state;
      }
  }
}
