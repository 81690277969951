import React from 'react'
import { withTranslate } from 'react-redux-multilingual'
import { Tooltip, Button } from '@material-ui/core'
import { ReplaceIcon } from '../ui/Icons'

const ReplaceButton = (props) => {

    const { translate, setShowFindText, showFindText } = props;

    const handleClickShowFind = () => {
        setShowFindText(!showFindText);
    }

    return (
        <React.Fragment>
            <Tooltip title={`${translate('EDITOR.controlsReplace')} ${translate('EDITOR.controlsReplaceShortcut')}`} placement="bottom">
                <Button color="default" onClick={handleClickShowFind} className="btn_replace">
                    <ReplaceIcon /> {translate('EDITOR.controlsReplace')}
                </Button>
            </Tooltip>
        </React.Fragment>
    )
}

export default withTranslate(ReplaceButton)
