import Immutable from 'immutable'

const Highlight = new Immutable.Record({
    start: 0, // @param {float} Start time offset in the whole transcript
    end: 0, // @param {float} End time offset in the whole transcript
    startPosition: 0, // @param {int} Start position within the segment
    endPosition: 0, // @param {int} End position within the segment
    // duration: 0,
});

export default Highlight;
