import React, { Component } from 'react'
import { EditorBlock } from '@amberscript/amberscript-draftjs/lib/Draft'
import BlockStartEndTime from './BlockStartEndTime'
import { PINKISH_RED } from '../../../helpers/colors'
import {
    getBlockStartTime, getBlockEndTime, getLastRowOfBlock
} from '../../../helpers/adjusBlockTimestampUtil'

const CHARACTER_PER_ROW_LIMIT = 42;
const SCREEN_TIME_LIMIT = 7.1; //seconds
const SHOW_CONTENT_BLOCK_TIME_LIMIT = 60; //seconds

function getNumberOfBlocks(editorState) {
    const contentState = editorState.getCurrentContent();
    const contentBlocks = contentState.getBlocksAsArray();
    return contentBlocks.length;
}

function getNumberOfBlocksWithNoText(editorState) {
    const contentState = editorState.getCurrentContent();
    const contentBlocks = contentState.getBlocksAsArray();
    const contentBlocksWithText = contentBlocks.filter(block => block.text.trim() !== '');
    return contentBlocksWithText.length;
}

/**
 * Every segment consists of one TranscriptEditorBlock. Within the current 
 * block, where the audio position is currently playing, all words are rendered
 * as TranscriptEditorWord components and it's inBoxidual styling. All other 
 * segments are plainly rendered as TranscriptEditorBlock with added styling.
 * This grants a significant performance boost, as we are not rendering components
 * for every single word anymore.
 */
class SubtitleEditorBlock extends Component {

    /*
    shouldComponentUpdate(nextProps) {
        // UNCOMMENT TO LOAD THE WHOLE SEGMENT, AND NOT JUST THE FIRST ROW
        // const isNewLine = this.checkIfThisBlockIsGroupedByAnotherBlock(nextProps.block);
        // const segmentStartTime = getBlockStartTime(this.getFirstBlockOfSegment(nextProps.blockProps.editorState, nextProps.block, isNewLine));
        // const timeInRange = Math.abs(segmentStartTime - nextProps.blockProps.currentTime) < SHOW_CONTENT_BLOCK_TIME_LIMIT;

        const startTime = getBlockStartTime(nextProps.block);
        const timeInRange = Math.abs(startTime - nextProps.blockProps.currentTime) < SHOW_CONTENT_BLOCK_TIME_LIMIT;

        // UNCOMMENT TO LOAD THE WHOLE SEGMENT, AND NOT JUST THE FIRST ROW
        // const isNewLineBefore = this.checkIfThisBlockIsGroupedByAnotherBlock(this.props.block);
        // const segmentStartTimeBefore = getBlockStartTime(this.getFirstBlockOfSegment(this.props.blockProps.editorState, this.props.block, isNewLineBefore));
        // const timeInRangeBefore = Math.abs(segmentStartTimeBefore - this.props.blockProps.currentTime) < SHOW_CONTENT_BLOCK_TIME_LIMIT;

        const timeInRangeBefore = Math.abs(startTime - this.props.blockProps.currentTime) < SHOW_CONTENT_BLOCK_TIME_LIMIT;

        if (timeInRangeBefore !== timeInRange) {
            return true;
        } else if (timeInRange) {
            // const nrOldBlocks = getNumberOfBlocks(this.props.blockProps.editorState);
            // const nrNewBlocks = getNumberOfBlocks(nextProps.blockProps.editorState);

            // const nrOldBlocksWithNoText = getNumberOfBlocksWithNoText(this.props.blockProps.editorState);
            // const nrNewBlocksWithNoText = getNumberOfBlocksWithNoText(nextProps.blockProps.editorState);

            // if (nrOldBlocks !== nrNewBlocks || nrOldBlocksWithNoText !== nrNewBlocksWithNoText) {
            //     return true;
            // }

            if (this.props.blockProps.currentTime !== nextProps.blockProps.currentTime) {
                if (nextProps.blockProps.isTimeChangedOnSeek) {
                    return true;
                } else {
                    const { currentBlockKey } = this.props.blockProps;
                    const nextCurrentBlockKey = nextProps.blockProps.currentBlockKey;
                    const thisBlockKey = this.props.block.getKey();

                    if (currentBlockKey === thisBlockKey || nextCurrentBlockKey === thisBlockKey) {
                        return true;
                    }
                }
            }

            if (this.props.block !== nextProps.block) {
                return true;
            }
        }

        return false;
    }
    */

    shouldComponentUpdate(nextProps) {
        const blockKey = nextProps.block.getKey();

        const { blockKeysInRange: oldBlockKeysInRange } = this.props.blockProps;
        const { blockKeysInRange } = nextProps.blockProps;

        if (oldBlockKeysInRange.includes(blockKey) !== blockKeysInRange.includes(blockKey)) {
            return true;
        }

        if (!blockKeysInRange.includes(blockKey)) {
            return false;
        }

        // Needed to update the line numbers
        const nrOldBlocks = this.props.blockProps.editorState.getCurrentContent().getBlocksAsArray().length;
        const nrNewBlocks = nextProps.blockProps.editorState.getCurrentContent().getBlocksAsArray().length;
        if (nrOldBlocks !== nrNewBlocks) {
            return true;
        }

        // Old logic
        if (this.props.blockProps.currentTime !== nextProps.blockProps.currentTime) {
            if (nextProps.blockProps.isTimeChangedOnSeek) {
                return true;
            } else {
                const { currentBlockKey } = this.props.blockProps;
                const nextCurrentBlockKey = nextProps.blockProps.currentBlockKey;
                const thisBlockKey = this.props.block.getKey();

                if (currentBlockKey === thisBlockKey || nextCurrentBlockKey === thisBlockKey) {
                    return true;
                }
            }
        }

        if (this.props.block !== nextProps.block) {
            return true;
        }


        return false;
    }

    // Check if the id of this block is equal to the id of the last block
    // if it is the first block, it is not grouped with anything = return false
    checkIfThisBlockIsGroupedByAnotherBlock = (currentBlock) => {
        return currentBlock.data.get('newLine');
    }

    blockCharacterPerLineLength = (currentBlock) => {
        const text = currentBlock.data.get('words').map(w => w.get('text')).join(' ');
        return text.length;
    }

    getBorderBottom = (editorState, currentBlock) => {
        const currentBlockId = currentBlock.getKey();

        const contentState = editorState.getCurrentContent();
        const contentBlocks = contentState.getBlocksAsArray();

        let blockIndex;
        for (let index = 0; index < contentBlocks.length; index += 1) {
            const block = contentBlocks[index];
            const blockId = block.getKey();

            if (currentBlockId === blockId) {
                blockIndex = index;
                break;
            }
        }

        // Last block
        if (blockIndex === contentBlocks.length - 1) {
            return 'unset';
        } else {
            const nextBlock = contentBlocks[blockIndex + 1];
            const isNextBlockNewLine = nextBlock.data.get('newLine');
            if (isNextBlockNewLine) {
                return 'unset';
            } else {
                return "1px solid #ECECEC";
            }
        }
    }

    getLineNumber = (editorState, currentBlock) => {
        const currentBlockId = currentBlock.getKey();

        const contentState = editorState.getCurrentContent();
        const contentBlocks = contentState.getBlocksAsArray();

        let lineNumber = 1;
        for (let index = 0; index < contentBlocks.length; index += 1) {
            const block = contentBlocks[index];
            const blockId = block.getKey()
            const blockNewLine = block.data.get('newLine');

            if (currentBlockId === blockId) {
                break;
            }

            if (!blockNewLine) {
                lineNumber += 1;
            }
        }

        return lineNumber;
    }

    getFirstBlockOfSegment = (editorState, block, isNewLine) => {
        if (isNewLine) {
            const contentState = editorState.getCurrentContent();
            let blockCandidate = contentState.getBlockBefore(block.getKey());
            let isBlockCandidateNewLine = this.checkIfThisBlockIsGroupedByAnotherBlock(blockCandidate);
            while (isBlockCandidateNewLine) {
                blockCandidate = contentState.getBlockBefore(blockCandidate.getKey());
                if (!blockCandidate) {
                    return block;
                }
                isBlockCandidateNewLine = this.checkIfThisBlockIsGroupedByAnotherBlock(blockCandidate);
            }
            return blockCandidate;
        } else {
            return block;
        }
    }

    getBlockStartTime = (editorState, block, isFirst) => {
        const result = isFirst ? getBlockStartTime(block) : getBlockEndTime(block);

        if (result) {
            return result;
        } else {
            const contentState = editorState.getCurrentContent();
            const blockBefore = contentState.getBlockBefore(block.getKey());
            if (blockBefore) {
                return this.getBlockStartTime(editorState, blockBefore, false);
            } else {
                return 0;
            }
        }
    }

    getBlockEndTime = (editorState, block, isFirst) => {
        const result = isFirst ? getBlockEndTime(block) : getBlockStartTime(block);

        if (result) {
            return result;
        } else {
            const contentState = editorState.getCurrentContent();
            const blockBefore = contentState.getBlockAfter(block.getKey());
            if (blockBefore) {
                return this.getBlockEndTime(editorState, blockBefore, false);
            } else {
                return null;
            }
        }
    }

    render() {
        const { block } = this.props;
        const { currentTime, onTimestampClick, editorState, blockKeysInRange } = this.props.blockProps;

        const isNewLine = this.checkIfThisBlockIsGroupedByAnotherBlock(block);
        // const segmentStartTime = getBlockStartTime(this.getFirstBlockOfSegment(editorState, block, isNewLine));
        // const timeInRange = Math.abs(segmentStartTime - currentTime) < SHOW_CONTENT_BLOCK_TIME_LIMIT;

        // if (!timeInRange) {
        //     return null;
        // }

        const blockKey = block.getKey();
        if (!blockKeysInRange.includes(blockKey)) {
            return null;
        }

        const id = block.getKey();
        const startTime = this.getBlockStartTime(editorState, block, true);
        const className = currentTime > startTime ? 'block active' : 'block';
        const blockTextLength = this.blockCharacterPerLineLength(block);

        if (isNewLine) {
            return (
                <div className={className} id={`b-${id}`} style={{ borderBottom: "1px solid #ECECEC" }}>
                    <div className="newLine">
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "baseline", marginLeft: "27px" }}>
                            <span style={{ fontSize: 14, fontWeight: 600, color: "#DC283C", width: 52, whiteSpace: "nowrap", marginRight: 8 }}>
                                {blockTextLength > CHARACTER_PER_ROW_LIMIT ? `${blockTextLength} CPL` : ''}
                            </span>
                            <div style={{ flex: 1, display: "flex", justifyContent: "center", paddingLeft: 4, paddingRight: 4, textAlign: "center", lineHeight: 1.8 }}>
                                <EditorBlock {...this.props} />
                            </div>
                            <div style={{ width: 52 }}></div>
                        </div>
                    </div>
                </div>
            )
        } else {
            const lastBlock = getLastRowOfBlock(editorState.getCurrentContent(), block);
            const endTime = this.getBlockEndTime(editorState, lastBlock, true);

            const screenTimeLimitExceeded = (endTime - startTime) > SCREEN_TIME_LIMIT;
            const lineNumber = this.getLineNumber(editorState, block);

            return (
                <div className={className} id={`b-${id}`} style={{ borderBottom: this.getBorderBottom(editorState, block) }}>
                    <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", backgroundColor: "#ffffff", paddingTop: 8 }}>
                        <div style={{ display: "flex", justifyContent: "center", width: 60, fontSize: 14, fontWeight: 600, color: "#D0D0D0" }}>
                            {lineNumber}
                        </div>
                        <div style={{ display: "flex", position: "relative", height: 40, flex: 1, marginLeft: 12 }}>
                            <BlockStartEndTime
                                startTime={startTime}
                                endTime={endTime}
                                onTimestampClick={blockTextLength === 0 ? () => { } : onTimestampClick}
                                block={block}
                                screenTimeLimitExceeded={screenTimeLimitExceeded}
                            />
                        </div>
                    </div>
                    <div className="txtSub draftJsTxtSub" style={{ paddingTop: 0 }}>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "baseline", marginLeft: 27 }}>
                            <span style={{ fontSize: 14, fontWeight: 600, color: "#DC283C", width: 52, whiteSpace: "nowrap", marginRight: 8 }}>
                                {blockTextLength > CHARACTER_PER_ROW_LIMIT ? `${blockTextLength} CPL` : ''}
                            </span>
                            <div style={{ flex: 1, display: "flex", justifyContent: "center", paddingLeft: 4, paddingRight: 4, textAlign: "center", lineHeight: 1.8 }}>
                                <EditorBlock {...this.props} />
                            </div>
                            <div style={{ width: 52 }}></div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default SubtitleEditorBlock;