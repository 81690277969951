import React from "react";
import PropTypes from "prop-types";
import { Box, Checkbox, FormControlLabel, TextField } from "@material-ui/core";
import { withTranslate } from "react-redux-multilingual";

function MaxCharCheckbox(props) {
  const {
    translate,
    maxCharsPerRow,
    handleMaxCharPerRow,
    handleMaxCharPerRowValue,
    testid,
  } = props;

  return (
    <FormControlLabel
      control={
        <Checkbox
          color="primary"
          checked={maxCharsPerRow.active}
          onChange={handleMaxCharPerRow}
          aria-label="maxCharsPerRow"
        />
      }
      label={
        maxCharsPerRow.active ? (
          <TextField
            type="number"
            label={translate("EDITOR.EXPORT.maxCharsPerRow")}
            value={maxCharsPerRow.value}
            onChange={handleMaxCharPerRowValue}
            style={{ whiteSpace: "nowrap", margin: 0 }}
            inputProps={{ "data-testid": `${testid}-inputText` }}
            variant="outlined"
          />
        ) : (
          <Box component="span" fontSize={14}>
            {translate("EDITOR.EXPORT.maxCharsPerRow")}
          </Box>
        )
      }
      data-testid={testid}
    />
  );
}

MaxCharCheckbox.propTypes = {
  maxCharsPerRow: PropTypes.shape({
    active: PropTypes.bool.isRequired,
    value: PropTypes.number.isRequired,
  }).isRequired,
  handleMaxCharPerRow: PropTypes.func.isRequired,
  handleMaxCharPerRowValue: PropTypes.func.isRequired,
  testid: PropTypes.string,
};

MaxCharCheckbox.defaultProps = {
  testid: "",
};

export default withTranslate(MaxCharCheckbox);
