import React, { useState } from "react";
import PropTypes from "prop-types";
import Notification from "../../ui/Notification";

import {
  DIALOG_STEP_EXPORT_BURN_IN_CONFIRMATION,
  DIALOG_STEP_EXPORT_BURN_IN_SUCCESS,
  DIALOG_STEP_EXPORT_SUBTITLES,
} from "../export/exportSubtitles/constants";
import { createBurnInJob } from "./BurnInApi";
import BurnInConfirmation from "./BurnInConfirmation";
import BurnInSuccessful from "./BurnInSuccessful";

export default function BurnInSteps(props) {
  const {
    step,
    translate,
    onClose,
    handleGoBack,
    handleGoToDashboard,
    handleGoToBurnInSuccessful,
    burnInJob,
  } = props;

  const [notification, setNotification] = useState({
    open: false,
    message: translate("CREATE_SUBTITLES.error1"),
    dismissable: true,
  });

  const showNotification = () => {
    setNotification({
      ...notification,
      open: true,
    });
  };

  const closeNotification = () => {
    setNotification({
      ...notification,
      open: false,
    });
  };

  // Create a new burn in video
  const handleCreateNewBurnInVideo = async () => {
    const { jobId } = burnInJob;
    if (!jobId) {
      return;
    }
    try {
      await createBurnInJob(jobId);
      handleGoToBurnInSuccessful();
    } catch (error) {
      showNotification();
    }
  };

  if (step === DIALOG_STEP_EXPORT_SUBTITLES) {
    return null;
  }

  return (
    <>
      <Notification
        open={notification.open}
        handleClose={closeNotification}
        message={notification.message}
        dismissable={notification.dismissable}
      />
      {step === DIALOG_STEP_EXPORT_BURN_IN_CONFIRMATION && (
        <BurnInConfirmation
          translate={translate}
          onClose={onClose}
          onBack={handleGoBack}
          onContinue={handleCreateNewBurnInVideo}
        />
      )}
      {step === DIALOG_STEP_EXPORT_BURN_IN_SUCCESS && (
        <BurnInSuccessful
          translate={translate}
          onClose={onClose}
          onContinue={handleGoToDashboard}
        />
      )}
    </>
  );
}

BurnInSteps.propTypes = {
  step: PropTypes.number.isRequired,
  translate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  handleGoBack: PropTypes.func.isRequired,
  handleGoToDashboard: PropTypes.func.isRequired,
  handleGoToBurnInSuccessful: PropTypes.func.isRequired,
  burnInJob: PropTypes.shape(),
};

BurnInSteps.defaultProps = {
  burnInJob: null,
};
