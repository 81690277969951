import React, { PureComponent } from 'react';

class CurrentTextDecorator extends PureComponent {
  render() {
    const { children } = this.props;
    return (
      <span style={{color: '#000000', borderBottom: '2px solid #dddddd'}} id="currentText">
        {children}
      </span>
    );
  }
}

class AfterCurrentTextDecorator extends PureComponent {
  render() {
    const { children } = this.props;
    return (
      <span style={{color: 'rgb(102, 102, 102)'}}  id="afterCurrentText">
        {children}
      </span>
    );
  }
}

export { CurrentTextDecorator, AfterCurrentTextDecorator }