import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import {
    IconButton,
    ListItemSecondaryAction,
    MenuItem,
    MenuList,
    Popover,
    Tooltip,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles';

import EditSpeaker from './EditSpeaker'

import { AddIcon, EditIcon } from '../../ui/Icons'
import { SET_TRANSCRIPT_SPEAKER } from '../../../sagas/constants'

const styles = {
    listItem: {
        paddingRight: 60,
    },
    addSpeaker: {
        zIndex: 10
    }
}

/**
 * TBD - why do we have TranscriptEditorBlockSpeaker?
 */
class TranscriptEditorSpeaker extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            id: null,
            name: '',
            anchorEl: props.anchorEl || null,
            openDropdown: props.openDropdown || false,
            openDialog: props.openDialog || false,
            editingSpeaker: props.editingSpeaker || null
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ ...nextProps });
    }

    componentWillMount() {
        const { block } = this.props;
        if (!block) {
            return;
        }
        const speakerId = block.data.get('speaker');
        const { id, name } = this._getSpeakerBy('id', speakerId);
        this.setState({ id, name });

    }

    _getSpeakerBy = (key, value) => {
        return this.props.speakers.find(function (speaker) {
            return speaker.get(key) === value;
        });
    }

    handleOpenDropdown = () => {
        this.setState({ openDropdown: true });
    }

    handleCloseDropdown = () => {
        this.setState({ openDropdown: false });
        this.props.onCloseDropdown();
    }

    handleOnEnter = () => {
        document.body.classList.add('SpeakerDropdown--open');
    }
    handleOnExited = () => {
        document.body.classList.remove('SpeakerDropdown--open');
    }

    handleOpenDialog = () => {
        // this.props.dispatch({type: DISABLE_TRANSCRIPT_EDITOR});
        this.setState({ openDialog: true });
    }

    handleCloseDialog = () => {
        // this.props.dispatch({type: ENABLE_TRANSCRIPT_EDITOR});
        this.setState({ openDialog: false });
    }

    handleDropdownSelect = (event) => {
        const { block, setBlockSpeaker } = this.props;
        const speakerId = event.target.getAttribute('data-speaker-id');
        this.setState({ id: speakerId });
        const { name } = this._getSpeakerBy('id', speakerId);
        setBlockSpeaker(block, speakerId, name);
        this.handleCloseDropdown();
    }

    handleSpeakerEdit = (speaker) => {
        this.setState({ editingSpeaker: speaker });
        this.handleOpenDialog();
    }

    handleAddNew = (event) => {
        this.setState({ editingSpeaker: null });
        this.handleOpenDialog();
    }

    handleDialogSave = (data) => {
        const { id, name } = data;
        this.props.dispatch({ type: SET_TRANSCRIPT_SPEAKER, id, name });
    }

    render() {
        const { speakers, classes } = this.props;
        const { anchorEl, openDropdown } = this.state;

        return (
            <div>
                <Popover
                    // className={classes.popover}
                    open={openDropdown}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    onEnter={this.handleOnEnter}
                    onExited={this.handleOnExited}
                    onBackdropClick={this.handleCloseDropdown}
                >
                    <MenuList role="menu" dense>
                        <MenuItem
                            key="add-speaker"
                            onClick={this.handleAddNew}
                            className={classes.addSpeaker}
                        >
                            <b>
                                <small>Add new &nbsp;</small>
                            </b>
                            <ListItemSecondaryAction>
                                <IconButton>
                                    <AddIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </MenuItem>
                        {speakers.map(speaker => (
                            <MenuItem
                                className={classes.listItem}
                                key={speaker.id}
                                // selected={speaker.id === this.state.id}
                                onClick={this.handleDropdownSelect}
                                data-speaker-id={speaker.id}>

                                {speaker.name}

                                <ListItemSecondaryAction>
                                    <Tooltip title={`Edit`}>
                                        <IconButton
                                            onClick={() => {
                                                this.handleSpeakerEdit(speaker)
                                            }}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                    </Tooltip>
                                </ListItemSecondaryAction>
                            </MenuItem>
                        ))}
                    </MenuList>
                </Popover>
                <EditSpeaker
                    ref={(el) => {
                        this.editSpeakerDialog = el
                    }}
                    open={this.state.openDialog}
                    onClose={this.handleCloseDialog}
                    onSubmit={this.handleDialogSave}
                    speaker={this.state.editingSpeaker}
                />
            </div>
        );
    }
}

const mapStateToProps = ({ transcript }) => ({
    speakers: transcript.data.speakers,
});

export default connect(mapStateToProps, null, null, { withRef: true })(withStyles(styles)(TranscriptEditorSpeaker));
