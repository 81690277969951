import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { DialogContent, Checkbox, Box, Typography } from '@material-ui/core';

import AmberOutlinedButton from '../../buttons/AmberOutlinedButton';
import AmberPrimaryButton from '../../buttons/AmberPrimaryButton';

import AmberDialog from '../AmberDialog';
import AmberDialogActions from '../AmberDialogActions';

const useStyles = makeStyles((theme) => ({
	dialogActions: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: theme.spacing(2),
	},
	actionButtons: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		gap: theme.spacing(2),
	},
	checkboxContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginBottom: theme.spacing(2), // Padding between checkboxes
	},
	checkboxText: {
		marginRight: theme.spacing(2),
	},
}));

function JobSubmitConfirmDialog(props) {
	const { submitQc, translate, setSubmitConfirmIsOpen, submitConfirmIsOpen } = props;

	const classes = useStyles();
	const [checkboxes, setCheckboxes] = useState({
		checkbox1: false,
		checkbox2: false,
		checkbox3: false,
	});

	const handlejobSubmitConfirmClose = () => {
		setCheckboxes({
			checkbox1: false,
			checkbox2: false,
			checkbox3: false,
		});
		setSubmitConfirmIsOpen(false);
	};

	const handleCheckboxChange = (event) => {
		setCheckboxes({
			...checkboxes,
			[event.target.name]: event.target.checked,
		});
	};

	const handleSubmitQC = () => {
		submitQc();
	}
	const allChecked = checkboxes.checkbox1 && checkboxes.checkbox2 && checkboxes.checkbox3;

	return (
		<AmberDialog
			open={submitConfirmIsOpen}
			title={translate('CONFIRM_SUBMIT.title')}
			fullWidth
			maxWidth="sm"
			handleClose={handlejobSubmitConfirmClose}
			classes={{ paper: classes.dialogPaper }}
		>
			<DialogContent>
				<Box className={classes.checkboxContainer}>
					<Typography className={classes.checkboxText} variant="body1">
						{translate('CONFIRM_SUBMIT.checkbox1')}
					</Typography>
					<Checkbox
						checked={checkboxes.checkbox1}
						onChange={handleCheckboxChange}
						name="checkbox1"
						color="primary"
					/>
				</Box>
				<Box className={classes.checkboxContainer}>
					<Typography className={classes.checkboxText} variant="body1">
						{translate('CONFIRM_SUBMIT.checkbox2')}
					</Typography>
					<Checkbox
						checked={checkboxes.checkbox2}
						onChange={handleCheckboxChange}
						name="checkbox2"
						color="primary"
					/>
				</Box>
				<Box className={classes.checkboxContainer}>
					<Typography className={classes.checkboxText} variant="body1">
						{translate('CONFIRM_SUBMIT.checkbox3')}
					</Typography>
					<Checkbox
						checked={checkboxes.checkbox3}
						onChange={handleCheckboxChange}
						name="checkbox3"
						color="primary"
					/>
				</Box>
			</DialogContent>

			<AmberDialogActions classes={{ root: classes.dialogActions }}>
				<div className={classes.actionButtons}>
					<AmberOutlinedButton
						text={translate('CONFIRM_SUBMIT.back')}
						onClick={handlejobSubmitConfirmClose}
					/>
					<AmberPrimaryButton
						text={translate('CONFIRM_SUBMIT.submit')}
						onClick={handleSubmitQC}
						disabled={!allChecked}
					/>
				</div>
			</AmberDialogActions>
		</AmberDialog>
	);
}

JobSubmitConfirmDialog.propTypes = {
	translate: PropTypes.func.isRequired,
};

const mapStateToProps = ({ job, transcript }) => ({
	job,
	transcript,
});

export default connect(mapStateToProps)(JobSubmitConfirmDialog);