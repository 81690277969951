import Immutable from 'immutable'

const Transcript = new Immutable.Record({
    startTimeOffset: 0,
    isConvertedToSubtitles: false,
    speakers: new Immutable.List(),
    segments: new Immutable.List(),
    highlights: new Immutable.List(),
});

export default Transcript;
