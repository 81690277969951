import React, { Component } from 'react'
import { withTranslate } from 'react-redux-multilingual'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles';

import { ADJUST_TIMESTAMP_START } from './dialogConstants';

import Slider from '../slider/Slider'

import { toHHMMSSMS } from '../../helpers/timeUtils'
import { getBlockStartTimeAdjustment, getBlockEndTimeAdjustment, getLastRowOfBlock } from '../../helpers/adjusBlockTimestampUtil';

import './AdjustTimestampsDialog.scss'

const styles = theme => ({
  button: {
    fontSize: 16,
  },
  actions: {
    justifyContent: 'space-between',
    margin: 20,
  }
});


class AdjustTimestampsDialog extends Component {

  constructor(props) {
    super(props);

    const { mode, block, translate } = this.props;
    const { contentState } = this.props; //modal props

    const lastBlock = getLastRowOfBlock(contentState, block);

    this.headerText = mode === ADJUST_TIMESTAMP_START ?
      translate('EDITOR.adjustStartTimestampHeader') :
      translate('EDITOR.adjustEndTimestampHeader');


    this.blockTimeAdjustment = mode === ADJUST_TIMESTAMP_START ?
      getBlockStartTimeAdjustment(block, contentState) :
      getBlockEndTimeAdjustment(lastBlock, contentState);

    this.state = {
      timestampValue: this.blockTimeAdjustment.defaultValue
    }
  }



  onSliderChange = (value) => {
    this.setState({ timestampValue: value });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { mode, onClose, block } = this.props;
    const { handleBlockTimestampStartChange, handleBlockTimestampEndChange } = this.props; //modal props
    const blockKey = block.getKey();

    if (mode === ADJUST_TIMESTAMP_START) {
      handleBlockTimestampStartChange(blockKey, this.state.timestampValue);
    } else {
      handleBlockTimestampEndChange(blockKey, this.state.timestampValue);
    }

    onClose();
  }

  //calling the onChange function inside of handleFixingScrollPosition
  //in order to be able to fix the scroll position in onChange function.
  handleNoChange = () => {
    const { handleFixingScrollPosition, onClose } = this.props;
    handleFixingScrollPosition();

    onClose();
  }

  render() {
    const { open, onClose, classes, translate } = this.props;

    const { min, max, defaultValue } = this.blockTimeAdjustment;
    return (
      <Dialog
        open={open}
        onClose={() => this.handleNoChange()}
        maxWidth="sm"
        fullWidth
        aria-labelledby="Adjust-timestamps-dialog"
      >
        <form onSubmit={this.handleSubmit}>
          <DialogTitle>{this.headerText}</DialogTitle>
          <DialogContent>
            <p>{translate('EDITOR.adjustTimestampDescription')}</p>
            <p className="timestamp-label">{toHHMMSSMS(this.state.timestampValue, ':')}</p>
            <Slider
              min={min}
              max={max}
              defaultValue={defaultValue}
              step={0.01}
              style={{ width: 280 }}
              onChange={this.onSliderChange} />
          </DialogContent>
          <DialogActions className={classes.actions}>
            <Button
              className={classes.button}
              fullWidth
              variant="contained"
              onClick={() => this.handleNoChange()}
            >{translate('EDITOR.adjustDialogCancel')}</Button>
            <Button
              className={classes.button}
              fullWidth
              color="primary"
              variant="contained"
              type="submit"
            >{translate('EDITOR.adjustDialogSubmit')}</Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}


export default (withStyles(styles)(withTranslate(AdjustTimestampsDialog)));
