import React, { Component } from 'react'
import { withTranslate } from 'react-redux-multilingual'
import { Tooltip, Button } from '@material-ui/core'

import Replay5Icon from '@material-ui/icons/Replay5';

import './RewindButton.scss'

class RewindButton extends Component {

    handleClick = () => {
        this.props.onRewind();
    }

    componentDidMount() {
        this.props.addSteps({
            id: 'rewind',
            textTranslate: 'TOUR.EDITOR.rewind',
            selector: '#RewindButton',
            position: 'bottom',
            type: 'hover',
            isFixed: true,
            allowClicksThruHole: true,
            style: {
                hole: {
                    borderRadius: '100%'
                }
            }
        });
    }

    render() {
        const { translate } = this.props;

        return (
            <Tooltip title="Shortcut: (Ctrl + r or Alt + Left arrow)" placement="bottom">
                <Button id="RewindButton" className="RewindButton" color="primary" onClick={this.handleClick}>
                    <Replay5Icon fontSize="large" style={{ color: '#757575', marginBottom: 4 }} />
                    {/* <em>{translate('EDITOR.rewindBtn')}</em> */}
                </Button>
            </Tooltip>
        )
    }
}

export default withTranslate(RewindButton);
