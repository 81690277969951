import React from 'react'
import { connect } from 'react-redux'
import { withTranslate } from 'react-redux-multilingual'
import AutoSave from './AutoSave'
import RealignBtn from './RealignBtn'
import ShortcutsButton from '../buttons/ShortcutsButton'
import EditorTourButton from '../buttons/EditorTourButton'
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import './Footer.scss';
import { GenAIAwernessButton } from './GenAIAwernessButton'

const DIRECTIONS = {
    back: "back",
    backOne: "back-one",
    forwardOne: "forward-one",
    forward: "forward",
}

function Footer(props) {
    const {
        transcript, account, translate, addSteps, enableTour, enableShortcuts,
        saveTranscript, showRealigningLoadingBar, realignTranscript, isCaptionFile,
        setExportModalOpen, editor
    } = props;
    const { language: currentLanguage } = account.data;

    const navigateTimeline = (direction) => {
        if (editor) {
            const currentTime = editor.audioPlayerTime;
            const currentSubtitleIndex = editor.subtitles.findIndex((subtitle) => {
                return (subtitle.startTime <= currentTime && subtitle.endTime >= currentTime)
                    || (subtitle.startTime > currentTime)
            });
            const nextSubtitle = currentSubtitleIndex > -1 ? editor.subtitles[currentSubtitleIndex + 1] : editor.subtitles[0];
            const previousSubtitle = currentSubtitleIndex > 0 ? editor.subtitles[currentSubtitleIndex - 1] : editor.subtitles[0];
            switch (direction) {
                case DIRECTIONS.backOne:
                    const backTimeOne = currentTime > 1 ? currentTime - 1 : currentTime;
                    editor.setAudioPlayerTime(backTimeOne, true, true);
                    break;
                case DIRECTIONS.forwardOne:
                    editor.setAudioPlayerTime(currentTime + 1, true, true);
                    break;
                case DIRECTIONS.forward:
                    editor.setAudioPlayerTime(nextSubtitle.startTime, true, true);
                    break;
                case DIRECTIONS.back:
                    editor.setAudioPlayerTime(previousSubtitle.startTime, true, true);
                    break;
                default:
                    break;
            }
        }
    }

    return (
        <footer className="footer_main_base footer_main">
            <div className="footer_left">
                <AutoSave saving={transcript.saving} saved={transcript.saved} saveError={transcript.saveError} saveTranscript={saveTranscript} currentLanguage={currentLanguage} />
                <RealignBtn showRealigningLoadingBar={showRealigningLoadingBar} realignTranscript={realignTranscript} />
            </div>
            {enableShortcuts && <div className="footer_middle">
                <ShortcutsButton addSteps={addSteps} isCaptionFile={isCaptionFile} />
                {
                    isCaptionFile() ?
                        <span className="shortcutsHint">
                            {translate('EDITOR.shortcutHintNewLine')}
                        </span> :
                        <span className="shortcutsHint">
                            {translate('EDITOR.shortcutHintAltClick')}
                        </span>
                }
                {!isCaptionFile() && <GenAIAwernessButton onButtonClick={setExportModalOpen} />}
                {enableTour && <EditorTourButton onClick={props.onEditorTourClick} />}
            </div>}
            {isCaptionFile() && 
                <div className="footer_right">
                    <KeyboardDoubleArrowLeftIcon color='primary' onClick={() => navigateTimeline(DIRECTIONS.back)} />
                    <KeyboardArrowLeftIcon color='primary' onClick={() => navigateTimeline(DIRECTIONS.backOne)} />
                    <KeyboardArrowRightIcon color='primary' onClick={() => navigateTimeline(DIRECTIONS.forwardOne)} />
                    <KeyboardDoubleArrowRightIcon color='primary' onClick={() => navigateTimeline(DIRECTIONS.forward)} />
                </div>
            }
        </footer>
    );
}

const mapStateToProps = ({ transcript, account }) => ({
    transcript,
    account,
});

export default connect(mapStateToProps)(withTranslate(Footer));