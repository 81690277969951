import React from "react";
import { Box } from "@material-ui/core";
import PropTypes from "prop-types";
import InfoIcon from "@material-ui/icons/Info";

import AmberOutlinedButton from "../../buttons/AmberOutlinedButton";
import AmberPrimaryButton from "../../buttons/AmberPrimaryButton";
import PanelDescription from "../export/exportSubtitles/panelDescription";
import { isBurnInBusy, isBurnInDone } from "./helpers";
import ProcessingButton from "./ProcessingButton";
import { convertSecondsToDDMMYYYY } from "../../../helpers/timeUtils";
import RequestNewBurnIn from "./RequestNewBurnIn";

export default function BurnInAvailable(props) {
  const {
    translate,
    handleGoToBurnInConfirmation,
    handleDownloadBurnInVideo,
    burnInJob,
    language,
  } = props;

  const isLastExport = () => !!burnInJob.updated;

  const shouldShowLastExport = () => isLastExport() && isBurnInDone(burnInJob);

  // If status is not DONE and not BUSY then the user can create a new version
  const burnInAvailable = () =>
    !isBurnInDone(burnInJob) && !isBurnInBusy(burnInJob);

  // Show 'Download' or 'Processing' button
  function DownloadOrProcessingButton() {
    if (isBurnInDone(burnInJob)) {
      return (
        <AmberPrimaryButton
          text={translate("EDITOR.EXPORT.download")}
          onClick={handleDownloadBurnInVideo}
          testid="export-subtitles-dialog-burnin-downloadBtn"
        />
      );
    }
    if (isBurnInBusy(burnInJob)) {
      return (
        <ProcessingButton
          text={translate("EDITOR.BURN_IN.processingVideo")}
          onClick={() => {}}
          testid="export-subtitles-dialog-burnin-processingBtn"
          disabled
          icon={<InfoIcon />}
        />
      );
    }
    return null;
  }

  // Get date from timestamp
  const getDate = () => {
    const floored = Math.floor(burnInJob.updated) * 1000;
    return convertSecondsToDDMMYYYY(floored);
  };

  return (
    <>
      <PanelDescription
        text={translate("EDITOR.EXPORT.asAVideoWithBurnInDesc")}
        marginBottom={2}
        testid="export-subtitles-dialog-burnin-description"
      />
      <Box
        display="flex"
        flexDirection="row"
        mt={1}
        alignItems="center"
        height={40}
      >
        {burnInAvailable() && (
          <AmberOutlinedButton
            text={translate("EDITOR.EXPORT.createNewVersion")}
            onClick={handleGoToBurnInConfirmation}
            testid="export-subtitles-dialog-burnin-createNewBtn"
          />
        )}
        <Box ml={!burnInAvailable() ? -1.5 : 0}>
          <DownloadOrProcessingButton />
        </Box>
        {shouldShowLastExport() && (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="start"
            gridRowGap={8}
            ml={1.5}
          >
            <Box
              fontSize={14}
              fontWeight="bold"
              data-testid="export-subtitles-dialog-burnin-lastExport"
            >
              {`${translate("EDITOR.EXPORT.lastExport")} ${getDate()}`}
            </Box>
            {!burnInAvailable() && (
              <RequestNewBurnIn translate={translate} language={language} />
            )}
          </Box>
        )}
      </Box>
    </>
  );
}

BurnInAvailable.propTypes = {
  translate: PropTypes.func.isRequired,
  handleGoToBurnInConfirmation: PropTypes.func.isRequired,
  handleDownloadBurnInVideo: PropTypes.func.isRequired,
  lastExportDate: PropTypes.string,
  burnInJob: PropTypes.shape().isRequired,
  language: PropTypes.string.isRequired,
};
