import React, { PureComponent } from 'react'
import { getTimeFromSec } from '../../../helpers/timeUtils'

class BlockStartTime extends PureComponent {
    render() {
        let time = '-';
        if (this.props.time !== null) {
            time = getTimeFromSec(this.props.time);
        }
        return (
            <time className="time" contentEditable={false}>{time}</time>
        )
    }
}

export default BlockStartTime;
