/* eslint-disable default-case */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, createStyles, IconButton, makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import { withTranslate } from "react-redux-multilingual";

import ExportTextContent from "./ExportTextContent";
import ExportTextActions from "./ExportTextActions";
import {
  CSV_MILLISECONDS,
  DEFAULT_FPS,
  DEFAULT_VALUE_FOR_CHARS_PER_ROW,
  FORMAT_CSV,
  FORMAT_JSON,
  FORMAT_TEXT,
  FORMAT_WORD,
} from "./constants";
import { getFormats } from "./helpers";
import {
  exportAsCsv,
  exportAsJson,
  exportAsTxt,
  exportAsWord,
  exportMedia,
  trackExport,
} from "../ExportApi";

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      color: theme.palette.custom.charcoalGray,
      fontWeight: 400,
      fontSize: 24,
    },
  })
);

function ExportText(props) {
  const {
    translate,
    isTranscriptionFile,
    onClose,
    transcript,
    transcriptStatus,
    isUserSubscriptionActive,
    isCaptions = false,
    onBack = () => {},
    openJobSatisfactionDialog,
  } = props;

  const [formats] = useState(getFormats(isTranscriptionFile));

  const [exportData, setExportData] = useState({
    format: formats[0],
    includeTimestamps: true,
    includeSpeakers: true,
    includeHighlights: false,
    exportOnlyHighlights: false,
    frameRate: DEFAULT_FPS,
    timeFormat: CSV_MILLISECONDS,
    maxCharsPerRow: {
      active: false,
      value: DEFAULT_VALUE_FOR_CHARS_PER_ROW,
    },
  });

  const classes = useStyles();

  // Export
  const handleExport = () => {
    const {
      format,
      includeTimestamps,
      includeSpeakers,
      includeHighlights,
      exportOnlyHighlights: highlightsOnly,
      maxCharsPerRow,
      frameRate,
      timeFormat,
    } = exportData;
    const options = {
      includeTimestamps,
      includeSpeakers,
      includeHighlights,
      highlightsOnly,
      frameRate,
      timeFormat,
      languageCode: transcriptStatus?.data?.language || 'en',
      filename: transcriptStatus?.data?.audioDisplayFileName || 'transcript',
    };

    switch (format) {
      case FORMAT_WORD:
        const footerText = isUserSubscriptionActive
          ? ""
          : translate("EDITOR.EXPORT.transcribedByAmberScript");
        exportAsWord(transcript, transcriptStatus, options, footerText);
        break;
      case FORMAT_JSON:
        exportAsJson(transcript, transcriptStatus, options);
        break;
      case FORMAT_TEXT:
        exportAsTxt(transcript, transcriptStatus, options, maxCharsPerRow);
        break;
      case FORMAT_CSV:
        exportAsCsv(transcriptStatus, options, maxCharsPerRow);
        break;
    }

    trackExport(format, transcriptStatus);
    setTimeout(() => {
      onClose();
      openJobSatisfactionDialog();
    }, 100);
  };

  // Download file
  const handleDownloadFile = () => {
    exportMedia(transcriptStatus);

    trackExport("Media", transcriptStatus);
    setTimeout(() => {
      onClose();
    }, 100);
  };

  const getAdaptedTitle = () =>
    isCaptions
      ? translate("EDITOR.EXPORT.title")
      : translate("EDITOR.EXPORT.titleTranscript");

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mt={3}
        mb={1.5}
        ml={isCaptions ? 2 : 4.5}
        mr={3}
      >
        <Box
          display="flex"
          gridColumnGap={isCaptions ? 12 : 4}
          alignItems="center"
        >
          {isCaptions && (
            <IconButton
              onClick={onBack}
              data-testid="export-text-dialog-back-btn"
            >
              <ArrowLeft />
            </IconButton>
          )}
          <Box className={classes.title}>{getAdaptedTitle()}</Box>
        </Box>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <ExportTextContent
        exportData={exportData}
        setExportData={setExportData}
        formats={formats}
        isCaptions={isCaptions}
      />
      <ExportTextActions
        handleExport={handleExport}
        handleDownloadFile={handleDownloadFile}
        isVideo={transcriptStatus.data.isVideo}
        isCaptions={isCaptions}
        transcriptStatus={transcriptStatus}
        onClose={onClose}
      />
    </>
  );
}

ExportText.propTypes = {
  translate: PropTypes.func.isRequired,
  isTranscriptionFile: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  transcript: PropTypes.shape().isRequired,
  transcriptStatus: PropTypes.shape().isRequired,
  openJobSatisfactionDialog: PropTypes.func.isRequired,
  isUserSubscriptionActive: PropTypes.bool,
  isCaptions: PropTypes.bool,
  onBack: PropTypes.func,
};

ExportText.defaultProps = {
  isUserSubscriptionActive: false,
  onBack: () => {},
  isCaptions: false,
};

export default withTranslate(ExportText);
