import React from 'react'
import { Box } from '@material-ui/core'
import { connect } from 'react-redux'

import ForwardButton from './ForwardButton'
import PlayButton from './PlayButton'
import RewindButton from './RewindButton'

class MediaFileControllers extends React.Component {
    render() {
        const { addSteps, onRewind, onForward } = this.props;

        return (
            <Box display="flex" marginLeft={0.5} marginRight={0.5} position="relative" justifyContent="space-between" alignItems="center">
                <RewindButton addSteps={addSteps} onRewind={onRewind} />
                <PlayButton addSteps={addSteps} />
                <ForwardButton addSteps={addSteps} onForward={onForward} />
            </Box>
        )
    }
}

const mapStateToProps = ({ transcriptAudio }) => ({ transcriptAudio });

export default connect(mapStateToProps)(MediaFileControllers)
