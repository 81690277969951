import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import RatingAverageNumber from '../../labels/ratingAverageNumber';

import { DIFFICULTY } from '../../../constants/ratingCategories';
import RatingSection from './ratingSection';
import AmberSectionTitle from '../../labels/AmberSectionTitle';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  averageSectionContainer: {
    paddingTop: '0.5em',
    paddingBottom: '1.5em',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  averageTitle: {
    color: theme.palette.custom.charcoalGray,
    marginBottom: 0,
  },
  cuteNumber: {
    borderRadius: '3em',
    textAlign: 'center',
    fontWeight: '900',
    padding: '0.1em 1em 0 1em',
    maxHeight: '1.2em',
  },
  cuteNumberRed: {
    backgroundColor: theme.palette.custom.pinkishRed,
    border: `1px solid ${theme.palette.custom.pinkishRed}`,
    color: theme.palette.primary.white,
  },
  cuteNumberGreen: {
    backgroundColor: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.white,
  },
  cuteNumberMango: {
    backgroundColor: theme.palette.custom.mango,
    color: theme.palette.custom.charcoalGray,
    border: `1px solid ${theme.palette.custom.pinkishRed}`,
  },
}));

const calculateAverage = (ratings, ratingKeys) => {
  const defaultWeight = 1 / ratingKeys.length;
  const total = ratingKeys.reduce((acc, key) => {
    const { selected, weight } = ratings[key];
    return acc + (selected || 0) * (weight || defaultWeight);
  }, 0);
  return parseFloat((total).toFixed(2));
};

function RatingForm(props) {

  const { handleRatingChange, id, translate, customRatings } = props;
  const [ratingDefaults, setRatingDefault] = useState(customRatings);
  const [average, setAverage] = useState(0);
  const classes = useStyles();
  const ratingKeys = Object.keys(ratingDefaults).filter(
    (key) => key !== DIFFICULTY,
  );

  const onClick = (event) => {
    const {
      target: { sectionKey, value },
    } = event;
    const newRatings = { ...ratingDefaults };
    newRatings[sectionKey].selected = value;
    const newAverage = calculateAverage(newRatings, ratingKeys);
    setAverage(newAverage);
    setRatingDefault(newRatings);
    handleRatingChange({ target: { value: newRatings, id } });
  };

  return (
    <div className={classes.formContainer}>
      {ratingKeys.map((key) => {
        const { label, tooltip, min, max } = ratingDefaults[key];
        return (
          <RatingSection
            key={key}
            label={label}
            tooltip={tooltip}
            sectionKey={key}
            currentSelected={ratingDefaults[key].selected}
            min={min}
            max={max}
            onClick={onClick}
          />
        );
      })}
      <div className={classes.averageSectionContainer}>
        <AmberSectionTitle
          text={translate('EDITOR.dialogJobFeedback.total')}
          typographyVariant="inherit"
          typographyClasses={{
            root: classes.averageTitle,
          }}
        />
        <RatingAverageNumber average={average} />
      </div>
      <RatingSection
        sectionKey={DIFFICULTY}
        label={ratingDefaults[DIFFICULTY].label}
        min={ratingDefaults[DIFFICULTY].min}
        max={ratingDefaults[DIFFICULTY].max}
        onClick={onClick}
        currentSelected={ratingDefaults[DIFFICULTY].selected}
        explanation={translate('EDITOR.dialogJobFeedback.difficultyExplanation')}
      />
    </div>
  );
}

RatingForm.propTypes = {
  handleRatingChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};
export default RatingForm;
