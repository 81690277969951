import {
	AUTH_LOGOUT,
	GET_QUALITY_CHECKS,
	GET_QUALITY_CHECKS_OK,
	GET_QUALITY_CHECKS_FAIL,
} from '../sagas/constants';

const initialState = {
	data: {},

	loading: true,
	loaded: false,

	isError: false,
	message: null,
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case GET_QUALITY_CHECKS: {
			return { ...state, loading: true, error: false };
		}

		case GET_QUALITY_CHECKS_OK: {
			return {
				...state,
				loading: false,
				loaded: true,
				isError: false,
				data: action?.payload?.content?.[0] || {},
			};
		}

		case GET_QUALITY_CHECKS_FAIL: {
			return {
				...state,
				loading: false,
				loaded: false,
				isError: true,
				message: action.payload,
			};
		}

		case AUTH_LOGOUT: {
			return {
				...state,
				companyKey: null,
			};
		}
		default: {
			return state;
		}
	}
}
