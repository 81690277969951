import { ALIGNMENT_SUPPORTED_LANGUAGES } from './constants';

/**
 * Returns true if language is a variant of the supported languages
 * @param {string} language
 * @returns {boolean}
 */
export function checkIfAlignmentSupportsLanguage(language) {
  const original = language.slice(0, 2);
  return ALIGNMENT_SUPPORTED_LANGUAGES.includes(original);
}
