import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslate } from 'react-redux-multilingual'
import Tooltip from 'rc-tooltip'
// keep an eye on the release of material-ui@next slider component: https://git.io/vNKVT
import Slider, { Handle } from 'rc-slider'
import { SET_AUDIO_SPEED } from '../../sagas/constants'
import './SpeedSlider.scss'


class SpeedSlider extends Component {

    state = {
        speed: 1
    }

    onSpeedChange = (speed) => {
        this.setState({speed});
        this.props.dispatch({
            type: SET_AUDIO_SPEED, speed: speed
        })
    }

    render() {
        const {speed} = this.state;
        const {translate} = this.props;

        const handle = (props) => {
            const {value, dragging, index, ...restProps} = props;

            return (
                <Tooltip
                    prefixCls="rc-slider-tooltip"
                    overlay={value}
                    visible={dragging}
                    placement="top"
                    key={index}
                >
                    <Handle value={value} {...restProps} />
                </Tooltip>
            );
        };

        return (
            <div className="SpeedSlider">
                <strong className="SpeedSlider__title">
                    {translate('EDITOR.metaPlaybackRate')}
                </strong>
                <Slider className="SpeedSlider__slider"
                        min={0.1} max={2.0} step={0.1} defaultValue={1.0}
                        handle={handle} onChange={this.onSpeedChange}
                />
                <span className="SpeedSlider__value">{speed}</span>
            </div>
        )
    }
}

export default connect()(withTranslate(SpeedSlider));