import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Box, CircularProgress, Button } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  btnDisabled: {
    whiteSpace: 'nowrap',
    textTransform: 'none',
    border: 'none',
  },
  btnActive: {
    whiteSpace: 'nowrap',
    textTransform: 'none',
    border: `2px solid ${theme.palette.primary.main}`,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonSuccess: {
    border: `2px solid ${theme.palette.success.main}`,
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.main,
    },
  },
  buttonFailure: {
    backgroundColor: theme.palette.error.main,
    border: `2px solid ${theme.palette.error.main}`,

    '&:hover': {
      backgroundColor: theme.palette.error.main,
    },
  },
  buttonProgress: {
    color: 'white',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));
export default function AmberLoadingButton(props) {
  const {
    text,
    success,
    loading,
    failure,
    onClick,
    spinnerClass,
    testid,
    ...rest
  } = props;
  const classes = useStyles();

  const timer = React.useRef();
  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
    [classes.buttonFailure]: failure,
    [classes.btnDisabled]: rest.disabled,
    [classes.btnActive]: !rest.disabled,
  });

  React.useEffect(
    () => () => {
      clearTimeout(timer.current);
    },
    []
  );

  return (
    <div className={classes.wrapper} style={rest.style}>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        className={buttonClassname}
        onClick={loading ? () => {} : onClick}
        data-testid={testid}
        {...rest}
      >
        <Box
          component="span"
          fontSize={14}
          fontWeight={600}
          paddingLeft={1}
          paddingRight={1}
        >
          {text}
        </Box>
      </Button>
      {loading && (
        <CircularProgress
          size={24}
          className={`${classes.buttonProgress} ${spinnerClass || ''}`}
          data-testid={`${testid}-spinner`}
        />
      )}
    </div>
  );
}
AmberLoadingButton.propTypes = {
  text: PropTypes.string.isRequired,
  success: PropTypes.bool,
  loading: PropTypes.bool,
  spinnerClass: PropTypes.string,
  failure: PropTypes.bool,
  onClick: PropTypes.func,
  testid: PropTypes.string,
};

AmberLoadingButton.defaultProps = {
  success: false,
  loading: false,
  failure: false,
  onClick: null,
  spinnerClass: '',
  testid: '',
};
