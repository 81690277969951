import React from 'react';
import { connect } from 'react-redux';
import { withTranslate } from 'react-redux-multilingual';
import classNames from 'classnames';
import {
	Button,
	ClickAwayListener,
	Divider,
	MenuList,
	Paper,
	Tooltip,
	Popper,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import InviteFriends from './InviteFriends';

import IconAvatar from '../../assets/images/icons/custom-avatar.svg';
import MenuItemLink from '../ui/MenuItemLink';

import { logout as logoutAPI, redirectToLogin } from '../../utils/api';
import { DASHBOARD_URL } from '../../settings';

import '../ui/HeaderButton.scss';

const styles = (theme) => ({
	popperClose: {
		pointerEvents: 'none',
	},
	menuList: {
		minWidth: 256,
	},
	[theme.breakpoints.down('sm')]: {
		buttonText: {
			display: 'none',
		},
	},
});

class ProfileMenu extends React.Component {
	state = {
		open: false,
		referralDialog: false,
		anchorEl: null,
	};

	handleClick = (event) => {
		const { currentTarget } = event;
		this.setState({
			open: true,
			anchorEl: currentTarget,
		});
	};

	handleClose = (event) => {
		this.setState({ open: false });
	};

	logout = () => {
		logoutAPI().then(() => {
			redirectToLogin();
		});
	};

	openInviteFriendsDialog = () => {
		this.setState({
			open: false,
			referralDialog: true,
		});
	};

	closeInviteFriendsDialog = () => {
		this.setState({
			referralDialog: false,
		});
	};

	render() {
		const { classes, user, translate, account, referralDetails } = this.props;
		const { open, referralDialog } = this.state;
		const newBadge = translate('NAV.newBadge');
		const activeReferralCode = account.data.activeReferralCode;

		if (!user.data) {
			return '';
		}
		const userName = user.data['userName'];

		return (
			<React.Fragment>
				<Tooltip title="Account" placement="bottom">
					<Button
						id="ProfileMenuToggle"
						className="HeaderButton"
						aria-owns={open ? 'menu-list' : null}
						aria-haspopup="true"
						onClick={this.handleClick}
						style={{ color: '#005A50' }}
					>
						<i>
							<img src={IconAvatar} alt="avatar" />
						</i>
						{userName && (
							<strong className={classes.buttonText}>{userName}</strong>
						)}
					</Button>
				</Tooltip>
				<Popper
					placement="bottom-start"
					open={open}
					className={classNames({ [classes.popperClose]: !open })}
					anchorEl={this.state.anchorEl}
					style={{ zIndex: 1000 }}
					transition
				>
					{({ TransitionProps }) => (
						<ClickAwayListener onClickAway={this.handleClose}>
							<Paper>
								<MenuList className={classes.menuList} role="menu">
									<MenuItemLink
										href={`${DASHBOARD_URL}/records`}
										text={translate('NAV.titleRecords')}
										color="primary"
									/>
									<Divider />
									<MenuItemLink
										href={`${DASHBOARD_URL}/account`}
										text={translate('NAV.titleAccount')}
									/>
									<MenuItemLink
										href={`${DASHBOARD_URL}/redeem`}
										text={translate('NAV.titleCoupon')}
									/>
									{activeReferralCode && !referralDetails.error && (
										<MenuItemLink
											new={newBadge}
											text={`${translate('NAV.titleInvite')}`}
											onClick={this.openInviteFriendsDialog}
										/>
									)}
									{account.data.userSubscription &&
										account.data.userSubscription.active && (
											<MenuItemLink
												href={`${DASHBOARD_URL}/plan`}
												text={translate('NAV.titlePlan')}
											/>
										)}
									<MenuItemLink
										href={`${DASHBOARD_URL}/billing`}
										text={translate('NAV.titleBilling')}
									/>
									<MenuItemLink
										href={`${DASHBOARD_URL}/invoices`}
										text={translate('NAV.titleInvoices')}
									/>
									<Divider />
									<MenuItemLink
										onClick={this.logout}
										text={translate('MISC.logout')}
										color="secondary"
									/>
								</MenuList>
							</Paper>
						</ClickAwayListener>
					)}
				</Popper>
				{referralDialog && (
					<InviteFriends onClose={this.closeInviteFriendsDialog} />
				)}
			</React.Fragment>
		);
	}
}

const mapStateToProps = ({ account, referralDetails }) => ({
	account,
	referralDetails,
});

export default connect(mapStateToProps)(
	withStyles(styles)(withTranslate(ProfileMenu)),
);
