import React, { Component } from 'react'
import { withTranslate } from 'react-redux-multilingual'
import { Button, Tooltip } from '@material-ui/core'
import { UndoIcon } from '../ui/Icons'

class UndoButton extends Component {

    handleClick = () => {
        this.props.onUndo();
    }

    render() {
        const { translate, steps } = this.props;

        return (
            <Tooltip title={`${translate('EDITOR.controlsUndo')} ${translate('EDITOR.controlsUndoShortcut')}`} placement="bottom">
                <Button onClick={this.handleClick} className={`UndoButton ${steps ? '' : 'UnActiveBtn'}`} color="default">
                    <UndoIcon /> {translate('EDITOR.controlsUndo')}
                </Button>
            </Tooltip>
        )
    }
}

export default withTranslate(UndoButton);
