import React, { Component } from 'react'
import { withTranslate } from 'react-redux-multilingual'
import { Tooltip, Button } from '@material-ui/core'

import Forward5Icon from '@material-ui/icons/Forward5'

import './RewindButton.scss'

class ForwardButton extends Component {

    handleClick = () => {
        this.props.onForward();
    }

    render() {
        const { translate } = this.props;

        return (
            <Tooltip title="Shortcut: (Alt + Right arrow)" placement="bottom">
                <Button id="ForwardButton" className="RewindButton" color="primary" onClick={this.handleClick}>
                    <Forward5Icon fontSize="large" style={{ color: '#757575', marginBottom: 4 }} />
                    {/* <em>{translate('EDITOR.forwardBtn')}</em> */}
                </Button>
            </Tooltip>
        )
    }
}

export default withTranslate(ForwardButton);
