import React, { Component } from "react";
import { connect } from "react-redux";
import queryString from "query-string";
import { BrowserRouter, Route } from "react-router-dom";

import Editor from "./EditorView";
import Login from "./Login";
import LoginFirebase from "./LoginFirebase";
import GTM from "./GTM";
import Loading from "./ui/Loading";

import { STORAGE_API_KEY } from "../settings";
import { GET_ACCOUNT, GET_ANALYTICS_DATALAYER } from "../sagas/constants";
import { informSentryOfCurrentAuthenticatedUser } from "../sentry/log";

class App extends Component {
  // Remove a parameter from the url
  removeURLParameter = (url, parameter) => {
    var urlparts = url.split("?");
    if (urlparts.length >= 2) {
      var prefix = encodeURIComponent(parameter) + "=";
      var pars = urlparts[1].split(/[&;]/g);

      for (var i = pars.length; i-- > 0; ) {
        if (pars[i].lastIndexOf(prefix, 0) !== -1) {
          pars.splice(i, 1);
        }
      }

      return urlparts[0] + (pars.length > 0 ? "?" + pars.join("&") : "");
    }
    return url;
  };

  // If there is a missmatch between apiKey in the url
  // and the amber.key we stored in the localstorage
  // should replace the one in the localstorage with the one in the url
  checkApiKey = () => {
    const parsedSearch = queryString.parse(window.location.search);
    const { apiKey, lang } = parsedSearch;

    if (apiKey) {
      if (apiKey !== localStorage.getItem(STORAGE_API_KEY)) {
        localStorage.setItem(STORAGE_API_KEY, apiKey);
      }

      const urlWithNoApiKey = this.removeURLParameter(
        window.location.href,
        "apiKey"
      );
      if (lang) {
        const urlWithNoLang = this.removeURLParameter(urlWithNoApiKey, "lang");
        window.location.assign(urlWithNoLang);
      } else {
        window.location.assign(urlWithNoApiKey);
      }
    }
  };

  componentWillMount() {
    this.checkApiKey();

    const { loaded, loading } = this.props.account;

    if (!loaded && !loading) {
      this.props.dispatch({ type: GET_ACCOUNT });
      this.props.dispatch({ type: GET_ANALYTICS_DATALAYER });
    }
  }

  isTranscriber(user) {
    return user.roles.includes("TRANSCRIBER");
  }

  addTranscriberParamsToURL() {
    const searchParams = new URLSearchParams(window.location.search);
    if (!searchParams.get("transcriber")) {
      searchParams.set("transcriber", true);
      window.location.search = searchParams.toString();
    }
  }

  componentDidUpdate(prevProps, prevStates) {
    // Succeeded
    if (this.props.account && this.props.account.data) {
      const user = this.props.account.data;
      const { email, userName, countryCode, userType } = user;

      // Setup Sentry for this user
      informSentryOfCurrentAuthenticatedUser(
        email,
        userName,
        countryCode,
        userType
      );

      if (this.isTranscriber(user)) {
        this.addTranscriberParamsToURL();
      }
    }
  }

  render() {
    const { loaded, error, errorMessage } = this.props.account;

    const NEW_LOGIN_ENABLED =
      process.env.REACT_APP_FF_NEW_LOGIN !== undefined
        ? JSON.parse(process.env.REACT_APP_FF_NEW_LOGIN)
        : false;

    if (!loaded && !error) {
      return <Loading />;
    }

    return (
      <BrowserRouter>
        <React.Fragment>
          {error && (NEW_LOGIN_ENABLED ? <LoginFirebase /> : <Login />)}
          {loaded && <Route exact path="/:transcriptId" component={Editor} />}
          <GTM />
        </React.Fragment>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = ({ auth, account }) => ({
  auth,
  account,
});

export default connect(mapStateToProps)(App);
