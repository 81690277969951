/**
 * Gets the start time of a word
 * @param {boolean} includeTimestamps
 * @param {object} word
 */
export function getStartTime(includeTimestamps, word) {
  if (includeTimestamps) {
    if (word.start !== null || word.start !== undefined) {
      return word.start;
    } else {
      return undefined;
    }
  } else {
    return undefined;
  }
}

/**
 * Gets the end time of a word
 * @param {boolean} includeTimestamps
 * @param {object} word
 */
export function getEndTime(includeTimestamps, word) {
  if (includeTimestamps) {
    if (word.end !== null || word.end !== undefined) {
      return word.end;
    } else {
      if (word.start !== null || word.start !== undefined) {
        return word.start;
      } else {
        return undefined;
      }
    }
  } else {
    return undefined;
  }
}

/**
 * Returns the number with 2 decimals
 * @param {number} number
 */
export function twoDecimalPoint(number) {
  if (typeof number === "number") {
    return Math.trunc(number * 100) / 100;
  } else {
    return Math.trunc(parseFloat(number) * 100) / 100;
  }
}

/**
 * Returns the duration of a word
 * @param {boolean} includeTimestamps
 * @param {object} word
 */
export function getDuration(includeTimestamps, word) {
  const startTime = word.start || 0;
  const endTime = word.end || startTime;

  if (includeTimestamps) {
    if (word.pristine === true) {
      if (word.dur) {
        return word.dur;
      } else {
        return twoDecimalPoint(endTime - startTime);
      }
    } else {
      return twoDecimalPoint(endTime - startTime);
    }
  } else {
    return undefined;
  }
}

/**
 * Returns an array of words objects
 * @param {object[]} words
 * @param {boolean} includeTimestamps
 * @param {boolean} includePristine
 * @param {boolean} newLine
 */
export function getWords(words, includeTimestamps, includePristine, newLine) {
  const result = [];

  for (let index = 0; index < words.length; index++) {
    const word = words[index];
    if (word) {
      if (word.text.trim().length > 0) {
        result.push({
          text:
            newLine && index === 0
              ? `<br>${word.text.trim()}`
              : word.text.trim(),
          start: getStartTime(includeTimestamps, word),
          end: getEndTime(includeTimestamps, word),
          dur: getDuration(includeTimestamps, word),
          conf: word.pristine === false ? 1 : word.conf,
          pristine:
            includePristine &&
            (word.pristine === true || word.pristine === false)
              ? word.pristine
              : undefined,
        });
      }
    }
  }

  return result;
}

export function getDataForSummaryCheck(speakers, segments) {

  const speakersJson = speakers.toArray().map((speaker) => ({
    spkid: speaker.id,
    name: speaker.name,
  }));

  const includeTimestamps = true;
  const includePristine = true;
  const segmentsJson = segments.toArray().reduce((result, segment) => {
    if (segment && segment.words) {
      const words = segment.words.toArray();
      if (words.length > 0) {
        const nonEmptyWords = getWords(
          words,
          includeTimestamps,
          includePristine,
          segment.newLine
        );
        if (nonEmptyWords.length > 0) {
          result.push({
            words: nonEmptyWords,
            speaker: segment.speaker,
          });
        }
      }
    }
    return result;
  }, []);

  const data = {
    speakers: speakersJson,
    segments: segmentsJson,
  };

  return data;
}
