import { all } from 'redux-saga/effects'

import {
    authorize,
    authorizeFirebase,
    deAuthorize,
    getAccountData,
    sendEmailValidation
} from './account'

import {
    getTranscript,
    getTranscriptAudio,
    getTranscriptList,
    getTranscriptStatus,
    saveTranscript,
    saveTranscriptStatus
} from './transcripts'

import { getAnalyticsDatalayer } from './analytics'

import { getAsrModels } from './asrModels'

import {
    sendReferralInvitation,
    getReferralCredit,
    getReferralCodeDetails
} from './referral'

import {
    getJobData,
    saveJobReview,
    submitCaptionJob
} from './job'

import {
    getQualityChecks
} from './qualityCheck'


export default function* rootSaga() {
    yield all([
        authorize(),
        authorizeFirebase(),
        deAuthorize(),
        getAccountData(),

        sendEmailValidation(),

        getTranscriptList(),
        getTranscriptStatus(),
        getTranscript(),
        getTranscriptAudio(),

        saveTranscript(),
        saveTranscriptStatus(),

        getAnalyticsDatalayer(),

        getAsrModels(),

        sendReferralInvitation(),
        getReferralCredit(),
        getReferralCodeDetails(),

        getJobData(),
        saveJobReview(),

        getQualityChecks(),
        submitCaptionJob()
    ])
}