import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import WaveSurfer from 'wavesurfer.js'
import classNames from 'classnames'
import { Box } from '@material-ui/core';
import FilterNoneIcon from '@material-ui/icons/FilterNone';

// import PlayerSubtitle from './PlayerSubtitle'

import Loading from '../ui/Loading'

import { SET_AUDIO_PAUSE, SET_AUDIO_TIME, RESIZE_VIDEO } from '../../sagas/constants'
import { DEBUG, REWIND_INTERVAL } from '../../settings'
import { getWaveformHighlights } from '../../helpers/highlightUtils'

import './TranscriptWavePlayer.scss'
import { trackSentryErrorByTag } from '../../sentry/log';
import { loadWaveform } from './helpers';
class WavePlayer extends Component {

    state = {
        loading: false,
        loaded: false,
        loadingMessage: 'Loading audio...',
        playing: false,
        pos: 0,
        currentTime: 0,
        speed: 1,
        broadcast: false,
        altKey: false,
        expanded: true
    };

    componentDidMount() {
        // set a key-listener for alt-clicking onSeek
        document.addEventListener("keydown", this._handleKeyDown);
        document.addEventListener("keyup", this._handleKeyUp);
    }

    componentWillUnmount() {
        this.setState({ playing: false });
        this.props.dispatch({ type: SET_AUDIO_PAUSE });
        this.pausePlaying();
        document.removeEventListener("keydown", this._handleKeyDown);
        document.removeEventListener("keyup", this._handleKeyUp);
        this.wavesurfer.unAll();
    }

    initWaveSurfer(props) {
        const { speed, transcriptStatus } = props;
        const { data: dataStatus } = transcriptStatus;
        const _this = this;

        let options = {
            container: this.waveContainer,
            waveColor: '#cccccc',
            barHeight: 4,
            progressColor: 'rgb(0, 90, 80)',
            cursorColor: 'transparent',
            scrollParent: false,
            fillParent: true,
            playing: false,
            pixelRatio: 1,
            responsive: true,
        };

        if (dataStatus.hasWaveformPeaks) {
            options = {
                ...options,
                normalize: true,
            }
        }

        // if (dataStatus.isVideo) {
        //     options = {...options, 
        //         backend: 'MediaElement',
        //         mediaType: dataStatus.isVideo ? 'video' : 'audio',
        //         mediaContainer: this.mediaContainer,
        //     };
        // }

        options = {
            ...options,
            backend: 'MediaElement',
            mediaType: dataStatus.isVideo ? 'video' : 'audio',
            mediaContainer: this.mediaContainer,
        };

        this.wavesurfer = WaveSurfer.create(options);

        /**
         * Flag for blocking editor content scrolling on rewind
         *
         * @type {boolean}
         */
        this.rewind = false;


        this.wavesurfer.on('loading', function (percent) {
            _this.setState({ loadingMessage: `Loading audio... ${percent}%` });
        });

        this.wavesurfer.on('ready', function () {
            _this.setState({ loaded: true, loading: false });
        });

        this.wavesurfer.on('finish', function () {
            _this.setState({ playing: false });
            _this.props.dispatch({ type: SET_AUDIO_PAUSE });
            _this.pausePlaying();
        });

        this.wavesurfer.on('seek', function (position) {
            const time = position * _this.wavesurfer.getDuration();
            _this.props.onSetAudioTime(time, !_this.rewind);
            _this.rewind = false; // Clear editor content scrolling block flag
            _this.props.dispatch({
                type: SET_AUDIO_TIME,
                currentTime: time,
                onSeek: true
            });
        });

        // TODO: either fix it or remove it: this click handler does not work
        // when clicked while presing the altKey
        this.wavesurfer.drawer.on("click", (e, progress) => {
          if (typeof progress === "number" && progress >= 0 && progress <= 1) {
            setTimeout(() => _this.wavesurfer.seekTo(progress), 0);
            _this.props.onSeekTime();
            if (_this.state.altKey) {
              _this.props.onPlaceCursor(
                progress * _this.wavesurfer.getDuration()
              );
              _this.setState({ placeCursor: true });
            }
          } else {
            console.error("Invalid progress value:", progress);
          }
        });

        this.wavesurfer.on('error', function (e) {
            _this.setState({
                loading: false,
                loaded: false,
            });
            _this.props.updateAudioLink();
        });

        this.setState({ speed });
        this.wavesurfer.setPlaybackRate(speed);
    }

    componentWillReceiveProps(nextProps) {
        const { playing, speed, data: dataAudio } = nextProps.transcriptAudio;
        const { data: dataStatus } = nextProps.transcriptStatus;

        if (dataStatus && !this.wavesurfer) {
            this.initWaveSurfer(nextProps);
        }

        if (dataAudio !== null && dataStatus !== null && !this.state.loading && !this.state.loaded) {
            const { audioDisplayFileName: fileName, nrAudioSeconds: duration } = dataStatus;

            this.setState({ loading: true });
            try {
                loadWaveform(dataStatus, this.wavesurfer, dataAudio, duration);
            } catch (error) {
                if (DEBUG) {
                    console.log(`Peak-data ${fileName}.json not found`);
                }
                if (dataAudio.byteLength === 0) {
                    this.setState({ loading: false });
                }
                trackSentryErrorByTag(error, 'transcript-editor-waveform-failed-to-load');
            }
        }

        if (playing && !this.state.playing && this.state.loaded) {
            this.setState({ playing });
            this.startPlaying();
        } else if (!playing && this.state.playing) {
            this.setState({ playing });
            this.pausePlaying();
        }

        if (this.state.speed !== speed) {
            this.setState({ speed });
            this.wavesurfer.setPlaybackRate(speed);
        }
    }

    isReady = () => {
        return this.state.loaded;
    }

    _handleKeyDown = (e) => {
        if (e.keyCode === 18) {
            this.setState({ altKey: true });
        }
    }

    _handleKeyUp = (e) => {
        if (e.keyCode === 18) {
            this.setState({ altKey: false });
        }
    }

    startPlaying = () => {
        this.wavesurfer.play();
        this.interval = setInterval(() => this.updateTime(), 1200);
    }

    pausePlaying = () => {
        this.wavesurfer.pause();
        clearInterval(this.interval);
    }

    updateTime = () => {
        const time = this.wavesurfer.getCurrentTime();
        this.props.onSetAudioTime(time, false);
        this.props.dispatch({
            type: SET_AUDIO_TIME,
            currentTime: time,
            onSeek: false
        });
    }

    doRewind = () => {
        this.rewind = true; // Block editor content scrolling
        this.wavesurfer.skipBackward(Math.max(REWIND_INTERVAL));
    }

    doForward = () => {
        this.rewind = true; // Block editor content scrolling
        this.wavesurfer.skipForward(Math.max(REWIND_INTERVAL));
    }

    doSeekAndCenter = (time) => {
        const position = (time - this.props.startTimeOffset) / this.wavesurfer.getDuration();
        if (!isNaN(position)) {
            this.rewind = true;  // Block editor content scrolling
            this.wavesurfer.seekAndCenter(position);
        }
    }

    changeVideoSize = () => {
        const { expanded } = this.state;
        this.setState({ expanded: !expanded })
        this.props.dispatch({
            type: RESIZE_VIDEO,
            expanded: !expanded
        })
    }

    render() {
        const { transcriptAudio, transcriptStatus, highlights, startTimeOffset } = this.props;
        const { loadingMessage } = this.state;
        const { loaded } = transcriptAudio;
        let highlightsUi = [];
        if (loaded && this.wavesurfer) {
            highlightsUi = getWaveformHighlights(highlights.toArray(), startTimeOffset, this.wavesurfer.getDuration())
        }
        const isVideo = transcriptStatus.data && transcriptStatus.data.isVideo;

        return (
            <Fragment>
                <div className="WavePlayer">
                    {!this.state.loaded &&
                        <Loading message={loadingMessage} cover={true} size={20} />}
                    <div
                        className={classNames({
                            "WavePlayer__wavewrap": true,
                            "loading": !this.state.loaded,
                        })}
                        ref={el => this.waveContainer = el}
                        onMouseDown={(event) => { event.preventDefault() }}>
                    </div>

                    {this.state.loaded && highlightsUi.map(({ left, width }, idx) =>
                        <div className="WavePlayer__highlight" key={idx} style={{ left, width }}></div>
                    )}
                </div>
                <div
                    ref={el => this.mediaContainer = el}
                    className={classNames({
                        "WavePlayer__mediawrap": this.props.shouldShowCreateSubtitleBtn(),
                        "WavePlayer__mediawrap_noguid": !this.props.shouldShowCreateSubtitleBtn(),
                        "isVideo": isVideo,
                        "isExpanded": this.state.expanded
                    })}
                >
                    {isVideo && this.state.loaded &&
                        <Box className={classNames({ "material-icons": true, "resize_video": true, "isExpanded": this.state.expanded })}>
                            <FilterNoneIcon onClick={e => this.changeVideoSize()} />
                        </Box>
                    }
                    {/* {isConvertedToSubtitles && this.state.loaded && <PlayerSubtitle audio={!isVideo} expanded={this.state.expanded} />} */}
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = ({ transcript, transcriptAudio, transcriptStatus }) => ({
    highlights: transcript.data.highlights,
    isConvertedToSubtitles: transcript.data.isConvertedToSubtitles,
    transcriptAudio,
    transcriptStatus
});

export default connect(mapStateToProps, null, null, { withRef: true })(WavePlayer);
