/**
 * Loads the waveform into the wavesurfer instance
 * Renders an empty waveform if waveform peaka are unavailable
 * @param {Object} dataStatus 
 * @param {Object} wavesurfer 
 * @param {Object} dataAudio 
 * @param {number} duration 
 */
export function loadWaveform(dataStatus, wavesurfer, dataAudio, duration) {
  const peaks = dataStatus.waveformPeaksData || [];
  wavesurfer.load(dataAudio.downloadUrl, peaks, 'auto', duration);
}