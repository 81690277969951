import React from 'react'
import { Snackbar, IconButton } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close'

const styles = theme => ({
    close: {
        width: theme.spacing.unit * 4,
        height: theme.spacing.unit * 4,
    },
    root: {
        right: 82,
    },
});

function Notification(props) {
    const { open, handleClose, classes, message, dismissable } = props;
    const snackbarClasses = { root: classes.root };

    const actions = [];

    if (dismissable) {
        actions.push = <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            className={classes.close}
            onClick={handleClose}
        >
            <CloseIcon />
        </IconButton>;
    }

    /*<Button
        key="undo"
        color="secondary"
        size="small"
        onClick={this.handleClose}>
      UNDO
    </Button>*/

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            open={open}
            autoHideDuration={5000}
            onClose={handleClose}
            SnackbarContentProps={{
                'aria-describedby': 'notification-id',
            }}
            message={<span id="notification-id">{message}</span>}
            action={actions}
            classes={snackbarClasses}
        />
    );
}

export default withStyles(styles)(Notification);
