import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { ENVIRONMENT, RAVEN_DNS } from '../settings';

export default function setupSentry() {
	Sentry.init({
		dsn: RAVEN_DNS,
		ignoreErrors: [
			`/Cannot use 'in' operator to search for/g`,
			`Cannot use 'in' operator to search for`,
			`right-hand side of 'in' should be an object, got undefined`,
			`cwvi.strg['urls'] is not an Object. (evaluating 'cwvi.rum.url in cwvi.strg['urls']')`,
			`b.cwvi.strg.urls is not an Object. (evaluating 'b.cwvi.rum.url in b.cwvi.strg.urls')`,
			`cwvi.strg['urls'] is not an Object`,
			`/cwvi.strg['urls'] is not an Object/g`,
		],
		integrations: [new Integrations.BrowserTracing()],
		environment: ENVIRONMENT,
	});
}
