import React from "react";
import { Box, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

// Style
const useStyles = makeStyles((theme) => ({
  btn_enabled: {
    textTransform: "none",
    whiteSpace: "nowrap",
    border: `2px solid ${theme.palette.primary.main}`,
  },
  btn_disabled: {
    textTransform: "none",
    whiteSpace: "nowrap",
    border: "2px solid #e0e0e0",
  },
}));

// Component
function AmberOutlinedButton(props) {
  const { onClick, text, disabled, iconComponent, testid, ...rest } = props;
  const classes = useStyles();

  function getClassNameByStatus() {
    if (disabled) {
      return classes.btn_disabled;
    }
    return classes.btn_enabled;
  }

  return (
    <Button
      variant="outlined"
      onClick={onClick}
      color="primary"
      className={getClassNameByStatus(rest)}
      disabled={disabled}
      data-testid={testid}
      {...rest}
    >
      {iconComponent}
      <Box
        component="span"
        fontSize={14}
        fontWeight={600}
        color="primary"
        paddingLeft={1}
        paddingRight={1}
      >
        {text}
      </Box>
    </Button>
  );
}

AmberOutlinedButton.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  testid: PropTypes.string,
};
AmberOutlinedButton.defaultProps = {
  disabled: false,
  testid: "",
};

export default AmberOutlinedButton;
