import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Tooltip from 'rc-tooltip'
// keep an eye on the release of material-ui@next slider component: https://git.io/vNKVT
import Slider, { Handle } from 'rc-slider'
import './Slider.scss'


class CustomSlider extends PureComponent {


    onChange = (value) => {
		this.props.onChange(value);
    }

    render() {
        const handle = (props) => {
            const {value, dragging, index, ...restProps} = props;

            return (
                <Tooltip
                    prefixCls="rc-slider-tooltip"
                    overlay={value}
                    visible={dragging}
                    placement="top"
                    key={index}
                >
                    <Handle value={value} {...restProps} />
                </Tooltip>
            );
        };

        return (
                <Slider
                    {...this.props}
                    handle={handle}
                />
        )
    }
}

export default CustomSlider;

CustomSlider.propTypes = {
    defaultValue: PropTypes.number.isRequired,
    step: PropTypes.number.isRequired,
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
};