import React from 'react'
import { withTranslate } from 'react-redux-multilingual'
import { Dialog, DialogContent, DialogTitle, Slide } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles';

import './Shortcuts.scss'


function Transition(props) {
    return <Slide direction="up" {...props} />;
}

const styles = theme => ({
});


function Shortcuts(props) {

    const { translate, classes, open, onClose, isCaptionFile } = props;

    return (
        <Dialog
            className={classes.root}
            open={open}
            onClose={onClose}
            transition={Transition}
            maxWidth="sm"
            aria-labelledby="Shortcuts-dialog-title"
        >
            <DialogTitle id="Shortcuts-dialog-title">{translate('EDITOR.shortcutsTitle')}</DialogTitle>
            <DialogContent>
                <table className="Shortcuts__table">
                    <tbody>
                        {/* PLAY PAUSE */}
                        <tr className="Shortcuts__shortcuts">
                            <td>
                                <div className="Shortcuts__label">{translate('SHORTCUTS.playPause')}</div>
                                <div className="Shortcuts__shortcut">
                                    <span className="Shortcuts__combination">
                                        <span className="Shortcuts__key">Tab</span>
                                    </span>
                                    {
                                        /* `Control + Space` dos not work for mac */
                                        window.navigator.userAgent.indexOf("Mac") === -1 ? (
                                            <>
                                                <span>{` ${translate('PASSWORDRECOVER.or')} `}</span>
                                                <span className="Shortcuts__combination">
                                                    <span className="Shortcuts__key">(Control</span>
                                                    <span className="Shortcuts__plus">+</span>
                                                    <span className="Shortcuts__key">Space)</span>
                                                </span>
                                            </>
                                        ) : null
                                    }
                                </div>
                            </td>
                        </tr>
                        {/* NEW LINE */}
                        {isCaptionFile() &&
                            <tr className="Shortcuts__shortcuts">
                                <td>
                                    <div className="Shortcuts__label">Add a new line within a block</div>
                                    <div className="Shortcuts__shortcut">
                                        <span className="Shortcuts__combination">
                                            <span className="Shortcuts__key">Shift + Enter</span>
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        }
                        {/* REWIND */}
                        <tr className="Shortcuts__shortcuts">
                            <td>
                                <div className="Shortcuts__label">{translate('SHORTCUTS.rewind')}</div>
                                <div className="Shortcuts__shortcut">
                                    <span className="Shortcuts__combination">
                                        <span className="Shortcuts__key">(Control</span>
                                        <span className="Shortcuts__plus">+</span>
                                        <span className="Shortcuts__key">R)</span>
                                        <span>{` ${translate('PASSWORDRECOVER.or')} `}</span>
                                        <span className="Shortcuts__combination">
                                            <span className="Shortcuts__key">(Alt</span>
                                            <span className="Shortcuts__plus">+</span>
                                            <span className="Shortcuts__key">Left arrow)</span>
                                        </span>
                                    </span>
                                </div>
                            </td>
                        </tr>
                        {/* FORWARD */}
                        <tr className="Shortcuts__shortcuts">
                            <td>
                                <div className="Shortcuts__label">{translate('SHORTCUTS.forward')}</div>
                                <div className="Shortcuts__shortcut">
                                    <span className="Shortcuts__combination">
                                        <span className="Shortcuts__combination">
                                            <span className="Shortcuts__key">Alt</span>
                                            <span className="Shortcuts__plus">+</span>
                                            <span className="Shortcuts__key">Right arrow</span>
                                        </span>
                                    </span>
                                </div>
                            </td>
                        </tr>
                        {/* Alt CLICK */}
                        <tr className="Shortcuts__shortcuts">
                            <td>
                                <div className="Shortcuts__label">{translate('SHORTCUTS.altClick')}</div>
                                <div className="Shortcuts__shortcut"><span className="Shortcuts__combination"><span className="Shortcuts__key">Alt</span><span className="Shortcuts__plus">+</span><span className="Shortcuts__key">Click</span></span></div>
                            </td>
                        </tr>
                        {/* HIGHLIGHT */}
                        <tr className="Shortcuts__shortcuts">
                            <td>
                                <div className="Shortcuts__label">{translate('SHORTCUTS.highlight')}</div>
                                <div className="Shortcuts__shortcut"><span className="Shortcuts__combination"><span className="Shortcuts__key">Control</span><span className="Shortcuts__plus">+</span><span className="Shortcuts__key">H</span></span></div>
                            </td>
                        </tr>
                        {/* UNDO */}
                        <tr className="Shortcuts__shortcuts">
                            <td>
                                <div className="Shortcuts__label">{translate('SHORTCUTS.undo')}</div>
                                <div className="Shortcuts__shortcut"><span className="Shortcuts__combination"><span className="Shortcuts__key">Control</span><span className="Shortcuts__plus">+</span><span className="Shortcuts__key">Z</span></span></div>
                            </td>
                        </tr>
                        {/* REDO */}
                        <tr className="Shortcuts__shortcuts">
                            <td>
                                <div className="Shortcuts__label">{translate('SHORTCUTS.redo')}</div>
                                <div className="Shortcuts__shortcut"><span className="Shortcuts__combination"><span className="Shortcuts__key">Control</span><span className="Shortcuts__plus">+</span><span className="Shortcuts__key">Shift</span><span className="Shortcuts__plus">+</span><span className="Shortcuts__key">Z</span></span></div>
                            </td>
                        </tr>
                        {/* SPELL CHECK */}
                        <tr className="Shortcuts__shortcuts">
                            <td>
                                <div className="Shortcuts__label">{translate('EDITOR.controlsSpellCheck')}</div>
                                <div className="Shortcuts__shortcut"><span className="Shortcuts__combination"><span className="Shortcuts__key">Control</span><span className="Shortcuts__plus">+</span><span className="Shortcuts__key">G</span></span></div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </DialogContent>
        </Dialog>
    );
}

export default withStyles(styles)(withTranslate(Shortcuts));