import Immutable from 'immutable'

const TranscriptSegment = new Immutable.Record({
    id: null,
    speaker: null,
    words: new Immutable.List(),
    newLine: null,
});

export default TranscriptSegment;
