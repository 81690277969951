import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

export default function Title(props) {
  const { text, color = 'unset' } = props;

  return (
    <Box
      display="flex"
      alignItems="center"
      fontSize={{ xs: 12, sm: 14, md: 14, lg: 16, xl: 18 }}
      fontWeight={800}
      lineHeight="1.2"
      color={color}
    >
      {text}
    </Box>
  );
}

Title.propTypes = {
  text: PropTypes.string.isRequired,
  color: PropTypes.string,
};

Title.defaultProps = {
  color: 'unset'
}
