import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithEmailAndPassword,
  inMemoryPersistence,
  signOut,
} from "firebase/auth";
import { apiLoginClient } from "../apiClient";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_AUTH_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_API_KEY,
};

export async function setupFirebaseAuthentication() {
  initializeApp(firebaseConfig);
  await getAuth().setPersistence(inMemoryPersistence);
}

export async function signInWithEmailPassword(username, password) {
  try {
    const auth = getAuth();

    const userCredential = await signInWithEmailAndPassword(
      auth,
      username,
      password
    );
    const { user } = userCredential;
    if (user) {
      const idToken = await user.getIdToken();

      const formData = new FormData();
      formData.append("idToken", idToken);

      await apiLoginClient.post("session/login", formData, {
        "Content-Type": "multipart/form-data",
        withCredentials: true,
      });

      await signOut(auth);
      return Promise.resolve();
    }
    return Promise.resolve(null);
  } catch (error) {
    return Promise.reject(error);
  }
}
