import TagManager from 'react-gtm-module'
import {
    GET_ANALYTICS_DATALAYER,
    GET_ANALYTICS_DATALAYER_FAIL,
    GET_ANALYTICS_DATALAYER_OK,
} from '../sagas/constants'
import { STORAGE_ANALYTICS_DATALAYER } from '../settings'
import { localStore } from '../helpers/misc'

const initialState = {
    data: localStore('get', STORAGE_ANALYTICS_DATALAYER),

    loading: false,
    loaded: false,

    error: false,
    errorMessage: null,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {

        case GET_ANALYTICS_DATALAYER: {
            return {...state, loading: true, error: false}
        }
        case GET_ANALYTICS_DATALAYER_FAIL: {
            return {
                ...state,
                loading: false,
                error: true,
                errorMessage: action.payload
            }
        }
        case GET_ANALYTICS_DATALAYER_OK: {
        	let {payload} = action;

        	let data = {};

		      for (let i = 0; i < payload.length; i++) {
		        let {label, value} = payload[i];
		        data[label] = value;

		        if (label === 'username') {
		        	data['userId'] = value;
		        }
		      }

		      localStore('set', STORAGE_ANALYTICS_DATALAYER, data);

		      // TagManager gets an object while the angular plugin uses an array
		      // so we keep the latter in localStorage and transform it here for the
		      // react module
		      TagManager.dataLayer({ dataLayer: data });

            return {
                ...state,
                loading: false,
                loaded: true,
                error: false,
                data,
            }
        }

        default: {
            return state;
        }
    }
}
