import {
    GET_TRANSCRIPT_STATUS,
    GET_TRANSCRIPT_STATUS_FAIL,
    GET_TRANSCRIPT_STATUS_OK,
    SAVE_TRANSCRIPT_STATUS,
    SAVE_TRANSCRIPT_STATUS_FAIL,
    SAVE_TRANSCRIPT_STATUS_OK,
    SET_TRANSCRIPT_TITLE
} from "../sagas/constants";

const initialState = {

    data: null,

    loading: false,
    loaded: false,

    saving: false,
    saved: true,
    saveError: false,

    error: false,
    errorMessage: null,

};


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case GET_TRANSCRIPT_STATUS: {
            return {...state, loading: true, error: false}
        }
        case GET_TRANSCRIPT_STATUS_FAIL: {
            return {
                ...state,
                loading: false,
                error: true,
                errorMessage: action.payload,
                errorCode: action.code
            }
        }
        case GET_TRANSCRIPT_STATUS_OK: {
            return {
                ...state,
                loading: false,
                loaded: true,
                error: false,
                data: action.payload
            }
        }
        case SET_TRANSCRIPT_TITLE: {
            let {data} = state;
            data.audioDisplayFileName = action.payload;
            return {
                ...state,
                data
            }
        }

        case SAVE_TRANSCRIPT_STATUS: {
            return {
                ...state,
                saving: true,
                saved: false,
                saveError: false
            };
        }
        case SAVE_TRANSCRIPT_STATUS_OK: {
            return {
                ...state,
                saving: false,
                saved: true,
                saveError: false
            };
        }
        case SAVE_TRANSCRIPT_STATUS_FAIL: {
            return {
                ...state,
                saving: false,
                saved: false,
                saveError: true
            };
        }


        default: {
            return state;
        }
    }
}
