import React from "react";
import PropTypes from "prop-types";
import { Box, IconButton, makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import { ReactComponent as CheckIcon } from "../../../assets/images/ui/check_circle.svg";
import { ReactComponent as UploadIcon } from "../../../assets/images/ui/file_upload.svg";
import AmberOutlinedButton from "../../buttons/AmberOutlinedButton";
import AmberPrimaryButton from "../../buttons/AmberPrimaryButton";

const useStyles = makeStyles((theme) => ({
  normal: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "24px",
  },
  bold: {
    fontSize: "14px",
    fontWeight: 800,
    lineHeight: "24px",
  },
}));

export default function BurnInSuccessful(props) {
  const { translate, onClose, onContinue } = props;

  const classes = useStyles();

  function Text() {
    return (
      <Box>
        <Box display="inline" className={classes.normal}>
          {translate("EDITOR.BURN_IN.successText1")}
        </Box>
        <Box display="inline-flex" className={classes.bold}>
          <Box pl={1} pr={1}>
            <UploadIcon />
            {` ${translate("EDITOR.BURN_IN.export")}`}
          </Box>
        </Box>
        <Box display="inline" className={classes.normal}>
          {translate("EDITOR.BURN_IN.successText2")}
        </Box>
      </Box>
    );
  }

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mt={3}
        mb={1.5}
        ml={4.5}
        mr={3}
      >
        <Box display="flex" gridColumnGap={4} alignItems="center">
          <Box
            fontSize={24}
            fontWeight={400}
            data-testid="burnin-success-dialog-title"
          >
            {translate("EDITOR.BURN_IN.successTitle")}
          </Box>
        </Box>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        gridRowGap={24}
        pt={2}
        pb={3}
        pl={4.5}
        pr={4.5}
      >
        <Box
          display="flex"
          flexDirection="row"
          gridColumnGap={32}
          alignItems="center"
        >
          <Box flex={3}>
            <CheckIcon />
          </Box>
          <Box data-testid="burnin-success-dialog-description">
            <Text />
          </Box>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        gridRowGap={24}
        pt={1}
        pb={4}
        pl={4.5}
        pr={4.5}
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="end"
        >
          <AmberOutlinedButton
            text={translate("EDITOR.BURN_IN.returnToEditor")}
            onClick={onClose}
            testid="burnin-success-dialog-editor-btn"
          />
          <AmberPrimaryButton
            text={translate("EDITOR.BURN_IN.continueToDashboard")}
            onClick={onContinue}
            testid="burnin-success-dialog-dashboard-btn"
          />
        </Box>
      </Box>
    </>
  );
}

BurnInSuccessful.propTypes = {
  translate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
};
