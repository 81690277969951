import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslate } from 'react-redux-multilingual'
import {
    Box, Button, Dialog, DialogActions, DialogContent, DialogTitle,
    FormControl, Grid, Input, InputLabel, Slide, FormHelperText,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles';
import InputMask from 'react-input-mask'

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

const styles = theme => ({
    button: {
        fontSize: 16,
    },
    actions: {
        justifyContent: 'space-between',
        margin: 20,
    },
    inputs: {
        marginTop: 20
    }
});


class StartTimeOffsetDialog extends Component {

    state = {
        startTimeOffset: null,
        maskedValue: null,
        submitOk: false,
        invalidMask: false
    }

    componentWillReceiveProps(nextProps) {
        this.setStartTimeOffset(nextProps.transcript.data.startTimeOffset);
        this.applyMask(nextProps.transcript.data.startTimeOffset)
    }

    handleSubmit = (e) => {
        e.preventDefault();

        let newValue = +this.state.startTimeOffset;

        this.props.onStartTimeOffsetChange(newValue);

        this.props.onClose();
    }

    handleResetClick = (e) => {
        this.setStartTimeOffset(0);
        this.applyMask(0);
    }
    applyMask = (time) => {
        var date = new Date(null);
        date.setMilliseconds(time * 1000);
        var result = date.toISOString().substr(11, 12).replace('.', ':');
        this.setState({ maskedValue: result })
    }
    parseMask = (mask) => {
        let split = mask.split(':');
        let hours = !isNaN(+split[0]) ? +split[0] : 0;
        if (hours > 24) return 'Invalid hours'
        let minutes = !isNaN(+split[1]) ? +split[1] : 0;
        if (minutes > 60) return 'Invalid minutes'
        let seconds = !isNaN(+split[2]) ? +split[2] : 0;
        if (seconds > 60) return 'Invalid seconds'
        let miliseconds = !isNaN(+split[3]) ? +split[3] : 0;
        return hours * 3600 + minutes * 60 + seconds + (miliseconds / 1000);
    }

    onChangeStartTimeOffset = (e) => {
        let seconds = this.parseMask(e.target.value);
        this.setState({ maskedValue: e.target.value });
        if (typeof seconds == 'number') {
            this.setStartTimeOffset(seconds);
            this.setState({ invalidMask: false })
        } else {
            this.setState({ invalidMask: seconds })
            this.setStartTimeOffset(-1);
        }
    }

    setStartTimeOffset = (val) => {
        this.setState({ startTimeOffset: +val }, () => {
            this.setState({ submitOk: this.checkOffsetCorrect() });
        });
    }

    checkOffsetCorrect = () => {
        if (this.state.startTimeOffset >= 0 && this.isNumeric(this.state.startTimeOffset)) {
            return true;
        } else {
            return false;
        }
    }

    isNumeric(n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    render() {
        const state = this.state;
        const { translate, classes, open, onClose } = this.props;

        return (
            <Dialog
                className={classes.root}
                open={open}
                onClose={onClose}
                transition={Transition}
                maxWidth="sm"
                fullWidth
                aria-labelledby="Start-time-offset-dialog"
            >
                <form onSubmit={this.handleSubmit}>
                    <DialogTitle id="Starttime-offset-dialog-title">
                        {translate('EDITOR.metaStartTimeOffsetTitle')}
                    </DialogTitle>
                    <DialogContent>
                        <Grid container>
                            <div>
                                {translate("EDITOR.metaStartTimeOffsetDescription")}
                            </div>
                            <Grid item md={12}>
                                <Box display="flex" alignItems="center">
                                    <FormControl component="fieldset" required className={classes.inputs}>
                                        <InputLabel style={{ width: 300 }} classes={{ root: classes.rootLabel }} error={Boolean(this.state.invalidMask)} htmlFor="startTimeHours-input">{translate('EDITOR.metaStartTimeOffsetLabel')}</InputLabel>
                                        <InputMask mask="99:99:99:999" maskChar="0" value={this.state.maskedValue} onChange={this.onChangeStartTimeOffset}>
                                            {(inputProps) => <Input {...inputProps} error={Boolean(this.state.invalidMask)} type="text" />}
                                        </InputMask>
                                        {this.state.invalidMask && <FormHelperText style={{ 'position': 'absolute', 'top': '100%', 'left': '0' }} error={true} id="component-error-text">{this.state.invalidMask}</FormHelperText>}

                                    </FormControl>
                                    <Button onClick={this.handleResetClick}>Reset</Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions className={classes.actions}>
                        <Button
                            className={classes.button}
                            fullWidth
                            variant="contained"
                            onClick={onClose}
                        >
                            {translate('EDITOR.EXPORT.cancel')}
                        </Button>
                        <Button
                            className={classes.button}
                            fullWidth
                            color="primary"
                            variant="contained"
                            type="submit"
                            disabled={!state.submitOk}
                        >
                            {translate('EDITOR.metaStartTimeOffsetSubmit')}
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        );
    }
}

const mapStateToProps = ({ transcript }) => ({
    transcript
});

export default connect(mapStateToProps)(withStyles(styles)(withTranslate(StartTimeOffsetDialog)));
