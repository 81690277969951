import React from 'react'
import { connect } from 'react-redux'
import { Box } from '@material-ui/core'

import { EditIcon } from '../ui/Icons'
import EditInfo from '../editor/EditInfo'

class TranscriptTitle extends React.Component {

    state = {
        openDialog: false,
    }

    handleOpen = () => {
        if (this.props.enableEditName)
            this.setState({ openDialog: true });
    }

    handleClose = () => {
        this.setState({ openDialog: false });
    }

    render() {
        const { transcriptStatus: status, enableEditName, className } = this.props;
        const name = status.loaded ? status.data.audioDisplayFileName : '';

        return (
            <React.Fragment>
                <Box display="flex" alignItems="center" className={className} onClick={this.handleOpen}>
                    <Box fontSize={{ xs: 12, sm: 14, md: 16, lg: 18, xl: 22 }} fontWeight={600} maxWidth={{ xs: 200, sm: 250, md: 250, lg: 400, xl: 600 }}
                        whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                        {name}
                    </Box>
                    {enableEditName && <EditIcon></EditIcon>}
                </Box>
                {enableEditName && <EditInfo
                    open={this.state.openDialog}
                    onClose={this.handleClose}
                    name={name}
                />}
            </React.Fragment>
        );
    }
}


const mapStateToProps = ({ transcriptStatus }) => ({
    transcriptStatus
});

export default connect(mapStateToProps)(TranscriptTitle)
