import React, { useState } from "react";
import PropTypes from "prop-types";
import { withTranslate } from "react-redux-multilingual";

import { getAvailableFormatsByDocumentType } from "./helpers";
import {
  DEFAULT_VALUE_FOR_CHARS_PER_ROW,
  DIALOG_STEP_EXPORT_SUBTITLES,
  DIALOG_STEP_EXPORT_TEXT,
  FORMAT_AVID,
  FORMAT_EBU_STL,
  FORMAT_PREMIERE,
  FORMAT_SRT,
  FORMAT_VTT,
  NETFLIX_MAX_NUMBER_OF_ROWS,
  SELECTED_REALIGNMENT_QUICK,
  SELECTED_RULE_CUSTOM,
  SELECTED_RULE_NETFLIX,
  SUBTITLE_DURATION_MAX,
} from "./constants";
import ExportSubtitlesContent from "./ExportSubtitlesContent";
import {
  exportMedia,
  exportSubtitles,
  trackExport,
} from "../ExportApi";
import ExportText from "../exportText/ExportText";

function ExportSubtitles(props) {
  const {
    isTranscriptionFile,
    onClose,
    transcriptId,
    isTranscriber,
    transcript,
    transcriptStatus,
    language,
    openJobSatisfactionDialog,
  } = props;

  const { isVideo } = transcriptStatus.data;

  const [formats] = useState(
    getAvailableFormatsByDocumentType(isVideo)
  );

  const [exportData, setExportData] = useState({
    format: formats[0],
    selectedRule: SELECTED_RULE_NETFLIX,
    selectedRealignment: SELECTED_REALIGNMENT_QUICK,
    customMaxNrRows: 2,
    customMaxCharPerRow: DEFAULT_VALUE_FOR_CHARS_PER_ROW,
  });

  const [step, setStep] = useState(0);

  // Goes back to Export subtitles
  const handleGoBack = () => {
    setStep(DIALOG_STEP_EXPORT_SUBTITLES);
  };

  // Go to export as text
  const handleDownloadAsText = () => {
    setStep(DIALOG_STEP_EXPORT_TEXT);
  };

  // Export subtitles
  const handleExportSubtitles = () => {
    const { format, selectedRule } = exportData;
    const option = {
      subtitleDurationMax: SUBTITLE_DURATION_MAX,
      maxNumberOfRows:
        selectedRule === SELECTED_RULE_CUSTOM ? exportData.customMaxNrRows : 2,
      maxCharsPerRow: NETFLIX_MAX_NUMBER_OF_ROWS,
    };
    const IS_TRANSCRIPT_FORMAT = isTranscriptionFile();
    const IS_TRANSCRIBER = isTranscriber();
    
    switch (format) {
      case FORMAT_SRT:
        exportSubtitles(
          transcript,
          transcriptStatus,
          option,
          IS_TRANSCRIPT_FORMAT,
          transcriptId,
          IS_TRANSCRIBER,
          "srt"
        );
        break;
      case FORMAT_VTT:
        exportSubtitles(
          transcript,
          transcriptStatus,
          option,
          IS_TRANSCRIPT_FORMAT,
          transcriptId,
          IS_TRANSCRIBER,
          "vtt"
        );
        break;
      case FORMAT_EBU_STL:
        exportSubtitles(
          transcript,
          transcriptStatus,
          option,
          IS_TRANSCRIPT_FORMAT,
          transcriptId,
          IS_TRANSCRIBER,
          "stl"
        );
        break;
      case FORMAT_AVID:
      case FORMAT_PREMIERE:
        exportSubtitles(
          transcript,
          transcriptStatus,
          option,
          IS_TRANSCRIPT_FORMAT,
          transcriptId,
          IS_TRANSCRIBER,
          format,
        );
        break;
      default:
    }

    trackExport(format, transcriptStatus);
    setTimeout(() => {
      onClose();
      openJobSatisfactionDialog();
    }, 100);
  };

  // Download file
  const handleDownloadFile = () => {
    exportMedia(transcriptStatus);

    trackExport("Media", transcriptStatus);
    setTimeout(() => {
      onClose();
    }, 100);
  };

  return (
    <>
      {step === DIALOG_STEP_EXPORT_SUBTITLES && (
        <>
          <ExportSubtitlesContent
            formats={formats}
            exportData={exportData}
            setExportData={setExportData}
            handleExportSubtitles={handleExportSubtitles}
            handleDownloadAsText={handleDownloadAsText}
            handleDownloadFile={handleDownloadFile}
            transcriptStatus={transcriptStatus}
            onClose={onClose}
            language={language}
          />
        </>
      )}
      {step === DIALOG_STEP_EXPORT_TEXT && (
        <ExportText
          onClose={onClose}
          isTranscriptionFile={isTranscriptionFile}
          transcriptId={transcriptId}
          transcript={transcript}
          transcriptStatus={transcriptStatus}
          isCaptions={true}
          onBack={handleGoBack}
          openJobSatisfactionDialog={openJobSatisfactionDialog}
        />
      )}
    </>
  );
}

ExportSubtitles.propTypes = {
  isTranscriptionFile: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  transcriptId: PropTypes.string.isRequired,
  isTranscriber: PropTypes.func.isRequired,
  transcript: PropTypes.shape().isRequired,
  transcriptStatus: PropTypes.shape().isRequired,
  language: PropTypes.string.isRequired,
  openJobSatisfactionDialog: PropTypes.func.isRequired,
};

export default withTranslate(ExportSubtitles);
