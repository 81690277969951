import React, { Component } from 'react'
import { withTranslate } from 'react-redux-multilingual'
import { Tooltip, Button } from '@material-ui/core'
import { RedoIcon } from '../ui/Icons'

class RedoButton extends Component {

    handleClick = () => {
        this.props.onRedo();
    }

    render() {
        const { translate, steps  } = this.props;

        return (
            <Tooltip title={`${translate('EDITOR.controlsRedo')} ${translate('EDITOR.controlsRedoShortcut')}`} placement="bottom">
                <Button onClick={this.handleClick} className={`RedoButton ${steps ? '' : 'UnActiveBtn'}`} color="default">
                    <RedoIcon /> {translate('EDITOR.controlsRedo')}
                </Button>
            </Tooltip>
        )
    }
}

export default withTranslate(RedoButton)