import React from "react";
import PropTypes from "prop-types";
import { Box, DialogContent } from "@material-ui/core";

import CheckCirclePng from "../../assets/images/ui/check_circle.png";
import CloseCirclePng from "../../assets/images/ui/cancel.png";

export default function PreQCDialogContent(props) {
  const { issues, translate, confirmation } = props;

  // Returns the right status icon
  function StatusIcon(props) {
    const { value } = props;
    const icon = value > 0 ? CloseCirclePng : CheckCirclePng;
    return <img src={icon} height="20px" alt={icon} />;
  }

  // Returns a row issue
  function IssueComponent(props) {
    const { property, value } = props;
    return (
      <Box display="flex" gridColumnGap={10} alignItems="center">
        <StatusIcon value={value} />
        <Box display="flex" alignItems="center">
          {value}
          {translate(`EDITOR.PRE_QC.${property}`)}
        </Box>
      </Box>
    );
  }

  // Creates an array of issue rows
  function getIssueList() {
    if (!issues) {
      return [];
    }
    const issueList = [];
    for (const issue in issues) {
      issueList.push(
        <IssueComponent key={issue} property={issue} value={issues[issue]} />
      );
    }
    return issueList;
  }


  return (
    <DialogContent>
      {!confirmation && (
        <Box display="flex" flexDirection="column" gridRowGap={12}>
          {getIssueList().map((issue) => issue)}
        </Box>
      )}
      {confirmation && (
        <Box display="flex" lineHeight={2}>
          {translate('EDITOR.PRE_QC.confirmation')}
        </Box>
      )}
    </DialogContent>
  );
}

PreQCDialogContent.propTypes = {
  translate: PropTypes.func.isRequired,
  issues: PropTypes.shape({
    cleanread: PropTypes.number,
    grammar: PropTypes.number,
    inaudible: PropTypes.number,
    spelling: PropTypes.number,
  }),
  confirmation: PropTypes.bool.isRequired,
};

PreQCDialogContent.defaultProps = {
  issues: {},
};
