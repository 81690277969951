import React from "react";
import PropTypes from "prop-types";
import { Box, Tooltip, withStyles } from "@material-ui/core";
import Name from "./name";

export default function ItemTooltip(props) {
  const { name, description } = props;

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "rgba(0,0,0,0.75)",
      color: "#FFFFFF",
      maxWidth: theme.spacing(39), //312px
      fontWeight: 400,
      marginBottom: theme.spacing(0.25),
    },
  }))(Tooltip);

  const isDescriptionBlank = () => {
    return description.trim() === "";
  };

  if (isDescriptionBlank()) {
    // Without any tooltip showing
    return <Name name={name} hover={!isDescriptionBlank()} />;
  }

  return (
    <HtmlTooltip
      title={
        <Box
          fontSize={{ xs: 10, sm: 12, md: 12, lg: 14, xl: 14 }}
          padding={0.5}
        >
          {description}
        </Box>
      }
      placement="top"
    >
      <span>
        <Name name={name} hover />
      </span>
    </HtmlTooltip>
  );
}

ItemTooltip.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};
