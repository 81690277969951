import {
    AUTH_LOGIN,
    AUTH_LOGIN_FIREBASE,
    AUTH_LOGIN_FAIL,
    AUTH_LOGIN_OK,
    AUTH_LOGOUT_OK
} from '../sagas/constants'

const initialState = {
    authenticating: false,
    authenticated: false,
    error: false,
    errorMessage: null
};

export default function reducer(state = initialState, action) {

    switch (action.type) {
        case AUTH_LOGIN: {
            return {
                ...state,
                loading: true
            }
        }
        case AUTH_LOGIN_FIREBASE: {
            return {
                ...state,
                loading: true
            }
        }        
        case AUTH_LOGIN_OK: {
            return {
                ...state,
                authenticating: false,
                authenticated: true,
                data: action.payload,
                error: false,
                errorMessage: null,
                loading: false
            }
        }
        case AUTH_LOGIN_FAIL: {
            return {
                ...state,
                authenticating: false,
                authenticated: false,
                error: true,
                errorMessage: action.message,
                loading: false
            }
        }
        case AUTH_LOGOUT_OK: {
            return {
                ...state,
                authenticated: false,
                loading: false
            }
        }
        default: {
            return state
        }
    }
}
