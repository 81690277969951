import React, { PureComponent } from 'react'
import classNames from 'classnames'
import { withTranslate } from 'react-redux-multilingual'

class BlockTextLength extends PureComponent {

    render() {
        const { overLimit, textLength, translate } = this.props;
        return (<span 
                    className={classNames({
                        "textLength": true,
                        "overLimit": overLimit
                    })}
                    contentEditable={false}>    
                        {translate('EDITOR.blockTextLength', {textLength: textLength})}
                </span>);
    }
}
    
export default (withTranslate(BlockTextLength));