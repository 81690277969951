import React from "react";
import PropTypes from "prop-types";
import { Box, styled } from "@material-ui/core";
import { getContactUsByLang } from "../../../helpers/links";

const CustomBox = styled(Box)(({ theme }) => ({
  cursor: "pointer",
  color: theme.palette.primary.main,
  fontWeight: 800,
}));

function LinkBox(props) {
  const { text, testid, language } = props;

  const openContactUsTab = () => {
    const win = window.open(getContactUsByLang(language), "_blank");
    win.focus();
  };

  return (
    <CustomBox
      fontSize={14}
      fontWeight={400}
      data-testid={testid}
      onClick={openContactUsTab}
    >
      {text}
    </CustomBox>
  );
}

LinkBox.propTypes = {
  text: PropTypes.string.isRequired,
  testid: PropTypes.string,
  language: PropTypes.string.isRequired,
};

LinkBox.defaultProps = {
  testid: "",
};

export default LinkBox;
