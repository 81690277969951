import React from 'react';
import { Box } from '@material-ui/core';
import { AMBER_SECONDARY, PURPLISH_GREY } from '../../helpers/colors';

function Title({ text }) {
    return (
        <Box component="span" flex={1} color={PURPLISH_GREY} fontSize={{ xs: 12, sm: 14, md: 14, lg: 16, xl: 18 }}
            fontWeight={800} marginBottom={0.5} lineHeight="1.2">
            {text}
        </Box>
    )
}

function Description({ text }) {
    return (
        <Box component="span" flex={1} color={PURPLISH_GREY} lineHeight="1.2" display={{ xs: 'none', sm: 'none', md: 'unset' }}
            fontSize={{ xs: 10, sm: 12, md: 12, lg: 14, xl: 14 }} fontWeight={400} maxWidth={{ xs: "unset", sm: 'unset', md: 470 }}>
            {text}
        </Box>
    )
}

export default function SubtitleFlowGuide({ isTranscriber, translate }) {
    return (
        <Box display="flex" width="100%" height={{ xs: 90, sm: 85, md: 80 }} bgcolor="rgba(245, 245, 245)" zIndex={10} boxShadow={2} alignItems="center"
            position="fixed" top={{ xs: 130, sm: 140, md: 155, lg: 160, xl: 180 }} paddingLeft={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 4 }}
            paddingRight={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 4 }}>

            <Box display="flex" p={1} justifyContent="flex-start" alignItems="center">
                <Box bgcolor={AMBER_SECONDARY} minWidth={30} width={30} height={30} borderRadius="50%"
                    display="flex" justifyContent="center" alignItems="center">
                    <Box component="span" color="#fff" fontSize={20} fontWeight={800}>1</Box>
                </Box>
                <Box display="flex" flexDirection="column" height="100%" marginLeft={{ xs: 1, sm: 1, md: 2 }}>
                    <Title text={translate("EDITOR.BANNER.firstStepTitle")} />
                    {
                        isTranscriber() ?
                            <Description text={translate("EDITOR.BANNER.firstStepDescTranscriber")} /> :
                            <Description text={translate("EDITOR.BANNER.firstStepDesc")} />
                    }
                </Box>
            </Box>

            <Box display="flex" p={1} justifyContent="flex-start" alignItems="center">
                <Box minWidth={30} width={30} height={30} borderRadius="50%" borderColor={PURPLISH_GREY} border="2px solid"
                    display="flex" justifyContent="center" alignItems="center">
                    <Box component="span" color={PURPLISH_GREY} fontSize={20} fontWeight={800}>2</Box>
                </Box>
                <Box display="flex" flexDirection="column" height="100%" marginLeft={{ xs: 1, sm: 1, md: 2 }}>
                    <Title text={translate("EDITOR.BANNER.secondStepTitle")} />
                    {!isTranscriber() && <Description text={translate("EDITOR.BANNER.secondStepDesc")} />}
                </Box>
            </Box>

        </Box>
    )
}