import React from 'react'
import { connect } from 'react-redux'
import { detect } from 'detect-browser';
import './TranscriptEditorBlockSpeaker.scss'

/**
 * Component that handles speaker changes. TBD
 * @param {*} props 
 */
class TranscriptEditorBlockSpeaker extends React.Component {

    render() {

        const {inputRef, onClick, speakerId, transcript} = this.props;

        let {speakers} = transcript.data;
        let speakerName = '';
        const speaker = speakers.find(function (speaker) {
            return speaker.get('id') === speakerId;
        });
        if (speaker) {
            speakerName = speaker.name;
        }

        let speakerButton;

        const browser = detect();
        if(browser.name === 'firefox') {
            speakerButton = <button
                                className="TranscriptEditorBlockSpeaker"
                                contentEditable={false}
                                ref={inputRef}
                                onClick={onClick}
                                title="Speaker">
                                <input readOnly type="text" className="TranscriptEditorBlockSpeaker"
                                    value={speakerName} style={{paddingRight:12, paddingLeft: 0, paddingTop: 2, marginLeft: 0}} />
                            </button>
        }
        else {
            speakerButton = <button
                                className="TranscriptEditorBlockSpeaker"
                                contentEditable={false}
                                ref={inputRef}
                                onClick={onClick}
                                title="Speaker">
                                {speakerName}
                            </button>
        }

        return (
            <span>
                {speakerButton}
            </span>
        )
    }
}

const mapStateToProps = ({transcript}) => ({
    transcript
});

export default connect(mapStateToProps)(TranscriptEditorBlockSpeaker);