import { apiClient } from "../../../apiClient";

/**
 * Gets a list of burn-in jobs for that recordId
 * @param {string} recordId
 * @returns array of burn in jobs
 */
export function getBurnInJobs(recordId) {
  return apiClient.get("/burn-in-jobs", {
    params: {
      recordId,
    },
  });
}

/**
 * Creates a burn in job for the jobId given by getBurnInJobs
 * @param {string} burnInJobId
 */
export function createBurnInJob(burnInJobId) {
  return apiClient.post(`/jobs/${burnInJobId}/burn-in`);
}

/**
 * Downloads the burn in video
 * @param {string} burnInJobId
 * @returns
 */
export function downloadBurnInVideo(burnInJobId) {
  apiClient.get(`/burn-in-jobs/${burnInJobId}/download-url`).then((res) => {
    window.open(res.data.downloadUrl, "_blank");
  });
}
