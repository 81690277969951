import { Modifier, SelectionState } from '@amberscript/amberscript-draftjs/lib/Draft';
import Immutable from 'immutable';
import WordTimeAdjustment from '../models/WordTimeAdjustment';

/**
 * @returns {Number}start of the first word in the block
 * @param {*}
 */
export const getBlockStartTime = (currentBlock) => {
	const words = currentBlock.data.get('words');
	if (words && words.first()) {
		return words.first().get('start');
	} else {
		return null;
	}
}

/**
 * @returns {Number} end of the last word in the block
 * @param {*} currentBlock 
 */
export const getBlockEndTime = (currentBlock) => {
	const words = currentBlock.data.get('words');
	if (words && words.last()) {
		return words.last().get('end');
	} else {
		return null;
	}
}

/**
 * @returns {WordTimeAdjustment} with default value - start of the first word in the block
 * 	min - end of the previous word, if default < min, min = default
 * 	max - start of the next word, if default > max, max = default
 * @param {*} currentBlock
 * @param {*} contentState 
 */
export const getBlockStartTimeAdjustment = (currentBlock, contentState) => {
	let defaultValue, max;

	const words = currentBlock.data.get('words');
	const key = currentBlock.getKey();
	if (words && words.first()) {
		defaultValue = words.first().get('start');
		max = words.size > 1 ? words.get(1).get('start') : getNextStartTime(defaultValue, contentState, key);
		max = Math.max(max, defaultValue);
		let min = getLastEndTime(defaultValue, contentState, key);
		min = Math.min(min, defaultValue);
		return new WordTimeAdjustment({ defaultValue, min, max });
	} else {
		return null;
	}
}

/**
 * @returns {WordTimeAdjustment} with default value - end of the last word in the block
 * 	min - end of the previous word, if default < min, min = default
 * 	max - start of the first word in the next block that contains words,
 *  if default > max, max = default
 * @param {*} currentBlock 
 * @param {*} contentState 
 */
export const getBlockEndTimeAdjustment = (currentBlock, contentState) => {
	let defaultValue, min;

	const words = currentBlock.data.get('words');
	const key = currentBlock.getKey();
	if (words && words.last()) {
		defaultValue = words.last().get('end');
		min = words.size > 1 ? words.get(-2).get('end') : getLastEndTime(defaultValue, contentState, key);
		min = Math.min(min, defaultValue);
		let max = getNextStartTime(defaultValue, contentState, key);
		max = Math.max(max, defaultValue);
		return new WordTimeAdjustment({ defaultValue, min, max });
	} else {
		return null;
	}
}

/**
 * @returns {ContentState} with updated wirst word start to the newValue
 * 	if newValue > word.end => word.end = newValue as well
 * @param {*} contentState 
 * @param {*} blockKey 
 * @param {*} newValue 
 */
export const adjustStartTime = (contentState, blockKey, newValue) => {
	const currentBlock = contentState.getBlockForKey(blockKey);
	const data = currentBlock.data;
	let words = data.get('words');

	let firstWord = words.first();
	firstWord = firstWord.set('start', newValue);
	const end = firstWord.get('end');
	if (newValue > end) {
		firstWord = firstWord.set('end', newValue);
	}

	if (words.size > 1) {
		words = new Immutable.List([firstWord]).concat(words.slice(1));
	} else {
		words = new Immutable.List([firstWord]);
	}

	return mergeBlocDataWords(contentState, blockKey, words);
}

/**
 * @returns {ContentState} with updated last word end to the newValue
 * 	if newValue < word.start => word.start = newValue as well
 * @param {*} contentState 
 * @param {*} blockKey 
 * @param {*} newValue 
 */
export const adjustEndTime = (contentState, blockKey, newValue) => {
	const currentBlock = contentState.getBlockForKey(blockKey);
	const data = currentBlock.data;
	let words = data.get('words');
	const wordsSize = words.size;

	let lastWord = words.last();
	lastWord = lastWord.set('end', newValue);
	const start = lastWord.get('start');
	if (newValue < start) {
		lastWord = lastWord.set('start', newValue);
	}
	if (wordsSize > 1) {
		words = new Immutable.List(words.slice(0, wordsSize - 1)).push(lastWord);
	} else {
		words = new Immutable.List([lastWord]);
	}

	return mergeBlocDataWords(contentState, blockKey, words);
}

/**
 * @returns {ContentState} with merged block data words
 * @param {*} contentState 
 * @param {*} blockKey 
 * @param {*} newWords 
 */
export const mergeBlocDataWords = (contentState, blockKey, newWords) => {
	const selectionState = SelectionState.createEmpty(blockKey);
	return Modifier.mergeBlockData(
		contentState, selectionState, { words: newWords }
	);
}




/**
 * @returns {Number} previous word end in the one of previous blocks, 
 * 	or return defaultValue, in case the current block is the first one
 * @param {*} defaultValue 
 * @param {*} contentState 
 * @param {*} blockKey
 */
const getLastEndTime = (defaultValue, contentState, blockKey) => {
	const blockBefore = contentState.getBlockBefore(blockKey);
	if (blockBefore === undefined) {
		return defaultValue;
	} else {
		return blockBefore.data.get('words') && blockBefore.data.get('words').size > 0
			? blockBefore.data.get('words').last().get('end')
			: getLastEndTime(defaultValue, contentState, blockBefore.getKey())
	}
}

/**
 * @returns {Number} next word start in the one of next blocks, 
 * 	or return defaultValue, in case the current block is the last one
 * @param {*} defaultValue 
 * @param {*} contentState 
 * @param {*} blockKey 
 */
const getNextStartTime = (defaultValue, contentState, blockKey) => {
	const blockAfter = contentState.getBlockAfter(blockKey)
	if (blockAfter === undefined) {
		return defaultValue;
	} else {
		return blockAfter.data.get('words').first() && blockAfter.data.get('words').first().size > 0
			? blockAfter.data.get('words').first().get('start')
			: getNextStartTime(defaultValue, contentState, blockAfter.getKey())
	}
}


/**
 * @returns {ContentBlock}
 * @param {*} editorState
 * @param {*} firstBlock
 */
export function getLastRowOfBlock(contentState, firstBlock) {
	const id = firstBlock.getKey();
	let blockCandidate = firstBlock;

	const contentBlocks = contentState.getBlocksAsArray();
	let currentBlockReached = false;

	for (const block of contentBlocks) {
		if (!currentBlockReached) {
			const blockId = block.getKey();
			if (blockId === id) {
				currentBlockReached = true;
				continue;
			}
		} else {
			const isNewLine = block.data.get('newLine');
			if (isNewLine) {
				blockCandidate = block;
			} else {
				break;
			}
		}
	}

	return blockCandidate;
}