import React, {Component} from 'react';
import {Box} from '@material-ui/core';
import classNames from 'classnames';
import Immutable from 'immutable';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {
    CompositeDecorator,
    Editor,
    EditorState,
    Modifier,
    getDefaultKeyBinding,
    SelectionState,
    RichUtils,
    KeyBindingUtil,
} from '@amberscript/amberscript-draftjs/lib/Draft';
import debounce from 'lodash.debounce';
import {detect} from 'detect-browser';

import checkForScrambledError from './helpers';
import {
    CurrentTextDecorator,
    AfterCurrentTextDecorator,
} from './EditorDecorators';
import SubtitleEditorBlock from './block/SubtitleEditorBlock';

import {ADJUST_TIMESTAMPS} from '../dialogs/dialogConstants';
import Notification from '../ui/Notification';
import ModalRoot from '../dialogs/ModalRoot';
import AlertEmailValidation from '../ui/AlertEmailValidation';
import Alert from '../ui/Alert';

import {HIGHLIGHT, DEBUG} from '../../settings';
import {
    GET_ACCOUNT,
    SAVE_TRANSCRIPT,
    SET_TRANSCRIPT,
    SET_TRANSCRIPT_HIGHLIGHTS,
    SHOW_MODAL,
    SEND_EMAIL_VALIDATION,
    SHOW_SNACKBAR,
    SET_AUDIO_TOGGLE,
    DOCUMENT_TYPE_SUBTITLES_JSON,
    TRANSCRIBER,
} from '../../sagas/constants';
import transcriptToContentState from '../../helpers/transcriptToContentState';
import {findWithRegex} from '../../helpers/textUtils';
import splitBlock from '../../helpers/splitBlock';
import newLineInBlock from '../../helpers/newLineInBlock';
import {
    isTimeInBlock,
    getWordAtOffset,
    getOffsetsByTime,
} from '../../helpers/wordsUtils';
import {scrollToNode} from '../../helpers/scrollUtil';
import {
    adjustStartTime,
    adjustEndTime,
} from '../../helpers/adjusBlockTimestampUtil';
import {apiClient} from '../../apiClient';
import replaceText from '../../helpers/replaceTextSubtitle';
import pasteText from '../../helpers/pasteText';
import insertText from '../../helpers/insertText';
import findReplaceText from '../../helpers/findReplaceText';
import highlightText from '../../helpers/highlightText';

import './SubtitleEditor.scss';

import {adjustTimestamps} from '../../helpers/adjustTimestamp';
import {logScrambledErrorOnSentry} from '../../sentry/log';
import {doUpdateSubtitlesInVideo} from "../EditorView";

const {hasCommandModifier} = KeyBindingUtil;
const browser = detect();

const styleMap = {
    [HIGHLIGHT]: {
        backgroundColor: 'rgba(98,200,213, .35)',
    },
};
const lastKeyPressedList = [];
const SAVE_EDITOR_DEBOUNCE_MS = 3000;
const VIDEO_PLAYER_ID = 'video_player';

export class SubtitleEditor extends Component {
    constructor(props) {
        super(props);

        this.state = {
            editorState: EditorState.createEmpty(),
            disabled: props.disabled || false,
            showEmailNotVerifiedAlert: false,
            notification: {
                open: false,
                message: 'Welcome to the Amberscript editor!',
                dismissable: true,
            },
            speakers: null,
            speaker: {
                block: null,
                anchorEl: null,
                openDropdown: false,
                clickedOn: 0,
            },
            showAlert: false,
            alertMessage: '',
        };

        this.isEditorUpdating = false;
        this.isDeadKeyPressed = false;
        this.isEditorRenderingWithDecoration = false;
        this.isEditorSelectionUpdating = false;
        this.lastTimeEditorRendered = 0;

        this.mouseInEditor = true; // this should be true so the selection works
        this.mousePressedInsideEditor = false;

        this.currentBlockKey = null;
        this.blockKeysInRange = [];

        this.hasScrambledHappened = false;

        this.debouncedSaveTranscript = debounce(
          this.saveTranscript,
          SAVE_EDITOR_DEBOUNCE_MS,
        );

        /**
         * The strategy converts only words of the current segment (based on play position)
         * into TranscriptEditorWord components. All segments outside of the current segment are
         * plain TranscriptEditorBlock components. This delivers a great performance gain, as
         * only components that are necessary for editing are created.
         */
        this.decorator = new CompositeDecorator([
            {
                strategy: (contentBlock, callback, contentState) => {
                    if (contentBlock.getKey() === this.currentBlockKey) {
                        const {start, end} = getOffsetsByTime(
                          contentBlock,
                          this.props.currentTimeWithOffset,
                        );
                        if (start !== end) {
                            const endIndex =
                              end === contentBlock.getText().length ? end + 1 : end - 1;
                            callback(start, endIndex);
                        }
                    }
                },
                component: CurrentTextDecorator,
            },
            {
                strategy: (contentBlock, callback, contentState) => {
                    if (contentBlock.getKey() === this.currentBlockKey) {
                        const {start, end} = getOffsetsByTime(
                          contentBlock,
                          this.props.currentTimeWithOffset,
                        );
                        if (end < contentBlock.getText().length) {
                            callback(end, contentBlock.getText().length);
                        }
                    }
                },
                component: AfterCurrentTextDecorator,
            },
        ]);
    }

    componentWillMount() {
        this.setupEditor();
    }

    componentDidMount() {
        this.setupTour();
        document.addEventListener('copy', this.handleCopyText);
        document.addEventListener('mouseup', this.mouseUpEvent);
    }

    shouldRenderEditorAgain = (prevTime, time) => {
        if (!prevTime && !time) {
            return false;
        } else if (prevTime === time) {
            return false;
        } else if (this.isEditorUpdating) {
            return false;
        } else if (this.isDeadKeyPressed) {
            return false;
        } else if (this.isEditorRenderingWithDecoration) {
            return false;
        } else if (this.isEditorSelectionUpdating) {
            return false;
        } else if (this.mousePressedInsideEditor) {
            return false;
        }
        return true;
    };

    componentDidUpdate(prevProps, prevState) {
        const time = this.props.currentTimeWithOffset;
        const {currentTimeWithOffset: prevTime} = prevProps;

        if (
          prevProps.transcript.data.speakers !== this.props.transcript.data.speakers
        ) {
            this.debouncedSaveTranscript();
        }

        if (this.shouldRenderEditorAgain(prevTime, time)) {
            this.lastTimeEditorRendered = time;

            const content = this.state.editorState.getCurrentContent();
            this.updateCurrentBlock(time, content);

            this.forceRenderEditor();
        }
    }

    componentWillUnmount() {
        document.removeEventListener('copy', this.handleCopyText);
        document.removeEventListener('mouseup', this.mouseUpEvent);
    }

    updateCurrentBlock = (time, content, editorState) => {
        let block = content
          .getBlockMap()
          .find((block) => isTimeInBlock(time, block));
        this.currentBlockKey = block ? block.getKey() : this.currentBlockKey;
        this.blockKeysInRange = this.getAcceptableBlocks(editorState);
    };

    setupTour = () => {
        this.props.addSteps({
            id: 'altClick',
            textTranslate: 'TOUR.EDITOR.altClick',
            selector: '.txt',
            position: 'center',
            type: 'hover',
            allowClicksThruHole: true,
            style: {
                zIndex: 3,
            },
        });
    };

    windowHasSelection = () => {
        let text = null;
        if (typeof window.getSelection != 'undefined') {
            text = window.getSelection().toString();
        } else if (
          typeof document.selection != 'undefined' &&
          document.selection.type == 'Text'
        ) {
            text = document.selection.createRange().text;
        }
        return text ? true : false;
    };

    clearSelection = () => {
        if (window.getSelection) {
            if (window.getSelection().empty) {
                // Chrome
                window.getSelection().empty();
            } else if (window.getSelection().removeAllRanges) {
                // Firefox
                window.getSelection().removeAllRanges();
            }
        } else if (document.selection) {
            // IE
            document.selection.empty();
        }
    };

    handleCopyText = (event) => {
        if (!this.props.account.data.emailValidated) {
            event.preventDefault();
        }
    };

    mouseUpEvent = () => {
        if (this.windowHasSelection()) {
            if (!this.mouseInEditor || !this.mousePressedInsideEditor) {
                this.clearSelection();
            }
        }
        this.mousePressedInsideEditor = false;
    };

    setupEditor = () => {
        /* We do it two times:
           After the initial setup the contentState/editorState
           will remain in local-state only. We only dispatch updates for saving
           to the API.
           2. We also do it user click convert to subtitles.
         */
        const {data} = this.props.transcript;
        const content = transcriptToContentState(data);
        const speakers = data.get('speakers');
        const editorState = EditorState.createWithContent(content);

        this.blockKeysInRange = this.getAcceptableBlocks(editorState);

        this.setState({
            editorState,
            speakers,
        });
    };

    forceRenderEditor = () => {
        const editorState = this.state.editorState;
        const contentState = editorState.getCurrentContent();

        const newEditorStateInstance = EditorState.createWithContent(
          contentState,
          this.decorator,
        );

        const copyOfEditorState = EditorState.set(newEditorStateInstance, {
            selection: editorState.getSelection(),
            undoStack: editorState.getUndoStack(),
            redoStack: editorState.getRedoStack(),
            lastChangeType: editorState.getLastChangeType(),
        });

        this.isEditorRenderingWithDecoration = true;
        this.setState(
          {
              editorState: copyOfEditorState,
          },
          () => {
              this.isEditorRenderingWithDecoration = false;
          },
        );
    };

    checkScrambledError = (editorState) => {
        const {hasError, reason} = checkForScrambledError(editorState);
        if (hasError) {
            this.sendScrambledErrorLog(reason);
            this.hasScrambledHappened = true;
        }
    };

    saveLastKeyPressed(key) {
        lastKeyPressedList.push({
            key_pressed: key,
        });
        if (lastKeyPressedList.length > 6) lastKeyPressedList.shift();
    }

    sendScrambledErrorLog(reason) {
        const {transcript} = this.props;

        logScrambledErrorOnSentry(
          'transcript',
          this.props.account,
          this.props.language,
          transcript.recordId,
          lastKeyPressedList,
          reason,
        );
    }

    isForbiddenInputs = (input) => {
        // This is option + space in mac. Something like half-space
        if (input === '\xa0') {
            return true;
        }
        if (input.split(' ').filter((f) => f !== '').length > 1) {
            return true;
        }
        return false;
    };

    getNewCharInput = (editorState, oldEditorState) => {
        const oldSelectionState = oldEditorState.getSelection();
        const oldStart = oldSelectionState.getStartOffset();

        const selectionState = editorState.getSelection();
        const anchorKey = selectionState.getAnchorKey();
        const currentContent = editorState.getCurrentContent();
        const currentContentBlock = currentContent.getBlockForKey(anchorKey);
        const start = selectionState.getStartOffset();

        // const words = currentContentBlock.getText().slice(0, start);
        // const listWord = words.split(' ');
        // const newChar = listWord[listWord.length - 1];

        return currentContentBlock.getText().slice(oldStart, start);
    };

    onChange = (editorState) => {
        const changeType = editorState.getLastChangeType();
        if (
          editorState.getCurrentContent() !=
          this.state.editorState.getCurrentContent()
        ) {
            // New character inserted
            if (changeType === 'insert-characters') {
                const newChar = this.getNewCharInput(
                  editorState,
                  this.state.editorState,
                );
                if (this.isForbiddenInputs(newChar)) {
                    this.updateEditorState(this.state.editorState, true, false);
                } else {
                    this.updateEditorState(editorState, true, false);
                }
            }
            // Edit || Spell check
            else if (changeType === 'apply-entity') {
                // if (browser.name === 'chrome') {
                // 	this.saveLastKeyPressed('SPELL_CHECK');
                // 	const newChar = this.getNewCharInput(
                // 		editorState,
                // 		this.state.editorState,
                // 	);
                // 	const newEditorState = insertText(
                // 		editorState,
                // 		this.state.editorState,
                // 		newChar,
                // 		null,
                // 		changeType,
                // 	);
                // 	this.updateEditorState(newEditorState, true, false);
                // } else {
                // 	this.updateEditorState(this.state.editorState, false, true);
                // }
                this.updateEditorState(this.state.editorState, false, true);
            }
            // Other cases
            else {
                this.updateEditorState(editorState, false, false);
            }
        } else {
            this.isEditorSelectionUpdating = true;
            this.setState(
              {
                  editorState,
              },
              () => {
                  this.isEditorSelectionUpdating = false;
              },
            );
        }
    };

    getTrimmedInput = (input) => {
        // whitestapce
        if (input.length === 1 && input.trim().length === 0) {
            return input;
        } else {
            return input.trim();
        }
    };

    handleBeforeInput = (input, editorState) => {
        if (
          this.isForbiddenInputs(input) ||
          this.isEditorUpdating ||
          this.isEditorRenderingWithDecoration ||
          this.isEditorSelectionUpdating
        ) {
            return true;
        }

        const oldEditorState = editorState || this.state.editorState;
        const newContent = replaceText(
          oldEditorState.getCurrentContent(),
          this.getTrimmedInput(input),
          oldEditorState.getSelection(),
        );
        const newEditorState = this.updateContent(newContent, 'insert-characters');

        if (
          newEditorState !== oldEditorState &&
          newEditorState.getCurrentContent() !== oldEditorState.getCurrentContent()
        ) {
            this.updateEditorState(newEditorState, false, false);
            return true;
        }

        return false;
    };

    handlePastedText = (text, html, editorState) => {
        // if (!this.isEmailValidated()) {
        //     this.setState({
        //         showEmailNotVerifiedAlert: true
        //     });
        //     return true;
        // } else {
        //     const newContentState = pasteText(text, editorState);
        //     const newEditorState = this.updateContent(newContentState, 'insert-characters');
        //     this.updateEditorState(newEditorState, false, false);
        //     return true;
        // }

        this.setState({
            notification: {
                open: true,
                message: 'Pasting is not supported yet, sorry.',
                dismissable: true,
            },
        });
        return true;
    };

    createListOfCues = () => {
        const cues = [];

        const {editorState} = this.state;
        const contentState = editorState.getCurrentContent();
        const contentBlocks = contentState.getBlocksAsArray();

        let candidate = [];
        let startTime, endTime;

        for (let index = 0; index < contentBlocks.length; index += 1) {
            const block = contentBlocks[index];
            const words = block.data.get('words');
            const text = words.map((w) => w.get('text')).join(' ');

            if (words && words.first() && words.last()) {
                if (index === 0) {
                    startTime = words.first().start;
                    endTime = words.last().start;
                    candidate.push(text);
                } else {
                    const isNewLine = block.data.get('newLine');
                    if (isNewLine) {
                        endTime = words.last().start;
                        candidate.push(text);
                    } else {
                        cues.push({
                            startTime,
                            endTime,
                            subs: candidate,
                        });

                        startTime = words.first().start;
                        endTime = words.last().start;
                        candidate = [];
                        candidate.push(text);
                    }
                }
            }
        }

        if (candidate.length > 0) {
            cues.push({
                startTime,
                endTime,
                subs: candidate,
            });
        }

        return cues;
    };

    isVideo = () => {
        return true;
    };

    updateSubtitlesInVideo = () => {
        doUpdateSubtitlesInVideo(this.createListOfCues())
    };

    // Sometimes we changes the data of the editorState
    // But draft.js doesn't re-render the editorState
    // This function does the trick!
    forceEditorStateToBeUpdated = (editorState) => {
        const contentState = Modifier.mergeBlockData(
          editorState.getCurrentContent(),
          editorState.getSelection(),
          Immutable.Map({fake: Math.random()}),
        );
        const newEditorStateInstance = EditorState.createWithContent(
          contentState,
          this.decorator,
        );
        return EditorState.set(newEditorStateInstance, {
            selection: editorState.getSelection(),
            undoStack: editorState.getUndoStack(),
            redoStack: editorState.getRedoStack(),
            lastChangeType: editorState.getLastChangeType(),
        });
    };

    shouldCheckForScrambled = () => {
        // return !this.hasScrambledHappened;
        return !this.hasScrambledHappened && this.props.isTranscriber();
    };

    canUpdateEditorState = () => {
        if (this.hasScrambledHappened && this.props.isTranscriber()) {
            return false;
        }
        return true;
    };

    updateEditorState = (
      editorState,
      shouldUpdateHighlight = false,
      forceEditorStateToBeUpdated = false,
    ) => {
        const updatedEditorState = forceEditorStateToBeUpdated
          ? this.forceEditorStateToBeUpdated(editorState)
          : editorState;

        this.isEditorUpdating = true;
        const P_Y = window.scrollY;

        // Check for scrambled error
        if (this.shouldCheckForScrambled()) {
            this.checkScrambledError(updatedEditorState);
        }

        if (this.canUpdateEditorState()) {
            this.setState(
              {
                  editorState: updatedEditorState,
              },
              () => {
                  if (window.scrollY !== P_Y) {
                      window.scrollTo(0, P_Y);
                  }
                  if (shouldUpdateHighlight) {
                      this.props.dispatch({
                          type: SET_TRANSCRIPT_HIGHLIGHTS,
                          contentState: updatedEditorState.getCurrentContent(),
                      });
                  }

                  this.isEditorUpdating = false;
                  this.isDeadKeyPressed = false;

                  this.debouncedSaveTranscript();
              },
            );
        } else {
            // Display modal to inform the user (transcriber) of the error.
            this.props.handleOpenScrambledErrorDialog();
        }
    };

    handleUndo = () => {
        this.saveLastKeyPressed('Undo');
        this.updateEditorState(
          EditorState.undo(this.state.editorState),
          true,
          false,
        );
    };

    handleRedo = () => {
        this.saveLastKeyPressed('Redo');
        this.updateEditorState(
          EditorState.redo(this.state.editorState),
          true,
          false,
        );
    };

    handleCloseSpeaker = () => {
        this.setState({
            speaker: {
                openDropdown: false,
            },
        });
    };

    handleSetBlockSpeaker = (block, speakerId, name) => {
        let {editorState} = this.state;
        let content = editorState.getCurrentContent();
        const newBlockData = block.getData().set('speaker', speakerId);
        const selectionState = SelectionState.createEmpty(block.getKey());
        content = Modifier.setBlockData(content, selectionState, newBlockData);

        editorState = EditorState.push(editorState, content, 'change-block-data');
        this.onChange(editorState);
    };

    handleSpeakerClick = (block, anchorEl) => {
        this.setState({
            speaker: {
                block,
                anchorEl,
                openDropdown: true,
                clickedOn: new Date(), // hacky...
            },
        });

        // hotfix 274 On Firefox, whenever you select a speaker, the window jumps to the top
        anchorEl.focus();
        anchorEl.blur();
    };

    handleTimestampClick = (modalProps) => {
        this.props.dispatch({
            type: SHOW_MODAL,
            modalType: ADJUST_TIMESTAMPS,
            modalProps: {
                ...modalProps,
                handleBlockTimestampStartChange: this.handleBlockTimestampStartChange,
                handleBlockTimestampEndChange: this.handleBlockTimestampEndChange,
                handleFixingScrollPosition: this.handleFixingScrollPosition,
                contentState: this.state.editorState.getCurrentContent(),
            },
        });
    };

    handleFindReplace = (findString, replaceString) => {
        replaceString = replaceString
          .split(' ')
          .filter((w) => w !== '')
          .join(' ');
        findString = findString.trim();
        if (findString === replaceString) return;

        const regex = new RegExp(`\\b${findString}\\b`, 'g');
        const {editorState} = this.state;
        let content = editorState.getCurrentContent();
        const blockMap = content.getBlockMap();

        const diff = replaceString.length - findString.length;
        blockMap.forEach((block) => {
            let stringCounter = 0;
            findWithRegex(regex, block, (start, end) => {
                const blockKey = block.getKey();
                let selection = SelectionState.createEmpty(blockKey);

                selection = selection.merge({
                    anchorOffset: start + diff * stringCounter,
                    focusOffset: end + diff * stringCounter,
                });

                content = findReplaceText(content, replaceString, selection);

                stringCounter += 1;
            });
        });

        if (content !== editorState.getCurrentContent()) {
            const newEditorState = EditorState.push(editorState, content);
            this.setState({
                editorState: newEditorState,
            });
        }
    };

    handleHighlight = () => {
        this.saveLastKeyPressed('HIGHLIGHT');
        const {editorState} = this.state;
        let newEditorState = highlightText(editorState);
        if (newEditorState) {
            newEditorState = RichUtils.toggleInlineStyle(newEditorState, HIGHLIGHT);
            this.props.dispatch({
                type: SET_TRANSCRIPT_HIGHLIGHTS,
                contentState: newEditorState.getCurrentContent(),
            });
            this.setState(
              {
                  editorState: newEditorState,
              },
              () => {
                  this.debouncedSaveTranscript();
              },
            );
        }
    };

    handleConvertToSubtitles = () => {
        this.setupEditor();
        const content = this.state.editorState.getCurrentContent();
        this.updateCurrentBlock(this.props.currentTimeWithOffset, content);
        this.scrollToCurrentText();
        this.debouncedSaveTranscript();
    };

    scrollToCurrentText = () => {
        let node =
          document.getElementById('currentText') ||
          document.getElementById('afterCurrentText');
        if (!node) {
            const activeBlocks = document.getElementsByClassName('active');
            node = activeBlocks[activeBlocks.length - 1];
        }

        if (node) {
            let offset = 0;
            const {data} = this.props.transcript;

            // if video container is on above the editor
            if (
              (window.screen.width < 1430 && this.props.isVideo) ||
              (data && data.isConvertedToSubtitles)
            ) {
                offset = -125;
            }

            scrollToNode(node, 300, offset);
        }
    };

    onPlaceCursor = (time) => {
        const {editorState} = this.state;
        const content = editorState.getCurrentContent();
        const block = content.getBlockForKey(this.currentBlockKey);
        if (block) {
            const {start} = getOffsetsByTime(
              block,
              this.props.currentTimeWithOffset,
            );
            if (start) {
                let selectionState = editorState.getSelection();

                selectionState = selectionState.merge({
                    anchorKey: block.getKey(),
                    anchorOffset: start,
                    focusKey: block.getKey(),
                    focusOffset: start,
                });

                this.onChange(EditorState.forceSelection(editorState, selectionState));
            }
        }
    };

    isEmailValidated = () => {
        return this.props.account.data.emailValidated;
    };

    handleCopyText = (event) => {
        if (!this.isEmailValidated()) {
            event.preventDefault();
            this.setState({
                showEmailNotVerifiedAlert: true,
            });
        }
    };

    handleClick = (event) => {
        event.preventDefault();
        let {editorState} = this.state;
        let selection = editorState.getSelection();

        if (event.altKey === true) {
            let content = editorState.getCurrentContent();
            const key = selection.getAnchorKey();
            const block = content.getBlockForKey(key);
            const offset = selection.getAnchorOffset();
            const start = getWordAtOffset(block, offset).get('start');
            this.props.seekAndCenterAudio(start);
        }
        this.setState({
            editorState: EditorState.acceptSelection(editorState, selection),
        });
    };

    handleNotificationClose = () => {
        this.setState({
            notification: {open: false},
        });
    };

    updateTranscript = (nextContentState) => {
        this.props.dispatch({
            type: SET_TRANSCRIPT,
            contentState: nextContentState,
        });
    };

    isOnlyTranscriber = () => {
        return this.props.roles.includes(TRANSCRIBER) && this.props.roles.length === 1;
    };

    saveTranscript = () => {
        this.updateTranscript(this.state.editorState.getCurrentContent());
        setTimeout(() => {
            this.props.dispatch({
                type: SAVE_TRANSCRIPT,
                transcript: this.props.transcript,
                documentType: DOCUMENT_TYPE_SUBTITLES_JSON,
                userName: this.props.account.data.userName,
                isTranscriber: this.props.isTranscriber(),
                isOnlyTranscriber: this.isOnlyTranscriber(),
                jobId: this.props.jobId

            });
        }, 100);
    };

    getAcceptableBlocks = (es) => {
        const editorState = es || this.state.editorState;
        const contentState = editorState.getCurrentContent();

        const currentKey =
          this.currentBlockKey || contentState.getFirstBlock().getKey();
        if (!currentKey) {
            return [];
        }

        const list = [currentKey];
        let totalAmountOfBlocksToDisplay = 25;
        let counter = 1;

        let key = currentKey;
        for (let index = 0; index < totalAmountOfBlocksToDisplay / 2; index += 1) {
            key = contentState.getKeyBefore(key);
            if (key) {
                list.push(key);
                counter += 1;
            } else {
                break;
            }
        }

        key = currentKey;
        for (
          let index = counter;
          index < totalAmountOfBlocksToDisplay;
          index += 1
        ) {
            key = contentState.getKeyAfter(key);
            if (key) {
                list.push(key);
            } else {
                break;
            }
        }

        return list;
    };

    blockRenderer = () => {
        return {
            component: SubtitleEditorBlock,
            props: {
                onTimestampClick: this.handleTimestampClick,
                currentBlockKey: this.currentBlockKey,
                currentTime: this.props.currentTimeWithOffset,
                blockKeysInRange: this.blockKeysInRange,
                isTimeChangedOnSeek: this.props.isTimeChangedOnSeek,
                editorState: this.state.editorState,
            },
        };
    };

    handleDelete = (editorState) => {
        const content = editorState.getCurrentContent();
        const selection = editorState.getSelection();
        const isCollapsed = selection.isCollapsed();
        const changeType = isCollapsed ? 'delete-character' : 'remove-range';

        let newSelection = selection;
        if (isCollapsed) {
            const block = content.getBlockForKey(selection.getAnchorKey());
            let anchorOffset = selection.getAnchorOffset();
            if (anchorOffset >= block.getText().length) {
                const blockAfter = content.getBlockAfter(selection.getAnchorKey());
                if (blockAfter == null) return false;

                newSelection = selection.merge({
                    focusOffset: 0,
                    focusKey: blockAfter.getKey(),
                });
            } else {
                newSelection = selection.merge({
                    focusOffset: selection.getFocusOffset() + 1,
                });
            }
        }

        const newContent = replaceText(content, '', newSelection);
        const newEditorState = this.updateContent(newContent, changeType);

        // Force render in case of (DeadKey and Backspace)
        if (
          lastKeyPressedList &&
          lastKeyPressedList.length >= 2 &&
          lastKeyPressedList[lastKeyPressedList.length - 2].key_pressed === 'Dead'
        ) {
            this.updateEditorState(newEditorState, false, true);
        } else {
            this.updateEditorState(newEditorState, false, false);
        }
    };

    handleBackspace = (editorState) => {
        const content = editorState.getCurrentContent();
        const selection = editorState.getSelection();
        const isCollapsed = selection.isCollapsed();
        const changeType = isCollapsed ? 'backspace-character' : 'remove-range';

        let newSelection = selection;
        if (isCollapsed) {
            const anchorOffset = selection.getAnchorOffset();
            if (anchorOffset === 0) {
                const blockBefore = content.getBlockBefore(selection.getAnchorKey());
                if (blockBefore == null) return false;

                newSelection = selection.merge({
                    anchorOffset: blockBefore.getText().length,
                    anchorKey: blockBefore.getKey(),
                });
            } else {
                newSelection = selection.merge({
                    anchorOffset: selection.getAnchorOffset() - 1,
                });
            }
        }

        const newContent = replaceText(content, '', newSelection);
        const newEditorState = this.updateContent(newContent, changeType);

        // Force render in case of (DeadKey and Backspace)
        if (
          lastKeyPressedList &&
          lastKeyPressedList.length >= 2 &&
          lastKeyPressedList[lastKeyPressedList.length - 2].key_pressed === 'Dead'
        ) {
            this.updateEditorState(newEditorState, false, true);
        } else {
            this.updateEditorState(newEditorState, false, false);
        }
    };

    handleNewLine = (editorState) => {
        this.saveLastKeyPressed('New line in a block');

        let newEditorState = newLineInBlock(editorState);

        const content = newEditorState.getCurrentContent();
        const changeType = 'newLine';

        // This will call getAcceptableBlocks with the newEditorState containing the new line
        newEditorState = this.updateContent(content, changeType, newEditorState);

        this.updateEditorState(newEditorState, true, false);
    };

    handleSplitBlock = (editorState) => {
        this.saveLastKeyPressed('Split block');
        let newEditorState = splitBlock(editorState);

        const content = newEditorState.getCurrentContent();
        const changeType = 'split-block';

        // This will call getAcceptableBlocks with the newEditorState containing the new block
        newEditorState = this.updateContent(content, changeType, newEditorState);

        this.updateEditorState(newEditorState, true, false);
    };

    keyBindingFn = (e) => {
        this.saveLastKeyPressed(e.key);
        // handle Enter
        if (e.key === 'Enter') {
            // Shift + Enter
            if (e.shiftKey) {
                return 'newLine';
            } else {
                return 'enter';
            }
        }
        // handle dead keys
        if (e.key === 'Dead') {
            return 'dead';
        }
        // handle highlight
        if (e.key === 'h' && (hasCommandModifier(e) || e.ctrlKey)) {
            return 'highlight';
        }
        // handle find & replace
        if (e.key === 'f' && e.ctrlKey && !e.shiftKey) {
            //TODO: open find replace window
        }
        // handle cut
        if (e.key === 'x' && hasCommandModifier(e)) {
            return 'cut';
        }
        // handle toggle spell checker
        if (e.key === 'g' && (hasCommandModifier(e) || e.ctrlKey) && !e.shiftKey) {
            e.preventDefault();
            return 'spellcheck';
        }
        // handle undo
        // 90 is z || Z
        if (
          e.keyCode === 90 &&
          (hasCommandModifier(e) || e.ctrlKey) &&
          !e.shiftKey
        ) {
            e.preventDefault();
            return 'undo';
        }
        // handle redo
        // 90 is z or Z
        if (
          e.keyCode === 90 &&
          (hasCommandModifier(e) || e.ctrlKey) &&
          e.shiftKey
        ) {
            e.preventDefault();
            return 'redo';
        }
        // disbale alt + space
        if (e.altKey && e.keyCode === 32) {
            e.preventDefault();
            return true;
        }
        return getDefaultKeyBinding(e);
    };

    handleKeyCommand = (command, editorState) => {
        const oldEditorState = editorState || this.state.editorState;
        switch (command) {
            case true:
                return true;
            //remove one char
            case 'dead':
                this.isDeadKeyPressed = true;
                return true;
            case 'delete':
                this.handleDelete(oldEditorState);
                return true;
            //remove word
            case 'delete-word':
                return true;
            //remove one char
            case 'backspace':
                this.handleBackspace(oldEditorState);
                return true;
            //remove word
            case 'backspace-word':
                return true;
            case 'backspace-to-start-of-line':
                return true;
            case 'enter':
            case 'split-block': {
                this.handleSplitBlock(oldEditorState);
                return true;
            }
            case 'newLine':
                this.handleNewLine(oldEditorState);
                return true;
            case 'highlight':
                this.handleHighlight();
                return true;
            case 'undo':
                this.handleUndo();
                return true;
            case 'redo':
                this.handleRedo();
                return true;
            case 'spellcheck':
                this.props.onChangeSpellCheckFeatureStatus();
                return true;
            case 'cut':
                return true;
            case 'bold':
                return true;
            case 'italic':
                return true;
            case 'code':
                return true;
            case 'underline':
                return true;
            case 'transpose-characters':
                return true;
            default:
                return true;
        }
    };

    updateContent = (content, changeType, editorState) => {
        const newEditorState = editorState || this.state.editorState;
        this.updateCurrentBlock(this.props.currentTimeWithOffset, content, newEditorState);
        return EditorState.push(newEditorState, content, changeType);
    };

    changeBlockData = (newContent) => {
        let {editorState} = this.state;
        const lastChangeType = 'change-block-data';
        editorState = EditorState.push(editorState, newContent, lastChangeType);
        this.onChange(editorState);
    };

    handleBlockTimestampStartChange = (blockKey, newValue) => {
        const contentState = this.state.editorState.getCurrentContent();
        const newContentState = adjustStartTime(contentState, blockKey, newValue);
        this.changeBlockData(newContentState);
    };

    handleBlockTimestampEndChange = (blockKey, newValue) => {
        const contentState = this.state.editorState.getCurrentContent();
        const newContent = adjustEndTime(contentState, blockKey, newValue);
        this.changeBlockData(newContent);
    };

    handleFixingScrollPosition = () => {
        const P_Y = window.scrollY;
        this.setState(
          {
              editorState: this.state.editorState,
          },
          () => {
              if (window.scrollY !== P_Y) {
                  window.scrollTo(0, P_Y);
              }
          },
        );
    };

    realignTranscript = (callback) => {
        // Pause the audio if it is playing
        if (this.props.audioPlaying) {
            this.props.dispatch({
                type: SET_AUDIO_TOGGLE,
            });
        }

        setTimeout(() => {
            const adjustedEditorState = adjustTimestamps(this.state.editorState);
            this.setState(
              {
                  editorState: adjustedEditorState,
              },
              () => {
                  this.saveTranscript();
                  callback();
              },
            );
        }, 100);
    };

    clearSelection = () => {
        if (window.getSelection) {
            if (window.getSelection().empty) {
                // Chrome
                window.getSelection().empty();
            } else if (window.getSelection().removeAllRanges) {
                // Firefox
                window.getSelection().removeAllRanges();
            }
        } else if (document.selection) {
            // IE
            document.selection.empty();
        }
    };

    mousePressedInsideEditor = () => {
        //mouse is pressed inside the editor
        this.mouseInDown = true;
    };

    render() {
        const {notification, editorState, showAlert, alertMessage} = this.state;
        const {readOnly, language, mediaData, isVideo} = this.props;

        return (
          <div
            className={classNames({
                noselect: true,
                SubtitleEditor: true,
            })}
          >
              <Box
                display="flex"
                justifyContent={{
                    xs: 'center',
                    sm: 'center',
                    md: 'space-between',
                    lg: 'space-between',
                }}
                alignItems="flex-start"
                width="100%"
              >
                  {
                      <Box
                        display={{xs: 'none', sm: 'none', md: 'flex', lg: 'flex'}}
                        flex={2}
                      ></Box>
                  }
                  <Box
                    display="flex"
                    lineHeight="32px"
                    zIndex={1}
                    fontSize={{xs: 12, sm: 14, md: 14, lg: 16, xl: 18}}
                    width={{xs: '100%', sm: '70%', md: '60%', lg: '60%'}}
                    flex={{xs: 'unset', sm: 'unset', md: 3, lg: 3}}
                    marginLeft={{xs: 0, sm: 0, md: 5, lg: 5}}
                    marginTop={{
                        xs: '180px',
                        sm: '210px',
                        md: '0px',
                        lg: '0px',
                        xl: '0px',
                    }}
                    paddingBottom="55px"
                  >
                      <div
                        lang={language}
                        spellCheck={this.props.spellCheck}
                        onClick={this.handleClick}
                        onMouseDown={() => {
                            this.mousePressedInsideEditor = true;
                            this.mouseInEditor = true;
                        }}
                        onMouseEnter={() => (this.mouseInEditor = true)}
                        onMouseLeave={() => (this.mouseInEditor = false)}
                      >
                          <Editor
                            lang={language}
                            spellCheck={
                                browser?.name === 'chrome' ? this.props.spellCheck : false
                            }
                            ref={(editor) => {
                                this.editor = editor;
                            }}
                            readOnly={readOnly}
                            customStyleMap={styleMap}
                            editorState={editorState}
                            onChange={this.onChange}
                            keyBindingFn={this.keyBindingFn}
                            handleBeforeInput={this.handleBeforeInput}
                            handleKeyCommand={this.handleKeyCommand}
                            handlePastedText={this.handlePastedText}
                            blockRendererFn={this.blockRenderer}
                            handleDrop={() => true}
                          />
                      </div>
                  </Box>
              </Box>
              <Notification
                open={notification.open}
                handleClose={this.handleNotificationClose}
                message={notification.message}
                dismissable={notification.dismissable}
              />
              <ModalRoot modalType={ADJUST_TIMESTAMPS}/>
              {showAlert && (
                <Alert
                  title="Scrambled?"
                  message={alertMessage}
                  onDismiss={() => this.setState({showAlert: false})}
                  onClose={() => this.setState({showAlert: false})}
                />
              )}
              <AlertEmailValidation
                showEmailNotVerifiedAlert={this.state.showEmailNotVerifiedAlert}
                onClose={() => {
                    this.mouseInEditor = true;
                    this.setState({showEmailNotVerifiedAlert: false});
                }}
                getAccount={() => {
                    this.mouseInEditor = true;
                    this.setState({showEmailNotVerifiedAlert: false});
                    this.props.dispatch({type: GET_ACCOUNT});
                }}
                sendActivationAgain={() => {
                    this.mouseInEditor = true;
                    this.setState({showEmailNotVerifiedAlert: false});
                    this.props.dispatch({
                        type: SHOW_SNACKBAR,
                        open: true,
                        message: 'EDITOR.btnActivationSent',
                    });
                    this.props.dispatch({type: SEND_EMAIL_VALIDATION});
                }}
              />
          </div>
        );
    }
}

SubtitleEditor.propTypes = {
    transcript: PropTypes.instanceOf(Object),
    onTranscriptUpdate: PropTypes.func,
    onSelectionChange: PropTypes.func,
    disabled: PropTypes.bool,
    onKeyboardEvent: PropTypes.func,
    isPerfectJobEditor: PropTypes.bool,
};

const mapStateToProps = ({
                             account,
                             transcript,
                             transcriptStatus,
                             transcriptAudio,
                             job
                         }) => {
    return {
        transcript,
        isVideo: transcriptStatus.data && transcriptStatus.data.isVideo,
        language: transcriptStatus.data && transcriptStatus.data.language,
        currentTimeWithOffset:
          transcriptAudio.currentTime + transcript.data.startTimeOffset,
        audioPlaying: transcriptAudio.playing,
        isTimeChangedOnSeek: transcriptAudio.isTimeChangedOnSeek,
        mediaData: transcriptAudio.data,
        account: account,
        roles: account.data && account.data.roles,
        jobId: job?.data?.jobId || transcriptStatus?.data?.job?.id
    };
};
export default connect(mapStateToProps, null, null, {withRef: true})(
  SubtitleEditor,
);
