
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

import AmberSectionTitle from './AmberSectionTitle';

const useStyles = makeStyles((theme) => ({
  cuteNumber: {
    borderRadius: '3em',
    textAlign: 'center',
    fontWeight: '900',
    padding: '0.1em 1em 0 1em',
    maxHeight: '1.2em',
  },
  cuteNumberRed: {
    backgroundColor: theme.palette.custom.pinkishRed,
    border: `1px solid ${theme.palette.custom.pinkishRed}`,
    color: theme.palette.primary.white,
  },
  cuteNumberGreen: {
    backgroundColor: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.white,
  },
  cuteNumberMango: {
    backgroundColor: theme.palette.custom.mango,
    color: theme.palette.custom.charcoalGray,
    border: `1px solid ${theme.palette.custom.mango}`,
  },
}));

function RatingAverageNumber(props) {
  const { average } = props;
  const classes = useStyles();

  const cuteNumberColour = clsx({
    [classes.cuteNumberRed]: average < 2.5,
    [classes.cuteNumberMango]: average >= 2.5 && average <= 4.25,
    [classes.cuteNumberGreen]: average > 4.25,
  });
  return (
    <AmberSectionTitle
      text={average}
      typographyClasses={{ root: `${classes.cuteNumber} ${cuteNumberColour}` }}
      typographyVariant="h5"
    />
  );
}
RatingAverageNumber.propTypes = {
  average: PropTypes.number.isRequired,
};

export default RatingAverageNumber;
