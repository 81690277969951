import React from "react";
import PropTypes from "prop-types";
import PanelTitle from "./panelTitle";
import MaxCharsCheckbox from "./maxCharsCheckbox";
import { CSV_FORMATS, CSV_FPS, CSV_FRAME_RATES } from "./constants";
import { Box, FormControl, MenuItem, Select } from "@material-ui/core";
import { withTranslate } from "react-redux-multilingual";

function CsvOptions(props) {
  const {
    translate,
    maxCharsPerRow,
    handleMaxCharPerRowValue,
    handleMaxCharPerRow,
    exportData,
    setExportData,
  } = props;

  const csvFormats = CSV_FORMATS;
  const fpsOptions = CSV_FRAME_RATES;

  const getFpsLabel = (option) => `${option} FPS`;

  const setFps = (event) => {
    setExportData({ ...exportData, frameRate: event.target.value });
  };

  const setTimeFormat = (event) => {
    setExportData({ ...exportData, timeFormat: event.target.value });
  };

  return (
    <Box display="flex" flexDirection="column">
      <PanelTitle
        text={translate("EDITOR.EXPORT.optionsTitle")}
        marginBottom={1}
        testid="export-text-dialog-options-title"
      />

      <Box display="flex" flexDirection="row" columnGap={10}>
        <Box display="flex" flexDirection="column">
          <Box display="flex" mb={4} mt={2}>
            <Box display="flex" mr={4}>
              <FormControl
                className="formControl"
                style={{ minWidth: 210, margin: 0 }}
              >
                <Select
                  variant="outlined"
                  data-testid="export-dialog-timeformat-select"
                  value={exportData.timeFormat}
                  onChange={setTimeFormat}
                  inputProps={{
                    "data-testid": "export-dialog-timeformat-select-input",
                  }}
                >
                  {csvFormats.map((option) => (
                    <MenuItem
                      key={option}
                      value={option}
                      data-testid={`export-dialog-timeformat-menuitem-${option}`}
                    >
                      {translate(`EDITOR.EXPORT.${option.toLowerCase()}`)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            {exportData.timeFormat === CSV_FPS && (
              <Select
                variant="outlined"
                data-testid="export-dialog-fps-select"
                value={exportData.frameRate}
                onChange={setFps}
                inputProps={{ "data-testid": "export-dialog-fps-select-input" }}
              >
                {fpsOptions.map((option) => (
                  <MenuItem
                    key={option}
                    value={option}
                    data-testid={`export-dialog-fps-menuitem-${option}`}
                  >
                    {getFpsLabel(option)}
                  </MenuItem>
                ))}
              </Select>
            )}
          </Box>
          <Box>
            <MaxCharsCheckbox
              maxCharsPerRow={maxCharsPerRow}
              handleMaxCharPerRow={handleMaxCharPerRow}
              handleMaxCharPerRowValue={handleMaxCharPerRowValue}
              testid="export-csv-dialog-maxCharsPerRow-checkbox"
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

CsvOptions.propTypes = {
  maxCharsPerRow: PropTypes.func.isRequired,
  handleMaxCharPerRowValue: PropTypes.func.isRequired,
  handleMaxCharPerRow: PropTypes.func.isRequired,
  exportData: PropTypes.func.isRequired,
  setExportData: PropTypes.func.isRequired,
};

export default withTranslate(CsvOptions);
