import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

import AmberSectionTitle from '../../labels/AmberSectionTitle';
import RatingForm from './ratingForm';
import {
  RATING_COMPONENT_ID,
  TEXT_INPUT_ID,
} from '../../../sagas/constants';
import AmberCustomBoxTextArea from '../../textfields/amberCustomBoxTextArea';
import { getRatingDefaults } from '../../../constants/ratingCategories';

const useStyles = makeStyles((theme) => ({
  contentsContainer: { display: 'flex', flexDirection: 'column' },
  label: {
    color: theme.palette.custom.charcoalGray,
  },
  dropDownContainer: {
    marginBottom: '1em',
  },
}));
const linkToGuidelines = (t, transcriberEmail) => (
    <div>
      {t('EDITOR.dialogJobFeedback.rateTranscriber', { email: transcriberEmail })}
      <a
          target="_blank"
          href="https://helpdesk.amberscript.com/en/quality-control-platform-workflow"
          rel="noreferrer noopener"
      >
        {t('EDITOR.dialogJobFeedback.qualityControlGuidelines')}
      </a>
    </div>
);
function JobRatingContents(props) {
  const classes = useStyles();
  const [notesError, setNotesError] = useState(false);

  const {
    handleChange,
    issueType,
    notes,
    job: { transcriber, workflowStyle, transcriptionType },
    translate
  } = props;

  const handleFormChange = useCallback(
    (event) => {
      let target = event;
      if (event.target) {
        target = event.target;
      }
      const { value, id } = target;

      handleChange({
        id,
        value,
      });
    },
    [handleChange],
  );

  useEffect(() => {
    if (notes !== undefined) {
      if (!notes.trim()) {
        setNotesError(true);
        return;
      }
      setNotesError(false);
    }
  }, [notes, issueType]);

  return (
    <div className={classes.contentsContainer}>
      <AmberSectionTitle
        typographyVariant="caption"
        text={linkToGuidelines(translate, transcriber)}
        component="strong"
      />
      <RatingForm
        id={RATING_COMPONENT_ID}
        handleRatingChange={handleFormChange}
        translate={translate}
        customRatings={getRatingDefaults(translate, workflowStyle, transcriptionType)}

      />
      <AmberCustomBoxTextArea
        handleChange={handleFormChange}
        value={notes}
        id={TEXT_INPUT_ID}
        hasError={notesError}
        formHelperText={translate('EDITOR.dialogJobFeedback.notesExplanation')}
        placeholder={translate('EDITOR.dialogJobFeedback.textAreaPlaceholder')}
        translate={translate}
        rows={13}
      />
    </div>
  );
}
JobRatingContents.propTypes = {
  issueType: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ]),
  notes: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  job: PropTypes.shape({
    _id: PropTypes.string,
    workflowStyle: PropTypes.string,
    transcriber: PropTypes.string,
  }).isRequired,
};

JobRatingContents.defaultProps = {
  issueType: undefined,
  notes: undefined,
};

export default JobRatingContents;
