import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { getRatingDefaults } from '../../../constants/ratingCategories';
import AmberSectionTitle from '../../labels/AmberSectionTitle';
import RatingAverageNumber from '../../labels/ratingAverageNumber';
import AmberSimpleTextField from '../../textfields/amberSimpleTextfield';
import parseLines from '../../../utils/stringUtils';
import AmberCard from '../../cards/AmberCard';
import { GET_QUALITY_CHECKS } from '../../../sagas/constants';
import Loading from '../../ui/Loading';

const useStyles = makeStyles((theme) => ({
	keyTitle: {
		flex: '0 1 50%',
		color: theme.palette.custom.charcoalGray,
		fontWeight: '900',
		marginBottom: 0,
		maxHeight: '1.5em',
	},
	keyValue: {
		fontWeight: '900',
		padding: '0.1em 1em 0 1em',
		maxHeight: '1.2em',
	},
	keyValueContainer: {
		display: 'flex',
		flexDirection: 'row',
		padding: '0.5em 0em 0.5em 0em',
		alignItems: 'center',
	},
	ratingsContainer: {
		display: 'flex',
		flexDirection: 'column',
	},
	ratingTotal: {
		fontWeight: '900',
		flex: '0 1 47%',
		fontSize: '1.1em',
		marginTop: '0.7em',
		marginRight: 2,
	},
	textFieldContainer: {
		display: 'flex',
		flexDirection: 'column',
	},
	noJobFound: {
		textAlign: 'center',
	},
	noJobFoundCardPaper: {
		width: '100%',
		padding: 0,
		transition: 'color 500ms, border 500ms ease-out',
		border: `1px solid ${theme.palette.custom.basicLightGray}`,
		color: theme.palette.custom.infoMessage,
		paddingTop: '1em',
		paddingBottom: '1em',
		marginTop: '15%',
    marginBottom: '15%'
	},
	noJobFoundCardContents: {
		padding: 0,
		'&:last-child': {
			padding: 0,
		},
	},
	loadingContainer: {
		marginTop: 50,
	},
}));

function KeyValueRating(props) {
	const { label, value } = props;
	const classes = useStyles();
	return (
		<div className={classes.keyValueContainer}>
			<AmberSectionTitle
				typographyClasses={{ root: classes.keyTitle }}
				typographyVariant="subtitle2"
				text={label}
			/>
			<AmberSectionTitle
				text={value}
				typographyClasses={{ root: classes.keyValue }}
				typographyVariant="subtitle2"
			/>
		</div>
	);
}

KeyValueRating.propTypes = {
	label: PropTypes.string.isRequired,
	value: PropTypes.number.isRequired,
};

function QualityCheckContents(props) {
	const {
		qualityCheck,
		qualityCheck: { loaded },
		translate,
		jobId,
	} = props;

	const classes = useStyles();
	const qualityCheckKeys = Object.keys(qualityCheck.data);
	const ratingDefaults = getRatingDefaults(translate);
	const ratingKeys = Object.keys(ratingDefaults);
	const dispatch = props.dispatch;

	React.useEffect(() => {
		if (!loaded) dispatch({ type: GET_QUALITY_CHECKS, jobId });
	}, [loaded, dispatch, jobId]);

	return (
		<>
			{qualityCheck.loading && (
				<div className={classes.loadingContainer}>
					<Loading />
				</div>
			)}

			{!qualityCheck.loading && !qualityCheckKeys.length && (
				<AmberCard
					classes={{ root: classes.noJobFoundCardPaper }}
					contentClasses={{ root: classes.noJobFoundCardContents }}
				>
					<AmberSectionTitle
						text={translate('EDITOR.dialogJobFeedback.noQualityCheck')}
						typographyClasses={{ root: classes.noJobFound }}
						margin={0}
					/>
				</AmberCard>
			)}

			{!qualityCheck.loading && !!qualityCheckKeys.length && (
				<div className={classes.ratingsContainer}>
					<div className={classes.keyValueContainer}>
						<AmberSectionTitle
							typographyClasses={{ root: classes.ratingTotal }}
							text={translate('EDITOR.dialogJobFeedback.total')}
							typographyVariant="h6"
						/>
						<RatingAverageNumber
							average={qualityCheck.data.transcriberRating.average}
						/>
					</div>
					{ratingKeys.map((key) => (
						<KeyValueRating
							key={key}
							label={ratingDefaults[key].label}
							value={qualityCheck.data.transcriberRating[key]}
						/>
					))}
					<div className={classes.textFieldContainer}>
						<AmberSectionTitle
							typographyClasses={{ root: classes.ratingTotal }}
							text={translate('EDITOR.dialogJobFeedback.notes')}
							typographyVariant="caption"
						/>
						<AmberSimpleTextField
							multiline
							value={
								qualityCheck.data.note ? parseLines(qualityCheck.data.note) : ''
							}
							disabled
							fullWidth
							maxRows={20}
							minRows={10}
						/>
					</div>
				</div>
			)}
		</>
	);
}
QualityCheckContents.propTypes = {
	translate: PropTypes.func.isRequired,
	jobId: PropTypes.string.isRequired,
};

const mapStateToProps = ({ qualityCheck }) => ({
	qualityCheck,
});

export default connect(mapStateToProps)(QualityCheckContents);
