import React, { Component } from "react";
import { withTranslate } from "react-redux-multilingual";
import { Tooltip, Button } from "@material-ui/core";
import { MenuIcon } from "../ui/Icons";

class ShowOrigininalButton extends Component {
  render() {
    const {
      translate,
      showOriginal, 
      triggerShowOriginal,
    } = this.props;
    return (
      <Tooltip title={translate("OriginalText.title")} placement="bottom" >
        <Button
          onClick={triggerShowOriginal}
          color="default"
        >
          <MenuIcon />
        </Button>
      </Tooltip>
    );
  }
}

export default withTranslate(ShowOrigininalButton);
