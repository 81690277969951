import {call, all, put, race, take} from 'redux-saga/effects';
import uuidV4 from 'uuid/v4';
import {
    AUTH_LOGOUT,
    DOCUMENT_TYPE_SUBTITLES_JSON,
    GET_AUDIO,
    GET_AUDIO_FAIL,
    GET_AUDIO_OK,
    GET_TRANSCRIPT,
    GET_TRANSCRIPT_FAIL,
    GET_TRANSCRIPT_LIST,
    GET_TRANSCRIPT_LIST_FAIL,
    GET_TRANSCRIPT_LIST_OK,
    GET_TRANSCRIPT_OK,
    GET_TRANSCRIPT_STATUS,
    GET_TRANSCRIPT_STATUS_FAIL,
    GET_TRANSCRIPT_STATUS_OK,
    SAVE_TRANSCRIPT,
    SAVE_TRANSCRIPT_FAIL,
    SAVE_TRANSCRIPT_OK,
    SAVE_TRANSCRIPT_STATUS,
    SAVE_TRANSCRIPT_STATUS_FAIL,
    SAVE_TRANSCRIPT_STATUS_OK,
} from './constants';
import {apiClient} from '../apiClient';
import {convertToJSON} from '../helpers/exportTranscript';
import {logSavingIssueOnSentry} from '../sentry/log';

function* getTranscriptListWorker() {
    try {
        return yield call(apiClient.get, 'transcription/list');
    } catch (error) {
        yield put({type: GET_TRANSCRIPT_LIST_FAIL, sending: false});
    }
}

export function* getTranscriptList() {
    while (true) {
        yield take(GET_TRANSCRIPT_LIST);
        const result = yield race({
            ok: call(getTranscriptListWorker),
            no: take(AUTH_LOGOUT),
        });
        if (result.ok) {
            yield put({type: GET_TRANSCRIPT_LIST_OK, payload: result.ok.data});
        }
    }
}

function* getTranscriptStatusWorker(jobId) {
    const params = {jobId}
    try {
        const response = yield call(apiClient.get, `document/status`, {params});
        // if we have calculated the waveform peaks in the server, then lets get the data of the peaks
        // otherwise, just return the response.
        if (response.data?.job?.jobConfiguration !== undefined) {
            response.data.job.jobConfiguration = mapStringTypesToRealTypes(response.data.job.jobConfiguration);
        }
        if (response.data.hasWaveformPeaks) {
            const waveformParams = {jobId: response.data?.job?.id};

            const responsePeaks = yield call(apiClient, {
                method: 'get',
                url: 'media/waveform/link',
                params: {...waveformParams},
            });
            const peaksResponce = yield call(fetch, responsePeaks.data.downloadUrl);
            const peaksData = yield peaksResponce.json();
            response.data.waveformPeaksData = peaksData.data;
            return response;
        } else {
            return response;
        }
    } catch (error) {
        yield put({type: GET_TRANSCRIPT_STATUS_FAIL, sending: false, code: error?.response?.status});
    }
}

export function* getTranscriptStatus() {
    while (true) {
        const request = yield take(GET_TRANSCRIPT_STATUS);

        const result = yield race({
            ok: call(getTranscriptStatusWorker, request.transcriptId),
            no: take(AUTH_LOGOUT),
        });
        if (result.ok) {
            yield put({
                type: GET_TRANSCRIPT_STATUS_OK,
                payload: result.ok.data,
            });
        }
    }
}

function* getTranscriptWorker(jobId, documentType) {
    try {
        if (documentType === DOCUMENT_TYPE_SUBTITLES_JSON) {
            return yield call(apiClient.get, `document/subtitles/${jobId}`);
        } else {
            return yield call(apiClient.get, `document/transcription/${jobId}`);
        }
    } catch (error) {
        yield put({type: GET_TRANSCRIPT_FAIL, sending: false});
    }
}

export function* getTranscript() {
    while (true) {
        const request = yield take(GET_TRANSCRIPT);

        const result = yield race({
            ok: call(getTranscriptWorker, request.transcriptId, request.documentType),
            no: take(AUTH_LOGOUT),
        });
        if (result.ok) {
            yield put({type: GET_TRANSCRIPT_OK, payload: result.ok.data});
        }
    }
}

function* getTranscriptAudioWorker(jobId, storageProvider) {
    const url = 'media/audio/link';
    const params = {jobId};
    try {
        return yield call(apiClient, {
            method: 'get',
            url,
            params,
        });
    } catch (error) {
        yield put({type: GET_AUDIO_FAIL, sending: false});
    }
}

export function* getTranscriptAudio() {
    while (true) {
        const request = yield take(GET_AUDIO);

        const result = yield race({
            ok: call(
                getTranscriptAudioWorker,
                request.transcriptId,
                request.storageProvider,
            ),
            no: take(AUTH_LOGOUT),
        });
        if (result.ok) {
            yield put({type: GET_AUDIO_OK, payload: result.ok.data});
        }
    }
}

function checkSegments(data) {
    const noNullSegment = data.segments.filter((s) => s !== null);
    if (noNullSegment.length > 0) {
        return true;
    } else {
        return false;
    }
}

function sendSaveTranscriptErrorMessageToServer(
    trackId,
    recordId,
    reason,
    transcript,
    userName,
) {
    const data = {
        type: '***** SAVING TRANSCRIPT ERROR *****',
        trackId,
        recordId,
        reason,
        userName,
        transcript: transcript ? JSON.stringify(transcript) : undefined,
    };

    logSavingIssueOnSentry(data);
}

function* saveTranscriptWorker(transcript, documentType, userName, isTranscriber, isOnlyTranscriber, jobId, transcriptJson) {
    const {recordId} = transcriptJson || transcript;

    const options = {
        includeTimestamps: true,
        includeSpeakers: true,
        includeHighlights: true,
        highlightsOnly: false,
        includeIsConvertedToSubtitles: true,
    };

    const data = transcriptJson ?
      {
          ... transcriptJson,
          jobId
      } :
      convertToJSON(transcript, options, true, jobId);
    const trackId = uuidV4();

    if (checkSegments(data)) {
        let generatorResults;
        let updateCall;
        try {
            if (documentType === DOCUMENT_TYPE_SUBTITLES_JSON) {
                generatorResults = yield call(apiClient.put, `document/subtitles/${jobId}`, data);
            } else {
                generatorResults = yield call(
                    apiClient.put,
                    `document/transcription/${jobId}`,
                    data,
                );
            }
            if (isTranscriber && jobId && isOnlyTranscriber) {
                updateCall = call(apiClient.put,
                    `jobs/metadata/last-transcriber-edit?jobId=${jobId}`,
                )
            }

            return yield all([generatorResults, updateCall]);
        } catch (error) {
            sendSaveTranscriptErrorMessageToServer(
                trackId,
                recordId,
                error.message,
                null,
                userName,
            );

            let errorCode = 'Error code';
            let errorMessage = 'Error message';

            // Error code
            if (error && error.response && error.response.status) {
                errorCode = error.response.status;
            } else if (error && error.code) {
                errorCode = error.code;
            }

            // Error message
            if (error && error.message) {
                errorMessage = error.message;
            }

            yield put({
                type: SAVE_TRANSCRIPT_FAIL,
                payload: {
                    errorCode,
                    errorMessage,
                },
                sending: false,
            });
        }
    } else {
        const errorMessage = 'Transcript is NULL';
        sendSaveTranscriptErrorMessageToServer(
            trackId,
            recordId,
            errorMessage,
            data,
            userName,
        );
        yield put({
            type: SAVE_TRANSCRIPT_FAIL,
            payload: {
                errorCode: 500,
                errorMessage,
            },
            sending: false,
        });
    }
}

export function* saveTranscript() {
    while (true) {
        const request = yield take(SAVE_TRANSCRIPT);
        const result = yield race({
            ok: call(
                saveTranscriptWorker,
                request.transcript,
                request.documentType,
                request.userName,
                request.isTranscriber,
                request.isOnlyTranscriber,
                request.jobId,
                request.transcriptJson
            ),
            no: take(AUTH_LOGOUT),
        });
        if (result.ok) {
            yield put({type: SAVE_TRANSCRIPT_OK, payload: result.ok.data});
        }
    }
}

function* saveTranscriptStatusWorker(transcriptStatus) {
    const {recordId, audioDisplayFileName} = transcriptStatus.data;
    const data = {
        recordId,
        audioDisplayFileName,
    };
    try {
        return yield call(apiClient.post, 'userrecord/update', data);
    } catch (error) {
        yield put({type: SAVE_TRANSCRIPT_STATUS_FAIL, sending: false});
    }
}

export function* saveTranscriptStatus() {
    while (true) {
        const request = yield take(SAVE_TRANSCRIPT_STATUS);

        const result = yield race({
            ok: call(saveTranscriptStatusWorker, request.transcriptStatus),
            no: take(AUTH_LOGOUT),
        });
        if (result.ok) {
            yield put({type: SAVE_TRANSCRIPT_STATUS_OK, payload: result.ok.data});
        }
    }
}

//Dirty code
const mapStringTypesToRealTypes = (jobConfiguration) => {
    return {
        ...jobConfiguration,
        includeSpaceInCharacterCount: jobConfiguration.includeSpaceInCharacterCount === "true",
        includeSpecialCharactersInCount: jobConfiguration.includeSpecialCharactersInCount === "true",
        maxCharsPerRow: Number(jobConfiguration.maxCharsPerRow),
        maxCharsPerSec: Number(jobConfiguration.maxCharsPerSec),
        maxNumberOfRows: Number(jobConfiguration.maxCharsPerSec),
        minFrameGap: parseFloat(jobConfiguration.minFrameGap),
        segmentMaxScreenTime: parseFloat(jobConfiguration.segmentMaxScreenTime),
    }
}