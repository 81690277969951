import React from 'react';
import { Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
// Style
const useStyles = makeStyles(() => ({
  btn: {
    whiteSpace: 'nowrap',
    textTransform: 'none',
  },
}));

// Component
function AmberTextButton(props) {
  const { onClick, text, color, textProps, ...rest } = props;
  const classes = useStyles();

  return (
    <Button variant="text" onClick={onClick} className={classes.btn} {...rest}>
      <Box
        component="span"
        fontSize={14}
        fontWeight={600}
        paddingLeft={1}
        paddingRight={1}
        color={color}
        {...textProps}
      >
        {text}
      </Box>
    </Button>
  );
}

AmberTextButton.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onClick: PropTypes.func.isRequired,
  color: PropTypes.string,
  textProps: PropTypes.objectOf(PropTypes.any),
};
AmberTextButton.defaultProps = {
  color: 'inherit',
  textProps: {},
};

export default AmberTextButton;
