import React from 'react'
import Alert from './ui/Alert'

function Error (props) {
    return (
        <Alert title="Error" message={props.message} />
    );
}

export default Error;
