import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslate } from 'react-redux-multilingual'
import { Snackbar } from '@material-ui/core'

import { HIDE_SNACKBAR } from '../../sagas/constants'

class SnackbarDialog extends Component {

    state = {
        open: false,
    }

    componentDidUpdate(prevProp, prevState) {
        if (this.props.snackbar.open !== prevProp.snackbar.open) {
            this.setState({
                open: this.props.snackbar.open
            })
        }
    }

    render() {
        const { snackbar, translate } = this.props;

        return (
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                autoHideDuration={2000}
                open={this.state.open}
                onClose={() => this.props.dispatch({ type: HIDE_SNACKBAR, open: false, message: '' })}
                message={
                    <span id="message-id">{translate(snackbar.message)}</span>
                }
            />
        );
    }
}

const mapStateToProps = ({ snackbar }) => ({
    snackbar
});

export default connect(mapStateToProps)(withTranslate(SnackbarDialog));