import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import AmberSimpleTextField from '../../textfields/amberSimpleTextfield';
import AmberSectionTitle from '../../labels/AmberSectionTitle';
import AmberCard from '../../cards/AmberCard';
import generateKey from '../../../utils/generateReactKey';
import { getFormattedDate } from '../../../utils/timeUtils';
import parseLines from '../../../utils/stringUtils';

const useStyles = makeStyles((theme) => ({
  historyNotesContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  historyCardContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  card: {
    background: theme.palette.custom.whiteTwo,
    borderRadius: '0.3em',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginBottom: '1em',
  },
  cardContents: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    '&:last-child': {
      paddingBottom: '0.5em',
    },
  },
  cardText: {
    flex: '0 0 80%',
    alignSelf: 'center',
    color: theme.palette.custom.purplishGray,
  },
  cardDate: {
    alignSelf: 'center',
    color: theme.palette.custom.purplishGray,
  },
  historyNotesTitle: {
    color: theme.palette.custom.purplishGray,
  },
  textField: {
    border: `2px solid ${theme.palette.custom.darkGrey}`,
    color: theme.palette.custom.purplishGray,
  },
}));

function Notes(props) {
  const { notes } = props;
  return (
    <AmberSimpleTextField
      multiline
      value={notes ? parseLines(notes) : ''}
      disabled
      fullWidth
    />
  );
}

Notes.propTypes = {
  notes: PropTypes.string,
};
Notes.defaultProps = {
  notes: '',
};

function HistoryCard(props) {
  const {
    history: { created, userName, message },
    translate,
  } = props;
  const classes = useStyles();

  return (
    <AmberCard
      classes={{ root: classes.card }}
      contentClasses={{ root: classes.cardContents }}
    >
      <div className={classes.cardText}>
        <AmberSectionTitle
          text={message}
          typographyVariant="caption"
          borderBottom={0}
          fontWeight="bolder"
        />
        <AmberSectionTitle
          typographyVariant="caption"
          text={userName || translate('EDITOR.jobItemDialog.unknownPerson')}
          borderBottom={0}
        />
      </div>
      <AmberSectionTitle
        text={getFormattedDate(created, null)}
        typographyClasses={{ root: classes.cardDate }}
        typographyVariant="caption"
        borderBottom={0}
      />
    </AmberCard>
  );
}

HistoryCard.propTypes = {
  history: PropTypes.shape({
    userName: PropTypes.string,
    message: PropTypes.string,
    created: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  translate: PropTypes.func.isRequired,
};

const filterHistory = (history = [], client) =>
  history.filter((item) => item.userName !== client);

function HistoryNotesTabPanelContents(props) {
  const { history, notes, client, translate } = props;
  const classes = useStyles();
  const historyFiltered = filterHistory(history, client);

  return (
    <div className={classes.historyNotesContainer}>
      <AmberSectionTitle
        typographyClasses={{ root: classes.historyNotesTitle }}
        text={translate('EDITOR.jobFields.historyLog')}
      />
      <div className={classes.historyCardContainer}>
        {historyFiltered.map((historyItem, index) => (
          <HistoryCard history={historyItem} key={generateKey(index)} translate={translate} />
        ))}
      </div>
      <AmberSectionTitle
        typographyClasses={{ root: classes.historyNotesTitle }}
        text={translate('EDITOR.jobFields.notes')}
      />
      <Notes notes={notes} />
    </div>
  );
}

HistoryNotesTabPanelContents.propTypes = {
  history: PropTypes.arrayOf(PropTypes.object).isRequired,
  notes: PropTypes.string,
  client: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired,
};
HistoryNotesTabPanelContents.defaultProps = {
  notes: '',
};
export default HistoryNotesTabPanelContents;
