import { Button } from "@mui/material";
import { withStyles } from "@material-ui/core/styles";

const ColorButton = withStyles(() => ({
    root: {
        color: '#005A50 !important',
        backgroundColor: 'white !important',
        border: '2px solid #005A50 !important',
        fontSize: '13px !important',
        borderRadius: '40px !important',
        minWidth: '170px',
        minHeight: 31,
    },
}))(Button);

export const GenAIAwernessButton = ({ onButtonClick }) => {
    return (
        <ColorButton id="GenAIAwernessButton" onClick={onButtonClick}>
            <div>Try our new intelligent exports!</div>
            <div style={{ position: 'absolute', padding: '5px', borderRadius: '5px', color: 'white', marginLeft: '100%', marginBottom: 40, backgroundColor: '#005A50', width: '40px', height: '15px', lineHeight: '15px', fontSize: '13px' }}>BETA</div>
        </ColorButton>
    )
}