export function findWithSplitting(wordToBeSearched, contentBlock, callback) {
    const text = contentBlock.getText();
    const splittedWords = text.split(' ');

    let start = 0, end = 0;
    for(const word of splittedWords) {
        end = start + word.length;
        if(word === wordToBeSearched) {
            callback(start, end);
        }
        start = end + 1;
    }
}

export function findWithRegex(regex, contentBlock, callback) {
    let matchWords = [];
    const text = contentBlock.getText();
    let matchArr, start, end;
    while ((matchArr = regex.exec(text)) !== null) {
        start = matchArr.index;
        end = start + matchArr[0].length;
        callback(start, end);
    }
}
