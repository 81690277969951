import React from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import { Provider } from "react-redux";
import ReactDOM from "react-dom";
import { IntlProvider } from "react-redux-multilingual";
import { ThemeProvider as ThemeProviderMui5 } from "@mui/material";

import store from "./store";
import setupSentry from "./sentry";
import reportWebVitals from "./reportWebVitals";
import Theme from "./components/ui/Theme";
import App from "./components/App";
import translations from "./assets/data/translations";

import "./index.scss";
import theme from "./components/ui/mui5/theme";

// Setup Sentry
setupSentry();

ReactDOM.render(
  <Provider store={store}>
    <IntlProvider translations={translations} locale="en">
      <ThemeProvider theme={Theme}>
        <ThemeProviderMui5 theme={theme}>
          <App />
        </ThemeProviderMui5>
      </ThemeProvider>
    </IntlProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
