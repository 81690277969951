import React, { Component } from 'react'
import { EditorBlock } from '@amberscript/amberscript-draftjs/lib/Draft'
import EditorBlockSpeaker from './TranscriptEditorBlockSpeaker'
import BlockStartTime from './BlockStartTime'
import BlockTextLength from './BlockTextLength'
import { getBlockStartTime } from '../../../helpers/adjusBlockTimestampUtil'


/**
 * Every segment consists of one TranscriptEditorBlock. Within the current 
 * block, where the audio position is currently playing, all words are rendered
 * as TranscriptEditorWord components and it's individual styling. All other 
 * segments are plainly rendered as TranscriptEditorBlock with added styling.
 * This grants a significant performance boost, as we are not rendering components
 * for every single word anymore.
 */
class TranscriptEditorBlock extends Component {

    shouldComponentUpdate(nextProps) {
        if (this.props.blockProps.currentTime !== nextProps.blockProps.currentTime) {
            if (nextProps.blockProps.isTimeChangedOnSeek) {
                return true;
            } else {
                const { currentBlockKey } = this.props.blockProps;
                const nextCurrentBlockKey = nextProps.blockProps.currentBlockKey;
                const thisBlockKey = this.props.block.getKey();

                if (currentBlockKey === thisBlockKey ||
                    nextCurrentBlockKey === thisBlockKey)
                    return true;
            }
        }
        if (this.props.block !== nextProps.block)
            return true;

        return false;
    }


    render() {
        let speakerRef;

        const { block } = this.props;
        const { onSpeakerClick,
            currentTime,
            isConvertedToSubtitles,
        } = this.props.blockProps;

        const id = block.data.get('id');

        let startTime = getBlockStartTime(block);

        const speakerId = block.data.get('speaker');

        let className = 'block'
        if (currentTime > startTime) {
            className += ' active'
        }

        return (
            <div className={className} id={`b-${id}`}>
                <EditorBlockSpeaker
                    onClick={() => {
                        onSpeakerClick(block, speakerRef)
                    }}
                    speakerId={speakerId}
                    inputRef={el => speakerRef = el} />
                <BlockStartTime time={startTime} />
                {isConvertedToSubtitles &&
                    <BlockTextLength
                        textLength={block.getLength()}
                        overLimit={block.getLength() > 70} />}
                <div className="txt">
                    <EditorBlock {...this.props} />
                    {/* <input type="text" /> */}
                </div>
            </div>
        );
    }
}

/**
 *  Deactivated, as they are very performance hungry and slow down the whole editor
 */
// TranscriptEditorBlock.propTypes = {
//     block: PropTypes.node,
//     blockProps: PropTypes.shape({
//         onSpeakerClick: PropTypes.func,
//     }),
// };

export default TranscriptEditorBlock;
