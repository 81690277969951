import { call, put, race, take } from 'redux-saga/effects';

import {
	AUTH_LOGOUT,
	GET_JOB,
	GET_JOB_FAIL,
	GET_JOB_OK,
	SAVE_JOB_REVIEW,
	SAVE_JOB_REVIEW_FAIL,
	SAVE_JOB_REVIEW_OK,
	SUBMIT_CAPTION_JOB,
	SUBMIT_CAPTION_JOB_OK,
	SUBMIT_CAPTION_JOB_FAIL,
	TRANSCRIPTION_TYPE_TRANSLATED_SUBTITLES,
	TRANSCRIPTION_TYPE_CAPTIONS,
	CAPTIONS_HIGH_QUALITY_STYLE,
} from './constants';

import { apiClient } from '../apiClient';

// ****************** Functions ******************

export function* getJobDataWorker(jobId) {
	try {
		const params = { jobId };
		return yield call(apiClient.get, 'jobs/perfect/status', { params });
	} catch (error) {
		yield put({ type: GET_JOB_FAIL, payload: error.response.data.message });
	}
}

export function* getJobData() {
	while (true) {
		const request = yield take(GET_JOB);

		const result = yield race({
			ok: call(getJobDataWorker, request.jobId),
			no: take(AUTH_LOGOUT),
		});

		if (result.ok) {
			yield put({ type: GET_JOB_OK, payload: result.ok.data.jobStatus });
		}
	}
}

function* saveJobReviewWorker(request) {
	const metadata = {
		transcriptionType: request.transcript,
		workflowStyle: request.workflowStyle
	}
	const data = {
		ratings: request.ratings,
		note: request.notes,
		jobId: request.jobId,
		qualityCheckStatus: request.status,
		transcript: request.transcript,
	};

	try {
		return yield call(apiClient.post, getQualityCheckUrl(metadata.workflowStyle, metadata.transcriptionType), {
			...data,
		});
	} catch (error) {
		yield put({
			type: SAVE_JOB_REVIEW_FAIL,
			message: error?.response?.data?.message ?? 'Unknown error occurred',
		});
	}
}

export function* saveJobReview() {
	while (true) {
		const request = yield take(SAVE_JOB_REVIEW);

		const result = yield race({
			ok: call(saveJobReviewWorker, request),
			no: take(AUTH_LOGOUT),
		});
		if (result.ok) {
			yield put({ type: SAVE_JOB_REVIEW_OK });
		}
	}
}

function* submitCaptionJobWorker(request) {
	try {
		return yield call(
			apiClient.put,
			`quality-controller/jobs/${request.jobId}/approve-aligned`,
		);
	} catch (error) {
		yield put({
			type: SUBMIT_CAPTION_JOB_FAIL,
			message: error?.response?.data?.message ?? 'Unknown error occurred',
		});
	}
}

export function* submitCaptionJob() {
	while (true) {
		const request = yield take(SUBMIT_CAPTION_JOB);

		const result = yield race({
			ok: call(submitCaptionJobWorker, request),
			no: take(AUTH_LOGOUT),
		});
		if (result.ok) {
			yield put({ type: SUBMIT_CAPTION_JOB_OK });
		}
	}
}


const getQualityCheckUrlCaptions = (workflowStyle) => {
	switch (workflowStyle) {
		case CAPTIONS_HIGH_QUALITY_STYLE:
			return '/quality-controller/quality-checks/hq-captions'
		default:
			return '/quality-controller/quality-checks/transcription'
	}
}

const getQualityCheckUrl = (workflowStyle, transcriptionType) => {
	switch (transcriptionType) {
		case TRANSCRIPTION_TYPE_TRANSLATED_SUBTITLES:
			return '/quality-controller/quality-checks/translation'
		case TRANSCRIPTION_TYPE_CAPTIONS:
			return getQualityCheckUrlCaptions(workflowStyle);
		default:
			return '/quality-controller/quality-checks/transcription'
	}
}
