import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Tooltip, withStyles } from "@material-ui/core";
import SubtitlesIcon from '@material-ui/icons/Subtitles';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect, useState } from "react";
import { apiClient } from "../../apiClient";
import { API_URL } from "../../settings";
import { CUSTOM_RULE, DEFAULT_VALUE_FOR_CHARS_PER_ROW } from "./constants";
import { CustomSubtitleRulesAccordion } from "./CreateSubtitleAccordion";

const ColorButton = withStyles((theme) => ({
    root: {
        color: 'white',
        backgroundColor: '#005A50',
        '&:hover': {
            backgroundColor: '#003e38',
        },
        whiteSpace: 'nowrap',
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
        [theme.breakpoints.down('sm')]: {
            height: 34,
        },
        [theme.breakpoints.up('md')]: {
            height: 40,
        },
    },
}))(Button);

export default function UpdateSubtitleRules({
    transcript,
    transcriptStatus,
    isTranscriber,
    translate,
}) {

    const [open, setOpen] = useState(false);

    return (
        <React.Fragment>
            <Tooltip title={'Update subtitles rules'} placement="bottom">
                <Button onClick={() => setOpen(true)} color="default">
                    <SubtitlesIcon />
                </Button>
            </Tooltip>
            <UpdateSubtitleRulesDialog
                open={open}
                setOpen={setOpen}
                transcript={transcript}
                transcriptStatus={transcriptStatus}
                isTranscriber={isTranscriber}
                translate={translate}
            />
        </React.Fragment>
    );
}

function UpdateSubtitleRulesDialog(props) {
    const {open, setOpen, transcriptStatus, translate} = props;
    const classes = useState();
    const defaultRulesObject = {
        maxLinesPerSegment: 2,
        includeSpaceInCharacterCount: true,
        includeSpecialCharactersInCount: true,
        maxCharsPerLine: DEFAULT_VALUE_FOR_CHARS_PER_ROW,
        maxCharsPerSecond: 30,
        minSubtitleDuration: 1,
        maxSubtitleDuration: 7,
        minFrameGap: 2,
    }
    const [customRules, setCustomRules] = useState(defaultRulesObject);
    const [defaultRules, setDefaultRules] = useState(defaultRulesObject);
    const isUpdateButtonAvailable = JSON.stringify(defaultRules) !== JSON.stringify(customRules);
    const updateSubtitleRules = () => {
        apiClient.patch(`${API_URL}/v2/jobs/subtitle-rules/${transcriptStatus.data.job.id}`, customRules)
            .then(() => {
                setOpen(false);
                window.location.reload();
            })
    }

    useEffect(() => {
        if (transcriptStatus.data.job.id !== undefined) {
            apiClient.get(`${API_URL}/v2/jobs/subtitle-rules/${transcriptStatus.data.job.id}`)
            .then((response) => {
                if (response.status !== 204 && response.data !== "") {
                    const {
                        maxLinesPerSegment,
                        includeSpaceInCharacterCount,
                        includeSpecialCharactersInCount,
                        maxCharsPerSecond,
                        maxCharsPerLine,
                        minSubtitleDuration,
                        maxSubtitleDuration,
                        minFrameGap,
                    } = response.data;
                    const newRules = {
                        maxLinesPerSegment,
                        includeSpaceInCharacterCount,
                        includeSpecialCharactersInCount,
                        maxCharsPerSecond,
                        maxCharsPerLine,
                        minSubtitleDuration,
                        maxSubtitleDuration,
                        minFrameGap,
                    }
                    setCustomRules(newRules);
                    setDefaultRules(newRules)
                }
            })
        }
    }, [open]);

    return (
        <Dialog
            open={open}
            onClose={()=> setOpen(false)}
            maxWidth="sm"
            aria-labelledby="create-subtitles-title"
            aria-describedby="create-subtitles-description"
        >
            <Box p={1}>
                <Box display="flex" alignItems="center" justifyContent="space-between" paddingRight={2}>
                    <DialogTitle id="create-subtitles-title">
                        Subtitle rules
                    </DialogTitle>
                    <IconButton onClick={() => setOpen(false)}>
                        <CloseIcon/>
                    </IconButton>
                </Box>
                <DialogContent>
                    <Box display="flex" flexDirection="column">
                        <CustomSubtitleRulesAccordion
                            customOpen={true}
                            setCustomOpen={() => {}}
                            systemBusy={false}
                            translate={translate}
                            customRules={customRules}
                            setCustomRules={setCustomRules}
                            selectedRule={CUSTOM_RULE}
                            isUpdateRules={true}
                        />
                    </Box>
                </DialogContent>
                <Box display={'flex'} justifyContent={'end'} marginRight={'5%'} minHeight={'40px'}>
                    { isUpdateButtonAvailable && 
                        <ColorButton
                            className={classes.button}
                            variant="contained"
                            color="primary"
                            startIcon={<SubtitlesIcon/>}
                            onClick={updateSubtitleRules}
                        >
                            Adjust rules
                        </ColorButton>
                    }
                </Box>
            </Box>
        </Dialog>
    )
}