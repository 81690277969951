import uuidV4 from 'uuid/v4'
import Immutable from 'immutable'
import Transcript from '../models/Transcript'
import TranscriptSegment from '../models/TranscriptSegment'
import Highlight from '../models/Highlight'
import Speaker from '../models/Speaker'
import TranscriptWord from '../models/TranscriptWord'

const DEFAULT_SPEAKER_ID = 'S0';

/**
 * Are transcript segments empty
 *
 * Deal wit empty transcripts, `segments` from the API can be:
 * 1) `[]` when there is an error on transcription server
 * 2) `[null]`
 * 3) `[{speaker: "Amber", words: []}]` when I select all text and delete
 *
 * @param  {Array}  segments
 * @return {Boolean}
 */
export function areSegmentsEmpty(segments) {
    const count = segments.length;

    if (!count) return true;
    if (count === 1) {
        if (!segments[0]) return true;
        if (segments[0].words && !segments[0].words.length) return true;
    }
    return false;
}

/**
 * Create empty transcript
 *
 * @return {Transcript}
 */
export function createEmptyTranscript() {
    const segments = new Immutable.List([createEmptySegment()]);
    const speakers = new Immutable.List([createEmptySpeaker()]);

    return new Transcript({
        speakers,
        segments,
    });
}

// Check if we need to replace the <br> tag with an empty string or not
// 
function shouldReplaceBreakLineTag(indexSegment, indexWord) {
    if (indexSegment === 0) {
        return false;
    } else {
        if (indexWord === 0) {
            return true;
        } else {
            return false;
        }
    }
}


/**
 * Create transcript from json
 *
 * @param  {json} json Transcript
 * @return {Transcript}
 */
export function createTranscriptFromJson(json) {
    const isEmpty = areSegmentsEmpty(json.segments);
    let data;
    let highlights;

    if (isEmpty) {
        data = createEmptyTranscript();
    } else {
        if (json.highlights) {
            highlights = new Immutable.List(
                json.highlights.map(highlight => new Highlight(highlight))
            );
        } else {
            highlights = new Immutable.List([]);
        }
        const speakers = new Immutable.List(
            json.speakers.map(speaker => new Speaker({
                id: speaker.spkid || speaker.name,
                name: speaker.name
            }))
        );
        let startPosition = 0;
        const segments = new Immutable.List(
            json.segments.reduce(function (_segments, segment, indexSegment) {
                if (!segment) {
                    return _segments;
                }
                const { speaker, words } = segment;

                if (words && words.length > 0) {
                    const segment = new TranscriptSegment({
                        id: uuidV4(),
                        speaker,
                        newLine: indexSegment !== 0 && words[0].text.includes("<br>"),
                        words: new Immutable.List(
                            words.reduce((result, { start, end, pristine, conf, text }, indexWord) => {
                                const cleanedText = shouldReplaceBreakLineTag(indexSegment, indexWord) ? text.replace("<br>", "").trim() : text;
                                if (text.length > 0 && text !== ' ') {
                                    // After we added pristine property
                                    if (pristine === true || pristine === false) {
                                        result.push(new TranscriptWord({
                                            id: uuidV4(),
                                            text: cleanedText,
                                            start,
                                            end,
                                            pristine,
                                            startPosition,
                                            endPosition: startPosition + cleanedText.length,
                                            conf,
                                        }));
                                    }
                                    // Old transcription without pristine property
                                    else {
                                        result.push(new TranscriptWord({
                                            id: uuidV4(),
                                            text: cleanedText,
                                            start,
                                            end,
                                            startPosition,
                                            endPosition: startPosition + cleanedText.length,
                                            conf,
                                        }));
                                    }
                                    startPosition += cleanedText.length + 1;
                                }
                                return result;
                            }, [])
                        ),
                    });

                    _segments.push(segment);
                }
                return _segments;
            }, [])
        );

        return new Transcript({
            startTimeOffset: json.startTimeOffset,
            isConvertedToSubtitles: json.isConvertedToSubtitles,
            highlights,
            speakers,
            segments,
        });
    }
}

/**
 * Create empty speaker
 *
 * @return {Speaker}
 */
function createEmptySpeaker() {
    return new Speaker({
        id: DEFAULT_SPEAKER_ID,
        name: DEFAULT_SPEAKER_ID,
    })
}

/**
 * Create empty segment
 *
 * @return {TranscriptSegment}
 */
function createEmptySegment() {
    return new TranscriptSegment({
        id: uuidV4(),
        speaker: DEFAULT_SPEAKER_ID,
        words: new Immutable.List([
            new TranscriptWord({
                start: 0,
                end: 0.1,
                conf: 0,
                text: ' ',
                id: uuidV4(),
            })
        ]),
        newLine: false,
    })
}

/**
 * Get words from the transcript 
 * @param {*} transcript 
 */
export const getTranscriptWords = (transcript) => {
    const words = [];
    transcript.segments.forEach(s => {
        if (s.words)
            s.words.forEach(w => words.push(w));
    });
    return words
}