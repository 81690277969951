/**
 * Return the link for Contact us
 * @param {string} lang
 * @returns link in the correct language
 */
export function getContactUsByLang(lang) {
  switch (lang) {
    case "en":
      return "https://www.amberscript.com/en/contact-us";
    case "de":
      return "https://www.amberscript.com/de/kontakt";
    case "fr":
      return "https://www.amberscript.com/fr/contactez-nous";
    case "nl":
      return "https://www.amberscript.com/nl/contacteer-ons";
    case "sv":
      return "https://www.amberscript.com/sv/kontakta-oss";
    case "pt":
      return "https://www.amberscript.com/pt-pt/contate-nos";
    case "es":
      return "https://www.amberscript.com/es/contactenos";
    case "dk":
      return "https://www.amberscript.com/da/kontakt-os";
    case "it":
      return "https://www.amberscript.com/it/contatti";
    default:
      return "https://www.amberscript.com/en/contact-us";
  }
}
