import axios from 'axios';
import {redirectToLogin} from './utils/api';
import {
    API_URL,
    STORAGE_API_KEY,
    CLIENT_ID,
    CLIENT_SECRET,
    ENVIRONMENT,
} from './settings';
import {trackAxiosErrors} from './sentry/log';
import Cookies from 'js-cookie';

// Variable(s)
const clientId = CLIENT_ID;
const clientSecret = CLIENT_SECRET;

const TIMEOUT = 120 * 1000; //120 seconds

let isRefreshingAccessToken = false;
let requestsToRefresh = [];

// ****************** LOGIN endpoint ******************
export const apiLoginClient = axios.create({
    withCredentials: true,
    baseURL: `${API_URL}/v2/`,
});

apiLoginClient.interceptors.request.use((config) => {
    const newConfig = config;

    newConfig.headers = {
        ...config.headers,
        Authorization: btoa(`${clientId}:${clientSecret}`),
    };

    return newConfig;
});


// ****************** API CLIENT ******************
export const apiClient = axios.create();

export function isV2Auth() {
    let localStorageAccessToken = localStorage.getItem('amber.accessToken');
    let authVersion = Cookies.get(
        'authVersion_' + ENVIRONMENT.toLowerCase().substring(0, 2),
    );
    return localStorageAccessToken || authVersion === 'v2';
}

apiClient.interceptors.request.use((config) => {
    // Auth v2
    if (isV2Auth()) {
        isRefreshingAccessToken = false;
        requestsToRefresh = [];

        const newConfig = config;

        newConfig.timeout = TIMEOUT;
        newConfig.baseURL = `${API_URL}/v2/`;
        newConfig.withCredentials = true;

        return newConfig;
    }
    // Auth v1
    else {
        let apiKey = localStorage.getItem(STORAGE_API_KEY) || null;
        if (apiKey !== null) {
            try {
                // remove double-quotes coming from angular
                apiKey = JSON.parse(apiKey);
            } catch (error) {
                // leave as is
            }

            if (config.params === undefined) {
                config.params = {};
            }
            config.params.apiKey = apiKey;
        }

        config.timeout = TIMEOUT;
        config.baseURL = API_URL;

        return config;
    }
});

apiClient.interceptors.response.use(null, (error) => {
    if (isV2Auth()) {
        try {
            const {response, config} = error;

            // Unauthorized user
            if (
                response &&
                response.status === 401 &&
                response.data === 'Invalid token'
            ) {
                // Check if we are in the middle of refreshing the token
                if (!isRefreshingAccessToken) {
                    isRefreshingAccessToken = true;

                    refreshAccessToken()
                        .then((resRefreshToken) => {
                            requestsToRefresh.forEach((cb) =>
                                cb(resRefreshToken.data.accessToken),
                            );
                        })
                        .catch((errRefreshToken) => {
                            trackAxiosErrors(errRefreshToken, config.url, 'errRefreshToken');
                            redirectToLogin();
                            requestsToRefresh.forEach((cb) => cb(null));
                        });
                }

                return new Promise((resolve, reject) => {
                    requestsToRefresh.push((token) => {
                        if (token) {
                            resolve(axios(config));
                        } else {
                            reject(error);
                        }
                    });
                });
            }

            return Promise.reject(error);
        } catch (e) {
            trackAxiosErrors(e, error.config.url, 'catch_block');
            return Promise.reject(e);
        }
    }
    return Promise.reject(error);
});

function refreshAccessToken() {
    return axios.post(
        `${API_URL}/v2/refresh-token`,
        {},
        {
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true,
        },
    );
}
