import {saveAs} from 'file-saver'
import { convertSegmentsToSubtitles, createSubtitlesFromSegments } from './subtitleUtil'

function _getExportFilename(title, ext) {
  return `${title.replace('.mp3', '').replace('.wav', '')}--edited.${ext}`;
}

function combineSubtitlesToFullSrt(subtitles) {
  let fullSrt = "";
  let index = 1;
  for (let subtitle of subtitles) {
    fullSrt += index++;
    fullSrt += "\r\n";
    fullSrt += subtitle.getSrtSubtitle();
    fullSrt += "\r\n\r\n"
  }
  return fullSrt;
}

function combineSubtitlesToFullVtt(subtitles) {
  let fullVtt = "WEBVTT";
  fullVtt += "\r\n\r\n"
  for (let subtitle of subtitles) {
    fullVtt += subtitle.getVttSubtitle();
    fullVtt += "\r\n\r\n"
  }
  return fullVtt;
}

function exportFile(fileName, fullSrtString) {
  const blob = new Blob(
    [fullSrtString],
    {type: 'text/plain'}
);
  saveAs(blob, fileName);
};


export function exportSrt(segments, transcriptStatus, subtitleDurationMax) {
  const { audioDisplayFileName } = transcriptStatus.data;
  let subtitles;
  if (subtitleDurationMax === undefined) {
    subtitles = createSubtitlesFromSegments(segments)
  } else {
    subtitles = convertSegmentsToSubtitles(segments, subtitleDurationMax);
  }
  const fileName = _getExportFilename(audioDisplayFileName, 'srt');
  let fullSrtString = combineSubtitlesToFullSrt(subtitles);
  exportFile(fileName, fullSrtString);
}


export function exportVtt(segments, transcriptStatus, subtitleDurationMax) {
  const { audioDisplayFileName } = transcriptStatus.data;
  let subtitles;
  if (subtitleDurationMax === undefined) {
    subtitles = createSubtitlesFromSegments(segments)
  } else {
    subtitles = convertSegmentsToSubtitles(segments, subtitleDurationMax);
  }
  const fileName = _getExportFilename(audioDisplayFileName, 'vtt');
  let fullVttString = combineSubtitlesToFullVtt(subtitles);
  exportFile(fileName, fullVttString);
}