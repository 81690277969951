import React from "react";
import PropTypes from "prop-types";
import { Box, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import { ReactComponent as EditIcon } from "../../../assets/images/ui/edit.svg";
import AmberOutlinedButton from "../../buttons/AmberOutlinedButton";
import AmberPrimaryButton from "../../buttons/AmberPrimaryButton";

export default function BurnInConfirmation(props) {
  const { translate, onClose, onBack, onContinue } = props;

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mt={3}
        mb={1.5}
        ml={4.5}
        mr={3}
      >
        <Box display="flex" gridColumnGap={4} alignItems="center">
          <Box
            fontSize={24}
            fontWeight={400}
            data-testid="burnin-confirmation-dialog-title"
          >
            {translate("EDITOR.BURN_IN.confirmationTitle")}
          </Box>
        </Box>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        gridRowGap={24}
        pt={2}
        pb={3}
        pl={4.5}
        pr={4.5}
      >
        <Box
          display="flex"
          flexDirection="row"
          gridColumnGap={32}
          alignItems="center"
        >
          <Box flex={3}>
            <EditIcon />
          </Box>
          <Box
            fontSize={14}
            fontWeight={400}
            lineHeight={1.5}
            data-testid="burnin-confirmation-dialog-description"
          >
            {translate("EDITOR.BURN_IN.confirmationText")}
          </Box>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        gridRowGap={24}
        pt={1}
        pb={4}
        pl={4.5}
        pr={4.5}
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <AmberOutlinedButton
            text={translate("EDITOR.BURN_IN.back")}
            onClick={onBack}
            testid="burnin-confirmation-dialog-back-btn"
          />
          <AmberPrimaryButton
            text={translate("EDITOR.BURN_IN.continue")}
            onClick={onContinue}
            testid="burnin-confirmation-dialog-continue-btn"
          />
        </Box>
      </Box>
    </>
  );
}

BurnInConfirmation.propTypes = {
  translate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
};
