import { HIGHLIGHT } from '../settings'
import { numberOfSpaces, getWordsBefore, getWordsAfter } from './wordsUtils';

/**
 * Get segment highlighted words
 *
 * @param  {Segment} segment
 * @param  {Highlight} highlights
 * @return {Array<Highlight>}
 */
export function getSegmentHighlightedWords (segment, highlights) {
    const wordsArray = [...segment.words];
    const highlightsArray = [...highlights];
    let output = [];

    for (var i = 0; i < wordsArray.length; i++) {
        let {start, end} = wordsArray[i];
        for (var j = 0; j < highlightsArray.length; j++) {
            if (start >= highlightsArray[j].start && end <= highlightsArray[j].end) {
                output.push(wordsArray[i]);
                break;
            }
        }
    }

    return output;
}

/**
 * Add property isHighlighted (true and false) to each word in a segment.
 *
 * @param  {Segment} segment
 * @param  {Highlight} highlights
 * @return {Array<Highlight>}
 */
export function checkHighlightedWordsInCurrentSegment(startPosition, endPosition, highlights) {
    return highlights.toArray().filter(h => (h.startPosition < endPosition && h.endPosition > startPosition)).map(h => {
        if(h.startPosition <= startPosition && h.endPosition <= endPosition) {
            return {
                startPosition: startPosition,
                endPosition: h.endPosition
            }
        } else if(h.startPosition >= startPosition && h.endPosition <= endPosition) {
            return {
                startPosition: h.startPosition,
                endPosition: h.endPosition
            }
        } else if(h.startPosition >= startPosition && h.endPosition >= endPosition) {
            return {
                startPosition: h.startPosition,
                endPosition: endPosition
            }
        } else if(h.startPosition <= startPosition && h.endPosition >= endPosition) {
            return {
                startPosition: startPosition,
                endPosition: endPosition
            }
        }
    });
}

/**
 * Get waveform highlights data
 *
 * Transform the highlights state data to something useful for the waveform
 * in order to show semi-transparent overlays on top of the waveform at the right
 * spots based on the highlights start/end times
 *
 * @param  {Array<Highlight>} highlights
 * @param {number} startTimeOffset
 * @param  {number} duration
 * @return {Array<HighlightWave}
 */
export function getWaveformHighlights (highlights, startTimeOffset, duration) {
    let output = [];

    for (let i = 0; i < highlights.length; i++) {
        let highlight = highlights[i];
        let highlightStartPercent = ((highlight.start - startTimeOffset) * 100) / duration;
        let highlightWidth = ((highlight.end - highlight.start) * 100) / duration;

      output.push({
          // start : duration = x : 100
          left: `${highlightStartPercent}%`,
          // end : duration = x : 100
          width: `${highlightWidth}%`
      })
  }
  return output;
}

/**
 * Get highlight's start block key
 *
 * When coming from the backend highlights don't indicate in which block/segment
 * they will start. This is left to be calculated here based on the start time
 * of the highlight model.
 *
 * @param  {ContentState} content
 * @param  {Array<ContentBlock>} blocks
 * @param  {number} start
 * @return {number}
 */


/**
 * Get highlights from content state
 *
 * That is draft-js representation of the UI interface
 *
 * @param {Object} contentState From `draft-js`
 */
export function getHighlightsFromContent (contentState) {
    let singleHighlightRanges = [];

    let continuesBlockPositions = 0;
    let extraSpacesCount = 0;

    contentState.getBlockMap().forEach((block) => {
        const blockKey = block.getKey();
        const textWithNoExtraSpace = block.getText().split(' ').filter(t => t !== '').join(' ');

        block.findStyleRanges(
            (character) => {
                return character.hasStyle(HIGHLIGHT);
            },
            (startPosition, endPosition) => {
                const words = block.data.get('words');
                const text = block.getText();

                //only removing trailing extra white spaces.
                const beforeStartPositionText = (text[startPosition - 1] !== ' ') ? text.substring(0, startPosition) : text.substring(0, startPosition - 1);
                const beforeStartPositionTextWithNoExtraSpace = beforeStartPositionText.split(' ').filter(t => t !== '').join(' ');
                //comparing the position with whitespaces and without whitespaces.
                let startPosition2 = startPosition - (beforeStartPositionText.length - beforeStartPositionTextWithNoExtraSpace.length);

                const beforeEndPositionText = text.substring(0, endPosition);
                const beforeEndPositionTextWithNoExtraSpace = beforeEndPositionText.split(' ').filter(t => t !== '').join(' ');
                let endPosition2 = endPosition - (beforeEndPositionText.length - beforeEndPositionTextWithNoExtraSpace.length);

                const spaces = numberOfSpaces(text);
                const firstWord = getWordsBefore(words, startPosition - spaces + 1).last() || block.data.get('words').first()
                const lastWord = getWordsAfter(words, endPosition - spaces - 1).first() || block.data.get('words').last()
                
                if(firstWord && lastWord) {
                    const start = firstWord.get('start')
                    const end = lastWord.get('end')

                    singleHighlightRanges.push({
                        start,
                        end,
                        startPosition: startPosition2 + continuesBlockPositions - extraSpacesCount,
                        endPosition: endPosition2 + continuesBlockPositions - extraSpacesCount,
                        _atEndOfBlock: block.getLength() === endPosition,
                        _atStartOfBlock: startPosition === 0,
                        _blockKey: blockKey,
                    })
                }
            }
        );

        //use counter to count extra spaces in prev segments.
        extraSpacesCount += block.getText().length - textWithNoExtraSpace.length;
        continuesBlockPositions += block.getText().length + 1;
    });

    const highlights = mergeContiguousHighlightRanges(contentState, singleHighlightRanges);

    return highlights;
}

/**
 * Merge contiguous highlight ranges
 *
 * It will create one single highlight object from multiple highlight ranges,
 * which come here separated for each word and space despite being contiguous.
 * So we use recursion and a the flag `_atEndOfBlock` to merge them even across
 * multipe blocks, that is in the situation where an highlight spans across
 * multiple paragraphs.
 *
 * @param  {Array<Highlight>} highlights
 * @return {Array<Highlight>}
 */
export function mergeContiguousHighlightRanges (contentState, highlights) {
    let mergedHighlights = [];
    let highlightsIdxs = {};

    for (let i = 0; i < highlights.length; i++) {
        const highlight = highlights[i];
        const startAt = _getStartAt(contentState, highlights, highlight, i);
        const endAt = _getEndAt(contentState, highlights, highlight, i);
        highlightsIdxs[startAt] = endAt;
    }

    for (let startIdx in highlightsIdxs) {
        let endIdx = highlightsIdxs[startIdx];
        let {start, startPosition} = highlights[startIdx];
        let {end, endPosition} = highlights[endIdx];

        mergedHighlights.push({
            start,
            end,
            startPosition,
            endPosition,
        });
    }
    return mergedHighlights;
}

/**
 * Get the index of the highlighted entity at the begin of the highlight
 *
 * It uses recursion to find the first highlighted entity of the current highlight
 *
 * @param  {Array<Highlight>} list
 * @param  {Highlight} currentHighlight
 * @param  {number} idx
 * @return {number}
 */
function _getStartAt (contentState, list, currentHighlight, idx) {
    const prevHighlight = list[idx - 1] || {};
    // if (idx > 17) {
    //     debugger;
    // }
    if (currentHighlight._atStartOfBlock) {
        if (!prevHighlight._atEndOfBlock) {
            return idx;
        }
        const prevBlock = contentState.getBlockBefore(currentHighlight._blockKey);
        const prevBlockKey = prevBlock ? prevBlock.getKey() : null;
        if (!prevBlockKey.length || prevBlockKey !== prevHighlight._blockKey) {
            return idx;
        }
    } else {
        if (currentHighlight.startPosition !== prevHighlight.endPosition) {
            return idx;
        }
    }
    return _getStartAt(contentState, list, prevHighlight, idx - 1);
}

/**
 * Get the index of the highlighted entity at the end of the highlight
 *
 * It uses recursion to find the last highlighted entity of the current highlight
 *
 * @param  {Array<Highlight>} list
 * @param  {Highlight} currentHighlight
 * @param  {number} idx
 * @return {number}
 */
function _getEndAt (contentState, list, currentHighlight, idx) {
    const nextHighlight = list[idx + 1] || {};
    if (currentHighlight._atEndOfBlock) {
        if (!nextHighlight._atStartOfBlock) {
            return idx;
        }
        const nextBlock = contentState.getBlockAfter(currentHighlight._blockKey);
        const nextBlockKey = nextBlock ? nextBlock.getKey() : null;
        if (!nextBlockKey.length || nextBlockKey !== nextHighlight._blockKey) {
            return idx;
        }

    } else {
        if (currentHighlight.endPosition !== nextHighlight.startPosition) {
            return idx;
        }
    }

    return _getEndAt(contentState, list, nextHighlight, idx + 1);
}