import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { RecoilRoot } from 'recoil';
import { Box, makeStyles, MenuItem, Select, Tooltip, Button  } from '@material-ui/core';
import { styled } from '@mui/material';
import TranslateIcon from '@material-ui/icons/Translate';
import MachineTranslations from "../../MachineTranslations";
import { DONE, PERFECT_DONE } from '../../../sagas/constants';
import {
  getJobTypeIcon,
  hasAutoAndManualJobs,
  shouldShowJobTypeIcon,
} from './helpers';
import { withTranslate } from 'react-redux-multilingual';

// Style
const useStyles = makeStyles((theme) => ({
  icon: {
    top: '1px',
  },
  jobTypeIcon: {
    top: '1px',
    color: theme.palette.custom.lightGray2,
  },
}));

const LanguageBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
}));

function FileTranslationPicker(props) {
  const { translations, sourceJob, history, translate, hasTranslations, isTranslateModalOpen, setTranslateModalOpen } = props;
  const currentJobId = window.location.pathname.slice(1);
  const classes = useStyles();

  const selectOptions = [sourceJob, ...translations];
  const [targetLanguage, setTargetLanguage] = useState(currentJobId);

  const shouldShowIcons = hasAutoAndManualJobs(translations);

  const redirectUserToNewJob = (jobId) => {
    history.push(jobId);
    history.go();
  };

  const handleMenuItemChange = (event) => {
    const lng = event.target.value;
    if (lng === "addNew"){
      setTranslateModalOpen(true)
    }
    else{
      setTargetLanguage(event.target.value);
      redirectUserToNewJob(event.target.value);
    }
  };

  const shouldMenuItemBeDisabled = (status) => {
    return ![PERFECT_DONE, DONE].includes(status);
  };

  const translationDisplayedLanguage = (translation) => {
    return translate(`EDITOR_LANGUAGE.${translation.targetLanguageCode}`)  || translation.language 
  }
  
  const handleClick = () => {
    setTranslateModalOpen(true)
  }
  const getColor = () => {
    if (props.spellCheck) {
      return { fill: '#005A50' }
    } else {
      return {};
    }
  }
  return (
    <>
      <Box display="inline-block" color="custom.lightGray2" mt={0}>
        <Tooltip title={translate('EDITOR.controlsTranslation')} placement="bottom">
          <Button onClick={handleClick} className="RedoButton" color="default">
            <TranslateIcon style={getColor()} /> {translate('EDITOR.controlsTranslation')}
          </Button>
        </Tooltip>
        {hasTranslations &&
          <Select
            onChange={handleMenuItemChange}
            displayEmpty
            inputProps={{
              'aria-label': 'Without label',
              classes: {
                icon: classes.icon,
              },
            }}
            value={targetLanguage}
            style={{ fontSize: '15px' }}
            disableUnderline
            classes={classes.select}
          >
            {selectOptions.map((translation, index) => (
              <MenuItem
                value={translation.id}
                disabled={shouldMenuItemBeDisabled(translation.status)}
              >
                <LanguageBox>
                  {translationDisplayedLanguage(translation)}
                  {shouldShowJobTypeIcon(shouldShowIcons, index) &&
                    getJobTypeIcon(
                      translation.jobType,
                      classes.jobTypeIcon,
                      translate
                    )}
                </LanguageBox>
              </MenuItem>
            ))}
            <MenuItem value={"addNew"} style={{ color: '#005A50' }} >
              <LanguageBox>
                {translate('EDITOR.addNewLanguage')}
              </LanguageBox>
            </MenuItem>
          </Select>
        }
      </Box>
      <RecoilRoot >
        <MachineTranslations currentJobId={currentJobId} setTranslateModalOpen={setTranslateModalOpen} isTranslateModalOpen={isTranslateModalOpen} translate={translate} />
      </RecoilRoot>

    </>
  );
}

export default withTranslate(withRouter(FileTranslationPicker));

FileTranslationPicker.propTypes = {
  translations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      targetLanguageTranslation: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
    })
  ),
};
