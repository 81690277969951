/**
 * Returns the status of the feature flag
 * @param {string} featureToggle 
 * @returns {boolean}
 */
export function parseFeatureToggle(featureToggle) {
  try {
    return JSON.parse(featureToggle);
  } catch (e) {
    return false;
  }
};