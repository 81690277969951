import { TRANSCRIPTION } from "../../constants/ratingCategories";

export const en = {
  locale: "en",
  messages: {
    MISC: {
      formErrRequired: "A value is required",
      formErrEmail: "Email must be a valid address",
      formHintYes: "Yes",
      formHintQuestion: "Use {{ what }}?",
      signedInAs: "You are signed in as",
      logout: "Logout",
      youOwner: "You",
      closeDialogBtn: "Close",
      ariaCloseDialog: "Close dialog",
      clearSearch: "Clear search",
      serverErrorDefaultToastMsg: "Server error",
      ienoticeTitle: "Unsupported browser",
      ienoticeTxt:
        "Internet Explorer is not supported at the moment. Please use another browser, such as Google Chrome or Microsoft Edge",
      ienoticeDismiss: "Got it",
      requestFullTranscriptBodyDialog:
        "If you want your text to be perfected by our language experts, please upload it again and choose transcription type 'manual'.",
      requestFullTranscriptTitle: "Get 100% accurate text",
      requestFullTranscriptBody:
        "Request a quote for an improved transcript by one of our human transcribers",
    },
    CONFIRM_SUBMIT: {
      title: "Are you sure you want to submit the file?",
      checkbox1:
        "I have checked the addition Notes and implemented the client specific requirements if requested.",
      checkbox2:
        "The entire ASR output of the file is checked with the spelling & Grammar checker.",
      checkbox3: "Transcript is created according to the guidelines.",
      back: "Take me back to the file",
      submit: "Submit file for review",
    },
    TRANSCRIPT_ERROR: {
      message:
        "You no longer have access to this file. The file might have been reassigned. If you have questions, contact operations.",
      button: "Return to dashboard",
    },
    NAV: {
      titleAccount: "Account",
      titleAccountNew: "Add new user",
      titleAccountMy: "My account",
      titleBilling: "Billing",
      titleInvoices: "Invoices",
      titleCheckout: "Checkout",
      titleCoupon: "Redeem coupon",
      titleInvite: "Invite friends",
      titleLogin: "Login",
      titlePasswordReset: "Reset password",
      titleEmailvalidate: "Email validation",
      titlePlan: "Plan",
      titleCosts: "Costs",
      titleRecords: "Your files",
      titleRecordsTooltip: "Back to dashboard",
      titleRegister: "Register",
      titleTransactions: "Transactions",
      titleUploads: "Uploads",
      titleDashboard: "Dashboard",
      titleManageUsers: "Manage users",
      titleAddUser: "Add user",
      titleManageTranscriptions: "Manage transcriptions",
      tooltipAdmin: "Admin",
      titleAdminUser: "Manage user",
      tooltipLanguage: "Change language",
      newBadge: "new",
      help: "Help",
      currentPlanFallback: "Get full plan",
      currentPlanTooltip: "Current plan",
    },
    LANGUAGES: {
      en: "English",
      nl: "Dutch",
      de: "German",
      fr: "French",
      es: "Spanish",
      it: "Italian",
      pt: "Portuguese",
    },
    ACCOUNT: {
      titleForm: "Account Details",
      username: "Username",
      email: "Email",
      firstName: "First name",
      lastName: "Last name",
      company: "Company",
      pwd: "Password",
      newPwd: "New Password",
      confirmPwd: "Confirm Password",
      changePwd: "Change password",
      createPwd: "Create password",
      msgUsernameMaxlength:
        "The name has to be less than {{ quantity }} characters long.",
      msgPwdLength: "The password must be at least {{ quantity }} characters.",
      msgPwdPattern:
        "The password must contain at least one number, one uppercase letter, one lowercase letter and one symbol.",
      msgPwdConfirm: "The new password must be confirmed",
      msgPwdMismatch: "Must match the previous entry",
      loading: "Loading details...",
      update: "Update data",
      updating: "Saving data...",
      save: "Save user",
      saving: "Saving user...",
    },
    ADMIN: {
      add: {
        donateSeconds: "Donate seconds",
      },
      dashboard: {
        loading: "Loading dashboard...",
        noResults: "No dashboard elements available",
      },
      transactions: {
        id: "ID",
        owner: "Owner",
        seconds: "Seconds",
        date: "Date",
        noId: "No ID specified",
        loading: "Loading transactions...",
        noResults: "No transactions to show",
        results: "Transactions retrieved:",
      },
      transcriptions: {
        created: "Created",
        id: "ID",
        name: "Name",
        user: "User",
        status: "Status",
        length: "Length",
        type: "Type",
        loading: "Loading transcriptions...",
        noResults: "No transcriptions to show",
        results: "Transcriptions retrieved:",
      },
      users: {
        created: "Created",
        username: "Username",
        fullname: "Full name",
        status: "status",
        minutes: "Audio time",
        pause: "Pause user",
        activate: "Activate user",
        delete: "Delete user",
        subscribed: "Subscribed",
        loading: "Loading users...",
        noResults: "No users to show",
        results: "Users retrieved:",
        deleteConfirm: "Are you sure you want to delete '{{ name }}'?",
        deleteOk: "Yes",
        deleteCancel: "Cancel",
        search: "Search users",
      },
      user: {
        navBackToUsers: "All Users",
        navEdit: "Profile",
        navTranscriptions: "Transcriptions",
        navTransactions: "Transactions",
        navDonate: "Donate",
        fullName: "Full name",
        availableSeconds: "Available time",
        created: "Created on",
        isSubscribed: "Current subscription",
        isntSubscribed: "No subscriptions",
        status: "Status",
        userType: "Type",
        language: "Preferred language",
        makeAdmin: "Admin capabilities",
        cancelSubscription: "Cancel subscription",
        cancellingSubscription: "Cancelling...",
      },
      donate: {
        title: "Donate hours",
        username: "Recipient's username",
        seconds: "Number of seconds",
        secondsFormatted: "Hours : minutes : seconds",
        comment: "Message to recipient",
        submit: "Send",
        submitting: "Sending...",
      },
      sendvalidationemail: {
        submit: "Send validation email",
        submitting: "Sending",
      },
    },
    BILLING: {
      titleForm: "Billing Details",
      firstName: "First name",
      lastName: "Last name",
      email: "Email",
      phone: "Phone",
      company: "Company",
      vatNumber: "VAT number",
      address: "Address",
      address2: "Address 2",
      city: "City",
      country: "Country",
      zipCode: "ZIP code",
      state: "State",
      loading: "Loading details...",
      save: "Save details",
      saving: "Saving details...",
    },
    CHECKOUT: {
      choose: "1. Choose plan",
      setup: "2. Set up payment",
      confirm: "3. Confirmation",
      loadingStripe: "Loading stripe...",
      titleCard: "Card Details",
      titleBilling: "Billing Details",
      titleSummary: "Order summary",
      subscribe: "Subscribe to plan",
      subscribing: "Subscribing...",
      info: "Amberscript accepts the following credit and debit cards. Payments are handled securely by Stripe.",
      labelNameOnCard: "Name on card",
      labelCard: "Credit or debit card",
      termsDisclaimer: "By continuing you agree to Amberscript's",
      termsDisclaimerLinkTxt: "Terms of Service",
    },
    SUMMARY: {
      plan: "Plan:",
      cost: "Cost:",
      beyond: "Beyond",
      hrs: "hrs",
      hour: "hour",
      billingCycle: "Billing cycle:",
      billingInterval: "monthly",
      total: "Total:",
      loading: "Loading summary...",
      error: "Failed to load summary :(",
    },
    COSTS: {
      titlePlan: "Current plan",
      titleCosts: "Summary",
      titleInvoices: "List of invoices",
      noResultsPlan: "Get full plan",
      noResultsCosts: "You have no costs for this month",
      noResultsInvoices: "There are no invoices yet.",
      loadingSummary: "Loading summary...",
      loadingInvoices: "Loading invoices...",
      summary:
        "This month you have used {{ time }} at the total cost of {{ cost }} euro.",
      remainder: "With your plan {{ planName }} you pay {{ price }} per hour",
      remainderB: "you have {{ num }} free hours included",
      remainderC1: "and a fixed cost of {{ cost }} per {{ interval }}.",
      remainderC2: "and no fixed costs.",
      INVOICES: {
        invoiceNr: "Nr",
        created: "Created",
        description: "Description",
        monthPeriod: "Month",
        yearPeriod: "Year",
        nettoAmount: "Netto",
        vatAmount: "VAT",
      },
    },
    COUPON: {
      title: "Redeem a coupon",
      inputLabel: "Coupon code",
      submit: "Submit",
      submitting: "Submitting...",
      cancel: "Cancel",
      invalidCouponCode: "Invalid coupon code",
    },
    EDITOR: {
      tooManyRows: "Too many rows",
      busy: "Loading ...",
      feedbackBtn: "Give feedback",
      shortcutsBtn: "Show all shortcuts",
      shortcutsHint: "Want to use Play or Pause? Use Control + Space",
      shortcutHintAltClick:
        "Want audio to jump when clicking on text? Keep alt pressed",
      shortcutHintNewLine:
        "Want to add a new line within a segment? Press shift + enter",
      shortcutsTitle: "Keyboard Shortcuts",
      ariaShortcutsClose: "Close shortcuts",
      replayTour: "Replay Tour",
      errorEmptySegments:
        "Amberscript did not recognize any words in the language you selected. Please make sure your audio has spoken language and you have sufficient credits left. Feel free to reach out if you have any questions!",
      errorLoadAudio: "Error when trying to load the file",
      errorLoadXML: "Error when trying to load the file",
      errorReadAudio: "Error reading the audio file",
      loadingAudio: "Loading audio...",
      loading: "Loading transcript...",
      parsing: "Parsing transcript...",
      speakerClear: "Clear speaker",
      speakerAddBtn: "Add new",
      speakerAddTitle: "Add speaker",
      speakerEditTitle: "Edit speaker",
      findreplTitle: "Find and Replace",
      findreplFindLabel: "Find:",
      findreplReplLabel: "Replace with:",
      findreplReplBtn: "Replace all",
      infoTitle: "Transcription info",
      infoClose: "Close transcription info",
      infoNameLabel: "Name",
      infoDateLabel: "Date",
      infoCancel: "Cancel",
      infoSave: "Save",
      infoSaving: "Saving...",
      feedbackTitle: "Feedback",
      feedbackRatingLabel: "How good was the quality of the transcription?",
      feedbackCommentLabel: "Leave a comment",
      feedbackSubmit: "Send",
      feedbackSubmitting: "Sending...",
      feedbackVal1: "Bad",
      feedbackVal2: "So so",
      feedbackVal3: "OK",
      feedbackVal4: "Good",
      feedbackVal5: "Very good",
      exportBtn: "Export",
      exportXML: "XML",
      exportWord: "Word",
      autosaveSuccess: "All changes saved",
      autosavePending: "Saving...",
      autoSaveSaved: "Saved",
      autoSaveError: "Error while saving",
      autoSaveTryAgain: "Click here to try again",
      metaCreated: "Created",
      metaLength: "Length",
      metaStartTimeOffset: "Start time",
      metaStartTimeOffsetLabel: "Start time (hh:mm:ss:ms)",
      metaStartTimeOffsetTitle: "Realign start timecode",
      metaStartTimeOffsetDescription:
        "Realign start timecode, to synchronize with original source. Enter the time, that should be added to all original timestamps of the transcript",
      metaStartTimeOffsetSubmit: "Realign timecode",
      metaWords: "Words",
      metaPlaybackRate: "Speed",
      metaPlaybackRateTip: "Change playback speed",
      controlsTranslation: "Add translations",
      addNewLanguage: "Add new language",
      controlsSpellCheck: "Toggle spell checker",
      controlsSpellCheckShortcut: "(ctrl+g)",
      controlsReplace: "Replace",
      controlsReplaceShortcut: "(ctrl+f)",
      controlsUndo: "Undo",
      controlsUndoShortcut: "(ctrl+z)",
      controlsRedo: "Redo",
      controlsRedoShortcut: "(ctrl+shift+z)",
      controlsHighlight: "Highlight",
      controlsHighlightShortcut: "(ctrl+h)",
      controlsSubtitles: "Subtitles",
      controlsSubtitlesShortcut: "Convert to subtitles",
      rewindBtn: "Rewind",
      forwardBtn: "Forward",
      resumeBtn: "Resume",
      ariaPlay: "Play",
      ariaPause: "Pause",
      blockTextLength: "{textLength} characters",
      adjustStartTimestampHeader: "Adjust start timestamp",
      adjustEndTimestampHeader: "Adjust end timestamp",
      adjustDialogSubmit: "Adjust",
      adjustDialogCancel: "Cancel",
      adjustTimestampDescription:
        "Minimum and maximum timestamps are determined by the previous' and next words timestamps.",
      subtitlesDialogHeader: "Convert to subtitles",
      subtitlesDialogDescription:
        "Automatically convert your transcript into subtitle-ready phrases. Amberscript will split up the text into smaller phrases that fit onto the screen.",
      subtitlesDialogNote:
        "Note: This action cannot be reversed automatically. However, your text will still be editable.",
      subtitlesDialogAdvancedOptions: "Advanced options",
      subtitlesDialogSubmit: "Convert",
      subtitlesDialogSubmitLoading: "Please wait",
      subtitlesDialogCancel: "Cancel",
      noEditingForMobileOrTablet:
        "Currently Amberscript does not support editing on your device. Amberscript works best on a laptop or PC running Google Chrome and also supports Microsoft Edge, Apple Safari and Mozilla Firefox.",
      emailValidationTitle: "Email is not validated yet",
      emailValidationDescription:
        "In order to export or copy your transcript, please validate your email.",
      btnAlreadyActivated: "I already activated my email",
      btnSendActivationAgain: "Resend verification email",
      btnActivationSent: "Email verification sent!",
      alertLoggedOutTitle: "Login needed",
      alertLoggedOutDescription:
        "You have logged out in another tab. Please login again to use Amberscript.",
      alertLoggedOutButton: "Go to login",
      alertAccessDeniedTitle: "Access denied",
      alertAccessDeniedDesciption: "You do not have access to edit this file.",
      btnApproveOrReject: "Approve or reject...",
      btnDetails: "Details",
      btnSubmit: "Submit",
      dialogJobFeedback: {
        btnApprove: "Approve",
        btnReject: "Reject",
        title: "Approve or reject quality check",
        rateTranscriber:
          "Rate {email} on each area for this job, 1 being lowest, 5 being highest quality. Need help? Read the ",
        qualityControlGuidelines: "Quality Control Guidelines.",
        textAreaPlaceholder: "Add notes...",
        notesExplanation:
          "Required: Add notes, even when approving, so the transcriber can see your feedback.",
        research: "Research (names & jargon)",
        transcription: "Content",
        syntax: "Guidelines",
        grammar: "Spelling/Typos/Grammar",
        punctuation: "Punctuation/Syntax",
        difficulty: "Difficulty level",
        difficultyExplanation:
          "This score is not part of the total score calculation.",
        total: "Total transcription rating",
        noQualityCheck: "No quality check was submitted for this job",
        notes: "Notes",
        tooltip: {
          transcription:
            "It is checked whether the spoken text has been correctly converted into written text. This includes the accurate reproduction of words, sentences and statements without errors or omissions, in accordance with the guidelines. It is checked whether there were any listening errors that could have led to inaccurate or incorrect interpretation of the transcriptions. (30%)",
          syntax:
            "This is where it is checked whether the transcription was carried out in accordance with the established guidelines.This includes the use of time marks for unintelligible passages, the labelling of speakers, the observance of pauses and sentence breaks as well as other guidelines that apply to the transcription. All special guidelines are evaluated especially strictly. It must be checked whether the formatting of the transcript is appropriate. This may include line spacing, font size, arrangement of text elements and other aspects of the visual presentation of the transcript. (25%)",
          grammar:
            "In this category, grammatical errors as well as spelling and typing errors in the transcript are evaluated. The aim is to ensure that the text is both grammatically correct and free of spelling errors according to guidelines. This includes checking for errors such as misspelled words, missing capitalisation and errors in the use of abbreviations. (25%)",
          punctuation:
            "It is checked whether punctuation marks such as full stops and commas have been used correctly to improve the readability and comprehensibility of the text. It also assesses whether the use of quotes is appropriate and whether other punctuation marks have been used correctly. Another aspect of this category is the correct division of long sentences. (15%)",
          research:
            "This category focuses on evaluating the research of technical terms, proper names and other specialised expressions that appear in the transcript. It is checked whether these terms have been spelt and used correctly and, if available, whether terms from a glossary have been used. (5%)",
        },
      },
      jobItemDialog: {
        title: "Job Details",
        historyNotesTab: "History & Notes",
        detailsTab: "Details",
        unknownPerson: "Unknown person",
        cleanread: "Clean read",
        transcription: "Transcription",
        captions: "Captions",
        translatedSubtitles: "Translated subtitles",
        unspecifiedWorkflowStyle: "Unspecified",
        BASIC: "Basic",
        FORMATTED: "Formatted",
        HQ: "High Quality",
        verbatim: "Verbatim",
        "TRANSLATION - BASIC": "Translation - Basic",
        "TRANSLATION - FORMATTED": "Translation - Formatted",
        "TRANSLATION - HQ": "Translation - HQ",
        qualityCheckTab: "QC report",
      },
      jobFields: {
        qualityControlDeadline: "Quality control deadline",
        jobId: "Job ID",
        recordId: "Record ID",
        created: "Created date",
        language: "Language",
        submitted: "Submitted",
        submittedDate: "Submitted date",
        userRecordStatus: "User record status",
        jobStatus: "Job status",
        nrAudioSeconds: "Length",
        transcriptionType: "Transcription type",
        transcriptionStyle: "Transcription style",
        filename: "Filename",
        numberOfSpeakers: "Number of speakers",
        transcriber: "Transcriber",
        perfectJobManager: "Perfect Job Manager",
        deadline: "Deadline",
        clientDeadline: "Client deadline",
        transcriberDeadline: "Transcriber deadline",
        client: "Client",
        sourceJobId: "Source Job ID",
        historyLog: "History",
        targetLanguage: "Target Language",
        isDeleted: "Deleted",
        invoiced: "Invoiced",
        invoiceItemId: "InvoiceItemId",
        notes: "Notes",
        workflowStyle: "Workflow Style",
        workflowStatus: "Workflow Status",
      },
      dialogCaptionJobSubmit: {
        title: "Submit job",
        informOfsIssues:
          "If you feel that there is an issue with these captions, please reach out to operations@amberscript.com to raise an issue on the algorithm.",
        afterSubmitting: "Submitting will send the job to the client.",
        btnSubmitJob: "Submit job",
      },
      dialogAlertEditNotAllowed: {
        title: "Invalid job type",
        message: "This job can not be viewed in the editor.",
        closeBtn: "OK",
      },
      SUBTITLE: {
        sliderShorterLabel: "Shorter",
        sliderLongerLabel: "Longer",
        sliderRecommendedLabel: "Recommended",
        slidercharsPerSecLabel: "{charsPerSec} characters / sec.",
      },
      EXPORT: {
        title: "Export text",
        format: "Format",
        export: "Export",
        optionsTitle: "Export options",
        optionsTimestamps: "Include timestamps",
        optionsSpeakers: "Include speakers",
        optionsIncludeHighlights: "Include highlights",
        optionsHighlights: "Export only highlights",
        maxCharsPerRow: "Max characters per row",
        millisecond: "Millisecond",
        fps: "FPS",
        cancel: "Cancel",
        ok: "Export",
        transcribedByAmberscript:
          "This speech-to-text was created by www.Amberscript.com",
        wayOfExport: "How do you want to export your file?",
        text: "Text",
        textDesc:
          "Word Doc or TXT. Great for transcripts of interviews, meetings and media tagging.",
        subtitleDesc:
          "SRT, VTT or EBU-STL. Can be used for Social Media, Youtube, Video players and more.",
        video: "Video",
        videoDesc:
          "Download video with or without included subtitles. Great for sharing videos with burned-in subtitles for social media.",
        rules: "Rules",
        burnSubToVideo: "Burn subtitles to video - Coming soon",
        subtitleRule: "Max {maxLine} lines, {maxChar} characters per line",
        realignRecommendation:
          "We recommend realigning timestamps before export.",
        realignRecommendationDesc:
          "Editing your transcript means the audio must be realigned to match your edits.",
        realignRecommendation1: "Quick",
        realignRecommendation1Desc:
          "Fast and general realignment. May not be 100% accurate.",
        realignRecommendation2: "Full - Coming soon",
        realignRecommendation2Desc:
          "A thorough, accurate realignment of your audio. This may take several minutes.",
        realignRecommendation3: "Export without realigning",
        realignRecommendation3Desc: "Your timestamps may not be accurate.",
        emailNotification:
          "You’ll receive an email when your file is ready to download.",
        options: "Options",
        whatIsJson:
          "JSON is a data format that can be used for software applications.",
        audio: "Audio",
        audioDesc: "Download your original media file.",
        downloadAudio: "Download Audio",
        downloadVideo: "Download Video",
        exportSubtitleTitle: "Export Subtitles",
        custom: "Custom",
        maxNrRows: "Maximum number of rows",
        maxCharPerRow: "Maximum characters per row, including spaces",
        titleTranscript: "Export transcript",
        downloadFile: "Download audio file",
        asAFile: "As a file",
        asAFileDesc:
          "SRT, VTT or EBU-STL. Can be used for Social Media, Youtube, Video players and more.",
        download: "Download",
        asAVideoWithBurnIn: "As a video with subtitles burned in",
        asAVideoWithBurnInDesc:
          "Subtitles will appear on the video file permanently. Export as .mp4, etc.",
        createNewVersion: "Create a new version",
        lastExport: "Last export:",
        additionalOptions: "Additional options",
        downloadAsText: "Download as text",
        downloadAudioFile: "Download audio file",
        downloadVideoFile: "Download video file",
        postVideoOnYoutube: "Post video on youtube",
        intelligentExport: "Intelligent Export",
        summary: "Summary",
        summaryTooltip: "Generate a summary of your transcript",
        anonymization: "Anonymization",
        anonymizationTooltip:
          "Anonymize all personal-related data (names, addresses, etc.) of your transcription.",
        cleanRead: "Clean Read",
        cleanReadTooltip:
          "Generate a transcript which is edited for easy readability.",
      },
      BURN_IN: {
        processingVideo: "Processing video",
        confirmationTitle: "Are you happy with your file?",
        confirmationText:
          "You can only burn the current version of these subtitles in once. If you edit the subtitle file afterwards and wish to burn them in again, an additional fee applies.",
        continue: "Yes, continue",
        back: "No, keep editing",
        successTitle: "Order successful",
        successText1:
          "It will take time to process. Once complete, you’ll be notified and can download the video through the",
        successText2: "screen on the dashboard, or in the editor.",
        export: "Export",
        returnToEditor: "Return to editor",
        continueToDashboard: "Continue to dashboard",
        notAvailableText: "Want to create an additional export?",
        notAvailableLink: "Contact customer support",
        notConverted:
          "You need to convert your document to subtitles before you can proceed with the burn-in.",
      },
      toast: {
        onCopy: "Copying, pasting and cutting is not available.",
        onCut: "Copying, pasting and cutting is not available.",
        onPaste: "Copying, pasting and cutting is not available.",
      },
      RPT: {
        title: "Let us do the editing for you",
        description:
          "By requesting the manual transcription service our language experts will get access to correct your file. Your current file changes are reset and your file will be locked until the corrections are finished.",
        description2: "Click on OK to proceed to checkout",
      },
      BANNER: {
        firstStepTitle: "Check and revise your transcript.",
        firstStepDescTranscriber:
          "You can skip this, but there may be errors in your subtitles.",
        firstStepDesc:
          "You can skip this, but there may be errors in your subtitles. You can also Request a review from one of our language experts.",
        secondStepTitle:
          "Done editing? Hit Create subtitles to align & format subtitles.",
        secondStepDesc:
          "You’ll also be able to save the transcript for your records.",
      },
      PRE_QC: {
        submitBtn: "Submit for Quality Check...",
        dialogTitle: "Quality Assistant",
        submitAnyway: "Continue with submission anyway",
        viewFix: "View & Fix",
        spelling: " spelling issues",
        grammar: " grammar issues",
        cleanread: " clean-read issues",
        inaudible: " inaudible tags",
        cancel: "Cancel",
        submit: "Submit",
        confirmationTitle: "Submit for review",
        confirmation:
          "You are about to submit the transcription for review. This step cannot be undone. Are you sure you want to submit the transcript?",
        emptyState: "Quickly get feedback on your document.",
        runPreQCBtn: "Run Quality Assistant",
        spellingIssueFound: " spelling issue found",
        spellingIssuesFound: " spelling issues found",
        grammarIssueFound: " grammar issue found",
        grammarIssuesFound: " grammar issues found",
        cleanreadIssueFound: " clean read issue found",
        cleanreadIssuesFound: " clean read issues found",
        inaudibleIssueFound: " inaudible tag found",
        inaudibleIssuesFound: " inaudible tags found",
        noSuggestion: "No suggestion",
        loadingSuggestions: "loading suggestions...",
        noIssueNotice: "No issues have been found, well done!",
        formatting: " formatting issues",
        formattingIssueFound: " formatting issue found",
        formattingIssuesFound: " formatting issues found",
        reportTitle: "Report false positive",
        reportBtn: "Report false positive",
        reportComment: "Enter comment",
        reportCommentPlaceholder: "Enter your comment here",
        spellingInfoTooltip:
          "The spelling of some words might be incorrect. Consider correcting them or report false positives by clicking the flag icon.",
        inaudibleInfoTooltip:
          "This section was marked as inaudible. Make sure to use this tag only when the speech is not clear.",
        grammarInfoTooltip:
          "A grammar mistake was found. Consider checking the capitalisation at the beginning of the sentence.",
        cleanReadInfoTooltip:
          "Some violations of the clean read guidelines were found. Consider removing repeated words, disallowed tags, and slang words.",
        formattingInfoTooltip:
          "Some violations of the subtitles guidelines were found. Consider adjusting for CPL/CPS limits, removing disallowed symbols and having shorter clauses.",
        confirmationMessage:
          "Note: Because of the number of {totalIssues} issues, the Quality Assistant needs more time. Are you sure you want to proceed?",
      },
    },
    CREATE_SUBTITLES: {
      title: "Help our algorithm do its best work",
      title1:
        "We use machine learning to align subtitles to audio in a readable format.",
      title2:
        "This automation means you spend less time fixing subtitle alignment and formatting.",
      title3:
        "Correcting errors in your transcript before creating subtitles makes them even more accurate!",
      note1:
        "All caption files in languages other than English, German, and Dutch which are longer than 10 minutes should be submitted for alignment to Operations first!",
      note2:
        "Once you convert this file to subtitles, your transcription will no longer be available. Please export the transcription now if you need it later.",
      note3:
        "The file will temporarily disappear from the current jobs tab, and appear again once it's fully converted.",
      note4:
        "It’ll take a few minutes for our algorithm to format your subtitles.",
      note5: "We’ll let you know when they’re ready.",
      confirmation1: "I’m done reviewing my transcript",
      rule1:
        "Maximum {maxLinesPerSegment} line, {maxCharsPerLine} characters per line, and {maxDisplayTimePerLine} seconds screen time",
      rule2: "Maximum number of lines",
      rule3: "Maximum characters per line (CPL)",
      rule5: "Maximum display time per line",
      customRules: "Custom rules",
      customRulesDescription:
        "CPL, CPS, Min/Max duration, Lines per subtitle, Frame gap, Counting",
      includeSpacesInCount: "Include spaces in character count",
      includeSpecialCharactersInCount:
        "Include special characters (#,&,%..) in character count",
      maxCharsPerSecond: "Maximum characters per second",
      minSubtitleDuration: "Minimum subtitle duration",
      maxSubtitleDuration: "Maximum subtitle duration",
      minFrameGap: "Minimum frame gap",
      netflixRules: "Netflix rules",
      msgSuccess1:
        "The process successfully started, you will be redirected back to the platform",
      msgSuccess2:
        "The process successfully started, you will be redirected back to the dashboard",
      error1: "Unknown error occurred, Please try again",
      button1: "Create subtitles",
    },
    OriginalText: {
      title: "Show Original",
      transcript: "Transcript",
    },
    SPELLCHECK: {
      title: "How to enable spell check?",
      clickHere: "Click here",
      linkDesc:
        "for a complete instruction on how to enable spell check in Chrome",
      step1: "Go to Chrome settings",
      step2: "Expand the Advanced section",
      step3: "Click on Languages",
      step4: "Open this link in a new Chrome tab",
      step5: "From the Languages section, add the desired language",
      step6: "Restart Chrome to apply changes",
    },
    LOGIN: {
      title: "Welcome back",
      subtitle: "Log in to your account",
      alreadyQuestion: "New?",
      alreadyAction: "Sign up now.",
      btn: "Login",
      emailOrUsername: "Email or username",
      loginFormDescription:
        "You are logged out or not authorized to see this page. Please login again with the right account.",
      errorLogin: "Invalid email and password combination",
      forgotPassword: "Forgot password?",
      loggedinActionBtn: "Go to my records",
    },
    PASSWORDRESET: {
      alreadyQuestion: "Need help?",
      alreadyAction: "Contact us at",
      actionLogin: "Log in",
      actionRegister: "Sign up",
      actionOr: "or",
      loggedinActionBtn: "Go to my records",
    },
    PASSWORDRECOVER: {
      title: "Forgot your password?",
      subtitle: "Create a new one here.",
      btn: "Reset my password",
      hintUsername:
        "This is the username you used to create your Amberscript account.",
      or: "or",
      hintEmail:
        "This is the email you used to create your Amberscript account.",
    },
    PASSWORDCHANGE: {
      title: "Change your password",
      subtitle: "Create a new password to log back in to Amberscript",
      btn: "Confirm my password",
    },
    EMAILVALIDATE: {
      loading: "Validation in progress...",
      inviteTitle: "Thanks for registering.",
      inviteSubtitle: "An email to activate your account has been sent to",
      successTitle: "Done!",
      successSubtitle:
        "Thanks for validating your email. Your account is now active.",
      errorTitle: "This email validation link is not right.",
      errorSubtitle: "If the problem persists contact us at",
      loggedinActionBtn: "Go to my records",
    },
    PLAN: {
      hour: "hour",
      select: "Select",
      unsubscribeBtn: "Unsubscribe",
      unsubscribeMsg:
        "Are you sure you want to unsubscribe from your plan {{ plan }}?",
      unsubscribeOk: "Proceed",
      unsubscribeCancel: "Cancel",
      yourCurrent: "Your current plan",
      vatDisclaimer: "Prices excl. VAT",
      loading: "Loading plans...",
      noResults: "No plans available at the moment",
      showPlans: "Show plans",
    },
    RECORDS: {
      upload: "Upload",
      name: "Name",
      words: "Words",
      length: "Length",
      owner: "Owner",
      lastModified: "Last modified",
      created: "Created",
      more: "More",
      ariaMore: "Open actions menu",
      share: "Share",
      delete: "Delete",
      deleting: "Deleting...",
      deletedSuccess: "{{ recordId }} deleted successfully.",
      slicedFileMsg:
        "This audio file has been sliced because of insufficient credits left.",
      invalid: "Invalid File",
      errorGeneric: "This transcription appears to be broken.",
      loading: "Loading your files...",
      noResults: "No files created yet",
      tipUploading: "Uploading...",
      tipOpen: "Scheduled for transcription",
      tipBusy: "Transcribing...",
      tipDone: "Ready",
      tipError: "Broken",
      scheduled:
        "{{ audioName }} is scheduled for transcription. This can take minutes.",
      transcribing: "Transcribing {{ audioName }}, {{ progress }}...",
      transcribed: "Transcription of {{ audioName }} is ready.",
      uploadErrorServer: "Server error. Failed uploading {{ audioName }}.",
      uploadError: "Upload of {{ audioName }} failed.",
      uploadProgress: "Uploading of {{ audioName }}:",
      uploadStart: "Began uploading {{ audioName }}.",
      uploaded: "Upload of {{ audioName }} has completed.",
      uploadhelpTitle: "Uploading on Amberscript",
      uploadhelpTxt: "Amberscript supports the following audio formats:",
      uploadUnsupprtedFormatTitle: "Format not supported",
      uploadUnsupprtedFormatMsg:
        "You need to select an audio file. Formats accepted:",
      uploadUnsupprtedFormatBtn: "Got it",
      uploadConfirmTitle: "Ready for transcription!",
      uploadConfirmMsgTop:
        "Note: make sure the quality of your audio is good enough. We assume your files:",
      uploadconfirmList1: "Contain clearly spoken speech",
      uploadconfirmList2: "Have no background noise",
      uploadconfirmList3: "Are not spoken in strong accents",
      uploadConfirmMsgBottom:
        "If any of these criteria are not met, Amberscript cannot guarantee high quality transcriptions.",
      uploadconfirmSelectASR: "Select transcription language",
      uploadConfirmOk: "Proceed",
      uploadConfirmCancel: "Cancel",
      unsupportedUploadTitle: "Upload not supported",
      unsupportedUploadMsg:
        "We are sorry but your browser does not support upload of audio files.",
      unsupportedUploadBtn: "Got it",
      deleteMsg: "Are you sure you want to delete '{{ name }}'?",
      deleteOk: "Confirm",
      deleteCancel: "Cancel",
      noCreditTitle: "Upgrade your plan",
      noCreditMsg:
        "You have insufficient credits. To continue uploading audio files subscribe to a plan.",
    },
    REGISTER: {
      title: "Claim your FREE TRIAL",
      subtitle: "Get 30 minutes of audio or video transcription for FREE",
      alreadyQuestion: "Have an account?",
      alreadyAction: "Log in",
      btn: "Register",
      forgotPassword: "Forgot password?",
      createPwd: "Choose a password",
      confirmPwd: "Confirm password",
      loggedinActionBtn: "Go to my records",
    },
    SHORTCUTS: {
      playPause: "Play and pause your audio file",
      rewind: "Rewind your audio 5 seconds",
      forward: "Fast-forward your audio 5 seconds",
      skip: "Skip a word",
      reverse: "Reverse a word",
      highlight: "Highlight text",
      undo: "Undo",
      redo: "Redo",
      altClick: "Jump audio while clicking",
    },
    STATUS: {
      actionBtn: "Get full plan",
      remainingMsg: "Transcription balance: {{ balance }}",
    },
    UPLOADS: {
      id: "ID",
      owner: "Owner",
      length: "Length",
      date: "Date",
      noId: "No ID specified",
      loading: "Loading uploads...",
      noResults: "No uploads yet",
    },
    TOUR: {
      back: "Back",
      close: "Close",
      last: "Thanks!",
      next: "Next",
      skip: "Skip",
      dismissBtn: "Don't show this again",
      EDITOR: {
        beginTitle: "Welcome",
        begin:
          "In this tour we show you how to make the most use of Amberscript",
        altClick:
          "Fast forward your audio by pressing the alt key while clicking on a certain word",
        play: "With play you play the audio file. You can easily stop and continue with the control + space shortcut",
        rewind:
          "When you use this button you automatically rewind 5 seconds. The shortcut for this is control + r",
        highlight:
          "With this button you easily add highlights on your audio timeline. Select a piece of text in the transcription and notice the highlight in your timeline",
        shortcuts:
          "This button shows a lot of other useful shortcuts you can use to work with Amberscript even faster",
        startTime:
          "You can adjust the start timecode of every paragraph to synchronize with the original source, such as a video, by clicking here.",
        end: "That's it! Any questions? Feel free to contact <a href='mailto:info@Amberscript.com'>info@Amberscript.com</a>",
      },
    },
    REFERRAL: {
      title: "Help your friends save their time",
      description:
        "Invite your friends to use Amberscript - for every person who signs up, you’ll get up to {signupCreditMinutes} min in credit.",
      terms: "Terms apply",
      enterEmail: "Enter email address",
      invitationSent:
        "Your invitation has been sent. Feel free to invite more friends!",
      sendButton: "Send Invites",
      shareInviteLink: "Share your invite link",
      transcriptionCredit: "Your transcription credit",
      pending: "Pending",
      earned: "Earned",
      copy: "Copy",
      copied: "Copied!",
      errorTitle: "The referral code is expired",
      errorDescription:
        "We will inform you about new campaigns via email or social media.",
    },
    REALIGN: {
      btnName: "Realign timestamps",
      dialogTitle: "Alignment in progress",
      dialogContent: "Improving timestamps of transcript, please wait...",
    },
    TRANSACTIONS: {
      id: "ID",
      owner: "Owner",
      seconds: "Seconds",
      date: "Date",
      noId: "No ID specified",
      loading: "Loading transactions...",
      noResults: "No transactions yet",
    },
    THANKS: {
      mainMsg: "Thanks!",
      summaryTitle: "Order summary",
    },
    SCRAMBLED_DIALOG: {
      title: "Oops! An error occurred in your document",
      description1:
        "The text within your document has accidentally gotten scrambled.",
      description2:
        "As a result, your document has to be restored to a previous state. Your last edit will be lost as the page needs to be refreshed.",
      titleFeedback:
        "Please let us know what action you took prior to seeing this dialog so that we can remediate the issue.",
      descriptionButton: "The page will be refreshed automatically",
    },
    COUNTRIES: {
      AFG: "Afghanistan",
      ALA: "Åland Islands",
      ALB: "Albania",
      DZA: "Algeria",
      ASM: "American Samoa",
      AND: "Andorra",
      AGO: "Angola",
      AIA: "Anguilla",
      ATA: "Antarctica",
      ATG: "Antigua and Barbuda",
      ARG: "Argentina",
      ARM: "Armenia",
      ABW: "Aruba",
      AUS: "Australia",
      AUT: "Austria",
      AZE: "Azerbaijan",
      BHS: "Bahamas",
      BHR: "Bahrain",
      BGD: "Bangladesh",
      BRB: "Barbados",
      BLR: "Belarus",
      BEL: "Belgium",
      BLZ: "Belize",
      BEN: "Benin",
      BMU: "Bermuda",
      BTN: "Bhutan",
      BOL: "Bolivia, Plurinational State of",
      BES: "Bonaire, Sint Eustatius and Saba",
      BIH: "Bosnia and Herzegovina",
      BWA: "Botswana",
      BVT: "Bouvet Island",
      BRA: "Brazil",
      IOT: "British Indian Ocean Territory",
      BRN: "Brunei Darussalam",
      BGR: "Bulgaria",
      BFA: "Burkina Faso",
      BDI: "Burundi",
      KHM: "Cambodia",
      CMR: "Cameroon",
      CAN: "Canada",
      CPV: "Cape Verde",
      CYM: "Cayman Islands",
      CAF: "Central African Republic",
      TCD: "Chad",
      CHL: "Chile",
      CHN: "China",
      CXR: "Christmas Island",
      CCK: "Cocos (Keeling) Islands",
      COL: "Colombia",
      COM: "Comoros",
      COG: "Congo",
      COD: "Congo, the Democratic Republic of the",
      COK: "Cook Islands",
      CRI: "Costa Rica",
      CIV: "Côte d'Ivoire",
      HRV: "Croatia",
      CUB: "Cuba",
      CUW: "Curaçao",
      CYP: "Cyprus",
      CZE: "Czech Republic",
      DNK: "Denmark",
      DJI: "Djibouti",
      DMA: "Dominica",
      DOM: "Dominican Republic",
      ECU: "Ecuador",
      EGY: "Egypt",
      SLV: "El Salvador",
      GNQ: "Equatorial Guinea",
      ERI: "Eritrea",
      EST: "Estonia",
      ETH: "Ethiopia",
      FLK: "Falkland Islands (Malvinas)",
      FRO: "Faroe Islands",
      FJI: "Fiji",
      FIN: "Finland",
      FRA: "France",
      GUF: "French Guiana",
      PYF: "French Polynesia",
      ATF: "French Southern Territories",
      GAB: "Gabon",
      GMB: "Gambia",
      GEO: "Georgia",
      DEU: "Germany",
      GHA: "Ghana",
      GIB: "Gibraltar",
      GRC: "Greece",
      GRL: "Greenland",
      GRD: "Grenada",
      GLP: "Guadeloupe",
      GUM: "Guam",
      GTM: "Guatemala",
      GGY: "Guernsey",
      GIN: "Guinea",
      GNB: "Guinea-Bissau",
      GUY: "Guyana",
      HTI: "Haiti",
      HMD: "Heard Island and McDonald Islands",
      VAT: "Holy See (Vatican City State)",
      HND: "Honduras",
      HKG: "Hong Kong",
      HUN: "Hungary",
      ISL: "Iceland",
      IND: "India",
      IDN: "Indonesia",
      IRN: "Iran, Islamic Republic of",
      IRQ: "Iraq",
      IRL: "Ireland",
      IMN: "Isle of Man",
      ISR: "Israel",
      ITA: "Italy",
      JAM: "Jamaica",
      JPN: "Japan",
      JEY: "Jersey",
      JOR: "Jordan",
      KAZ: "Kazakhstan",
      KEN: "Kenya",
      KIR: "Kiribati",
      PRK: "Korea, Democratic People's Republic of",
      KOR: "Korea, Republic of",
      KWT: "Kuwait",
      KGZ: "Kyrgyzstan",
      LAO: "Lao People's Democratic Republic",
      LVA: "Latvia",
      LBN: "Lebanon",
      LSO: "Lesotho",
      LBR: "Liberia",
      LBY: "Libya",
      LIE: "Liechtenstein",
      LTU: "Lithuania",
      LUX: "Luxembourg",
      MAC: "Macao",
      MKD: "Macedonia, the former Yugoslav Republic of",
      MDG: "Madagascar",
      MWI: "Malawi",
      MYS: "Malaysia",
      MDV: "Maldives",
      MLI: "Mali",
      MLT: "Malta",
      MHL: "Marshall Islands",
      MTQ: "Martinique",
      MRT: "Mauritania",
      MUS: "Mauritius",
      MYT: "Mayotte",
      MEX: "Mexico",
      FSM: "Micronesia, Federated States of",
      MDA: "Moldova, Republic of",
      MCO: "Monaco",
      MNG: "Mongolia",
      MNE: "Montenegro",
      MSR: "Montserrat",
      MAR: "Morocco",
      MOZ: "Mozambique",
      MMR: "Myanmar",
      NAM: "Namibia",
      NRU: "Nauru",
      NPL: "Nepal",
      NLD: "Netherlands",
      NCL: "New Caledonia",
      NZL: "New Zealand",
      NIC: "Nicaragua",
      NER: "Niger",
      NGA: "Nigeria",
      NIU: "Niue",
      NFK: "Norfolk Island",
      MNP: "Northern Mariana Islands",
      NOR: "Norway",
      OMN: "Oman",
      PAK: "Pakistan",
      PLW: "Palau",
      PSE: "Palestinian Territory, Occupied",
      PAN: "Panama",
      PNG: "Papua New Guinea",
      PRY: "Paraguay",
      PER: "Peru",
      PHL: "Philippines",
      PCN: "Pitcairn",
      POL: "Poland",
      PRT: "Portugal",
      PRI: "Puerto Rico",
      QAT: "Qatar",
      REU: "Réunion",
      ROU: "Romania",
      RUS: "Russian Federation",
      RWA: "Rwanda",
      BLM: "Saint Barthélemy",
      SHN: "Saint Helena, Ascension and Tristan da Cunha",
      KNA: "Saint Kitts and Nevis",
      LCA: "Saint Lucia",
      MAF: "Saint Martin (French part)",
      SPM: "Saint Pierre and Miquelon",
      VCT: "Saint Vincent and the Grenadines",
      WSM: "Samoa",
      SMR: "San Marino",
      STP: "Sao Tome and Principe",
      SAU: "Saudi Arabia",
      SEN: "Senegal",
      SRB: "Serbia",
      SYC: "Seychelles",
      SLE: "Sierra Leone",
      SGP: "Singapore",
      SXM: "Sint Maarten (Dutch part)",
      SVK: "Slovakia",
      SVN: "Slovenia",
      SLB: "Solomon Islands",
      SOM: "Somalia",
      ZAF: "South Africa",
      SGS: "South Georgia and the South Sandwich Islands",
      SSD: "South Sudan",
      ESP: "Spain",
      LKA: "Sri Lanka",
      SDN: "Sudan",
      SUR: "Suriname",
      SJM: "Svalbard and Jan Mayen",
      SWZ: "Swaziland",
      SWE: "Sweden",
      CHE: "Switzerland",
      SYR: "Syrian Arab Republic",
      TWN: "Taiwan, Province of China",
      TJK: "Tajikistan",
      TZA: "Tanzania, United Republic of",
      THA: "Thailand",
      TLS: "Timor-Leste",
      TGO: "Togo",
      TKL: "Tokelau",
      TON: "Tonga",
      TTO: "Trinidad and Tobago",
      TUN: "Tunisia",
      TUR: "Turkey",
      TKM: "Turkmenistan",
      TCA: "Turks and Caicos Islands",
      TUV: "Tuvalu",
      UGA: "Uganda",
      UKR: "Ukraine",
      ARE: "United Arab Emirates",
      GBR: "United Kingdom",
      USA: "United States",
      UMI: "United States Minor Outlying Islands",
      URY: "Uruguay",
      UZB: "Uzbekistan",
      VUT: "Vanuatu",
      VEN: "Venezuela, Bolivarian Republic of",
      VNM: "Viet Nam",
      VGB: "Virgin Islands, British",
      VIR: "Virgin Islands, U.S.",
      WLF: "Wallis and Futuna",
      ESH: "Western Sahara",
      YEM: "Yemen",
      ZMB: "Zambia",
      ZWE: "Zimbabwe",
    },
    GLOSSARY: {
      title: "Dictionary",
      names: "Names",
      terms: "Terms",
      notFound:
        "The dictionary could not be found. Please create a dictionary next time before uploading your file to improve the accuracy of your text.",
    },
    MACHINE_TRANSLATIONS: {
      direct: "Machine-made",
      perfect: "Human-made",
    },
    EDITOR_LANGUAGE: {
      "en-us": "English (United States)",
      "en-uk": "English (United Kingdom)",
      nl: "Dutch",
      de: "German",
      fr: "French",
      es: "Spanish",
      it: "Italian",
      pt: "Portuguese",
      da: "Danish",
      fi: "Finnish",
      no: "Norwegian (Bokmål)",
      sv: "Swedish",
      pl: "Polish",
      ro: "Romanian",
      uk: "Ukrainian",
      ru: "Russian",
      bg: "Bulgarian",
      cs: "Czech",
      el: "Greek",
      et: "Estonian",
      hu: "Hungarian",
      ja: "Japanese",
      ko: "Korean",
      lt: "Lithuanian",
      lv: "Latvian",
      sk: "Slovak",
      sl: "Slovenian",
      tr: "Turkish",
      zh: "Chinese",
    },
  },
};
