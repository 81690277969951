import React from "react";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";

export default function GlossaryNotFound(props) {
  const { translate } = props;

  return (
    <Box
      alignItems="center"
      paddingLeft={2.75}
      paddingRight={2.75}
      lineHeight="2"
      color="#2B292D"
      fontSize={{ xs: 12, sm: 12, md: 14, lg: 16, xl: 18 }}
    >
      {translate("GLOSSARY.notFound")}
    </Box>
  );
}

GlossaryNotFound.propTypes = {
  translate: PropTypes.func.isRequired,
};
