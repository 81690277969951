import React from 'react'
import { connect } from "react-redux";
import { withTranslate } from 'react-redux-multilingual';
import {
    Dialog, Button, DialogContent, DialogContentText,
    DialogActions, DialogTitle, Box
} from '@material-ui/core';

import { DASHBOARD_URL } from '../../settings';

const mapStateToProps = state => {
    return {
        userRecord: state.transcriptStatus.data
    };
};

class OpenDialog extends React.Component {
    constructor(props) {
        super(props);

        this.translate = props.translate;
    }

    state = {
        open: this.props.open,
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.props.open !== prevProps.open) {
            this.setState({
                open: this.props.open
            });
        }
    }

    handleOpen = () => {
        this.setState({
            open: true
        });
    };

    openRequestPerfectWindow = () => {
        window.location.href = `${DASHBOARD_URL}/upload?m=rpt&recordId=${encodeURIComponent(this.props.recordId)}`;
    }

    render() {
        return (
            <Dialog
                open={this.state.open}
                onClose={this.props.handleClose}
            >
                <DialogTitle>
                    {this.props.translate('EDITOR.RPT.title')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <p>{this.props.translate('EDITOR.RPT.description')}</p>
                        <span>{this.props.translate('EDITOR.RPT.description2')}</span>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Box display="flex" p={2} width="100%">
                        <Button onClick={this.props.handleClose} variant="contained" color="default" fullWidth style={{ marginRight: 4 }}>
                            {this.props.translate('EDITOR.EXPORT.cancel')}
                        </Button>
                        <Button onClick={this.openRequestPerfectWindow} variant="contained" color="primary" fullWidth style={{ marginLeft: 4 }}>
                            {this.props.translate('EDITOR.feedbackVal3')}
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
        )
    }
}

class RequestFullTranscriptButton extends React.Component {

    constructor(props) {
        super(props);
    }

    state = {
        open: false,
    };

    openDialogToReqForPerfect = () => {
        this.setState({
            open: true
        })
    }

    handleRequestTranscriptionClick = () => {
        this.openDialogToReqForPerfect();
    }

    handleClose = () => {
        this.setState({
            open: false
        });
    };

    render() {
        const { translate } = this.props;

        return (
            <Box display={{ xs: 'none', sm: 'none', md: 'unset', lg: 'unset', xl: 'unset' }}>
                <React.Fragment>
                    <Button id="request_upgrade" className={this.props.className} aria-label={translate('MISC.requestFullTranscriptTitle')} color="primary" onClick={this.handleRequestTranscriptionClick}>
                        {translate('MISC.requestFullTranscriptTitle')}
                    </Button>
                    <OpenDialog open={this.state.open} translate={translate} handleClose={this.handleClose} recordId={this.props.userRecord.recordId} />
                </React.Fragment>
            </Box>
        )
    }
}

export default connect(mapStateToProps)(withTranslate(RequestFullTranscriptButton));

