import {saveAs} from 'file-saver';
import {toHHMMSS} from './timeUtils';
import {getSegmentHighlightedWords, checkHighlightedWordsInCurrentSegment} from './highlightUtils';
import {DEBUG} from '../settings';

// Javascript has a lot of problem with calculations
// This function changes 1.80000000003 to 1.80
function twoDecimalPoint(number) {
    if (typeof number === "number") {
        return Math.trunc(number * 100) / 100;
    } else {
        return Math.trunc(parseFloat(number) * 100) / 100;
    }
}

function getStartTime(includeTimestamps, word) {
    if (includeTimestamps) {
        if (word.start !== null || word.start !== undefined) {
            return word.start;
        } else {
            if (DEBUG) console.log('No start value for', word);
            return undefined;
        }
    } else {
        return undefined;
    }
}

function getEndTime(includeTimestamps, word) {
    if (includeTimestamps) {
        if (word.end !== null || word.end !== undefined) {
            return word.end;
        } else {
            if (DEBUG) console.log('No end value for', word);
            if (word.start !== null || word.start !== undefined) {
                return word.start;
            } else {
                return undefined;
            }
        }
    } else {
        return undefined;
    }
}

function getDuration(includeTimestamps, word) {
    const startTime = word.start || 0;
    const endTime = word.end || startTime;

    if (includeTimestamps) {
        if (word.pristine === true) {
            if (word.duration) {
                return word.duration;
            } else {
                return twoDecimalPoint(endTime - startTime);
            }
        } else {
            return twoDecimalPoint(endTime - startTime);
        }
    } else {
        return undefined;
    }
}

function getWords(words, includeTimestamps, includePristine, newLine) {
    const result = [];

    for (let index = 0; index < words.length; index++) {
        const word = words[index];
        if (word) {
            if (word.text.trim().length > 0) {
                result.push({
                    text: (newLine && index === 0) ? `<br>${word.text.trim()}` : word.text.trim(),
                    start: getStartTime(includeTimestamps, word),
                    end: getEndTime(includeTimestamps, word),
                    duration: getDuration(includeTimestamps, word),
                    conf: (word.pristine === false) ? 1 : word.conf,
                    pristine: (includePristine && (word.pristine === true || word.pristine === false)) ? word.pristine : undefined
                });
            }
        }
    }

    return result;
}

export const convertToJSON = (transcript, options, includePristine, jobId) => {
    const {segments, highlights, speakers, startTimeOffset, isConvertedToSubtitles} = transcript.data;
    const {
        includeTimestamps,
        includeSpeakers,
        includeHighlights,
        highlightsOnly,
        includeIsConvertedToSubtitles
    } = options;

    const json = {
        id: transcript.id,
        ...(jobId ? {jobId} : {}),
        recordId: transcript.recordId,
        isConvertedToSubtitles: (includeIsConvertedToSubtitles) ? isConvertedToSubtitles : undefined,
        startTimeOffset,
        highlights: (includeHighlights) ? highlights.toArray() : undefined,
        speakers: (includeSpeakers ? speakers.toArray().map(
                speaker => ({
                    spkid: speaker.id,
                    name: speaker.name
                })) : undefined
        ),
        segments: segments.toArray().reduce((result, segment) => {
            if (segment && segment.words) {
                let words;
                if (highlightsOnly) {
                    words = getSegmentHighlightedWords(segment, highlights);
                } else {
                    words = segment.words.toArray();
                }
                if (words.length > 0) {
                    const nonEmptyWords = getWords(words, includeTimestamps, includePristine, segment.newLine);
                    if (nonEmptyWords.length > 0) {
                        result.push({
                            words: nonEmptyWords,
                            speaker: (includeSpeakers ? segment.speaker : undefined)
                        });
                    }
                }
            }
            return result;
        }, []),
    };
    return json;
};


function _getExportFilename(title, ext) {
    return `${title.replace('.mp3', '').replace('.wav', '').replace('.m4a', '')}--edited.${ext}`;
}


export const exportJSON = (transcript, transcriptStatus, options) => {
    const {audioDisplayFileName} = transcriptStatus.data;
    const blob = new Blob(
        [JSON.stringify(convertToJSON(transcript, options, false), null, 2)],
        {type: 'application/json;charset=utf-8'}
    );
    const fileName = _getExportFilename(audioDisplayFileName, 'json');
    saveAs(blob, fileName);
};


export const exportWordDoc = (transcript, transcriptStatus, options) => {
    const {segments, highlights, speakers} = transcript.data;
    const {audioDisplayFileName: title} = transcriptStatus.data;
    const {
        includeTimestamps, includeSpeakers, includeHighlights, highlightsOnly,
        transcriptByAmberScriptText
    } = options;

    let html = '<html ' +
        'xmlns:office="urn:schemas-microsoft-com:office:office" ' +
        'xmlns:word="urn:schemas-microsoft-com:office:word" ' +
        'xmlns="http://www.w3.org/TR/REC-html40">' +
        '<head>' +
        '<style>' +
        ' @page main {mso-page-orientation: portrait; size: 21cm 29.7cm; mso-footer: f1;}' +
        'div.main {page:main;}' +
        '</style>' +
        '<xml>' +
        '<word:WordDocument>' +
        '<word:View>Print</word:View>' +
        '<word:Zoom>90</word:Zoom>' +
        '<word:DoNotOptimizeForBrowser/>' +
        '</word:WordDocument>' +
        '</xml>' +
        '</head>' +
        '<body>' +
        '<div class="main">' +
        `<h2>${title}</h2>`;

    // segmentsLength: continuously count the length of segments.
    let segmentsLength = 0;

    // exports only highlighted words in the word file.
    (highlightsOnly) && (html = exportHighlightsOnly(transcript, options, html));
    (!highlightsOnly) && segments.forEach((segment) => {
        if (segment.words.first() === undefined) {
            return;
        }

        let words = segment.words.map(w => w.get('text')).join(' ');
        let sentence = '';
        //highlights the highlighted words in the word file.
        if (includeHighlights) {
            let highlightedList = checkHighlightedWordsInCurrentSegment(segmentsLength, words.length + segmentsLength, highlights);
            //check if this segment has highlighted words.
            if (highlightedList.length > 0) {
                let start = 0;
                for (let h of highlightedList) {
                    //add every words before the highlighted word with no style
                    sentence += words.substring(start, h.startPosition - segmentsLength);
                    //add the highlighted word with a background
                    sentence += `<span style='background:#005A50'>${words.substring(h.startPosition - segmentsLength, h.endPosition - segmentsLength)}</span>`;
                    start = h.endPosition - segmentsLength;
                }
                //add every words after the highlighted word and before the next highlighted word.
                sentence += words.substring(start);
            }
            //if this segment has no highlighted words, just simply add the words to the sentence with no style
            else {
                sentence += words;
            }
        } else {
            sentence = segment.words.map(w => w.get('text')).join(' ');
        }
        if (!words.length || !sentence) {
            return;
        }

        //update the segmentsLength with this segment wrods length
        segmentsLength += words.length + 1;

        html += `<p>`;
        if (includeTimestamps) {
            let segmentStartTime = segment.words.first().get('start');
            segmentStartTime = toHHMMSS(segmentStartTime);

            html += `<small><font color="#666666">${segmentStartTime}</font></small>`;
            html += `<br />`;
        }

        if (includeSpeakers) {
            const speaker = speakers.find(function (speaker) {
                return speaker.get('id') === segment.speaker;
            });
            if (speaker) { // @todo this shouldn't always be here?
                if (speaker.name) {
                    html += `<i>${speaker.name}</i>`;
                } else if (speaker.id) {
                    html += `<i>Speaker ${speaker.id}</i>`;
                }
                html += `: `;
            }
        }

        html += `${sentence}</p>`;
    });

    if (transcriptByAmberScriptText)
        html += `<div style='mso-element:footer' id=f1><p>${transcriptByAmberScriptText}</p></div>`;

    html += '</div></body></html>';

    //FOLLOWING CODE RUINS THE ACCENTED CHARACTERS
    // const byteNumbers = new Uint8Array(html.length);
    // for (let j = 0; j < html.length; j++) {
    //     byteNumbers[j] = html.charCodeAt(j);
    // }

    const blob = new Blob([html], {type: "text/html;charset=utf-8"});
    saveAs(blob, _getExportFilename(title, 'doc'));
};

const exportHighlightsOnly = (transcript, options, html) => {
    const {segments, highlights, speakers} = transcript.data;
    const {includeTimestamps, includeSpeakers} = options;
    let segmentsLength = 0;
    for (let segment of segments.toArray()) {
        let words = segment.words.map(w => w.get('text')).join(' ');
        let sentence = '';
        if (segment.words.first() === undefined) {
            return;
        }

        let highlightedList = checkHighlightedWordsInCurrentSegment(segmentsLength, words.length + segmentsLength, highlights);
        if (highlightedList.length > 0) {
            for (let h of highlightedList) {
                sentence += `${words.substring(h.startPosition - segmentsLength, h.endPosition - segmentsLength)} `;
            }
            sentence = sentence.trim();
        }

        if (sentence !== '') {
            html += `<p>`;
            if (includeTimestamps) {
                let segmentStartTime = segment.words.first().get('start');
                segmentStartTime = toHHMMSS(segmentStartTime);

                html += `<small><font color="#666666">${segmentStartTime}</font></small>`;
                html += `<br />`;
            }

            if (includeSpeakers) {
                const speaker = speakers.find(function (speaker) {
                    return speaker.get('id') === segment.speaker;
                });
                if (speaker) { // @todo this shouldn't always be here?
                    if (speaker.name) {
                        html += `<i>${speaker.name}</i>`;
                    } else if (speaker.id) {
                        html += `<i>Speaker ${speaker.id}</i>`;
                    }
                    html += `: `;
                }
            }

            html += `${sentence}</p>`;
        }
        segmentsLength += words.length + 1;
    }
    return html;
}

export const exportToTxtFile = (transcript, transcriptStatus, options) => {
    const newLine = '\r\n';
    const {segments, highlights, speakers} = transcript.data;
    const {audioDisplayFileName: title} = transcriptStatus.data;
    const {includeTimestamps, includeSpeakers, highlightsOnly} = options;

    let exportedText = `${title}${newLine}${newLine}`;

    // segmentsLength: continuously count the length of segments.
    let segmentsLength = 0;

    for (let segment of segments.toArray()) {
        let words = segment.words.map(w => w.get('text')).join(' ');
        let timeStamps = '';
        let speakerText = '';
        let sentence = '';
        if (segment.words.first() === undefined) {
            return;
        }
        if (includeTimestamps) {
            let segmentStartTime = segment.words.first().get('start');
            timeStamps = toHHMMSS(segmentStartTime);
        }
        if (includeSpeakers) {
            const speaker = speakers.find(function (speaker) {
                return speaker.get('id') === segment.speaker;
            });
            if (speaker) {
                if (speaker.name) {
                    speakerText = `${speaker.name}: `;
                } else if (speaker.id) {
                    speakerText = `${speaker.id}: `;
                }
            }
        }
        if (highlightsOnly) {
            let highlightedList = checkHighlightedWordsInCurrentSegment(segmentsLength, words.length + segmentsLength, highlights);
            if (highlightedList.length > 0) {
                for (let h of highlightedList) {
                    sentence += `${words.substring(h.startPosition - segmentsLength, h.endPosition - segmentsLength)} `;
                }
                sentence = sentence.trim();
            }
        } else {
            sentence = segment.words.map(w => w.get('text')).join(' ');
        }

        if (sentence !== '') {
            (includeTimestamps) && (exportedText += `${timeStamps}${newLine}`);
            (includeSpeakers) && (exportedText += speakerText);
            exportedText += `${sentence}${newLine}${newLine}`;
        }

        segmentsLength += words.length + 1;
    }

    const blob = new Blob([exportedText.trim()], {type: "text/plain;charset=utf-8"});
    saveAs(blob, _getExportFilename(title, 'txt'));
};
