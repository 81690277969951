import React from "react";
import PropTypes from "prop-types";
import { Box, DialogContent, styled, TextField } from "@material-ui/core";
import UnderlineBoxWithSuggestion from "../UnderlineBoxWithSuggestion";
import Title from "../title";

const CustomTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(0),
}));

export default function ReportSpellingDialogContent(props) {
  const { issue, value, handleChange, translate } = props;
  const { original_text, suggestion } = issue;

  return (
    <DialogContent data-testid="pre-qc-report-false-positive-dialog-content">
      <Box width="100%" display="flex" flexDirection="column" gridRowGap={36}>
        <UnderlineBoxWithSuggestion
          originalText={original_text}
          suggestion={suggestion}
          translate={translate}
          testid="pre-qc-report-false-positive-dialog-underline-box"
        />
        <Box width="100%" display="flex" flexDirection="column" gridRowGap={8}>
          <Title text={translate("EDITOR.PRE_QC.reportComment")} />
          <CustomTextField
            id="outlined-basic"
            variant="outlined"
            placeholder={translate("EDITOR.PRE_QC.reportCommentPlaceholder")}
            value={value}
            onChange={handleChange}
            fullWidth
            inputProps={{
              "data-testid":
                "pre-qc-report-false-positive-dialog-comment-input",
            }}
          />
        </Box>
      </Box>
    </DialogContent>
  );
}

ReportSpellingDialogContent.propTypes = {
  value: PropTypes.string,
  issue: PropTypes.shape({
    end_time: PropTypes.number.isRequired,
    end_word_index: PropTypes.number.isRequired,
    issue_severity: PropTypes.string.isRequired,
    issue_type: PropTypes.string.isRequired,
    issue_type_detail: PropTypes.string,
    original_text: PropTypes.string.isRequired,
    segment_index: PropTypes.number.isRequired,
    start_time: PropTypes.number.isRequired,
    start_word_index: PropTypes.number.isRequired,
    suggestion: PropTypes.string,
  }).isRequired,
  translate: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
};

ReportSpellingDialogContent.defaultProps = {
  value: null,
};
