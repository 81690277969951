import {apiClient} from '../../../apiClient';

/**
 * Gets the supported languages and target languages
 * @param {string} jobId
 * @returns {Promise<object>} object containing array of supportedLanguages and targetLanguages
 */
export async function getMachineTranslationLanguages() {
  return apiClient.get('/jobs/auto-translation/languages');
}

/**
 * Orders the machine translation jobs for the target languages
 * @param {string} sourceJobId
 * @param {string[]} targetLanguages
 * @param {string} costCenter
 * @returns {Promise<string[]>} job ids
 */
export async function createMachineTranslationsJob(
  sourceJobId,
  targetLanguages,
  costCenter,
) {
  const url = '/jobs/auto-translation/job';
  const body = {
    sourceJobId,
    targetLanguages,
    costCenter,
  };

  return apiClient.post(url, body);
}

/**
 * Moves the translation jobs to the queue in order to be processed
 * @param {string[]} jobIds
 * @returns {Promise<string>} success or fail
 */
export async function moveTranslationJobsToQueue(jobIds) {
  const url = '/jobs/auto-translation/translate';
  const body = {
    jobIds,
  };
  return apiClient.post(url, body);
}
