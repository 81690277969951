import Immutable from 'immutable'

const Speaker = new Immutable.Record({
    name: null,
    id: null,
    gender: null,
    channel: null,
    lang: null
});

export default Speaker;
