import React from "react";
import PropTypes from "prop-types";
import { Box, Checkbox, FormControlLabel } from "@material-ui/core";

// Component
export default function AmberCheckbox(props) {
  const { checked, label, name, handleChange, testid } = props;
  return (
    <FormControlLabel
      data-testid={testid}
      control={
        <Checkbox
          checked={checked}
          name={name}
          color="primary"
        />
      }
      label={
        <Box component="span" fontSize={14}>
          {label}
        </Box>
      }
      onChange={handleChange}
    />
  );
}

AmberCheckbox.propTypes = {
  testid: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
};

AmberCheckbox.defaultProps = {
  testid: "",
  name: "",
};
