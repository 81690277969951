import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "./en.json";
import de from './de.json';
import es from './es.json';
import fr from "./fr.json";
import it from './it.json';
import nl from './nl.json';
import pt from './pt.json';

const DEFAULT_LANGUAGE = "en";
const SUPPORTED_LANGUAGES = ["en", "de", "es", "fr", "it", "nl", "pt"];

function isLangParamSupported(lang) {
  return SUPPORTED_LANGUAGES.includes(lang);
}

// Read lang from arg, set as default language if exists
function getDefaultLanguage(lang) {
  if (lang) {
    if (isLangParamSupported(lang)) {
      return lang;
    }
  }
  return DEFAULT_LANGUAGE;
}

// Only used for shared components from @amberscript/amberscript-fe-components package
export default function setupI18nextInit(language) {
  return i18n.use(initReactI18next).init({
    debug: process.env.NODE_ENV === "development",
    resources: {
      en: { translation: en },
      de: { translation: de },
      es: { translation: es },
      fr: { translation: fr },
      it: { translation: it },
      nl: { translation: nl },
      pt: { translation: pt },
    },
    lng: getDefaultLanguage(language),
    fallbackLng: DEFAULT_LANGUAGE,
    interpolation: {
      escapeValue: false,
    },
  });
}
