import React from "react";
import PropTypes from "prop-types";
import { Box, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  normal: {
    userSelect: "text",
    "-moz-user-select": "text",
    "-webkit-user-select": "text",
    "-ms-user-select": "text",
  },
  hover: {
    userSelect: "text",
    "-moz-user-select": "text",
    "-webkit-user-select": "text",
    "-ms-user-select": "text",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

export default function Name(props) {
  const { name, hover = false } = props;

  const classes = useStyles();

  return (
    <Box lineHeight="2" className={hover ? classes.hover : classes.normal}>
      {name}
    </Box>
  );
}

Name.propType = {
  name: PropTypes.string.isRequired,
  hover: PropTypes.bool,
};

Name.defaultProps = {
  hover: false,
};
