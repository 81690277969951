import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import AmberCard from '../../cards/AmberCard';
import AmberSectionTitle from '../../labels/AmberSectionTitle';
import {
  getFormattedDate,
  getFormattedSeconds,
} from '../../../utils/timeUtils';

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    background: theme.palette.custom.whiteTwo,
    borderRadius: '1em',
    display: 'flex',
    flexDirection: 'column',
    flex: '0 0 32%',
  },
  cardsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  detailsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  cardTitle: {
    color: theme.palette.custom.charcoalGray,
    marginBottom: 0,
  },
  cardDates: {
    color: theme.palette.custom.purplishGray,
    fontWeight: 'bolder',
    marginBottom: 0,
  },
  fieldValueContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  textDetailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '60%',
    marginTop: '1em',
  },
  fieldName: {
    color: theme.palette.custom.charcoalGray,
    flex: '0 0 50%',
  },
  fieldValue: {
    color: theme.palette.custom.charcoalGray,
    fontWeight: 900,
    fontStyle: 'normal',
  },
}));

function FieldValue(props) {
  const { fieldName, value } = props;
  const classes = useStyles();
  return (
    <div className={classes.fieldValueContainer}>
      <AmberSectionTitle
        typographyClasses={{ root: classes.fieldName }}
        text={fieldName}
        typographyVariant="body2"
      />
      <div />
      <AmberSectionTitle
        classes={{ root: classes.fieldValue }}
        typographyVariant="body2"
        text={value}
      />
    </div>
  );
}
FieldValue.propTypes = {
  fieldName: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};
function DeadlineContainer(props) {
  const { fieldName, deadline, translate } = props;
  const classes = useStyles();
  
  return (
    <AmberCard classes={{ root: classes.cardRoot }}>
      <AmberSectionTitle
        typographyVariant="caption"
        text={translate(`EDITOR.jobFields.${fieldName}`)}
        classes={{ root: classes.cardTitle }}
      />
      <AmberSectionTitle
        typographyVariant="body1"
        text={getFormattedDate(deadline, 'DD MMMM YYYY')}
        classes={{ root: classes.cardDates }}
      />
      <AmberSectionTitle
        typographyVariant="body1"
        text={getFormattedDate(deadline, 'HH:mm')}
        classes={{ root: classes.cardDates }}
      />
    </AmberCard>
  );
}
DeadlineContainer.propTypes = {
  fieldName: PropTypes.string.isRequired,
  deadline: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  translate: PropTypes.func.isRequired,
};

function DetailsTabPanelContents(props) {
  const classes = useStyles();

  const {
    jobDetails: {
      jobId,
      transcriptionType,
      qualityControlDeadline,
      nrAudioSeconds,
      language,
      transcriberDeadline,
      created,
      numberOfSpeakers,
      workflowStyle,
      jobOptions: { transcriptionStyle },
    },
    translate
  } = props;
  return (
    <div className={classes.detailsContainer}>
      <div className={classes.cardsContainer}>
        <DeadlineContainer fieldName="created" deadline={created} translate={translate} />
        <DeadlineContainer
          fieldName="transcriberDeadline"
          deadline={transcriberDeadline}
          translate={translate}
        />
        <DeadlineContainer
          fieldName="qualityControlDeadline"
          deadline={qualityControlDeadline}
          translate={translate}
        />
      </div>
      <div className={classes.textDetailsContainer}>
        <FieldValue
          fieldName={translate('EDITOR.jobFields.transcriptionType')}
          value={translate(`EDITOR.jobItemDialog.${transcriptionType}`)}
        />
        <FieldValue
          fieldName={translate('EDITOR.jobFields.workflowStyle')}
          value={
            workflowStyle
              ? translate(`EDITOR.jobItemDialog.${workflowStyle}`)
              : translate('EDITOR.jobItemDialog.unspecifiedWorkflowStyle')
          }
        />
        <FieldValue
          fieldName={translate('EDITOR.jobFields.language')}
          value={language.toUpperCase()}
        />
        <FieldValue
          fieldName={translate('EDITOR.jobFields.numberOfSpeakers')}
          value={numberOfSpeakers}
        />
        <FieldValue
          fieldName={translate('EDITOR.jobFields.nrAudioSeconds')}
          value={getFormattedSeconds(nrAudioSeconds)}
        />
        <FieldValue
          fieldName={translate('EDITOR.jobFields.transcriptionStyle')}
          value={transcriptionStyle}
        />
        <FieldValue fieldName={translate('EDITOR.jobFields.jobId')} value={jobId} />
      </div>
    </div>
  );
}
DetailsTabPanelContents.propTypes = {
  jobDetails: PropTypes.shape({
    jobId: PropTypes.string,
    transcriptionType: PropTypes.string,
    qualityControlDeadline: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    nrAudioSeconds: PropTypes.number,
    language: PropTypes.string,
    transcriberDeadline: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    numberOfSpeakers: PropTypes.number,
    workflowStyle: PropTypes.string,
    created: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    jobOptions: PropTypes.shape({
      transcriptionStyle: PropTypes.string,
    }),
  }).isRequired,
  translate: PropTypes.func.isRequired,
};
export default DetailsTabPanelContents;
