import React, { Component } from 'react'
import uniqby from 'lodash.uniqby'
import sortBy from 'lodash.sortby'
import Joyride from 'react-joyride'
import './Tour.scss'

class Tour extends Component {

    constructor (props) {
        super(props);

        this.key = this.getKey();
        this.stepsOrder = this.getStepsOrder();
        this.opts = {
            autoStart: true,
            debug: false,
            type: 'continuous',
            showSkipButton: true,
            showStepsProgress: true,
        };
        this.modalSteps = [];

        this.state = {
            steps: [],
            stepIndex: this._onLoadStartFrom(),
            run: this._onLoadRun(),
        };

        this.callback = this.callback.bind(this);
    }

    /**
     * Get storage key
     *
     * @abstract
     * @return {string}
     */
    getKey () {
        return 'dummy';
    }

    /**
     * Get steps order
     *
     * @abstract
     * @return {Array}
     */
    getStepsOrder () {
        return [];
    }

    /**
     * Should resume run from specific index on load? (try to read it from
     * localStorage)
     *
     * @return {boolean}
     */
    _onLoadStartFrom () {
        if (!this.key) {
            return 0;
        }
        const length = this.stepsOrder.length - 1;
        const {index} = this.getStored();

        // this does not seem to work, @see bug
        // https://github.com/gilbarbara/react-joyride/issues/223
        if (index === 0 || index < length) {
            return index;
        }
        return 0;
    }

    /**
     * Should run on load? (try to read it from localStorage)
     *
     * @return {boolean}
     */
    _onLoadRun () {
        if (!this.key) {
            return true;
        }
        const { action, type, hasFinishedOnce } = this.getStored();

        // if the tour has never been run
        if (!action) {
          return true;
        }

        // if the tour has never been finished and hasn't been skipped or finished
        if (type !== 'finished' && !hasFinishedOnce) {
          return true;
        }

        return false;
    }

    /**
     * Set stored tour data
     *
     * @param {Object} value
     */
    setStored (value) {
        value = JSON.stringify(value);
        if (window.localStorage) {
            window.localStorage.setItem(this.key, value);
        }

    }

    /**
     * Get stored tour data
     *
     * @return {Object}
     */
    getStored () {
      let output;
      if (window.localStorage) {
          try {
              output = JSON.parse(window.localStorage.getItem(this.key));
          } catch (e) {}
      }

      if (!output) {
        return {};
      }
      return output;
    }

    /**
     * Joyride callback
     *
     * Useful for tracking and/or saving state to localStorage
     *
     * @param  {Object}   data
     */
    callback (data) {
        const {type, action, index, step} = data;
        const length = this.stepsOrder.length - 1;
        const { hasFinishedOnce } = this.getStored();

        // Example tracking:
        // const {steps} = this.state;
        // if (type === 'finished' && step) {
        //     console.info(`Finished: with action ${action} at step ${step.id} (${index}/${steps.length})`);
        // }
        // else if (type === 'step:after' && action === 'close' && step) {
        //     console.info(`Unfinished: with action ${action} at step ${step.id} (${index}/${steps.length})`);
        // }

        // skip
        // close

        if (this.key) {
            this.setStored({
                type: (action === 'skip' || action === 'close') ? 'finished' : type, //never show the tour next time, if x button selected
                action,
                index,
                stepId: step ? step.id : '',
                hasFinishedOnce: hasFinishedOnce ? true : type === 'finished' || index === length,
            })
        }

        // close the tour if skip button or x button was selected or if user clicked outside of the modal
        if(action === 'skip' || action === 'close') {
            this.setState({ run: false });
        }
    }

    /**
     * Reorder steps based on the predefined class property
     *
     * @param  {Object} nextProps
     */
    componentWillReceiveProps (nextProps) {
        let {steps, replay, translate} = nextProps;

        // replay tour
        if (replay) {
            this.joyride.reset(true);
        }

        this.setState({
            steps: transformSteps(steps, this.stepsOrder, translate),
        })
    }

    /**
     * Automatically add the "static" tour steps that behave like modals instead
     * of tooltips.
     */
    componentDidMount () {
        const modalSharedProps = getModalTooltip();
        const modals = this.modalSteps;

        if (!modals.length) {
            return;
        }
        for (let i = 0; i < modals.length; i++) {
          this.props.addSteps({
              ...modalSharedProps,
              ...modals[i],
          });
        }
    }

    render () {
        const {opts} = this;
        const {steps, stepIndex, run} = this.state;
        const {translate} = this.props;


        return (
            <div>
                <div
                    id="TourModal"
                    style={{
                        position: 'fixed',
                        top: '50%',
                        left: '50%',
                        width: '1px',
                        height: '1px',
                    }}
                >
                </div>
                <Joyride
                    ref={c => (this.joyride = c)}
                    {...opts}
                    steps={steps}
                    stepIndex={stepIndex}
                    run={run}
                    callback={this.callback}
                    locale={{
                        back: (<span>{translate('TOUR.back')}</span>),
                        close: (<span>{translate('TOUR.close')}</span>),
                        last: (<span>{translate('TOUR.last')}</span>),
                        next: (<span>{translate('TOUR.next')}</span>),
                        skip: (<span>{translate('TOUR.skip')}</span>),
                    }}
                />
            </div>
        );
    }
}

/**
 * Get step data for custom Joyride modal tooltip, that is tooltip without
 * selector and an invisible beacon
 *
 * @param  {Number} width
 * @param  {Number} height
 * @return {Object}
 */
export function getModalTooltip (width=450, height=170) {
    return {
        selector: '#TourModal',
        position: 'top',
        isFixed: true,
        type: 'hover',
        style: {
            textAlign: 'center',
            arrow: {
                display: 'none'
            },
            beacon: {
                offsetX: -((window.innerHeight / 2) - (width / 2)),
                offsetY: -((window.innerHeight / 2) - (height / 2)),
            }
        }
    }
}

/**
 * Transform steps: order them and translate their text
 *
 * @param  {Array} steps
 * @param  {Array} stepsOrder
 * @param  {function} translate
 * @return {Array}
 */
export function transformSteps (steps, stepsOrder, translate) {
    let output = [];

    for (let i = 0; i < steps.length; i++) {
        let step = steps[i];
        let transformedStep = {...step};

        if (step.titleTranslate) transformedStep.title = translate(step.titleTranslate);
        if (step.textTranslate) transformedStep.text = translate(step.textTranslate);
        transformedStep.order = stepsOrder.indexOf(step.id);
        output.push(transformedStep);
    }

    output = sortBy(uniqby(output, 'id'), ['order']);

    return output;
}

export default Tour;
