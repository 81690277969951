import React, { Component } from 'react'
import { Button, Tooltip } from '@material-ui/core'
import { withTranslate } from 'react-redux-multilingual'
import { connect } from 'react-redux'

import Export from '../editor/export/ExportNew'
import AlertEmailValidation from '../ui/AlertEmailValidation'

import ExportIcon from '../../assets/images/icons/custom-export.svg'
import { GET_ACCOUNT, SEND_EMAIL_VALIDATION, SHOW_SNACKBAR } from '../../sagas/constants'

import '../ui/HeaderButton.scss'
import JobSatisfactionDialog from '@amberscript/amberscript-fe-components/dist/components/JobSatisfaction/JobSatisfactionDialog'
import { canGiveJobFeedback } from '../../helpers/permissions'
import { JOB_SATISFACTION_SUBMITTED_FLAG } from './constants'

class ExportButton extends Component {
    state = {
        openDialog: this.props.isExportModalOpen,
        showEmailNotVerifiedAlert: false,
        openJobSatisfactionDialog: false,
        hasGivenFeedback: false,
        translate: this.props.translate,
        realignTranscript: this.props.realignTranscript,
        jobId: this.props.jobId,
        canSeeJobSatisfactionDialog: this.props.canSeeJobSatisfactionDialog,
        setExportModalOpen: this.props.setExportModalOpen,
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.isExportModalOpen !== this.props.isExportModalOpen) {
            this.setState({...this.state, openDialog: this.props.isExportModalOpen});
        }
    }

    openJobSatisfactionDialog = () => {
        this.setState({ openJobSatisfactionDialog: true });
    }

    closeJobSatisfactionDialog = (event, reason) => {
        // Prevent closing on backdrop click or ESC key
        if (reason && (reason === 'backdropClick' || reason === 'escapeKeyDown')) {
            return;
        }

        this.setState({ openJobSatisfactionDialog: false });
        if (reason === JOB_SATISFACTION_SUBMITTED_FLAG) {
            this.setState({ hasGivenFeedback: true });
        }
    }

    openCorrectDialog = () => {
        if (this.props.isEmailValidated) {
            this.setState({ openDialog: true });
            this.state.setExportModalOpen(true);
        } else {
            this.setState({
                showEmailNotVerifiedAlert: true
            })
        }
    }

    handleOpen = () => {
        this.openCorrectDialog();
    }

    handleClose = () => {
        this.setState({ openDialog: false });
        this.state.setExportModalOpen(false);
    }

    render() {
        let shouldOpenBasedOnParam = false;

        const checkIfShouldOpenDialogOnLoad = () => {    
            const search = window.location.search;
            const params = new URLSearchParams(search);
            const openDialog = params.get('openDialog');
    
            if(openDialog === "true") {
                if (this.props.isEmailValidated) {
                    shouldOpenBasedOnParam = true
                } else {
                    this.setState({
                        showEmailNotVerifiedAlert: true
                    })
                }
              
            }
            params.delete('openDialog'); 
            window.history.replaceState({}, "", decodeURIComponent(`${window.location.pathname}?${params}`));
        }

        // Shows the dialog only if user role is adapted and if hasn't given feedback yet
        const shouldShowJobSatisfactionDialog = () => (
            this.state.openJobSatisfactionDialog && this.state.canSeeJobSatisfactionDialog && !this.state.hasGivenFeedback
        );

        checkIfShouldOpenDialogOnLoad();

        return (
            <React.Fragment>
                <Tooltip title={this.state.translate('EDITOR.exportBtn')} placement="bottom">
                    <Button
                        className="HeaderButton"
                        color="primary"
                        variant="contained"
                        onClick={this.handleOpen}
                    >
                        <i style={{ background: '#fff' }}><img src={ExportIcon} alt="Export" /></i>
                        <strong>
                            {this.state.translate('EDITOR.exportBtn')}
                        </strong>
                    </Button>
                </Tooltip>
                <Export
                    shouldOpenBasedOnParam={shouldOpenBasedOnParam}
                    open={this.state.openDialog}
                    onClose={this.handleClose}
                    realignTranscript={this.props.realignTranscript}
                    saveTranscript={this.props.saveTranscript}
                    isTranscriptionFile={this.props.isTranscriptionFile}
                    transcriptId={this.props.transcriptId}
                    isTranscriber={this.props.isTranscriber}
                    isCaptionFile={this.props.isCaptionFile}
                    openJobSatisfactionDialog={this.openJobSatisfactionDialog}
                />
                {shouldShowJobSatisfactionDialog() && 
                (<JobSatisfactionDialog
                    jobId={this.state.jobId}
                    open={this.state.openJobSatisfactionDialog}
                    closeDialog={this.closeJobSatisfactionDialog}
                />)}
                <AlertEmailValidation
                    showEmailNotVerifiedAlert={this.state.showEmailNotVerifiedAlert}
                    onClose={() => {
                        this.setState({ showEmailNotVerifiedAlert: false })
                    }}
                    getAccount={() => {
                        this.setState({ showEmailNotVerifiedAlert: false })
                        this.props.dispatch({ type: GET_ACCOUNT })
                    }}
                    sendActivationAgain={() => {
                        this.setState({ showEmailNotVerifiedAlert: false })
                        this.props.dispatch({
                            type: SHOW_SNACKBAR,
                            open: true,
                            message: 'EDITOR.btnActivationSent'
                        })
                        this.props.dispatch({ type: SEND_EMAIL_VALIDATION })
                    }}
                />
            </React.Fragment>
        )
    }
}

const mapStateToProps = ({ transcriptStatus, account }) => (
    { 
        jobId: transcriptStatus.data.job.id,
        canSeeJobSatisfactionDialog: canGiveJobFeedback(account.data.roles),
    });

export default connect(mapStateToProps)(withTranslate(ExportButton));
