import React from "react";
import PropTypes from "prop-types";

import ItemsPanel from "./itemsPanel";
import NamesPanel from "./namesPanel";
import { Box } from "@material-ui/core";

export default function GlossaryFound(props) {
  const { glossary, translate } = props;

  const hasNames = () => {
    return glossary && glossary.names && glossary.names.length !== 0;
  };

  const hasItems = () => {
    return glossary && glossary.items && glossary.items.length !== 0;
  };

  return (
    <Box maxHeight={{ md: "60vh", lg: "65vh", xl: "75vh" }} overflow="scroll" data-testid="glossary-panel-found">
      {/* Body */}
      {hasNames() && (
        <NamesPanel names={glossary.names} translate={translate} />
      )}
      {/* Terms */}
      {hasItems() && (
        <ItemsPanel items={glossary.items} translate={translate} />
      )}
    </Box>
  );
}

GlossaryFound.propTypes = {
  glossary: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    names: PropTypes.arrayOf(PropTypes.string).isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        description: PropTypes.string,
      })
    ),
  }),
  translate: PropTypes.func.isRequired,
};
