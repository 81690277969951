import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, Dialog, DialogTitle, DialogActions, Button, IconButton } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from "@material-ui/icons/Close";
import WarningIcon from "@material-ui/icons/Warning"; // Use a warning icon from Material UI

import PreQCDialogContent from "./preQCDialogContent";
import PreQCDialogActions from "./preQCDialogActions";
import CircularIndeterminate from "../loading/indeterminateSpinner";

const useStyles = makeStyles((theme) => ({
  circularIndeterminate: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    margin: '-1.5em 0 0 -1.5em',
  },
  warningDialog: {
    backgroundColor: '#fff5e5', // light yellow background for warning
    padding: '20px',
  },
  warningIcon: {
    color: '#ffcc00', // Warning color for the icon
    fontSize: '2rem',
    marginRight: '10px',
  },
  dialogActions: {
    justifyContent: 'space-between',
  },
}));

export default function PreQCDialog(props) {
  const {
    open,
    translate,
    issues,
    handleClose,
    handleViewAndFix,
    handleSubmit,
    confirmation,
    setConfirmation,
    openPreQCDialogLoading
  } = props;

  const classes = useStyles();

  const [showWarningDialog, setShowWarningDialog] = useState(false);

  const handleSetConfirmation = (state) => {
    setConfirmation(state);
  };

  // Calculate the total number of issues
  const totalIssues = Object.values(issues).reduce((acc, issueCount) => acc + issueCount, 0);

  const handleViewAndFixClick = () => {
    if (totalIssues > 200) {
      // Show the custom warning dialog if issues exceed 200
      setShowWarningDialog(true);
    } else {
      handleViewAndFix();
    }
  };

  const handleProceed = () => {
    setShowWarningDialog(false);
    handleViewAndFix();
  };

  const handleCancel = () => {
    setShowWarningDialog(false);
  };

  return (
    <Dialog
      open={open}
      disableEscapeKeyDown
      maxWidth="sm"
      fullWidth
      data-testid="pre-qc-dialog-issues"
    >
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          {confirmation
            ? translate("EDITOR.PRE_QC.confirmationTitle")
            : translate("EDITOR.PRE_QC.dialogTitle")}
          <IconButton
            onClick={handleClose}
            data-testid="pre-qc-dialog-btn-close"
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      {openPreQCDialogLoading && (
        <CircularIndeterminate
          size="3.6rem"
          thickness={3.6}
          className={classes.circularIndeterminate}
        />
      )}
      <PreQCDialogContent
        issues={issues}
        translate={translate}
        confirmation={confirmation}
      />

      <PreQCDialogActions
        translate={translate}
        handleClose={handleClose}
        handleViewAndFix={handleViewAndFixClick}  // Trigger the custom dialog
        confirmation={confirmation}
        setConfirmation={handleSetConfirmation}
        handleSubmit={handleSubmit}
      />

      {/* Custom Warning Dialog */}
      <Dialog open={showWarningDialog} onClose={handleCancel}>
        <Box className={classes.warningDialog}>
          <Box display="flex" alignItems="center">
            <WarningIcon className={classes.warningIcon} />
            <Box>
              {translate("EDITOR.PRE_QC.confirmationMessage", { totalIssues })}
            </Box>
          </Box>
          <DialogActions className={classes.dialogActions}>
            <Button onClick={handleCancel} color="default">
              {translate("EDITOR.PRE_QC.cancel")}
            </Button>
            <Button onClick={handleProceed} color="primary">
              {translate("EDITOR.PRE_QC.viewFix")}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </Dialog>
  );
}

PreQCDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  translate: PropTypes.func.isRequired,
  issues: PropTypes.shape({
    cleanread: PropTypes.number,
    grammar: PropTypes.number,
    inaudible: PropTypes.number,
    spelling: PropTypes.number,
  }),
  handleClose: PropTypes.func.isRequired,
  handleViewAndFix: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  confirmation: PropTypes.bool.isRequired,
  setConfirmation: PropTypes.func.isRequired,
};

PreQCDialog.defaultProps = {
  issues: {},
};
