import React, { PureComponent } from 'react'
import { withTranslate } from 'react-redux-multilingual'
import { Tooltip, Button } from '@material-ui/core';
import { HighlightIcon } from '../ui/Icons'

class HighlightButton extends PureComponent {

    componentDidMount() {
        this.props.addSteps({
            id: 'highlight',
            textTranslate: 'TOUR.EDITOR.highlight',
            selector: '#HighlightButton',
            position: 'bottom',
            type: 'hover',
            isFixed: true,
            allowClicksThruHole: true,
            style: {
                hole: {
                    borderRadius: '100%'
                }
            }
        });
    }

    render() {
        const { translate, onHighlight } = this.props;

        return (
            <Tooltip title={`${translate('EDITOR.controlsHighlight')} ${translate('EDITOR.controlsHighlightShortcut')}`} placement="bottom">
                <Button
                    id="HighlightButton"
                    className="HighlightButton"
                    color="default"
                    onClick={onHighlight}>
                    <HighlightIcon />  {translate('EDITOR.controlsHighlight')}
                </Button>
            </Tooltip>
        )
    }
}

export default withTranslate(HighlightButton)
