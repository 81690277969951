import React from "react";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";

import PanelTitle from "../export/exportSubtitles/panelTitle";
import { downloadBurnInVideo } from "./BurnInApi";
import BurnInAvailable from "./BurnInAvailable";
import RequestNewBurnIn from "./RequestNewBurnIn";
import { isBurnInBusy, isBurnInDone, isBurnInOpen } from "./helpers";
import { DOCUMENT_TYPE_SUBTITLES_JSON } from "../../../sagas/constants";

export default function BurnInExport(props) {
  const {
    translate,
    handleGoToBurnInConfirmation,
    burnInJob,
    documentType,
    language,
  } = props;

  const isDocumentTypeSubtitles = () =>
    documentType === DOCUMENT_TYPE_SUBTITLES_JSON;

  const isEligibleToBurnIn = () =>
    isDocumentTypeSubtitles() &&
    (isBurnInOpen(burnInJob) ||
      isBurnInBusy(burnInJob) ||
      isBurnInDone(burnInJob));

  const handleDownloadBurnInVideo = () => {
    const { id } = burnInJob;
    downloadBurnInVideo(id);
  };

  function BurnInNotAvailable() {
    if (!isDocumentTypeSubtitles()) {
      return (
        <Box
          fontSize={14}
          fontWeight={400}
          lineHeight={1.5}
          data-testid="export-subtitles-dialog-burnin-notConverted-text"
        >
          {translate("EDITOR.BURN_IN.notConverted")}
        </Box>
      );
    }
    return <RequestNewBurnIn translate={translate} language={language} />;
  }

  return (
    <Box>
      <PanelTitle
        text={translate("EDITOR.EXPORT.asAVideoWithBurnIn")}
        marginBottom={2}
        testid="export-subtitles-dialog-burnin-title"
      />
      {isEligibleToBurnIn() ? (
        <BurnInAvailable
          translate={translate}
          burnInJob={burnInJob}
          handleGoToBurnInConfirmation={handleGoToBurnInConfirmation}
          handleDownloadBurnInVideo={handleDownloadBurnInVideo}
          language={language}
        />
      ) : (
        <BurnInNotAvailable />
      )}
    </Box>
  );
}

BurnInExport.propTypes = {
  translate: PropTypes.func.isRequired,
  handleGoToBurnInConfirmation: PropTypes.func.isRequired,
  burnInJob: PropTypes.shape(),
  documentType: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
};

BurnInExport.defaultProps = {
  burnInJob: null,
};
