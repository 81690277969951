import {
    AUTH_LOGOUT,
    GET_ACCOUNT,
    GET_ACCOUNT_FAIL,
    GET_ACCOUNT_OK,
    SET_ACCOUNT_LANGUAGE,
    SEND_EMAIL_VALIDATION
} from "../sagas/constants";
import { STORAGE_USER_DATA, STORAGE_PATH_USER_SUBSCRIPTION_ACTIVE } from '../settings'
import { localStore } from '../helpers/misc'
import _ from 'lodash'

const initialState = {
    isUserSubscriptionActive: _.get(localStore('get', STORAGE_USER_DATA), "data.userSubscription.active", false),
    data: null,

    loading: false,
    loaded: false,

    error: false,
    errorMessage: null,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {

        case GET_ACCOUNT: {
            return {...state, loading: true, error: false}
        }
        case GET_ACCOUNT_FAIL: {
            return {
                ...state,
                loading: false,
                error: true,
                errorMessage: action.payload
            }
        }
        case GET_ACCOUNT_OK: {
            return {
                ...state,
                loading: false,
                loaded: true,
                error: false,
                data: action.payload
            }
        }

        case SET_ACCOUNT_LANGUAGE: {
            let {data} = state;
            data.language = action.language;
            return {
                ...state,
                data
            }
        }

        case SEND_EMAIL_VALIDATION: {
            return {
                ...state
            }
        }

        case AUTH_LOGOUT: {
            return {
                ...state,
                companyKey: null
            }
        }
        default: {
            return state;
        }
    }
}
