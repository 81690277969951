import React from 'react'
import { connect } from 'react-redux'
import { IntlActions } from 'react-redux-multilingual'
import { withTranslate } from 'react-redux-multilingual'
import classNames from 'classnames'
import { Box, Button, ClickAwayListener, Grow, MenuList, Paper, Tooltip, Popper } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles';

import { LangIcon } from '../ui/Icons'
import MenuItemLink from '../ui/MenuItemLink'

import { SET_ACCOUNT_LANGUAGE } from '../../sagas/constants'

import './LangMenu.scss'
import '../ui/HeaderButton.scss'

const styles = theme => ({
    root: {
    },
    [theme.breakpoints.down('xs')]: {
        root: {
            display: 'none',
        },
    },
    popperClose: {
        pointerEvents: 'none',
    },
});

class LangMenu extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            currentLanguage: '',
            anchorEl: null,
        };
        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.setLanguage = this.setLanguage.bind(this);
    };

    componentWillMount() {
        const { account } = this.props;
        const { language } = account.data;
        this.setState({ currentLanguage: language });
        this.setLanguage(language);
    }

    handleClick(event) {
        const { currentTarget } = event;
        this.setState({
            anchorEl: currentTarget,
            open: !this.state.open
        });
    };

    handleClose() {
        this.setState({ open: false });
    };

    setLanguage(code) {
        // TODO: post language change to API?
        this.setState({ currentLanguage: code });
        this.props.dispatch({ type: SET_ACCOUNT_LANGUAGE, language: code });
        this.props.dispatch(IntlActions.setLocale(code));
    }

    render() {
        const { translate, classes } = this.props;
        const { open, currentLanguage } = this.state;

        return (
            <Box display={{ xs: 'none', sm: 'none', md: 'unset' }}>
                <Box className="LangMenu">
                    <Tooltip title={translate('NAV.tooltipLanguage')} placement="bottom">
                        <Box className={this.props.classes.root}>
                            <Button
                                id="LangMenuToggle"
                                className="HeaderButton"
                                aria-owns={open ? 'LangMenu' : null}
                                aria-haspopup="true"
                                onClick={this.handleClick}
                                style={{ color: "#808080" }}
                            >
                                <i style={{ backgroundColor: 'unset' }}><LangIcon style={{ backgroundColor: "white" }} /></i>
                                <strong>{currentLanguage}</strong>
                            </Button>
                        </Box>
                    </Tooltip>
                </Box>
                <Popper
                    placement="bottom-end"
                    open={open}
                    className={classNames({ [classes.popperClose]: !open })}
                    anchorEl={this.state.anchorEl}
                    style={{ zIndex: 1000 }}
                >
                    <Grow in={open} id="LangMenu" style={{ transformOrigin: '0 0 0' }}>
                        <ClickAwayListener onClickAway={this.handleClose}>
                            <Paper>
                                <MenuList className="LangMenu__list" role="menu">
                                    <MenuItemLink
                                        onClick={() => this.setLanguage('en')}
                                        code="en" text={translate('LANGUAGES.en')} color="primary" />
                                    <MenuItemLink
                                        onClick={() => this.setLanguage('nl')}
                                        code="nl" text={translate('LANGUAGES.nl')} color="primary" />
                                    <MenuItemLink
                                        onClick={() => this.setLanguage('de')}
                                        code="de" text={translate('LANGUAGES.de')} color="primary" />
                                    <MenuItemLink
                                        onClick={() => this.setLanguage('fr')}
                                        code="fr" text={translate('LANGUAGES.fr')} color="primary" />
                                    <MenuItemLink
                                        onClick={() => this.setLanguage('es')}
                                        code="es" text={translate('LANGUAGES.es')} color="primary" />
                                    <MenuItemLink
                                        onClick={() => this.setLanguage('it')}
                                        code="it" text={translate('LANGUAGES.it')} color="primary" />
                                    <MenuItemLink
                                        onClick={() => this.setLanguage('pt')}
                                        code="pt" text={translate('LANGUAGES.pt')} color="primary" />
                                </MenuList>
                            </Paper>
                        </ClickAwayListener>
                    </Grow>
                </Popper>
            </Box>
        );
    }
}

export default connect()(withStyles(styles)(withTranslate(LangMenu)))
