import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box, makeStyles } from '@material-ui/core';
import { getSpellingSuggestionForListOfWords } from '../../../sagas/qualityCheck';
import SpellingPanel from './SpellingPanel';
import {
  PRE_QC_ISSUE_TYPE_CLEANREAD,
  PRE_QC_ISSUE_TYPE_FORMATTING,
  PRE_QC_ISSUE_TYPE_GRAMMAR,
  PRE_QC_ISSUE_TYPE_INAUDIBLE,
  PRE_QC_ISSUE_TYPE_SPELLING,
} from '../../../sagas/constants';
import GrammarPanel from './GrammarPanel';
import CleanreadPanel from './CleanreadPanel';
import InaudiblePanel from './InaudiblePanel';
import {
  getIssuesByType,
  getIssuesDetails,
  getWordsArrays,
  mapSuggestionsToWords,
} from './helpers';
import FormattingPanel from './FormattingPanel';
import { trackSentryErrorByTag } from '../../../sentry/log';

const useStyles = makeStyles((theme) => ({
  overflowBox: {
    overflowX: 'hidden',
    overflowY: 'scroll',
    overflowWrap: 'anywhere',
    maxHeight: 'calc(100vh - 295px)',
  },
}));

export default function PreQCFilledPanel({
  translate,
  preQCIssues,
  language,
  setLoadingQC,
  handleOpenReportDialog,
}) {
  const classes = useStyles();
  const [loadingSpelling, setLoadingSpelling] = useState(false);
  const [preQCIssuesDetails, setPreQCIssuesDetails] = useState({
    grammar: [],
    cleanread: [],
    inaudible: [],
    formatting: [],
  });
  const [spellingDetails, setSpellingDetails] = useState([]);

  
  const initializeIssuesDetails = async () => {
    if (!preQCIssues.issues || preQCIssues.issues.length === 0) return;
    const issues = preQCIssues.issues;
    try {
      setPreQCIssuesDetails({
        grammar: issues.filter(issue => issue.issue_type === PRE_QC_ISSUE_TYPE_GRAMMAR),
        cleanread: issues.filter(issue => issue.issue_type === PRE_QC_ISSUE_TYPE_CLEANREAD),
        inaudible: issues.filter(issue => issue.issue_type === PRE_QC_ISSUE_TYPE_INAUDIBLE),
        formatting: issues.filter(issue => issue.issue_type === PRE_QC_ISSUE_TYPE_FORMATTING),
      });      
      setSpellingDetails(issues.filter(issue => issue.issue_type === PRE_QC_ISSUE_TYPE_SPELLING));
    } catch (error) {
      trackSentryErrorByTag(error, 'pre-qc-fill-categories');
    } finally {
      setLoadingQC(false);
    }
  };

  useEffect(() => {
    initializeIssuesDetails();
  }, [preQCIssues]);

  const hasIssues = useMemo(
    () => ({
      grammar: preQCIssuesDetails?.grammar?.length > 0,
      cleanread: preQCIssuesDetails?.cleanread?.length > 0,
      inaudible: preQCIssuesDetails?.inaudible?.length > 0,
      formatting: preQCIssuesDetails?.formatting?.length > 0,
    }),
    [preQCIssuesDetails]
  );

  return (
    <Box className={classes.overflowBox} data-testid="pre-qc-box-filled-panel">
      <SpellingPanel
        spellingIssues={spellingDetails}
        translate={translate}
        loading={loadingSpelling}
        handleOpenReportDialog={handleOpenReportDialog}
      />
      {hasIssues.grammar && <GrammarPanel grammarIssues={preQCIssuesDetails.grammar} translate={translate} />}
      {hasIssues.cleanread && <CleanreadPanel cleanreadIssues={preQCIssuesDetails.cleanread} translate={translate} />}
      {hasIssues.inaudible && <InaudiblePanel inaudibleIssues={preQCIssuesDetails.inaudible} translate={translate} />}
      {hasIssues.formatting && <FormattingPanel formattingIssues={preQCIssuesDetails.formatting} translate={translate} />}
    </Box>
  );
}

const issueShape = PropTypes.shape({
  end_time: PropTypes.number.isRequired,
  end_word_index: PropTypes.number.isRequired,
  issue_severity: PropTypes.string.isRequired,
  issue_type: PropTypes.string.isRequired,
  issue_type_detail: PropTypes.string,
  original_text: PropTypes.string.isRequired,
  segment_index: PropTypes.number.isRequired,
  start_time: PropTypes.number.isRequired,
  start_word_index: PropTypes.number.isRequired,
  suggestion: PropTypes.string.isRequired,
});

PreQCFilledPanel.propTypes = {
  translate: PropTypes.func.isRequired,
  preQCIssues: PropTypes.arrayOf(issueShape).isRequired,
  language: PropTypes.string.isRequired,
  setLoadingQC: PropTypes.func.isRequired,
  handleOpenReportDialog: PropTypes.func.isRequired,
};
