import React from "react";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";

export default function PanelTitle(props) {
  const { text, marginBottom, testid } = props;

  return (
    <Box fontSize={18} fontWeight={400} mb={marginBottom} data-testid={testid}>
      {text}
    </Box>
  );
}

PanelTitle.propTypes = {
  text: PropTypes.string.isRequired,
  testid: PropTypes.string,
  marginBottom: PropTypes.number,
};

PanelTitle.defaultProps = {
  marginBottom: 0,
  testid: "",
};
