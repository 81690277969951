import moment from 'moment';

export const getFormattedSeconds = (seconds) =>
  moment.utc(seconds * 1000).format('HH:mm:ss');
export const getFormattedDate = (deadline, format) =>
{
  if(!deadline) return '-';
  return moment(deadline).format(format || 'DD-MM-YYYY HH:mm');
}
export const getCountDown = (deadline, locale) => {
  moment.locale(locale);
  return moment(deadline).from(new Date());
};
export const getTimeDifferenceInHours = (deadline) =>
  moment(deadline).diff(new Date(), 'hours');
