import { call, put, race, take } from 'redux-saga/effects';

import {
	AUTH_LOGOUT,
	GET_QUALITY_CHECKS,
	GET_QUALITY_CHECKS_FAIL,
	GET_QUALITY_CHECKS_OK,
} from './constants';

import { apiClient } from '../apiClient';

// ****************** Functions ******************

export function* getQualityChecksWorker(jobId) {
	try {
		const params = {
			transcriberUserName: null,
			jobId,
			qualityControllerUserName: null,
			page: 0,
			pageSize: 20
		};
		return yield call(apiClient.get, 'quality-controller/quality-checks', {params});
	} catch (error) {
		yield put({ type: GET_QUALITY_CHECKS_FAIL, payload: error.response.data.message });
	}
}

export function* getQualityChecks() {
	while (true) {
		const request = yield take(GET_QUALITY_CHECKS);

		const result = yield race({
			ok: call(getQualityChecksWorker, request.jobId),
			no: take(AUTH_LOGOUT),
		});

		if (result.ok) {
			yield put({ type: GET_QUALITY_CHECKS_OK, payload: result.ok.data });
		}
	}
}

/**
 * Gets the list of all languages supported by QC
 */
export function getSupportedLanguage() {
	return apiClient.get('quality-inspector-service/spelling/supported_languages');
}

/**
 * Checks if spelling is supported for this language
 * @param {string} language 
 * @returns boolean
 */
export function checkSupportedLanguage(language) {
	return apiClient.get(`quality-inspector-service/summary/is_language_supported/${language}`);
}

/**
 * Gets all the spelling mistakes for a transcript
 * @param {string} language 
 * @param {object} data
 */
export function getSpellingMistakes(language, data) { 
	const url = `quality-inspector-service/spelling/check/${language}`;
	return apiClient.post(url, data);
}

/**
 * Returns an array of key-value pairs
 * key: word, value: suggestion
 * @param {string} language 
 * @param {string[]} words 
 */
export function getSpellingSuggestionForListOfWords(language, words) {
	const url = `quality-inspector-service/spelling/suggest_multiple/${language}`;
	const data = {
		words,
		max_suggestions: 1,
	}
	return apiClient.post(url, data);
}

/**
 * Gets all issues for a transcript
 * @param {string} language 
 * @param {string} transcriptionStyle 
 * @param {object} data 
 */
export function getSummaryCheck(language, transcriptionStyle, transcriptionType, documentType, data) { 
	const url = `quality-inspector-service/summary/check/${language}`;
	const params = {
		transcription_style: transcriptionStyle,
		transcription_type: transcriptionType,
		document_type: documentType,
	}
	return apiClient.post(url, data, { params });
}

/**
 * Submit the transcript for review
 * @param {string} jobId 
 */
export function submitJob(jobId) {
  return apiClient.post(
    `jobs/transcribers/submit-perfect-file`,
    {},
    { params: { jobId } },
  );
}

/**
 * Reports a spelling issue as false positive
 * @param {string} jobId 
 * @param {string} userName 
 * @param {string[]} userRoles 
 * @param {object} issue 
 * @param {string} language 
 * @param {string} issueComment 
 */
export function reportFalsePositive(jobId, userName, userRoles, issue, language, issueComment) {
	const url = `quality-inspector-service/issue/report`;
	const body = {
		jobId, 
		userName, 
		userRoles, 
		issue, 
		language, 
		issueComment,
	};
	return apiClient.post(url, body);
}