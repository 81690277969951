import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

import AmberTextButton from '../../buttons/AmberTextButton';

const useStyles = makeStyles((theme) => ({
  highlighted: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.white,
    borderRadius: 0,
    minWidth: 0,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      filter: 'brightness(85%)',
    },
  },
  basic: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.white,
    borderRight: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 0,
    minWidth: 0,
    '&:last-child': {
      borderRight: 0,
    },
  },
}));
function RatingButton(props) {
  const { number, onClick, sectionKey, currentSelected } = props;
  const classes = useStyles();
  const handleClick = useCallback(() => {
    const synteticEvent = {
      target: {
        value: number,
        sectionKey,
      },
    };
    onClick(synteticEvent);
  }, [onClick]);
  const buttonClass = clsx({
    [classes.highlighted]: currentSelected === number,
    [classes.basic]: currentSelected !== number,
  });

  return (
    <AmberTextButton
      text={number}
      onClick={handleClick}
      classes={{ root: buttonClass }}
      textProps={{
        padding: 0,
      }}
    />
  );
}

RatingButton.propTypes = {
  number: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  sectionKey: PropTypes.string.isRequired,
  currentSelected: PropTypes.number,
};
RatingButton.defaultProps = {
  currentSelected: 0,
};
export default RatingButton;
