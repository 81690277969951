import React from "react";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";

import GlossaryFound from "./glossaryFound";
import GlossaryNotFound from "./glossaryNotFound";
import Title from "./title";

export default function GlossaryPanel(props) {
  const { glossary, translate } = props;

  return (
    <Box display="flex" flexDirection="column" height="100vh" width="100vw">
      <Box
        display="flex"
        alignItems="center"
        paddingLeft={2.75}
        height={54}
        borderBottom="1px solid #D0D0D0"
      >
        <Title text={translate("GLOSSARY.title")} />
      </Box>
      {glossary.id ? (
        <GlossaryFound glossary={glossary} translate={translate} />
      ) : (
        <GlossaryNotFound translate={translate} />
      )}
    </Box>
  );
}

GlossaryPanel.propTypes = {
  glossary: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    names: PropTypes.arrayOf(PropTypes.string).isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        description: PropTypes.string,
      })
    ),
  }),
  translate: PropTypes.func.isRequired,
};
