import React, { Component } from 'react'
import { withTranslate } from 'react-redux-multilingual'
import { Tooltip, Button } from '@material-ui/core'
import SpellcheckIcon from '@material-ui/icons/Spellcheck';
import { Dialog, DialogContent, DialogActions } from '@material-ui/core';

class SpellCheck extends Component {

    state = {
        counter: localStorage.getItem('spellCheckTour') ? 2 : 0,
        copyText: this.props.translate('REFERRAL.copy')
    }

    handleClick = () => {
        this.props.onChangeSpellCheckFeatureStatus();
    }

    getColor = () => {
        if (this.props.spellCheck) {
            return { fill: '#005A50' }
        } else {
            return {};
        }
    }

    componentDidUpdate(prevProps, prevStates) {
        if (!prevProps.spellCheck && this.props.spellCheck) {
            this.setState({
                counter: this.state.counter + 1
            })
        }
    }

    copyCommand = () => {
        const _this = this;

        var text = "chrome://settings/languages";
        navigator.clipboard.writeText(text)
            .then(function () {
                _this.setState({ copyText: _this.props.translate('REFERRAL.copied') })
                setTimeout(() => {
                    _this.setState({ copyText: _this.props.translate('REFERRAL.copy') })
                }, 1000)
            });
    }

    onCloseDialog = () => {
        localStorage.setItem('spellCheckTour', 1);
        this.setState({
            counter: 2
        })
    }

    getLink = () => {
        if (this.props.account && this.props.account.data && this.props.account.data.language) {
            const { language } = this.props.account.data;
            switch (language) {
                case 'en':
                    return 'https://www.amberscript.com/en/academy/enabling-spell-check-chrome/';
                case 'de':
                    return 'https://www.amberscript.com/de/academy/rechtschreibpruefung-aktivieren-chrome/';
                case 'fr':
                    return 'https://www.amberscript.com/fr/academy/activer-correcteur-orthographique-chrome/';
                case 'nl':
                    return 'https://www.amberscript.com/nl/academy/spellingscontrole-aanzetten-tijdens-het-bewerken-van-mijn-transcript/';
                case 'pt':
                    return 'https://www.amberscript.com/pt-pt/academy/ativar-corretor-ortografico-chrome/';
                case 'es':
                    return 'https://www.amberscript.com/es/academy/activar-corrector-ortografico-google/';
                case 'it':
                    return 'https://www.amberscript.com/it/academy/controllo-ortografico-trascrizioni-online/';
                default:
                    return 'https://www.amberscript.com/en/academy/enabling-spell-check-chrome/';
            }
        } else {
            return 'https://www.amberscript.com/en/academy/enabling-spell-check-chrome/';
        }
    }

    render() {
        const { translate } = this.props;

        return (
            <React.Fragment>
                <Dialog
                    maxWidth="md"
                    open={this.state.counter === 1}
                    onClose={this.onCloseDialog}
                >
                    <DialogContent>
                        <p style={{ fontSize: 18, fontWeight: 600 }}>{translate("SPELLCHECK.title")}</p>
                        <div style={{ fontSize: 14 }}>
                            <p>
                                <a alt="spell-checker-instruction" href={this.getLink()} target="_blank">{translate("SPELLCHECK.clickHere")}</a> {translate("SPELLCHECK.linkDesc")}
                            </p>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.onCloseDialog} color="primary">
                            {translate('EDITOR.feedbackVal3')}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Tooltip title={`${translate('EDITOR.controlsSpellCheck')} ${translate('EDITOR.controlsSpellCheckShortcut')}`} placement="bottom">
                    <Button onClick={this.handleClick} className="RedoButton" color="default">
                        <SpellcheckIcon style={this.getColor()} /> {translate('EDITOR.controlsSpellCheck')}
                    </Button>
                </Tooltip>
            </React.Fragment>
        )
    }
}

export default withTranslate(SpellCheck)