import { createTheme } from '@material-ui/core/styles';

export const AMBER_PRIMARY = '#005A50';
export const AMBER_SECONDARY = '#FAAA28';
export const AMBER_RED_COLOR = '#bf360c'
export const PINKISH_RED = '#DC283C';
export const AMBER_LIGHT_GRAY = '#ECECEC';
export const DARK_GRAY_COLOR = '#D0D0D0';
export const CHARCOAL_GRAY = '#2B292D';
export const WHITE = '#FFFFFF';
export const PURPLISH_GRAY = '#726F7B';
export const MANGO = '#FAAA28';
export const LIGHT_GRAY = '#E5E5E5';
export const AMBER_INFO_GRAY = '#726F7B';
export const WHITE_TWO = '#F5F5F5';
export const AMBER_LIGHT_GREEN = '#329678';
export const AMBER_LIGHT_GRAY2 = '#6D6E6E';

const spacing = 8;

const theme = createTheme({
    app: {
        footerHeight: 48,
    },
    typography: {
        fontFamily: "'Geomanist', sans-serif",
        fontSize: 15,
        span: {
            textTransform: "none"
        },
        button: {
            textTransform: "none"
        }
    },
    background: {
        appBar: '#f7f7f7',
    },
    palette: {
        primary: {
            main: AMBER_PRIMARY,
            white: WHITE,
        },
        secondary: {
            main: AMBER_SECONDARY,
        },
        error: {
            main: AMBER_RED_COLOR
        },
        custom: {
          pinkishRed: PINKISH_RED,
          lightGray: AMBER_LIGHT_GRAY,
          darkGrey: DARK_GRAY_COLOR,
          charcoalGray: CHARCOAL_GRAY,
          purplishGray: PURPLISH_GRAY,
          mango: MANGO,
          basicLightGray: LIGHT_GRAY,
          infoMessage: AMBER_INFO_GRAY,
          whiteTwo: WHITE_TWO,
          lightGreen: AMBER_LIGHT_GREEN,
          lightGray2: AMBER_LIGHT_GRAY2,
        },
    },
    mixins: {
        toolbar: {
            minHeight: 64
        }
    },
    overrides: {
        MuiTypography: {
            headline: {
                fontSize: 34,
                marginTop: '1em',
                marginBottom: '1em',
            },
            subheading: {
                marginTop: '1em',
                marginBottom: '1em',
                color: '#707273',
            },
        },
        MuiButton: {
            root: {
                border: 0,
                boxShadow: 'none',
                textTransform: 'none',
            },
        },
        MuiFormControl: {
            root: {
                marginBottom: spacing * 4,
            }
        },
    },
});

export default theme;
