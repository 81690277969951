import React, { PureComponent } from 'react'
import { withTranslate } from 'react-redux-multilingual'
import Slider from './Slider'
import './SubtitleSlider.scss'


class SubtitleSlider extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			sliderValue: 3500
		}
	}

	onChange = (value) => {
		this.setState({
			sliderValue: value
		});
		this.props.onSliderChange(value);
	}

	render() {
		const { translate, sliderStyle } = this.props; 
		const charsPerSec = Math.round(70/ (this.state.sliderValue/1000));

		const marks = {
			1000: translate('EDITOR.SUBTITLE.sliderShorterLabel'),
			6000: translate('EDITOR.SUBTITLE.sliderLongerLabel'),
			3500: translate('EDITOR.SUBTITLE.sliderRecommendedLabel')
		};

		return  (<div>
					<Slider 
						marks={marks}
						style={{...sliderStyle}}
						min={1000}
						max={6000}
						defaultValue={this.state.sliderValue}
						step={100} 
						onChange={this.onChange}/>
					<div className="currentValue">{translate('EDITOR.SUBTITLE.slidercharsPerSecLabel', {charsPerSec: charsPerSec})}</div>
				</div>);
	}
}


export default (withTranslate(SubtitleSlider));