import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslate } from 'react-redux-multilingual'
import {
    Box, Button, Dialog, DialogTitle, DialogContentText, DialogActions,
    InputAdornment, DialogContent, TextField, Paper, FormHelperText,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles';
import { validateEmail } from '../../helpers/misc'
import {
    REFERRAL_SEND_INVITATION,
    REFERRAL_GET_CREDIT,
    REFERRAL_GET_DETAILS,
    GET_ACCOUNT
} from '../../sagas/constants';
import { toHHMMSS } from '../../helpers/timeUtils'

import './InviteFriends.scss'

const styles = theme => ({
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    }
});

class InviteFriends extends Component {

    state = {
        showInvitationSentMessage: false,
        textCopy: this.props.translate('REFERRAL.copy'),
        textEmailAddress: '',
        textErrorEmailAddress: ''
    }

    componentDidMount() {
        //get the account data again.
        this.props.dispatch({ type: GET_ACCOUNT });
        //get credit (available and pending) data of the user
        this.props.dispatch({ type: REFERRAL_GET_CREDIT });
    }

    componentDidUpdate(nextProps, nextStates) {
        //when the account data is refetched
        if (this.props.account.loading !== nextProps.account.loading) {
            //get the referral code details
            this.props.dispatch({
                type: REFERRAL_GET_DETAILS,
                data: this.props.account.data.activeReferralCode
            });
        }
        if (this.props.referralInvitation.loading !== nextProps.referralInvitation.loading) {
            //get the credit data
            this.props.dispatch({ type: REFERRAL_GET_CREDIT });
        }
    }

    emailAddressChanged = (e) => {
        this.setState({
            textEmailAddress: e.target.value,
            textErrorEmailAddress: '',
            showInvitationSentMessage: false,
        })
    }

    copyCommand = () => {
        const el = document.getElementById('shareLinkAddress')
        el.select();
        document.execCommand('copy');
        if (this.state.textCopy !== this.props.translate('REFERRAL.copied')) {
            this.setState({
                textCopy: this.props.translate('REFERRAL.copied'),
            });
            setTimeout(() => {
                this.setState({
                    textCopy: this.props.translate('REFERRAL.copy'),
                })
            }, 2000)
        }
    }

    shareInvitationViaFacebook = () => {
        const appId = 307775436568764;
        const referralLink = this.getReferralUrl();
        const redirectUri = 'https://app.amberscript.com';
        const link = `http://www.facebook.com/dialog/send?app_id=${appId}&link=${referralLink}&redirect_uri=${redirectUri}`;

        window.open(link, '_blank', 'height=600,width=650');
    }

    shareInvitationViaWhatsapp = () => {
        const shareTitle = `Try Amberscript and get ${this.props.referralDetails.data.referral.signupCreditMinutes} Minutes towards your first automatic transcription!`
        const link = `https://api.whatsapp.com/send?text=${shareTitle} ${encodeURIComponent(this.getReferralUrl())}`
        window.open(link, '_blank', 'height=600,width=650');
    }

    sendInvitesViaEmail = (e) => {
        e.preventDefault();

        if (!validateEmail(this.state.textEmailAddress)) {
            this.setState({
                textErrorEmailAddress: this.props.translate('MISC.formErrEmail'),
                showInvitationSentMessage: false
            })
        } else {
            this.props.dispatch({
                type: REFERRAL_SEND_INVITATION,
                data: {
                    referralCode: this.props.account.data.activeReferralCode,
                    emails: [
                        this.state.textEmailAddress
                    ]
                }
            });
            this.setState({
                textEmailAddress: '',
                showInvitationSentMessage: true
            });
        }
    }

    //convert HH:MM:SS to HHh MMm
    showHHMM = (credit) => {
        let time = toHHMMSS(credit * 60);
        let times = time.split(':');
        return `${times[0]}h ${times[1]}m`
    }

    getReferralUrl() {
        const origin = window.location.origin;
        const link = `${origin}/register?referralCode=${this.props.account.data.activeReferralCode}`;
        return link;
    }

    render() {
        const { onClose, referralCredit, referralDetails, translate, Intl } = this.props;
        const { locale } = Intl;
        const pendingCredit = (referralCredit.loaded) ? referralCredit.data.pendingCredit : 0;
        const availableCredit = (referralCredit.loaded) ? referralCredit.data.availableCredit : 0;
        const signupCreditMinutes = (referralDetails.loaded) ? referralDetails.data.referral.signupCreditMinutes : 0;

        return (
            <React.Fragment>
                {(referralDetails.error) ?
                    <Dialog open={true} onClose={onClose} maxWidth="sm">
                        <DialogTitle id="alert-dialog-title">
                            {translate('REFERRAL.errorTitle')}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {translate('REFERRAL.errorDescription')}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={onClose} color="primary" autoFocus>
                                Ok
                            </Button>
                        </DialogActions>
                    </Dialog>
                    :
                    (referralDetails.loaded) && <Dialog open={true} onClose={onClose} maxWidth="md">
                        <i className="fa fa-times closeDialog" aria-hidden="true" onClick={onClose}></i>
                        <DialogContent className="inviteFriend">
                            <div className="__left">
                                <h1>{translate('REFERRAL.title')}</h1>
                                <p>
                                    {translate('REFERRAL.description', { signupCreditMinutes })}
                                    <a href={`https://www.amberscript.com/${locale}/referral`} target="_blank"> {translate('REFERRAL.terms')}</a>
                                </p>
                                <Box display="flex">
                                    <TextField id="email1" placeholder={translate('REFERRAL.enterEmail')} className="emailInputField" value={this.state.textEmailAddress}
                                        helperText={this.state.textErrorEmailAddress} onChange={e => this.emailAddressChanged(e)} onKeyDown={(ev) => {
                                            if (ev.key === 'Enter') {
                                                this.sendInvitesViaEmail(ev);
                                                ev.preventDefault();
                                            }
                                        }} />
                                    <Box marginLeft={3}>
                                        <Button color="primary" variant="contained" type="submit" onClick={this.sendInvitesViaEmail}>
                                            {translate('REFERRAL.sendButton')}
                                        </Button>
                                    </Box>
                                    {this.state.showInvitationSentMessage && <FormHelperText id="weight-helper-text" className="invitationMailSent">{this.props.translate("REFERRAL.invitationSent")}</FormHelperText>}
                                </Box>
                                <h4>{this.props.translate("REFERRAL.shareInviteLink")}</h4>
                                <div className="shareInvite">
                                    <TextField
                                        id="shareLinkAddress"
                                        className="invitationLink"
                                        value={this.getReferralUrl()}
                                        InputProps={{
                                            endAdornment: document.queryCommandSupported('copy') &&
                                                <InputAdornment position="end" onClick={() => this.copyCommand()}>{this.state.textCopy}</InputAdornment>,
                                            readOnly: true,
                                            disableUnderline: true,
                                        }}
                                    />
                                    <i className="fab fa-facebook-messenger __fb" onClick={() => this.shareInvitationViaFacebook()}></i>
                                    <i className="fab fa-whatsapp __wa" onClick={() => this.shareInvitationViaWhatsapp()}></i>
                                </div>
                            </div>
                            <Paper className="__right" elevation={1}>
                                <h3>{translate('REFERRAL.transcriptionCredit')}</h3>
                                <div className="__child">
                                    <span className="__blue">{`${translate('REFERRAL.pending')}:`}</span>
                                    <span>{this.showHHMM(pendingCredit)}</span>
                                </div>
                                <div className="__child">
                                    <span className="__blue">{`${translate('REFERRAL.earned')}:`}</span>
                                    <span>{this.showHHMM(availableCredit)}</span>
                                </div>
                            </Paper>
                        </DialogContent>
                    </Dialog>
                }
            </React.Fragment>
        );

    }
}

const mapStateToProps = ({ account, referralInvitation, referralCredit, referralDetails, Intl }) => ({
    account,
    referralInvitation,
    referralCredit,
    referralDetails,
    Intl
});

export default connect(mapStateToProps)(withStyles(styles)(withTranslate(InviteFriends)));
