import React from 'react';
import PropTypes from 'prop-types';
import { Box, makeStyles } from '@material-ui/core';
import InfoIcon from '@mui/icons-material/Info';

import Title from './title';
import TimestampIssueItem from './TimestampIssueItem';
import AmberTooltip from '../../tooltips/AmberTooltip';

const useStyles = makeStyles((theme) => ({
  boxColor: {
    color: theme.palette.custom.charcoalGray,
  },
}));
export default function GrammarPanel(props) {
  const { grammarIssues, translate } = props;
  const classes = useStyles();

  const adaptedMessage = () =>
    grammarIssues.length > 1
      ? 'EDITOR.PRE_QC.grammarIssuesFound'
      : 'EDITOR.PRE_QC.grammarIssueFound';

  return (
    <Box data-testid="pre-qc-box-grammar-panel">
      <Box
        display="flex"
        alignItems="center"
        paddingLeft={2.75}
        marginTop={3}
        flexDirection="row"
        gridRowGap={2}
      >
        <Title text={`${grammarIssues.length}${translate(adaptedMessage())}`} />
        <AmberTooltip
          tooltip={translate('EDITOR.PRE_QC.grammarInfoTooltip')}
          testid="pre-qc-grammar-info-tooltip"
        >
          <InfoIcon
            sx={{ color: 'custom.gray', pl: 1 }}
            data-testid="pre-qc-grammar-info-icon"
          />
        </AmberTooltip>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        paddingLeft={2.75}
        paddingRight={2.75}
        className={classes.boxColor}
        marginTop={2}
        gridRowGap={8}
      >
        {grammarIssues.length !== 0 &&
          grammarIssues.map((item, index) => (
            <Box
              key={`pre-qc-grammar-issue-${index}`}
              display="flex"
              fontSize={{ xs: 10, sm: 12, md: 12, lg: 14, xl: 14 }}
            >
              <TimestampIssueItem item={item} />
            </Box>
          ))}
      </Box>
    </Box>
  );
}

GrammarPanel.propTypes = {
  grammarIssues: PropTypes.arrayOf(
    PropTypes.shape({
      original_text: PropTypes.string.isRequired,
      start_time: PropTypes.number.isRequired,
    })
  ),
  translate: PropTypes.func.isRequired,
};

GrammarPanel.defaultProps = {
  grammarIssues: [],
};
