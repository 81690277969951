import React from "react";
import PropTypes from "prop-types";
import { Box, styled } from "@material-ui/core";

import RedDot from "../../../assets/images/ui/red_dot.svg";

const RedDottedUnderlineBox = styled(Box)(({ theme }) => ({
  backgroundRepeat: "space no-repeat",
  backgroundImage: `url(${RedDot})`,
  backgroundPositionY: theme.spacing(2.7),
}));

export default function UnderlineBoxWithSuggestion(props) {
  const { originalText, suggestion, translate, testid, smallFontSize } = props;

  const getSuggestion = () =>
    suggestion || translate("EDITOR.PRE_QC.noSuggestion");

  return (
    <Box
      display="flex"
      flexDirection="row"
      gridColumnGap={3}
      lineHeight={2}
      flexWrap="wrap"
      width="fit-content"
      data-testid={testid}
      fontSize={smallFontSize ? { xs: 10, sm: 12, md: 12, lg: 14, xl: 14 } : 'inherit' }
    >
      <RedDottedUnderlineBox>{originalText}</RedDottedUnderlineBox>
      {`→ ${getSuggestion()}`}
    </Box>
  );
}

UnderlineBoxWithSuggestion.defaultProps = {
  originalText: PropTypes.string.isRequired,
  suggestion: PropTypes.string,
  translate: PropTypes.func.isRequired,
  testid: PropTypes.string.isRequired,
  smallFontSize: PropTypes.bool,
};

UnderlineBoxWithSuggestion.defaultProps = {
  suggestion: "",
  smallFontSize: false,
};
