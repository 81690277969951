import React from 'react';
import PropTypes from 'prop-types';
import { Box, makeStyles } from '@material-ui/core';
import InfoIcon from '@mui/icons-material/Info';

import Title from './title';
import TimestampIssueItem from './TimestampIssueItem';
import AmberTooltip from '../../tooltips/AmberTooltip';

const useStyles = makeStyles((theme) => ({
  boxColor: {
    color: theme.palette.custom.charcoalGray,
  },
}));
export default function InaudiblePanel(props) {
  const { inaudibleIssues, translate } = props;
  const classes = useStyles();

  const adaptedMessage = () =>
    inaudibleIssues.length > 1
      ? 'EDITOR.PRE_QC.inaudibleIssuesFound'
      : 'EDITOR.PRE_QC.inaudibleIssueFound';

  return (
    <Box data-testid="pre-qc-box-inaudible-panel">
      <Box
        display="flex"
        alignItems="center"
        paddingLeft={2.75}
        marginTop={3}
        flexDirection="row"
        gridRowGap={2}
      >
        <Title
          text={`${inaudibleIssues.length}${translate(adaptedMessage())}`}
        />
        <AmberTooltip
          tooltip={translate('EDITOR.PRE_QC.inaudibleInfoTooltip')}
          testid="pre-qc-inaudible-info-tooltip"
        >
          <InfoIcon
            sx={{ color: 'custom.gray', pl: 1 }}
            data-testid="pre-qc-inaudible-info-icon"
          />
        </AmberTooltip>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        paddingLeft={2.75}
        paddingRight={2.75}
        className={classes.boxColor}
        marginTop={2}
        gridRowGap={8}
      >
        {inaudibleIssues.length !== 0 &&
          inaudibleIssues.map((item, index) => (
            <Box
              key={`pre-qc-inaudible-issue-${index}`}
              display="flex"
              fontSize={{ xs: 10, sm: 12, md: 12, lg: 14, xl: 14 }}
            >
              <TimestampIssueItem item={item} />
            </Box>
          ))}
      </Box>
    </Box>
  );
}

InaudiblePanel.propTypes = {
  inaudibleIssues: PropTypes.arrayOf(
    PropTypes.shape({
      original_text: PropTypes.string.isRequired,
      start_time: PropTypes.number.isRequired,
    })
  ),
  translate: PropTypes.func.isRequired,
};

InaudiblePanel.defaultProps = {
  inaudibleIssues: [],
};
