import React, { PureComponent } from 'react'
import { withTranslate } from 'react-redux-multilingual'
import { Tooltip, Button } from '@material-ui/core'
import { SubtitleIcon } from '../ui/Icons'
import { connect } from 'react-redux'
import { SUBTITLE } from '../dialogs/dialogConstants'
import { SHOW_MODAL } from '../../sagas/constants'
import ModalRoot from '../dialogs/ModalRoot'


class SubtitleButton extends PureComponent {

	handleOpenSubtitleDialog = () => {
		const { onConvertToSubtitles, dispatch } = this.props;

		dispatch({
			type: SHOW_MODAL,
			modalType: SUBTITLE,
			modalProps: {
				onConvertToSubtitles: onConvertToSubtitles,
				minSubtitleLenght: 1000,
				deafultSubtitleLenght: 3500,
				maxSubtitleLenght: 6000,
				step: 100
			}
		});
	}

	render() {
		const { translate } = this.props;

		return (
			<React.Fragment>
				<Tooltip title={translate('EDITOR.controlsSubtitlesShortcut')} placement="bottom">
					<Button
						id="SubtitleButton"
						className="SubtitleButton"
						color="default"
						onClick={this.handleOpenSubtitleDialog}>
						<SubtitleIcon />
						{translate('EDITOR.controlsSubtitles')}
					</Button>
				</Tooltip>
				<ModalRoot
					modalType={SUBTITLE} />
			</React.Fragment>
		)
	}
}

export default connect()(withTranslate(SubtitleButton))
