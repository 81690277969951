import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, Divider, styled, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import { withTranslate } from "react-redux-multilingual";

import BurnInExport from "../../burn-in/BurnInExport";
import FileExport from "./FileExport";
import MoreOptions from "./MoreOptions";
import { getBurnInJobs } from "../../burn-in/BurnInApi";
import {
  DIALOG_STEP_EXPORT_BURN_IN_CONFIRMATION,
  DIALOG_STEP_EXPORT_BURN_IN_SUCCESS,
  DIALOG_STEP_EXPORT_SUBTITLES,
} from "./constants";
import BurnInSteps from "../../burn-in/BurnInSteps";

const FullWidthDivider = styled(Divider)(() => ({
  width: "100%",
}));

function ExportSubtitlesContent(props) {
  const {
    translate,
    formats,
    exportData,
    setExportData,
    handleExportSubtitles,
    handleDownloadAsText,
    handleDownloadFile,
    transcriptStatus,
    onClose,
    language,
  } = props;

  const DASHBOARD_URL = process.env.REACT_APP_DASHBOARD_URL;

  const { isVideo, recordId } = transcriptStatus.data;

  const [step, setStep] = useState(DIALOG_STEP_EXPORT_SUBTITLES);

  const [burnInJobs, setBurnInJobs] = useState(null);

  // Go back to Export Subtitle
  const handleGoBack = () => {
    setStep(DIALOG_STEP_EXPORT_SUBTITLES);
  };

  // Go to Burn in confirmation
  const handleGoToBurnInConfirmation = () => {
    setStep(DIALOG_STEP_EXPORT_BURN_IN_CONFIRMATION);
  };

  // Go to Burn in successful
  const handleGoToBurnInSuccessful = () => {
    setStep(DIALOG_STEP_EXPORT_BURN_IN_SUCCESS);
  };

  const handleFormatChange = (event) => {
    setExportData({
      ...exportData,
      format: event.target.value,
    });
  };

  const shouldShowBurnInOption = () => isVideo;

  // Gets the list of burn in jobs for that recordId
  const getListOfBurnInJobs = () => {
    getBurnInJobs(recordId)
      .then((result) => {
        if (result && result.data) {
          const { burnInJobs } = result.data;
          setBurnInJobs(burnInJobs);
        }
      })
      .catch(() => {});
  };

  // Initializes the state
  const initBurnInJobs = () => {
    getListOfBurnInJobs();
  };

  useEffect(() => {
    initBurnInJobs();
  }, []);

  // Returns the existing burn in job
  const getBurnInJob = () => {
    if (burnInJobs && burnInJobs.length !== 0) {
      return burnInJobs[0];
    }
    return null;
  };

  // Redirect to dashboard
  const handleGoToDashboard = () => {
    window.location.href = DASHBOARD_URL;
  };

  return (
    <>
      {step === DIALOG_STEP_EXPORT_SUBTITLES && (
        <>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mt={3}
            mb={1.5}
            ml={4.5}
            mr={3}
          >
            <Box display="flex" gridColumnGap={4} alignItems="center">
              <Box
                fontSize={24}
                fontWeight={400}
                data-testid="export-subtitles-dialog-title"
              >
                {translate("EDITOR.EXPORT.exportSubtitleTitle")}
              </Box>
            </Box>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            gridRowGap={24}
            pt={2}
            pb={3}
            pl={4.5}
            pr={4.5}
          >
            <Box
              display="flex"
              flexDirection="column"
              gridRowGap={30}
              alignItems="start"
              mb={1.5}
            >
              <FileExport
                translate={translate}
                exportData={exportData}
                handleFormatChange={handleFormatChange}
                formats={formats}
                handleExportSubtitles={handleExportSubtitles}
              />
              <FullWidthDivider />
              {shouldShowBurnInOption() && (
                <>
                  <BurnInExport
                    translate={translate}
                    handleGoToBurnInConfirmation={handleGoToBurnInConfirmation}
                    burnInJob={getBurnInJob()}
                    documentType={transcriptStatus.data.documentType}
                    language={language}
                  />
                  <FullWidthDivider />
                </>
              )}
              <MoreOptions
                translate={translate}
                handleDownloadAsText={handleDownloadAsText}
                handleDownloadFile={handleDownloadFile}
                isVideo={isVideo}
              />
            </Box>
          </Box>
        </>
      )}
      <BurnInSteps
        step={step}
        translate={translate}
        onClose={onClose}
        handleGoBack={handleGoBack}
        handleGoToDashboard={handleGoToDashboard}
        handleGoToBurnInSuccessful={handleGoToBurnInSuccessful}
        burnInJob={getBurnInJob()}
      />
    </>
  );
}

ExportSubtitlesContent.propTypes = {
  formats: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  exportData: PropTypes.shape({
    format: PropTypes.string.isRequired,
    selectedRule: PropTypes.string.isRequired,
    selectedRealignment: PropTypes.string.isRequired,
    customMaxNrRows: PropTypes.number.isRequired,
    customMaxCharPerRow: PropTypes.number.isRequired,
  }),
  setExportData: PropTypes.func.isRequired,
  handleExportSubtitles: PropTypes.func.isRequired,
  handleDownloadAsText: PropTypes.func.isRequired,
  handleDownloadFile: PropTypes.func.isRequired,
  transcriptStatus: PropTypes.shape().isRequired,
  onClose: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
};

export default withTranslate(ExportSubtitlesContent);
