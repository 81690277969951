import { useEffect } from "react";
import {
    Box,
    Checkbox,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Fab,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import { 
    AMBER_LIGHT_GREEN,
    CUSTOM_RULE,
    DEFAULT_VALUE_FOR_CHARS_PER_ROW,
    MAX_VALUE_CHARS_PER_SECOND,
    MAX_VALUE_FOR_CHARS_PER_ROW,
    MAX_VALUE_SUBTITLE_DURATION,
    MIN_VALUE_CHARS_PER_SECOND,
    MIN_VALUE_FOR_CHARS_PER_ROW,
    MIN_VALUE_SUBTITLE_DURATION,
    NETFLIX_RULE
} from "./constants";
import { AMBER_PRIMARY } from "../ui/mui5/colors";

function RoundButton({enabled, value, customRules, setCustomRules}) {
    if (enabled) {
        return (
            <Fab
                size="small"
                color="primary"
                aria-label={value}
                onClick={() => {
                    setCustomRules({
                        ...customRules, 
                        maxLinesPerSegment: value
                    })
                }}
                style={{marginRight: 4, marginLeft: 4, boxShadow: 'none'}}
            >
                <Box display="block" component="span" fontSize={16} fontWeight={600}>
                    {value}
                </Box>
            </Fab>
        );
    } else {
        return (
            <Fab
                size="small"
                aria-label={value}
                onClick={() => {
                    setCustomRules({
                        ...customRules, 
                        maxLinesPerSegment: value
                    })
                }}
                style={{
                    marginRight: 4,
                    marginLeft: 4,
                    backgroundColor: 'white',
                    boxShadow: 'none',
                    border: '1px solid #D0D0D0',
                }}
            >
                <Box display="block" component="span" fontSize={16} fontWeight={600}>
                    {value}
                </Box>
            </Fab>
        );
    }
}

export function CustomSubtitleRulesAccordion(props) {
    const {
        customOpen,
        setCustomOpen,
        customRules,
        setCustomRules,
        selectedRule,
        setSelectedRule,
        systemBusy,
        translate,
        isUpdateRules = false,
    } = props;

    function handleAccordionChange() {
        setCustomOpen(!customOpen);
        setSelectedRule(CUSTOM_RULE);
    }

    useEffect(() => {
        if (systemBusy) {
            setCustomOpen(false);
        }
    }, [systemBusy]);

    return (
        <Accordion
            disabled={systemBusy}
            expanded={customOpen}
            onChange={handleAccordionChange}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
                style={{backgroundColor: selectedRule === CUSTOM_RULE ? AMBER_PRIMARY : '#ECECEC'}}
            >
                <Box display="flex" flexDirection="column">
                    <Box
                        display="block"
                        component="span"
                        fontSize={14}
                        fontWeight={600}
                        color={isUpdateRules ? "white" : "black"}
                        marginBottom={1}
                    >
                        {`${translate('CREATE_SUBTITLES.customRules')} `}
                    </Box>
                    <Box
                        display="block"
                        component="span"
                        fontSize={12}
                        color={selectedRule === CUSTOM_RULE ? "white" : "black"}
                    >
                        {`${translate('CREATE_SUBTITLES.customRulesDescription')} `}
                    </Box>
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                <Box display="flex" flexDirection="column" width="100%">
                    {/* Max number of lines */}
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        marginTop={1}
                        width="100%"
                    >
                        <Box
                            display="block"
                            component="span"
                            fontSize={14}
                            fontWeight={400}
                            color="#2B292D"
                        >
                            {translate('CREATE_SUBTITLES.rule2')}
                        </Box>
                        <Box display="flex">
                            <RoundButton
                                enabled={customRules.maxLinesPerSegment === 1}
                                value={1}
                                setCustomRules={setCustomRules}
                                customRules={customRules}
                            />
                            <RoundButton
                                enabled={customRules.maxLinesPerSegment === 2}
                                value={2}
                                setCustomRules={setCustomRules}
                                customRules={customRules}
                            />
                        </Box>
                    </Box>
                    {/* Include spaces in character count */}
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        marginTop={1}
                        width="100%"
                    >
                        <Box
                            display="block"
                            component="span"
                            fontSize={14}
                            fontWeight={400}
                            color="#2B292D"
                        >
                            {translate('CREATE_SUBTITLES.includeSpacesInCount')}
                        </Box>
                        <Box display="flex">
                            <Checkbox
                                checked={customRules.includeSpaceInCharacterCount}
                                color="primary"
                                onChange={() => {
                                    setCustomRules({...customRules, includeSpaceInCharacterCount: !customRules.includeSpaceInCharacterCount})
                                }} />
                        </Box>
                    </Box>
                    {/* Include special character in count */}
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        marginTop={1}
                        width="100%"
                    >
                        <Box
                            display="block"
                            component="span"
                            fontSize={14}
                            fontWeight={400}
                            color="#2B292D"
                        >
                            {translate('CREATE_SUBTITLES.includeSpecialCharactersInCount')}
                        </Box>
                        <Box display="flex">
                            <Checkbox
                                checked={customRules.includeSpecialCharactersInCount}
                                color="primary"
                                onChange={() => {
                                    console.log('Checkbox Clicked:', {
                                        ...customRules,
                                        includeSpecialCharactersInCount: !customRules.includeSpecialCharactersInCount
                                    });
                                    setCustomRules({...customRules, includeSpecialCharactersInCount: !customRules.includeSpecialCharactersInCount})
                                }} />
                        </Box>
                    </Box>
                    {/* Max character per line */}
                    <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="space-between"
                        marginTop={2}
                    >
                        <Box
                            display="block"
                            component="span"
                            fontSize={14}
                            fontWeight={400}
                            color="#2B292D"
                        >
                            {`${translate('CREATE_SUBTITLES.rule3')} `}
                        </Box>
                        <Box display="flex" alignItems="center" marginTop={1}>
                            {/* - */}
                            <Fab
                                aria-label="minus"
                                onClick={() =>
                                    customRules.maxCharsPerLine > MIN_VALUE_FOR_CHARS_PER_ROW &&
                                    setCustomRules({
                                        ...customRules, 
                                        maxCharsPerLine: customRules.maxCharsPerLine - 1
                                    })
                                }
                                style={{
                                    width: 30,
                                    height: 30,
                                    minHeight: 'unset',
                                    marginRight: 2,
                                    backgroundColor: 'white',
                                    boxShadow: 'none',
                                    border: `1px solid ${AMBER_LIGHT_GREEN}`,
                                }}
                            >
                                <RemoveIcon
                                    style={{transform: `scale(0.8)`}}
                                    color="primary"
                                />
                            </Fab>

                            {/* number */}
                            <Box
                                display="block"
                                component="span"
                                fontSize={16}
                                fontWeight={400}
                                color="black"
                                marginLeft={1}
                                marginRight={1}
                            >
                                {customRules.maxCharsPerLine}
                            </Box>

                            {/* + */}
                            <Fab
                                aria-label="plus"
                                onClick={() =>
                                    customRules.maxCharsPerLine < MAX_VALUE_FOR_CHARS_PER_ROW &&
                                    setCustomRules({
                                        ...customRules, 
                                        maxCharsPerLine: customRules.maxCharsPerLine + 1
                                    })
                                }
                                style={{
                                    width: 30,
                                    height: 30,
                                    minHeight: 'unset',
                                    marginLeft: 2,
                                    backgroundColor: 'white',
                                    boxShadow: 'none',
                                    border: `1px solid ${AMBER_LIGHT_GREEN}`,
                                }}
                            >
                                <AddIcon style={{transform: `scale(0.8)`}} color="primary"/>
                            </Fab>
                        </Box>
                    </Box>
                    {/* Max characters per second */}
                    <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="space-between"
                        marginTop={2}
                    >
                        <Box
                            display="block"
                            component="span"
                            fontSize={14}
                            fontWeight={400}
                            color="#2B292D"
                        >
                            {translate('CREATE_SUBTITLES.maxCharsPerSecond')}
                        </Box>

                        <Box display="flex" alignItems="center" marginTop={1}>
                            {/* - */}
                            <Fab
                                aria-label="minus"
                                onClick={() =>
                                    customRules.maxCharsPerSecond > MIN_VALUE_CHARS_PER_SECOND &&
                                    setCustomRules({
                                        ...customRules, 
                                        maxCharsPerSecond: customRules.maxCharsPerSecond - 1
                                    })
                                }
                                style={{
                                    width: 30,
                                    height: 30,
                                    minHeight: 'unset',
                                    marginRight: 2,
                                    backgroundColor: 'white',
                                    boxShadow: 'none',
                                    border: `1px solid ${AMBER_LIGHT_GREEN}`,
                                }}
                            >
                                <RemoveIcon
                                    style={{transform: `scale(0.8)`}}
                                    color="primary"
                                />
                            </Fab>

                            {/* number */}
                            <Box
                                display="block"
                                component="span"
                                fontSize={16}
                                fontWeight={400}
                                color="black"
                                marginLeft={1}
                                marginRight={1}
                            >
                                {customRules.maxCharsPerSecond}
                            </Box>

                            {/* + */}
                            <Fab
                                aria-label="plus"
                                onClick={() =>
                                    customRules.maxCharsPerSecond < MAX_VALUE_CHARS_PER_SECOND &&
                                    setCustomRules({
                                        ...customRules, 
                                        maxCharsPerSecond: customRules.maxCharsPerSecond + 1
                                    })
                                }
                                style={{
                                    width: 30,
                                    height: 30,
                                    minHeight: 'unset',
                                    marginLeft: 2,
                                    backgroundColor: 'white',
                                    boxShadow: 'none',
                                    border: `1px solid ${AMBER_LIGHT_GREEN}`,
                                }}
                            >
                                <AddIcon style={{transform: `scale(0.8)`}} color="primary"/>
                            </Fab>
                        </Box>
                    </Box>
                    {/* Minimum subtitle duration */}
                    <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="space-between"
                        marginTop={2}
                    >
                        <Box
                            display="block"
                            component="span"
                            fontSize={14}
                            fontWeight={400}
                            color="#2B292D"
                        >
                            {translate('CREATE_SUBTITLES.minSubtitleDuration')}
                        </Box>

                        <Box display="flex" alignItems="center" marginTop={1}>
                            {/* - */}
                            <Fab
                                aria-label="minus"
                                onClick={() =>
                                    customRules.minSubtitleDuration > MIN_VALUE_SUBTITLE_DURATION &&
                                    setCustomRules({
                                        ...customRules, 
                                        minSubtitleDuration: customRules.minSubtitleDuration - 1
                                    })
                                }
                                style={{
                                    width: 30,
                                    height: 30,
                                    minHeight: 'unset',
                                    marginRight: 2,
                                    backgroundColor: 'white',
                                    boxShadow: 'none',
                                    border: `1px solid ${AMBER_LIGHT_GREEN}`,
                                }}
                            >
                                <RemoveIcon
                                    style={{transform: `scale(0.8)`}}
                                    color="primary"
                                />
                            </Fab>

                            {/* number */}
                            <Box
                                display="block"
                                component="span"
                                fontSize={16}
                                fontWeight={400}
                                color="black"
                                marginLeft={1}
                                marginRight={1}
                            >
                                {`${customRules.minSubtitleDuration}s`}
                            </Box>

                            {/* + */}
                            <Fab
                                aria-label="plus"
                                onClick={() =>
                                    customRules.minSubtitleDuration < MAX_VALUE_SUBTITLE_DURATION &&
                                    setCustomRules({
                                        ...customRules, 
                                        minSubtitleDuration: customRules.minSubtitleDuration + 1
                                    })
                                }
                                style={{
                                    width: 30,
                                    height: 30,
                                    minHeight: 'unset',
                                    marginLeft: 2,
                                    backgroundColor: 'white',
                                    boxShadow: 'none',
                                    border: `1px solid ${AMBER_LIGHT_GREEN}`,
                                }}
                            >
                                <AddIcon style={{transform: `scale(0.8)`}} color="primary"/>
                            </Fab>
                        </Box>
                    </Box>
                    {/* Maximum subtitle duration */}
                    <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="space-between"
                        marginTop={2}
                    >
                        <Box
                            display="block"
                            component="span"
                            fontSize={14}
                            fontWeight={400}
                            color="#2B292D"
                        >
                            {translate('CREATE_SUBTITLES.maxSubtitleDuration')}
                        </Box>

                        <Box display="flex" alignItems="center" marginTop={1}>
                            {/* - */}
                            <Fab
                                aria-label="minus"
                                onClick={() =>
                                    customRules.maxSubtitleDuration > MIN_VALUE_SUBTITLE_DURATION &&
                                    setCustomRules({
                                        ...customRules, 
                                        maxSubtitleDuration: customRules.maxSubtitleDuration - 1
                                    })
                                }
                                style={{
                                    width: 30,
                                    height: 30,
                                    minHeight: 'unset',
                                    marginRight: 2,
                                    backgroundColor: 'white',
                                    boxShadow: 'none',
                                    border: `1px solid ${AMBER_LIGHT_GREEN}`,
                                }}
                            >
                                <RemoveIcon
                                    style={{transform: `scale(0.8)`}}
                                    color="primary"
                                />
                            </Fab>

                            {/* number */}
                            <Box
                                display="block"
                                component="span"
                                fontSize={16}
                                fontWeight={400}
                                color="black"
                                marginLeft={1}
                                marginRight={1}
                            >
                                {`${customRules.maxSubtitleDuration}s`}
                            </Box>

                            {/* + */}
                            <Fab
                                aria-label="plus"
                                onClick={() =>
                                    customRules.maxSubtitleDuration < MAX_VALUE_SUBTITLE_DURATION &&
                                    setCustomRules({
                                        ...customRules, 
                                        maxSubtitleDuration: customRules.maxSubtitleDuration + 1
                                    })
                                }
                                style={{
                                    width: 30,
                                    height: 30,
                                    minHeight: 'unset',
                                    marginLeft: 2,
                                    backgroundColor: 'white',
                                    boxShadow: 'none',
                                    border: `1px solid ${AMBER_LIGHT_GREEN}`,
                                }}
                            >
                                <AddIcon style={{transform: `scale(0.8)`}} color="primary"/>
                            </Fab>
                        </Box>
                    </Box>
                    {/* Minimum frame gap */}
                    <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="space-between"
                        marginTop={2}
                    >
                        <Box
                            display="block"
                            component="span"
                            fontSize={14}
                            fontWeight={400}
                            color="#2B292D"
                        >
                            {translate('CREATE_SUBTITLES.minFrameGap')}
                        </Box>

                        <Box display="flex" alignItems="center" marginTop={1}>
                            {/* - */}
                            <Fab
                                aria-label="minus"
                                onClick={() =>
                                    customRules.minFrameGap > MIN_VALUE_SUBTITLE_DURATION &&
                                    setCustomRules({
                                        ...customRules, 
                                        minFrameGap: customRules.minFrameGap - 1
                                    })
                                }
                                style={{
                                    width: 30,
                                    height: 30,
                                    minHeight: 'unset',
                                    marginRight: 2,
                                    backgroundColor: 'white',
                                    boxShadow: 'none',
                                    border: `1px solid ${AMBER_LIGHT_GREEN}`,
                                }}
                            >
                                <RemoveIcon
                                    style={{transform: `scale(0.8)`}}
                                    color="primary"
                                />
                            </Fab>

                            {/* number */}
                            <Box
                                display="block"
                                component="span"
                                fontSize={16}
                                fontWeight={400}
                                color="black"
                                marginLeft={1}
                                marginRight={1}
                            >
                                {customRules.minFrameGap}
                            </Box>

                            {/* + */}
                            <Fab
                                aria-label="plus"
                                onClick={() =>
                                    customRules.minFrameGap < MAX_VALUE_SUBTITLE_DURATION &&
                                    setCustomRules({
                                        ...customRules, 
                                        minFrameGap: customRules.minFrameGap + 1
                                    })
                                }
                                style={{
                                    width: 30,
                                    height: 30,
                                    minHeight: 'unset',
                                    marginLeft: 2,
                                    backgroundColor: 'white',
                                    boxShadow: 'none',
                                    border: `1px solid ${AMBER_LIGHT_GREEN}`,
                                }}
                            >
                                <AddIcon style={{transform: `scale(0.8)`}} color="primary"/>
                            </Fab>
                        </Box>
                    </Box>
                    
                </Box>
            </AccordionDetails>
        </Accordion>
    );
}

export function NetflixSubtitleRulesAccordion(props) {
    const {
        selectedRule,
        setSelectedRule,
        setCustomOpen,
        systemBusy,
        translate,
    } = props;

    function setNetflixRule() {
        setSelectedRule(NETFLIX_RULE);
        setCustomOpen(false);
    }

    return (
        <Accordion
            disabled={systemBusy}
            expanded={false}
            onClick={setNetflixRule}
        >
            <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
                style={{backgroundColor: selectedRule === NETFLIX_RULE ? AMBER_PRIMARY : '#ECECEC'}}
            >
                <Box display="flex" flexDirection="column">
                    <Box
                        display="block"
                        component="span"
                        fontSize={14}
                        fontWeight={600}
                        color="black"
                        marginBottom={1}
                    >
                        {translate('CREATE_SUBTITLES.netflixRules')}
                    </Box>
                    <Box
                        display="block"
                        component="span"
                        fontSize={12}
                        color={selectedRule === NETFLIX_RULE ? "white" : "black"}
                    >
                        {translate('CREATE_SUBTITLES.rule1', {
                            maxLinesPerSegment: 2,
                            maxCharsPerLine: DEFAULT_VALUE_FOR_CHARS_PER_ROW,
                            maxDisplayTimePerLine: 7,
                        })}
                    </Box>
                </Box>
            </AccordionSummary>
        </Accordion>
    );
}