// @see https://www.npmjs.com/package/react-gtm-module
import TagManager from 'react-gtm-module';
import {
	GTM_ID,
	STORAGE_ANALYTICS_DATALAYER,
	DEBUG,
	GTM_AUTH_STAGING,
	GTM_PREVIEW_STAGING,
} from '../settings';
import { localStore } from '../helpers/misc';

function getArgs() {
	if (DEBUG) {
		return {
			gtmId: GTM_ID,
			auth: GTM_AUTH_STAGING,
			preview: GTM_PREVIEW_STAGING,
			dataLayer: localStore('get', STORAGE_ANALYTICS_DATALAYER),
		};
	} else {
		return {
			gtmId: GTM_ID,
			dataLayer: localStore('get', STORAGE_ANALYTICS_DATALAYER),
		};
	}
}

TagManager.initialize(getArgs());

function GTM() {
	return '';
}

export default GTM;
