/* eslint-disable import/prefer-default-export */
import { atom } from 'recoil';
import { MACHINE_MADE_TRANSLATION } from './constants';

export const machineTranslationsAtom = atom({
  key: MACHINE_MADE_TRANSLATION,
  default: {
    open: false,
    sourceLanguages: [],
    targetLanguages: [],
    jobId: '',
    jobLanguage: '',
  },
});
