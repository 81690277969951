import { Modifier, EditorState } from '@amberscript/amberscript-draftjs/lib/Draft';

/**
 * @param editorState: EditorState
**/

const highlightText = (editorState) => {    
    let selection = editorState.getSelection();

    const content = editorState.getCurrentContent();

    let startKey = selection.getStartKey();
    let endKey = selection.getEndKey();

    const startBlock = content.getBlockForKey(startKey);
    const endBlock = content.getBlockForKey(endKey);

    const textStartBlock = startBlock.getText();
    const textEndBlock = endBlock.getText();

    let start = selection.getStartOffset();
    let end = selection.getEndOffset() - 1;

    const isSelectionOnlyWhiteSpace = checkSelectionForWhiteSpace(startKey === endKey, textStartBlock, textEndBlock, start, end);

    //if the selection is only white space(s) and before or we are at the edge of the segment.
    if(isSelectionOnlyWhiteSpace && (!textStartBlock[start - 1] || !textStartBlock[end + 1])) {
        return null;
    }

    const isSelectionWhiteSpaceWasHighlighted = startBlock.getInlineStyleAt(start).filter(style => style === 'HIGHLIGHT').length > 0;

    //if the selection is only white space(s) and before and after the selection is a character.
    if(isSelectionWhiteSpaceWasHighlighted && isSelectionOnlyWhiteSpace && textStartBlock[start - 1] !== ''
        && textStartBlock[start - 1] !== ' ' && textEndBlock[end + 1] !== '' && textEndBlock[end + 1] !== ' ') {
        return editorState;
    }

    //check for extra spaces at start or end of the selection.
    while(textStartBlock[start] === ' ') {
        start += 1;
    }
    while(textEndBlock[end] === ' ' && end !== 0) {
        end -= 1;
    }

    selection = selection.merge({
        anchorKey: startKey,
        anchorOffset: start,
        focusKey: endKey,
        focusOffset: end + 1,
        isBackward: false
    });
    editorState = EditorState.forceSelection(editorState, selection);
    return editorState;
}

const checkSelectionForWhiteSpace = (isSelectionInOneSegment, textStartBlock, textEndBlock, start, end) => {
    if (isSelectionInOneSegment) {
        return textStartBlock.slice(start, end).trim() === "";
    }
    else return (textStartBlock.slice(start) + textEndBlock.slice(0, end + 1)).trim() === "";
}

export default highlightText;