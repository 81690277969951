import { Box, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

// Style
const useStyles = makeStyles((theme) => ({
  btn_disabled: {
    textTransform: "none",
    whiteSpace: "nowrap",
    marginLeft: 12,
    border: "2px solid #e0e0e0",
    color: theme.palette.custom.charcoalGray,
  },
}));

// Component
function ProcessingButton(props) {
  const { onClick, text, testid, icon } = props;
  const classes = useStyles();

  return (
    <Button
      variant="contained"
      onClick={onClick}
      color="primary"
      className={classes.btn_disabled}
      disabled
      data-testid={testid}
      startIcon={icon}
    >
      <Box
        component="span"
        fontSize={14}
        fontWeight={600}
        paddingLeft={1}
        paddingRight={1}
      >
        {text}
      </Box>
    </Button>
  );
}

ProcessingButton.propTypes = {
  text: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  testid: PropTypes.string,
  icon: PropTypes.node.isRequired,
};
ProcessingButton.defaultProps = {
  testid: "",
};

export default ProcessingButton;
