export const FORMAT_SRT = 'SRT';
export const FORMAT_VTT = 'VTT';
export const FORMAT_EBU_STL = 'EBU-STL';
export const FORMAT_AVID = 'AVID';
export const FORMAT_PREMIERE = 'PREMIERE';
export const FORMAT_AVID_EXTENSION = 'txt';
export const FORMAT_PREMIERE_EXTENSION = 'xml';

export const FORMAT_LIST_AUDIO = [FORMAT_SRT, FORMAT_VTT, FORMAT_EBU_STL];

export const FORMAT_LIST_VIDEO = [
  ...FORMAT_LIST_AUDIO,
  FORMAT_AVID,
  FORMAT_PREMIERE,
];

export const MIN_VALUE_FOR_CHARS_PER_ROW = 30;
export const DEFAULT_VALUE_FOR_CHARS_PER_ROW = 42;
export const MAX_VALUE_FOR_CHARS_PER_ROW = 45;

export const NETFLIX_MAX_NUMBER_OF_ROWS = 42;
export const BBC_MAX_NUMBER_OF_ROWS = 34;

export const SUBTITLE_DURATION_MAX = 3500;

export const SELECTED_RULE_NETFLIX = 'Netflix';
export const SELECTED_RULE_CUSTOM = 'Custom';
export const SELECTED_REALIGNMENT_QUICK = 'QUICK';

export const DIALOG_STEP_EXPORT_SUBTITLES = 0;
export const DIALOG_STEP_EXPORT_TEXT = 1;
export const DIALOG_STEP_EXPORT_BURN_IN_CONFIRMATION = 2;
export const DIALOG_STEP_EXPORT_BURN_IN_SUCCESS = 3;
