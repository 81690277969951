import React from 'react'
import {connect} from 'react-redux'
import AdjustTimestampsDialog from './AdjustTimestampsDialog'
import SubtitleDialog from './SubtitleDialog'
import { HIDE_MODAL } from '../../sagas/constants';


const MODAL_COMPONENTS = {
	ADJUST_TIMESTAMPS: AdjustTimestampsDialog,
	SUBTITLE: SubtitleDialog,
  /* other modals */
}



const ModalRoot = ({ modalType, modalProps, dispatch }) => {
  if (!modalType) {
    return null; // after React v15 you can return null here
	}

  const SpecificModal = MODAL_COMPONENTS[modalType]
	return <SpecificModal
						{...modalProps}
						open={true}

						onClose={() => dispatch({
							type: HIDE_MODAL
						})} />
}

export default connect(
  state => state.modal
)(ModalRoot)