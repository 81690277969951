import React, {useEffect, useState} from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    FormControlLabel,
    DialogTitle,
    Checkbox,
    IconButton,
    CircularProgress,
} from '@material-ui/core';

import SubtitlesIcon from '@material-ui/icons/Subtitles';
import CloseIcon from '@material-ui/icons/Close';
import CachedIcon from '@material-ui/icons/Cached';
import WarningIcon from '@material-ui/icons/Warning';
import AssignmentIcon from '@material-ui/icons/Assignment';
import {API_URL, DASHBOARD_URL, TRANSCRIBER_DASHBOARD} from '../../settings';
import {apiClient, isV2Auth} from '../../apiClient';
import {convertToJSON} from '../../helpers/exportTranscript';
import { checkIfAlignmentSupportsLanguage } from './helpers';
import { CustomSubtitleRulesAccordion, NetflixSubtitleRulesAccordion } from './CreateSubtitleAccordion';
import { AMBER_LIGHT_GREEN, CUSTOM_RULE, DEFAULT_VALUE_FOR_CHARS_PER_ROW, NETFLIX_RULE, TIMEOUT_BEFORE_REDIRECTING_USER } from './constants';

const useStyles = makeStyles((theme) => ({
    button: {
        textTransform: 'none',
    },
}));

const WhiteCheckbox = withStyles({
    root: {
        color: '#fff',
        '&$checked': {
            color: '#fff',
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

function Title({text, color}) {
    return (
        <Box
            display="block"
            component="span"
            color={color}
            fontSize={18}
            fontWeight={500}
            marginBottom={1}
        >
            {text}
        </Box>
    );
}

function ReviewWork({
        isReviewed,
        setIsReviewed,
        systemBusy,
        isTranscriber,
        translate,
    }) {
    return (
        <Box
            flex={1}
            bgcolor={AMBER_LIGHT_GREEN}
            p={3}
            lineHeight="1.6"
            borderRadius="15px"
        >
            {/* Title */}
            <Title text={translate('CREATE_SUBTITLES.title')} color="white"/>
            {/* Description */}
            <Box display="flex" marginBottom={1}>
                <Box display="flex" flexDirection="column" flex={1}>
                    <Box
                        display="inline-block"
                        component="span"
                        fontSize={14}
                        color="white"
                        marginBottom={1}
                    >
                        {translate('CREATE_SUBTITLES.title1')}
                        <Box
                            display="inline-block"
                            component="span"
                            fontSize={14}
                            color="white"
                            marginBottom={1}
                        >
                            {translate('CREATE_SUBTITLES.title2')}
                        </Box>
                    </Box>
                    <Box
                        display="block"
                        component="span"
                        fontSize={14}
                        color="white"
                        marginBottom={2}
                    >
                        {translate('CREATE_SUBTITLES.title3')}
                    </Box>
                    {isTranscriber() && (
                        <Box
                            display="block"
                            component="span"
                            fontSize={14}
                            fontWeight={600}
                            color="white"
                        >
                            {translate('CREATE_SUBTITLES.note1')}
                        </Box>
                    )}
                </Box>

                {/* <Box display="flex" flex={1} justifyContent="center" alignItems="center">
                    <img src={AutomaticSubtitleImage} alt="Automatic subtitle" width="auto" height="60px" />
                </Box> */}
            </Box>
            {/* Checkbox review */}
            <Box>
                <FormControlLabel
                    control={
                        <WhiteCheckbox
                            checked={isReviewed}
                            onChange={() => !systemBusy && setIsReviewed(!isReviewed)}
                        />
                    }
                    label={
                        <Box
                            display="block"
                            component="span"
                            fontSize={14}
                            fontWeight={600}
                            color="white"
                        >
                            {translate('CREATE_SUBTITLES.confirmation1')}
                        </Box>
                    }
                />
            </Box>
        </Box>
    );
}

function SubtitleOptions(props) {
    const {
        customRules,
        setCustomRules,
        selectedRule,
        setSelectedRule,
        systemBusy,
        isTranscriber,
        translate,
    } = props;
    const [customOpen, setCustomOpen] = useState(false);

    return (
        <Box display="flex" flex={1} flexDirection="column" marginTop={4}>
            <Title text={`Subtitle rules`} color="white"/>

            <Box display="flex" flexDirection="column" marginBottom={3}>
                <Box marginTop={0.5}>
                    <NetflixSubtitleRulesAccordion
                        systemBusy={systemBusy}
                        translate={translate}
                        selectedRule={selectedRule}
                        setSelectedRule={setSelectedRule}
                        setCustomOpen={setCustomOpen}
                    />
                    <CustomSubtitleRulesAccordion
                        customOpen={customOpen}
                        setCustomOpen={setCustomOpen}
                        systemBusy={systemBusy}
                        translate={translate}
                        customRules={customRules}
                        setCustomRules={setCustomRules}
                        selectedRule={selectedRule}
                        setSelectedRule={setSelectedRule}
                    />
                    {selectedRule === undefined && (<div style={{color: 'red', marginTop:'15px', fontSize: "12px"}}>Please select a rule</div>)}
                </Box>
            </Box>

            {!isTranscriber() && (
                <Box display="flex" alignItems="flex-start" marginTop={1}>
                    <AssignmentIcon
                        style={{color: '#FAAA28', height: 25, marginRight: 4}}
                    />
                    <Box
                        display="inline-block"
                        component="span"
                        fontSize={14}
                        fontWeight={600}
                        color="black"
                        marginLeft={0.5}
                        lineHeight="1.5"
                    >
                        {translate('CREATE_SUBTITLES.note2')}
                    </Box>
                </Box>
            )}

            {isTranscriber() && (
                <Box display="flex" alignItems="flex-start" marginTop={2}>
                    <WarningIcon
                        style={{color: '#FAAA28', height: 25, marginRight: 4}}
                    />
                    <Box
                        display="inline-block"
                        component="span"
                        fontSize={14}
                        fontWeight={400}
                        color="black"
                        marginLeft={0.5}
                        lineHeight="1.5"
                    >
                        {translate('CREATE_SUBTITLES.note3')}
                    </Box>
                </Box>
            )}

            <Box display="flex" alignItems="flex-start" marginTop={2}>
                <CachedIcon style={{color: '#726F7B', height: 25, marginRight: 4}}/>
                <Box
                    display="inline-block"
                    component="span"
                    fontSize={14}
                    fontWeight={600}
                    color="black"
                    marginLeft={0.5}
                    lineHeight="1.5"
                >
                    {translate('CREATE_SUBTITLES.note4')}
                    <Box
                        display="inline"
                        component="span"
                        fontSize={14}
                        fontWeight={400}
                        color="custom.purplishGray"
                        marginLeft={0.5}
                        lineHeight="1.5"
                    >
                        {translate('CREATE_SUBTITLES.note5')}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

function CreateSubtitleActions({
        createSubtitleFromTranscript,
        selectedRule,
        systemBusy,
        error,
        success,
        isReviewed,
        isTranscriber,
        translate,
    }) {
    function getMessage() {
        if (success) {
            if (isTranscriber()) {
                return translate('CREATE_SUBTITLES.msgSuccess1');
            } else {
                return translate('CREATE_SUBTITLES.msgSuccess2');
            }
        } else {
            return translate('CREATE_SUBTITLES.error1');
        }
    }

    return (
        <Box display="flex" p={1} alignItems="center">
            {(error || success) && (
                <Box
                    component="span"
                    fontSize={14}
                    color={success ? 'green' : 'red'}
                    p={1}
                >
                    {getMessage()}
                </Box>
            )}
            <Button
                onClick={createSubtitleFromTranscript}
                color="primary"
                variant="contained"
                disabled={systemBusy || !isReviewed || selectedRule === undefined}
            >
                <Box display="flex" justifyContent="space-around" alignItems="center">
                    {systemBusy && <CircularProgress size={25} color="white"/>}
                    <Box
                        component="span"
                        marginLeft={systemBusy ? 1 : 0}
                        fontSize={14}
                        fontWeight={600}
                        color="white"
                        whiteSpace="nowrap"
                        style={{textTransform: 'none'}}
                    >
                        {translate('CREATE_SUBTITLES.button1')}
                    </Box>
                </Box>
            </Button>
        </Box>
    );
}

function CreateSubtitleDialog(props) {
    const {open, setOpen, transcript, transcriptStatus, isTranscriber, translate} = props;
    const [isReviewed, setIsReviewed] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [systemBusy, setSystemBusy] = useState(false);
    const [customRules, setCustomRules] = useState({
        maxLinesPerSegment: transcriptStatus.maxLinesPerSegment || 2,
        includeSpaceInCharacterCount: transcriptStatus.includeSpaceInCharacterCount || true,
        includeSpecialCharactersInCount: transcriptStatus.includeSpecialCharactersInCount || true,
        maxCharsPerLine: transcriptStatus.maxCharsPerLine || DEFAULT_VALUE_FOR_CHARS_PER_ROW,
        maxCharsPerSecond: transcriptStatus.maxCharsPerSecond || 30,
        minSubtitleDuration: transcriptStatus.minSubtitleDuration || 1,
        maxSubtitleDuration: transcriptStatus.maxSubtitleDuration || 7,
        minFrameGap: transcriptStatus.minFrameGap || 2,
    })
    const [selectedRule, setSelectedRule] = useState(undefined);

    useEffect(() => {
        if (transcriptStatus.data.job.id !== undefined) {
            apiClient.get(`${API_URL}/v2/jobs/subtitle-rules/${transcriptStatus.data.job.id}`)
            .then((response) => {
                if (response.status !== 204 && response.data !== "") {
                    const {
                        maxLinesPerSegment,
                        includeSpaceInCharacterCount,
                        includeSpecialCharactersInCount,
                        maxCharsPerSecond,
                        maxCharsPerLine,
                        minSubtitleDuration,
                        maxSubtitleDuration,
                        minFrameGap,
                    } = response.data;
                    setCustomRules({
                        maxLinesPerSegment,
                        includeSpaceInCharacterCount,
                        includeSpecialCharactersInCount,
                        maxCharsPerSecond,
                        maxCharsPerLine,
                        minSubtitleDuration,
                        maxSubtitleDuration,
                        minFrameGap,
                    });
                }
            })
        }
    }, [open])

    const getSelectedRulesValues = () => {
        switch(selectedRule) {
            case NETFLIX_RULE: {
                return {
                    maxLinesPerSegment: 2,
                    maxCharsPerLine: DEFAULT_VALUE_FOR_CHARS_PER_ROW,
                    segmentMaxScreenTime: 7
                };
            }
            case CUSTOM_RULE: {
                return customRules;
            }
            default: {
                return undefined;
            }
        }
    }

    function handleClose() {
        if (!systemBusy) {
            setIsReviewed(false);
            setError(false);
            setSuccess(false);
            setSystemBusy(false);
            setSelectedRule(undefined);

            if (!systemBusy) {
                setOpen(false);
            }
        }
    }

    function shouldRunAlignment() {
        const { language } = transcriptStatus?.data;
        if (!language) {
            return false;
        }
        return checkIfAlignmentSupportsLanguage(language);
    }

    function shouldRunFormatting() {
        return true;
    }

    function getCreateSubtitleEndpoint() {
        return isV2Auth() ? `${API_URL}/v2/document/subtitles` : `${API_URL}/document/subtitles`;
    }

    function createSubtitleFromTranscript() {
        const options = {
            params: {
                jobId: transcriptStatus.data?.job?.id,
                runAlignment: shouldRunAlignment(),
                runFormatting: shouldRunFormatting(),
                ...getSelectedRulesValues(),
            }
        };

        const convertToJsonOptions = {
            includeTimestamps: true, includeSpeakers: true, includeHighlights: true,
            highlightsOnly: false, includeIsConvertedToSubtitles: true
        };

        setSystemBusy(true);
        setError(false);
        setSuccess(false);

        apiClient.post(getCreateSubtitleEndpoint(), convertToJSON(transcript, convertToJsonOptions, true, transcriptStatus.data.jobId), options)
            .then(res => {
                setSuccess(true);
                setTimeout(() => {
                    window.location.replace(isTranscriber() ? TRANSCRIBER_DASHBOARD : DASHBOARD_URL);
                }, TIMEOUT_BEFORE_REDIRECTING_USER);
            })
            .catch(err => {
                setError(true);
                setSystemBusy(false);
            })
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="sm"
            aria-labelledby="create-subtitles-title"
            aria-describedby="create-subtitles-description"
        >
            <Box p={1}>
                <Box display="flex" alignItems="center" justifyContent="space-between" paddingRight={2}>
                    <DialogTitle id="create-subtitles-title">
                        {translate("CREATE_SUBTITLES.button1")}
                    </DialogTitle>
                    <IconButton onClick={handleClose}>
                        <CloseIcon/>
                    </IconButton>
                </Box>
                <DialogContent>
                    <Box display="flex" flexDirection="column">
                        <ReviewWork 
                            isReviewed={isReviewed} 
                            setIsReviewed={setIsReviewed} 
                            systemBusy={systemBusy}
                            isTranscriber={isTranscriber}
                            translate={translate}
                        />
                        <SubtitleOptions
                            customRules={customRules}
                            setCustomRules={setCustomRules}
                            selectedRule={selectedRule}
                            setSelectedRule={setSelectedRule}
                            systemBusy={systemBusy}
                            isTranscriber={isTranscriber}
                            translate={translate}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <CreateSubtitleActions 
                        createSubtitleFromTranscript={createSubtitleFromTranscript}
                        selectedRule={selectedRule}
                        systemBusy={systemBusy}
                        error={error}
                        success={success}
                        isReviewed={isReviewed}
                        isTranscriber={isTranscriber}
                        translate={translate}
                    />
                </DialogActions>
            </Box>
        </Dialog>
    )
}

const ColorButton = withStyles((theme) => ({
    root: {
        color: 'white',
        backgroundColor: '#005A50',
        '&:hover': {
            backgroundColor: '#003e38',
        },
        whiteSpace: 'nowrap',
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
        [theme.breakpoints.down('sm')]: {
            height: 34,
        },
        [theme.breakpoints.up('md')]: {
            height: 40,
        },
    },
}))(Button);

export default function CreateSubtitleButton({
        transcript,
        transcriptStatus,
        isTranscriber,
        translate,
    }) {
    const classes = useStyles();

    const [open, setOpen] = useState(false);

    return (
        <React.Fragment>
            <Box display="flex" marginRight={1} marginLeft={1}>
                <ColorButton
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    startIcon={<SubtitlesIcon/>}
                    onClick={() => setOpen(true)}
                >
                    {translate('CREATE_SUBTITLES.button1')}
                </ColorButton>
            </Box>
            <CreateSubtitleDialog
                open={open}
                setOpen={setOpen}
                transcript={transcript}
                transcriptStatus={transcriptStatus}
                isTranscriber={isTranscriber}
                translate={translate}
            />
        </React.Fragment>
    );
}
