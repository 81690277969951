import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, Dialog, DialogTitle, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ReportSpellingDialogContent from "./ReportSpellingDialogContent";
import ReportSpellingDialogActions from "./ReportSpellingDialogActions";
import { reportFalsePositive } from "../../../../sagas/qualityCheck";
import { trackSentryErrorByTag } from "../../../../sentry/log";

export default function ReportSpellingDialog(props) {
  const {
    open,
    translate,
    handleClose,
    issue,
    language,
    jobId,
    userName,
    roles,
  } = props;
  const [comment, setComment] = useState("");

  const handleChangeComment = (event) => {
    const { value } = event.target;
    setComment(value);
  };

  // Sets an empty string for suggestion field if it's undefined to avoid BE issue
  const fixUndefinedSuggestion = () => {
    if (!issue.suggestion) {
      issue.suggestion = "";
    }
  };

  // Reports the false positive
  const report = async () => {
    fixUndefinedSuggestion();
    try {
      await reportFalsePositive(
        jobId,
        userName,
        roles,
        issue,
        language,
        comment
      );
    } catch (error) {
      trackSentryErrorByTag(error, "pre-qc-report-false-positive");
    }
  };

  const handleClickReport = () => {
    report();
    setComment("");
    handleClose();
  };

  return (
    <Dialog
      open={open}
      disableEscapeKeyDown
      maxWidth="sm"
      fullWidth
      data-testid="pre-qc-report-false-positive-dialog"
    >
      <DialogTitle data-testid="pre-qc-report-false-positive-dialog-title">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          {translate("EDITOR.PRE_QC.reportTitle")}
          <IconButton
            onClick={handleClose}
            data-testid="pre-qc-report-false-positive-dialog-btn-close"
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <ReportSpellingDialogContent
        issue={issue}
        translate={translate}
        value={comment}
        handleChange={handleChangeComment}
      />

      <ReportSpellingDialogActions
        handleClose={handleClose}
        handleReport={handleClickReport}
        translate={translate}
      />
    </Dialog>
  );
}

ReportSpellingDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  translate: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  issue: PropTypes.shape({
    end_time: PropTypes.number.isRequired,
    end_word_index: PropTypes.number.isRequired,
    issue_severity: PropTypes.string.isRequired,
    issue_type: PropTypes.string.isRequired,
    issue_type_detail: PropTypes.string,
    original_text: PropTypes.string.isRequired,
    segment_index: PropTypes.number.isRequired,
    start_time: PropTypes.number.isRequired,
    start_word_index: PropTypes.number.isRequired,
    suggestion: PropTypes.string,
  }).isRequired,
  language: PropTypes.string.isRequired,
  jobId: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  roles: PropTypes.arrayOf(PropTypes.string.isRequired),
};
