import { Modifier } from '@amberscript/amberscript-draftjs/lib/Draft';
import { List } from 'immutable';
import TranscriptWord from '../models/TranscriptWord';
import { getWordsBefore, getWordsAfter } from './wordsUtils'
import uuidV4 from 'uuid/v4';

const numberOfSpaces = (text) => {
    const spaces = text.match(/\s/gi)
    return spaces != null ? spaces.length : 0;
}

/**
 * replaceText takes a ContentState object, a string and a selection
 * object and returns a new ContentState.
 *
 * It synchronises the text displayed with the Words model (located
 * in block.data.words)
 *
 * The Words model contains start and end times, and is used to
 * decorate the blocks with words (only if the current time of
 * the audio playback is situated within a block)
 *
 * replaceText is used for all editor operations and all
 * editor operations can be modelled with or in combination with
 * this method.
 * @param contentState: ContentState
 * @param text: string
 * @param selection: Selection
 * @returns ContentState
 */
const replaceText = (content, text, selection) => {
    const startKey = selection.getStartKey();
    const endKey = selection.getEndKey();
    const startOffset = selection.getStartOffset();
    const endOffset = selection.getEndOffset();

    const startBlock = content.getBlockForKey(startKey);
    const endBlock = content.getBlockForKey(endKey);

    const blockBefore = content.getBlockBefore(startKey);
    const blockAfter = content.getBlockAfter(endKey);

    const startText = startBlock.getText();
    const endText = endBlock.getText();

    const startBlockWords = startBlock.getIn(['data', 'words'])
    const endBlockWords = endBlock.getIn(['data', 'words'])

    const startOffsetWithoutSpaces = startOffset - numberOfSpaces(startText.slice(0, startOffset))
    const endOffsetWithoutSpaces = endOffset - numberOfSpaces(endText.slice(0, endOffset))

    let words = List(), start = 0, end = 0;

    // get the words before and after the selection
    const wordsBefore = getWordsBefore(startBlockWords, startOffsetWithoutSpaces)
    const wordsAfter = getWordsAfter(endBlockWords, endOffsetWithoutSpaces)

    const leadingSpace = /\s/.test(startText[startOffset - 1]) || startOffset === 0
    const trailingSpace = /\s/.test(endText[endOffset]) || endOffset === endText.length

    const isSpace = /\s/.test(text) && text.length === 1

    // get last end time
    // if there are words before our selection, get the last end time from there
    // if there are no words before this selection but a previous block,
    // take the last end time from that block
    if (wordsBefore.size > 0) {
        start = wordsBefore.last().get('end')
    } else {
        start = getEndFromPrevBlocks(content, startKey, 0)
    }

    // get next start time
    // if there are words after our selection, get the last end time from there
    // if there are no words After this selection but a next block,
    // take the first start time from that block
    if (wordsAfter.size > 0) {
        end = wordsAfter.first().get('start')
    } else {
        end = getStartFromNextBlocks(content, endKey, start)
    }

    // if it's just a space and the space doesn't split words, exit now
    if (isSpace && selection.isCollapsed() && (leadingSpace || trailingSpace)) {
        return content;

        // if it's a space and there is no leading or trailingSpace, split the word
    } else if (isSpace && !leadingSpace && !trailingSpace) {

        words = wordsBefore.slice(0, -1)
            .push(wordsBefore.last().update('end', val => wordsAfter.first().get('start')).set('pristine', false))
            .push(
                wordsAfter.first().set('pristine', false).set('id', uuidV4())
            ).concat(wordsAfter.slice(1))

        // if there is a leading and trailing space
        // (or we're at the edge of the block)
        // insert a new word [...wordsBefore, word, ...wordsAfter]
    } else if (leadingSpace && trailingSpace) {
        words = wordsBefore
            .push(TranscriptWord({
                id: uuidV4(),
                text,
                start,
                end,
                conf: null,
                pristine: false
            }))
            .concat(wordsAfter)
        // if there is a previous word and a trailing space, update that
    } else if (wordsBefore.size > 0 && trailingSpace) {
        words = wordsBefore.slice(0, -1)
            .push(wordsBefore.last().update('text', val => val + text).set('pristine', false))
            .concat(wordsAfter)
        // if there is a next word and a trailing space, update that
    } else if (wordsAfter.size > 0 && leadingSpace) {
        words = wordsBefore
            .push(wordsAfter.first().update('text', val => text + val).set('pristine', false))
            .concat(wordsAfter.slice(1))
        // if there is no leading and trailing space, combine the leading and trailing word
    } else if (!trailingSpace && !leadingSpace) {
        words = wordsBefore.slice(0, -1)
            .push(wordsBefore.last().update(word => {
                const wordAfter = wordsAfter.first();

                if (wordAfter) {
                    return word.merge({
                        text: word.get('text') + text + wordAfter.get('text'),
                        start: word.get('start'),
                        end: wordAfter.get('end'),
                        pristine: false
                    })
                } else {
                    return word.merge({
                        text: word.get('text') + text,
                        pristine: false
                    })
                }
            }))
            .concat(wordsAfter.slice(1))
    }

    if (selection.isCollapsed()) {
        content = Modifier.insertText(
            content,
            selection,
            text,
            startBlock.getInlineStyleAt(startOffset)
        )
    } else {
        content = Modifier.replaceText(
            content,
            selection,
            text,
        )
    }

    content = Modifier.mergeBlockData(
        content,
        content.getSelectionAfter(),
        {
            words: filterAndCorrectedWords(words),
        }
    )

    return content
}

const filterAndCorrectedWords = (words) => {
    const filteredWords = words.filter(record => record.get('text').trim().length !== 0);
    const correctedWords = filteredWords.map(record => record.update('text', val => val.replace(/\s\s+/g, ' ').trim()));
    return correctedWords;
}

const getEndFromPrevBlocks = (content, blockKey, defaultValue) => {
    const blockBefore = content.getBlockBefore(blockKey)
    if (blockBefore === undefined)
        return defaultValue;

    const words = blockBefore.getIn(['data', 'words']);
    if (words && words.size > 0) {
        return words.last().get('end');
    }
    return getEndFromPrevBlocks(content, blockBefore.getKey(), defaultValue)
}

const getStartFromNextBlocks = (content, blockKey, defaultValue) => {
    const blockAfter = content.getBlockAfter(blockKey)
    if (blockAfter === undefined)
        return defaultValue;

    const words = blockAfter.getIn(['data', 'words']);
    if (words && words.size > 0) {
        return words.first().get('start');
    }
    return getStartFromNextBlocks(content, blockAfter.getKey(), defaultValue)
}


export default replaceText;