import React, { Component } from 'react'
import { withTranslate } from 'react-redux-multilingual'
import {
    Box, Dialog, DialogActions, DialogContent,
    DialogContentText, DialogTitle, Button
} from '@material-ui/core'

class AlertEmailValidation extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Dialog open={this.props.showEmailNotVerifiedAlert} onClose={this.props.onClose} maxWidth="sm">
                <DialogTitle>{this.props.translate('EDITOR.emailValidationTitle')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {this.props.translate('EDITOR.emailValidationDescription')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Box p={1} display="flex" width="100%" justifyContent="space-between">
                        <Button variant="contained" onClick={this.props.getAccount} fullWidth style={{ marginRight: 4 }}>
                            {this.props.translate('EDITOR.btnAlreadyActivated')}
                        </Button>
                        <Button onClick={this.props.sendActivationAgain} variant="contained" color="primary" fullWidth style={{ marginLeft: 4 }}>
                            {this.props.translate('EDITOR.btnSendActivationAgain')}
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
        )
    }
}

export default withTranslate(AlertEmailValidation);
