import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

import AmberLoadingButton from '../../buttons/AmberLoadingButton';
import CheckCircle from '../../../assets/images/ui/check_circle.png';

export default function PreQCEmptyPanel(props) {
  const { translate, runPreQC, QCHasBeenRun, loadingQC } = props;

  const handleRunPreQc = () => {
    runPreQC(false, false); // openDialog = false, submitIfAllOK = false
  };

  function GetText() {
    if (QCHasBeenRun) {
      return (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          gridColumnGap={16}
          data-testid="pre-qc-box-no-issue-panel"
        >
          <img src={CheckCircle} alt={CheckCircle} height="20px" />
          {translate('EDITOR.PRE_QC.noIssueNotice')}
        </Box>
      );
    }
    return <>{translate('EDITOR.PRE_QC.emptyState')}</>;
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      marginTop={3}
      paddingLeft={2}
      data-testid="pre-qc-box-empty-panel"
    >
      <Box
        display="flex"
        alignItems="center"
        lineHeight="2"
        color="#2B292D"
        pl={1}
        pr={2.75}
        fontSize={{ xs: 12, sm: 12, md: 14, lg: 16, xl: 18 }}
      >
        <GetText />
      </Box>
      <Box display="flex" pl={-1} mt={1}>
        <AmberLoadingButton
          text={translate('EDITOR.PRE_QC.runPreQCBtn')}
          onClick={handleRunPreQc}
          loading={loadingQC}
          testid="pre-qc-button-run-assistant"
        />
      </Box>
    </Box>
  );
}

PreQCEmptyPanel.propTypes = {
  translate: PropTypes.func.isRequired,
  runPreQC: PropTypes.func.isRequired,
  QCHasBeenRun: PropTypes.bool.isRequired,
  loadingQC: PropTypes.bool.isRequired,
};
