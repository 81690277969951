import {
  SET_SEARCH_TEXT,
  SET_SEARCH_INDEX,
  SET_CASE_MATCH,
  SET_FOUND_WORDS,
  ADD_FOUND_WORDS,
} from "../sagas/constants";

const initialState = {
    searchText: '',
    searchIndex: undefined,
    foundStartWords: [],
    isCheckedCaseMatch: false,
}

export function findText(state = initialState, action) {
  switch (action.type) {
    case SET_SEARCH_TEXT:
      return {...state, searchText: action.value}
    case SET_SEARCH_INDEX:
        return {...state, searchIndex: action.value}
    case SET_CASE_MATCH:
        return {...state, isCheckedCaseMatch: action.value}
    case SET_FOUND_WORDS:
        return {...state, foundStartWords: [...action.value]}
    case ADD_FOUND_WORDS:
        return {...state, foundStartWords: [...state.foundStartWords, action.value]}
    default:
      return state
  }
}