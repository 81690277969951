import React, { useEffect, useState } from "react";
import { Box, IconButton } from "@material-ui/core";
import SubtitleEditor from "@amberscript/subtitle-editor/dist/SubtitleEditor";
import classNames from "classnames";
import CloseIcon from "@material-ui/icons/Close";
import { MenuIcon } from "../ui/Icons";

export default function SlateSubtitleEditor(props) {
  const {
    transcriptJsonOriginal,
    originalLang,
    showOriginal,
    triggerHideOriginal,
    translate,
  } = props;

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const scrollToTriggerReflow = () => {
      window.scrollTo(0, document.body.scrollHeight + 100);
      setTimeout(() => {
        window.scrollTo(0, 0);
        setIsLoading(false);

        const videoPlayer = document.getElementById("amber-video-player");

        const observer = new MutationObserver(() => {
          const busyIndicator = document.getElementsByClassName(
            "editor_busy_indicator"
          )[0];
          if (busyIndicator) {
            if (showOriginal && videoPlayer) {
              busyIndicator.style.display = "none";
            } else {
              busyIndicator.style.display = "block";
            }
            observer.disconnect(); // Stop observing after the element is modified
          }
        });

        const config = { childList: true, subtree: true };
        observer.observe(document.body, config); // Observe the document for changes

        if (showOriginal && videoPlayer) {
          videoPlayer.style.width = "33%";
        } else if (videoPlayer) {
          videoPlayer.style.width = "40%";
        }
      }, 1);
    };

    setIsLoading(true);
    scrollToTriggerReflow();
  }, [showOriginal]);
  // Array to keep start and end times
  const segmentTimes = [];

  const renderTranscript = () => {
    if (!transcriptJsonOriginal) return "";

    // Helper function to format time
    const formatTime = (timeInSeconds) => {
      const date = new Date(null);
      date.setSeconds(timeInSeconds);
      const timeString = date.toISOString().substr(11, 12); // Extract HH:MM:SS.sss
      return timeString.replace("T", ""); // Remove any T in the format
    };

    return transcriptJsonOriginal?.segments?.map((segment, index) => {
      const words = segment.words;
      const start = words[0]?.start || 0; // First word's start time
      const end = words[words.length - 1]?.end || 0; // Last word's end time

      // Store the start and end times in the array
      segmentTimes.push({ start, end });

      const text = words.map((word) => word.text).join(" ");

      return (
        <Box
          key={index}
          style={{
            padding: "2px 0",
            marginTop: "5px",
          }}
        >
          <Box
            style={{ color: "#329678", fontSize: "11px", lineHeight: "20px" }}
          >
            {formatTime(start)}-{formatTime(end)} | {segment.speaker}
          </Box>
          <Box style={{ color: "grey", fontSize: "11px", lineHeight: "20px" }}>
            {text}
          </Box>
        </Box>
      );
    });
  };

  return (
    <div
      className={classNames({
        SubtitleEditor: true,
      })}
      style={{ marginBottom: "55px", display: "flex", width: "100%" }}
    >
      {isLoading ? (
        <div>..</div>
      ) : (
        <Box
          display="flex"
          justifyContent={{
            xs: "center",
            sm: "center",
            md: "space-between",
            lg: "space-between",
          }}
          alignItems="flex-start"
          width="100%"
        >
          {showOriginal ? (
            <div style={{ width: "33%" }}></div>
          ) : (
            <Box
              display={{ xs: "none", sm: "none", md: "flex", lg: "flex" }}
              flex={2}
              id="boxid1"
            ></Box>
          )}
          <Box
            display="flex"
            lineHeight="32px"
            zIndex={1}
            fontSize={{ xs: 12, sm: 14, md: 14, lg: 16, xl: 18 }}
            width={{
              xs: "100%",
              sm: showOriginal ? "100" : "70%",
              md: showOriginal ? "66%" : "60%",
              lg: showOriginal ? "66%" : "60%",
            }}
            flex={{ xs: "unset", sm: "unset", md: 3, lg: 3 }}
            marginLeft={{ xs: 0, sm: 0, md: 5, lg: 5 }}
            marginTop={{
              xs: "180px",
              sm: "210px",
              md: "0px",
              lg: "0px",
              xl: "0px",
            }}
            paddingBottom="55px"
            id="boxid2"
          >
            {showOriginal && (
              <div
                id="mainOriginalSubtitleContainer"
                style={{
                  position: "sticky",
                  top: 0,
                  height: "90%",
                  width: "48%",
                }}
              >
                {/** header should remain on top and sticky */}
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  borderBottom="2px solid white"
                  paddingBottom="10px"
                  marginBottom="15px"
                  style={{
                    background: "#329678",
                    color: "white",
                    borderRadius: "4px 4px 0 0",
                    marginBottom: "0px",
                    position: "fixed",
                    zIndex: 1,
                    width: "30%",
                    fontWeight: "bold",
                    fontSize: "11px",
                    paddingBottom: 0,
                    height: "40px",
                  }}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    style={{
                      fontSize: "11px",
                      fontWeight: "bold",
                      padding: "10px",
                    }}
                  >
                    <MenuIcon
                      style={{ marginRight: "10px", fontSize: "12px" }}
                    />{" "}
                    <span>
                      {translate("OriginalText.transcript")} {originalLang ? "-" :""} {originalLang}
                    </span>
                  </Box>
                  <IconButton style={{ color: "white", padding: "0 20px 0 0" }}>
                    <CloseIcon
                      onClick={triggerHideOriginal}
                      style={{ fontSize: "12px" }}
                    />
                  </IconButton>
                </Box>
                <Box
                  style={{
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                    borderRadius: "4px",
                    borderLeft: "2px solid #329678",
                    marginRight: "15px",
                    background: "white",
                    width: "100%",
                    height: "600px",
                  }}
                >
                  <Box
                    style={{
                      background: "white",
                      padding: "0px 10px",
                      height: "calc(100% - 50px)",
                      overflowY: "scroll", // Make this box scrollable
                      marginTop: "40px", // Space for the sticky header
                      marginBottom: "50px",
                    }}
                  >
                    {renderTranscript()}
                  </Box>
                </Box>
              </div>
            )}

            <Box
              id="subtitleBox"
              display="flex"
              lineHeight="32px"
              zIndex={1}
              fontSize={{
                xs: 12,
                sm: 14,
                md: showOriginal ? 12 : 14,
                lg: 16,
                xl: 18,
              }}
              width={showOriginal ? "48%" : "100%"}
              flex={{ xs: "unset", sm: "unset", md: 3, lg: 3 }}
              paddingBottom="55px"
            >
              <SubtitleEditor {...props} />
            </Box>
          </Box>
        </Box>
      )}
    </div>
  );
}
