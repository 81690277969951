import {
  TRANSCRIBER,
  PERFECT_JOB_MANAGER,
  QUALITY_CONTROLLER,
  WORK_MODE_QUERY_PARAM,
  PERFECT_PLATFORM_QUERY_PARAM_VALUE,
  ADMIN,
} from "../sagas/constants";

export function isPerfectJobEditor(roles) {
  if (
    !roles.includes(TRANSCRIBER) &&
    !roles.includes(PERFECT_JOB_MANAGER) &&
    !roles.includes(QUALITY_CONTROLLER)
  ) {
    return false;
  }

  let searchParams = new URLSearchParams(window.location.search);
  let workModeParam = searchParams.get(WORK_MODE_QUERY_PARAM);
  if (workModeParam !== PERFECT_PLATFORM_QUERY_PARAM_VALUE) {
    return false;
  }

  return true;
}

/**
 * Returns true if user has one of the roles
 * @param {string[]} roles 
 * @returns 
 */
export function isPerfectJobEditorOrAdmin(roles) {
  if (
    !roles.includes(TRANSCRIBER) &&
    !roles.includes(PERFECT_JOB_MANAGER) &&
    !roles.includes(QUALITY_CONTROLLER) &&
    !roles.includes(ADMIN)
  ) {
    return false;
  }

  let searchParams = new URLSearchParams(window.location.search);
  let workModeParam = searchParams.get(WORK_MODE_QUERY_PARAM);
  if (workModeParam !== PERFECT_PLATFORM_QUERY_PARAM_VALUE) {
    return false;
  }

  return true;
}
