import { Modifier, EditorState } from '@amberscript/amberscript-draftjs/lib/Draft'
import { getWordsBefore, getWordsAfter } from './wordsUtils'
import replaceText from './replaceText'

const spaces = (text) => (text.match(/\s/gi) || []).length

const splitBlock = (editorState) => {
  let selection = editorState.getSelection();
  let content = editorState.getCurrentContent();
  const startBlock = content.getBlockForKey(selection.getStartKey());
  const endBlock = content.getBlockForKey(selection.getEndKey());

  const startOffset = selection.getStartOffset() - spaces(startBlock.getText().slice(0, selection.getStartOffset()));
  const endOffset = selection.getEndOffset() - spaces(endBlock.getText().slice(0, selection.getEndOffset()));

  content = replaceText(content, '', selection);
  selection = content.getSelectionAfter();

  const wordsBefore = getWordsBefore(startBlock.data.get('words'), startOffset);
  const wordsAfter = getWordsAfter(endBlock.data.get('words'), endOffset);

  content = Modifier.mergeBlockData(
    content,
    selection,
    { words: wordsBefore }
  );

  content = Modifier.splitBlock(content, selection);

  selection = content.getSelectionAfter();

  content = Modifier.mergeBlockData(
    content,
    selection,
    {
      speaker: startBlock.data.get('speaker'),
      words: wordsAfter,
    }
  );

  return EditorState.push(
    editorState,
    content,
    'split-block'
  );
}

export default splitBlock