import {en} from './en';
import {nl} from './nl';
import {de} from './de';
import {fr} from './fr';
import {es} from './es';
import {it} from './it';
import {pt} from './pt';

const translations = {
    en,
    nl,
    de,
    fr,
    es,
    it,
    pt,
};

export default translations;
