export const ALIGNMENT_SUPPORTED_LANGUAGES = ['en', 'de', 'nl', 'fr', 'es', 'it', 'sv', 'fi'];

//Subtitles dialog constants
export const AMBER_LIGHT_GREEN = '#329678';
export const MIN_VALUE_FOR_CHARS_PER_ROW = 30;
export const DEFAULT_VALUE_FOR_CHARS_PER_ROW = 42;
export const MAX_VALUE_FOR_CHARS_PER_ROW = 52;
export const MAX_VALUE_CHARS_PER_SECOND = 40;
export const MIN_VALUE_CHARS_PER_SECOND = 1;
export const MAX_VALUE_SUBTITLE_DURATION = 100;
export const MIN_VALUE_SUBTITLE_DURATION = 1;
export const TIMEOUT_BEFORE_REDIRECTING_USER = 4000;
export const NETFLIX_RULE = 'Netflix';
export const CUSTOM_RULE = 'Custom';
