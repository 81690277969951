import {
	SHOW_MODAL,
  HIDE_MODAL,
  SHOW_SNACKBAR,
  HIDE_SNACKBAR
} from "../sagas/constants";

const initialState = {
  modalType: null,
  modalProps: {}
}

export function modal(state = initialState, action) {
  switch (action.type) {
    case SHOW_MODAL:
      return {
        modalType: action.modalType,
        modalProps: action.modalProps
      }
    case HIDE_MODAL:
      return initialState
    default:
      return state
  }
}

const initialStateSnackbar = {
  open: false,
  message: ''
}

export function snackbar(state = initialStateSnackbar, action) {
  switch (action.type) {
    case SHOW_SNACKBAR:
      return {
        open: action.open,
        message: action.message
      }
    case HIDE_SNACKBAR:
      return {
        open: action.open,
        message: action.message
      }
    default:
      return state
  }
}