import React, { Component } from 'react'
import { withTranslate } from 'react-redux-multilingual'
import { Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles';

import Shortcuts from '../editor/Shortcuts'

const styles = theme => ({
    button: {
        lineHeight: '11px',
        minWidth: 120,
        fontSize: 13,
        background: '#6d6e6e',
        color: '#fff',
        height: 31,
        minHeight: 31,
        padding: `0 ${theme.spacing.unit * 2}px`,
        borderRadius: 40,
        '&:hover': {
            background: '#545454',
        }
    },
    [theme.breakpoints.down('sm')]: {
        button: {
            display: 'none',
        },
    },
});

const ColorButton = withStyles((theme) => ({
    root: {
        color: 'white',
        backgroundColor: '#6d6e6e',
        '&:hover': {
            backgroundColor: '#545454',
        },
        fontSize: 13,
        borderRadius: 40,
        minWidth: 120,
        minHeight: 31,
    },
}))(Button);

class ShortcutsButton extends Component {

    state = {
        openDialog: false,
    }

    handleOpen = () => {
        this.setState({ openDialog: true });
    }

    handleClose = () => {
        this.setState({ openDialog: false });
    }

    componentDidMount() {
        this.props.addSteps({
            id: 'shortcuts',
            textTranslate: 'TOUR.EDITOR.shortcuts',
            selector: '#ShortcutsButton',
            position: 'top',
            type: 'hover',
            isFixed: true,
            allowClicksThruHole: true,
        });
    }

    render() {
        const { translate, classes } = this.props;

        return (
            <React.Fragment>
                <ColorButton
                    id="ShortcutsButton"
                    className={classes.button}
                    color="default"
                    variant="contained"
                    onClick={this.handleOpen}
                >
                    {translate('EDITOR.shortcutsBtn')}
                </ColorButton>
                <Shortcuts
                    open={this.state.openDialog}
                    onClose={this.handleClose}
                    isCaptionFile={this.props.isCaptionFile}
                />
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(withTranslate(ShortcutsButton))
