export const AMBER_PRIMARY = '#329678';
export const AMBER_SECONDARY = '#FAAA28';
export const AMBER_RED_COLOR = '#bf360c';

export const AMBER_INFO_GRAY = '#726F7B';

export const DARK_GREEN_COLOR = '#005A50';
export const GREEN_COLOR = '#25d366';
export const RED_COLOR = '#e36a57';
export const GRAY_COLOR = '#D0D0D0';

export const PIG_PINK = '#F082A0';
export const CHARCOAL_GRAY = '#2B292D';
export const AMBER_LIGHT_GRAY = '#ECECEC';

export const LIGHT_RED = '#DC283C';
export const LIGHT_YELLOW = '#FAAA28';
export const LIGHT_GREEN = '#329678';

export const HOVER_DARK_GREEN = '#005A50';
export const HOVER_LIGHT_GREEN = '#DBF1EB';
