import {call, put, race, take} from 'redux-saga/effects'
import {
    REFERRAL_SEND_INVITATION,
    REFERRAL_SEND_INVITATION_OK,
    REFERRAL_SEND_INVITATION_FAIL,

    REFERRAL_GET_CREDIT,
    REFERRAL_GET_CREDIT_OK,
    REFERRAL_GET_CREDIT_FAIL,

    REFERRAL_GET_DETAILS,
    REFERRAL_GET_DETAILS_OK,
    REFERRAL_GET_DETAILS_FAIL,

    AUTH_LOGOUT,
} from './constants';
import { apiClient } from '../apiClient'

//SEND REFERRAL
export function* sendReferralInvitationWorker(data) {
    try {
        return yield call(apiClient.post, `/referral/invite`, data);
    } catch (error) {
        yield put({type: REFERRAL_SEND_INVITATION_FAIL});
    }
}

export function* sendReferralInvitation() {
    while (true) {
        const request = yield take(REFERRAL_SEND_INVITATION);

        const result = yield race({
            ok: call(sendReferralInvitationWorker, request.data),
            no: take(AUTH_LOGOUT)
        });

        if (result.ok) {
            yield put({type: REFERRAL_SEND_INVITATION_OK, payload: result.ok.data});
        }
    }
}

//GET CREDIT
export function* getReferralCreditWorker() {
    try {
        return yield call(apiClient.get, `/referral/credit`);
    } catch (error) {
        yield put({type: REFERRAL_GET_CREDIT_FAIL});
    }
}

export function* getReferralCredit() {
    while (true) {
        yield take(REFERRAL_GET_CREDIT);

        const result = yield race({
            ok: call(getReferralCreditWorker),
            no: take(AUTH_LOGOUT)
        });

        if (result.ok) {
            yield put({type: REFERRAL_GET_CREDIT_OK, payload: result.ok.data});
        }
    }
}

// GER REFERRAL CODE DEETAILS
export function* getReferralCodeDetailsWorker(data) {
    try {
        return yield call(apiClient.get, `/referral/code?referralCode=${data}`);
    } catch (error) {
        yield put({type: REFERRAL_GET_DETAILS_FAIL});
    }
}

export function* getReferralCodeDetails() {
    while (true) {
        const request = yield take(REFERRAL_GET_DETAILS);

        const result = yield race({
            ok: call(getReferralCodeDetailsWorker, request.data),
            no: take(AUTH_LOGOUT)
        });

        if (result.ok) {
            yield put({type: REFERRAL_GET_DETAILS_OK, payload: result.ok.data});
        }
    }
}


