import { List } from 'immutable';

const getWords = (block) => block.data.words || block.data.get('words')
const extraGap = 0.6;

export const getOffsetsByTime = (block, currentTime) => {
  const words = block.getIn(['data', 'words']);
  const blockText = block.getText();

  let start = null;
  let end = blockText.length;
  let cursorPosition = numberOfSpacesBeforeNextWord(blockText, 0);
  let nextCursorPosition = 0;
  if (!words || words.size === 0)
    return;
   
  for (let word of words) {
    nextCursorPosition = cursorPosition + word.get('text').length;
    nextCursorPosition +=  numberOfSpacesBeforeNextWord(blockText, nextCursorPosition);

    if (currentTime > (word.get('start') - extraGap)) {
      if (currentTime <= (word.get('end') + extraGap) && (start === null)) {
        start = cursorPosition;
      }
    } else {
      end = nextCursorPosition;
      break;
    }
    cursorPosition = nextCursorPosition;
  }

  if (start === null)
    start = end;  

  return {start, end};
}

//determine by position that in which block, the word is highlighted
export const isPositionInBlock = (position, block) => {
  const words = getWords(block);
    if (!words || words.size === 0) return false;
  const startPosition = words.first().get('startPosition')
  const endPosition = words.last().get('endPosition')
  return position >= startPosition && position <= endPosition
}

//determine by timestamps that in which block, the word is highlighted
export const isTimeInBlock = (time, block) => {
  const words = getWords(block);
    if (!words || words.size === 0) return false;
  const start = words.first().get('start')
  const end = words.last().get('end')
  return time >= start && time <= end
}

export const numberOfSpaces = (text) => {
  const spaces = text.match(/\s/gi);
  return spaces != null ? spaces.length : 0;
}

const numberOfSpacesBeforeNextWord = (text, position) => {
  const notSpaceIndex = text.slice(position, text.length-1).search(/\S/);
  return notSpaceIndex === -1 ? 0 : notSpaceIndex;
}

export const getWordAtOffset = (block, offset) => {
  const words = block.data.get('words');
  const spaces = numberOfSpaces(block.getText().slice(0, offset));
  return getWordsBefore(words, offset - spaces + 1).last()
}

function getNewStartTimeForCutWord(start, end, text, slicedText) {
  const duration = parseFloat((end-start).toFixed(2));
  const newEnd = (duration * slicedText.length) / text.length;
  const newTiming = parseFloat((end - newEnd + 0.01).toFixed(2));
  return newTiming;
}

function getNewEndTimeForCutWord(start, end, text, slicedText) {
  const duration = parseFloat((end-start).toFixed(2));
  const newEnd = (duration * slicedText.length) / text.length;
  const newTiming = parseFloat((newEnd + start).toFixed(2));
  return newTiming;
}

export const getWordsBefore = (words, offset) => {
  return words.reduce(
    (acc, word) => {
      const text = word.get('text')
      let { i } = acc;
      let newWords = acc.words;

      // if our word is below the offset, return it
      if (i + text.length < offset) {
        newWords = newWords.push(word)
      // if the offset is inside the word, cut it
      } else if (i + text.length >= offset && i < offset) {
        newWords = newWords.push(
          word
            .set('text', text.slice(0, offset - i))
            .update('end', val => getNewEndTimeForCutWord(word.get('start'), val, text, text.slice(0, offset - i)))
            .set('pristine', false)
        )
      }

      i = i + text.length;
      // ignore the rest of the words

      return {
        words: newWords,
        i,
      }
    },
    {
      words: List(),
      i: 0,
    }
  ).words
}

export const getWordsAfter = (words, offset) => {
  return words.reduce(
    (acc, word) => {
      let { i } = acc;
      const text = word.get('text')
      let newWords = acc.words;

      // if our word is beyond the offset, return it
      if (i > offset) {
        newWords = newWords.push(word)
      // if the offset is inside the word, cut it
      } else if (i + text.length > offset && i <= offset) {
        newWords = newWords.push(
          word
            .set('text', text.slice(offset - i, text.length))
            .update('start', val => getNewStartTimeForCutWord(val, word.get('end'), text, text.slice(offset - i, text.length)))
            .set('pristine', false)
        )
      }

      i = i + text.length;
      // ignore the rest of the words

      return {
        words: newWords,
        i,
      }
    },
    {
      words: List(),
      i: 0,
    }
  ).words
}
