import {
	WORK_MODE_QUERY_PARAM,
	PERFECT_PLATFORM_QUERY_PARAM_VALUE,
	TRANSCRIBER,
	QUALITY_CONTROLLER,
	PERFECT_IN_REVIEW,
	ADMIN,
	CAPTIONS_HIGH_QUALITY_STYLE,
	TRANSLATIONS_HIGH_QUALITY_STYLE,
	PERFECT_EDITING,
	PERFECT_JOB_MANAGER,
	TRANSCRIPTION_TYPE_TRANSCRIPTION,
	TRANSCRIPTION_TYPE_TRANSLATED_SUBTITLES,
	BASIC_STYLE,
	FORMATTED_STYLE,
} from '../sagas/constants';

export function canEdit(job) {
	return job?.data?.transcriptionType !== TRANSCRIPTION_TYPE_TRANSLATED_SUBTITLES;
}

export function isAdmin(user) {
	return !!user?.roles?.includes(ADMIN);
}

export function canViewJobDetails() {
	let urlSearchParams = new URLSearchParams(window.location.search);
	let workModeSearchParam = urlSearchParams.get(WORK_MODE_QUERY_PARAM);

	return workModeSearchParam === PERFECT_PLATFORM_QUERY_PARAM_VALUE;
}

export function canSubmitCaptionJob(user, job, isCaptionFile) {
	if (!job) {
		return false;
	}

	if (isHighQualityJob(job.workflowStyle)) {
		return false;
	}

	let urlSearchParams = new URLSearchParams(window.location.search);
	let workModeSearchParam = urlSearchParams.get(WORK_MODE_QUERY_PARAM);

	if (workModeSearchParam !== PERFECT_PLATFORM_QUERY_PARAM_VALUE) {
		return false;
	}

	if (!isCaptionFile) {
		return false;
	}

	return user.roles.includes(QUALITY_CONTROLLER);
}

export function canRateJob(user, job, isTranscriptionFile) {
	if (!job) {
		return false;
	}

	if (isHighQualityJob(job.workflowStyle)) {
		return false;
	}

	if (!isTranscriptionFile) {
		return false;
	}

	let urlSearchParams = new URLSearchParams(window.location.search);
	let workModeSearchParam = urlSearchParams.get(WORK_MODE_QUERY_PARAM);

	if (workModeSearchParam !== PERFECT_PLATFORM_QUERY_PARAM_VALUE) {
		return false;
	}

	if (
		user.roles.includes(QUALITY_CONTROLLER) &&
		!user.roles.includes(TRANSCRIBER)
	) {
		return true;
	}

	return !!(user.roles.includes(QUALITY_CONTROLLER) &&
		user.roles.includes(TRANSCRIBER) &&
		job.jobStatus === PERFECT_IN_REVIEW);
}

const isHighQualityJob = (jobWorkflowStyle) =>
	jobWorkflowStyle === CAPTIONS_HIGH_QUALITY_STYLE ||
	jobWorkflowStyle === TRANSLATIONS_HIGH_QUALITY_STYLE;

const canQualityControllerCreateSubtitles = (job, roles) => {
	if (!job || !job.data) {
		return false;
	}

	if (roles.length === 1 && roles.includes(QUALITY_CONTROLLER)) {
		return false;
	}
	const {
		data: { workflowStyle, jobStatus },
	} = job;

	return (
		[QUALITY_CONTROLLER, TRANSCRIBER].every((role) => roles.includes(role)) &&
		isHighQualityJob(workflowStyle) &&
		jobStatus === PERFECT_EDITING
	);
};

const canTranscriberCreateSubtitles = (job, roles) => {
	if (!job || !job.data) {
		return false;
	}
	const {
		data: { workflowStyle },
	} = job;

	return (
		roles.length === 1 &&
		roles.includes(TRANSCRIBER) &&
		isHighQualityJob(workflowStyle)
	);
};

const canCreateSubtitlesBasedOnTranscriptionType = (job, transcriptStatus) => {
	if(!job?.data){
		return transcriptStatus?.data?.transcriptionType !== TRANSCRIPTION_TYPE_TRANSCRIPTION;
	}

	return  job?.data?.transcriptionType !== TRANSCRIPTION_TYPE_TRANSLATED_SUBTITLES && transcriptStatus?.data?.transcriptionType !== TRANSCRIPTION_TYPE_TRANSCRIPTION
}

const canCreateBasedOnTranscriptType = (transcriptStatus, job, roles) => {
	if (
		[QUALITY_CONTROLLER, TRANSCRIBER, PERFECT_JOB_MANAGER].every((role) =>
			!roles.includes(role),
		)
	) {
		return canCreateSubtitlesBasedOnTranscriptionType(null, transcriptStatus)
	}
	return false;
};

export function canCreateSubtitles(job, transcriptStatus, roles) {
	if(job?.data?.workflowStyle === BASIC_STYLE){
		return false;
	}

	if(job?.data?.workflowStyle === FORMATTED_STYLE){
		return false;
	}

	return (
		canCreateBasedOnTranscriptType(transcriptStatus, job, roles) ||
		canCreateSubtitlesBasedOnTranscriptionType(job, transcriptStatus) ||
		canTranscriberCreateSubtitles(job, roles) ||
		canQualityControllerCreateSubtitles(transcriptStatus, roles)
	);
}

// Job satisfaction dialog is only shown to users with no role
export function canGiveJobFeedback(roles) {
	return roles && !roles.length;
}
