import {
    GET_TRANSCRIPT_LIST,
    GET_TRANSCRIPT_LIST_OK,
    GET_TRANSCRIPT_LIST_FAIL
} from "../sagas/constants";

const initialState = {
    data: null,

    loading: false,
    loaded: false,

    error: false,
    errorMessage: null,
};

export default function reducer(state=initialState, action) {
    switch(action.type) {

        case GET_TRANSCRIPT_LIST: {
            return {...state, loading: true, error: false}
        }
        case GET_TRANSCRIPT_LIST_FAIL: {
            return {
                ...state,
                loading: false,
                error: true,
                errorMessage: action.payload
            }
        }
        case GET_TRANSCRIPT_LIST_OK: {
            return {
                ...state,
                loading: false,
                loaded: true,
                error: false,
                data: action.payload
            }
        }
        default: {
            return state;
        }
    }
}
