import React from 'react';
import { makeStyles, Fade } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import AmberSimpleTextField from './amberSimpleTextfield';
import AmberFormHelper from '../labels/AmberFormHelper';

const useStyles = makeStyles((theme) => ({
  textAreaRoot: {
    margin: 0,
  },
  textField: {
    border: `1px solid ${theme.palette.custom.darkGrey}`,
    borderRadius: '0.5em',
    padding: '1em',
    margin: 0,
    '&:hover': {
      borderColor: theme.palette.custom.lightGray,
    },
  },
  textFieldError: {
    border: `1px solid ${theme.palette.error.main}`,
    borderRadius: '0.5em',
    padding: '1em',
    margin: 0,
    '&:hover': {
      borderColor: theme.palette.error.main,
    },
  },
}));

function AmberCustomBoxTextArea(props) {
  const { formHelperText, hasError, handleChange, id, value, translate, rows, ...rest } =
    props;
  const classes = useStyles();

  const textFieldClass = clsx({
    [classes.textField]: !hasError,
    [classes.textFieldError]: hasError,
  });
  return (
    <AmberSimpleTextField
      helperText={
        hasError ? (
          <Fade transition={700} in={hasError}>

            <AmberFormHelper error
            text={translate('MISC.formErrRequired')}  />
          </Fade>
        ) : (
          <AmberFormHelper error={false} text={formHelperText} />
        )
      }
      maxRows={rows}
      minRows={rows}
      InputProps={{
        classes: {
          root: textFieldClass,
        },
        disableUnderline: true,
      }}
      classes={{ root: classes.textAreaRoot }}
      variant="standard"
      onChange={handleChange}
      value={value}
      id={id}
      multiline
      {...rest}
    />
  );
}
AmberCustomBoxTextArea.propTypes = {
  formHelperText: PropTypes.string,
  hasError: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  id: PropTypes.string,
  value: PropTypes.string,
  rows: PropTypes.number
};
AmberCustomBoxTextArea.defaultProps = {
  formHelperText: '',
  hasError: false,
  id: null,
  value: '',
  rows: 3
};
export default AmberCustomBoxTextArea;
