import React from 'react'
import { withTranslate } from 'react-redux-multilingual'
import Alert from './ui/Alert'
import { isIE11andLower } from '../helpers/misc'
import { STORAGE_IE_NOTICE } from '../settings'

function IEnotice (props) {
    const {translate} = props;
    let storedIeNotice;

    const onDismiss = () => {
    	if (window.localStorage) {
	    	window.localStorage.setItem(STORAGE_IE_NOTICE, 'dismissed');
	    }
    }

    if (window.localStorage) {
	    storedIeNotice = window.localStorage.getItem(STORAGE_IE_NOTICE);
    }

    if (isIE11andLower() && storedIeNotice !== 'dismissed') {
      return (
        <Alert
        	title={translate('MISC.ienoticeTitle')}
        	message={translate('MISC.ienoticeTxt')}
        	closeBtn={translate('MISC.ienoticeDismiss')}
        	onDismiss={onDismiss} />
      );
    }
    return ('');
}

export default withTranslate(IEnotice);
