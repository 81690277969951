import React from 'react'
import { Link } from 'react-router-dom'
import { MenuItem, Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles';

const styles = {
    root: {
        padding: 0,
        height: 'auto',
        // fontSize: 15,
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    new: {
        display: 'inline-block',
        marginLeft: 4
    },
    newSpan: {
        backgroundColor: '#005A50',
        borderRadius: 2,
        color: 'white',
        padding: 2,
    },
    link: {
        display: 'block',
        width: '100%',
        textDecoration: 'none',
    },
    button: {
        padding: '12px 16px',
        display: 'block',
        width: '100%',
        textAlign: 'left',
        textTransform: 'none',
        borderRadius: 0,
        '&:hover': {
            backgroundColor: 'rgba(158,158,158,0.2)',
        },
    },
};

function MenuItemLink(props) {

    function getColor() {
        if (props.color) {
            if (props.color === "primary") {
                return '#005A50';
            } else {
                return '#FAAA28';
            }
        } else {
            return '#000000de';
        }
    }

    const btn = <Button
        className={props.classes.button}
        disableRipple={true}
        fullWidth={true}
        style={{ color: getColor() }}
    >
        {props.text}
        {props.new &&
            <div className={props.classes.new}>
                <span className={props.classes.newSpan}>
                    {props.new}
                </span>
            </div>
        }
    </Button>;

    return (
        <MenuItem className={props.classes.root}>
            {props.to &&
                <Link to={props.to} className={props.classes.link} {...props}>
                    {btn}
                </Link>
            }
            {props.onClick &&
                <Link onClick={props.onClick} to={{}} className={props.classes.link} {...props}>
                    {btn}
                </Link>
            }
            {props.href &&
                <a href={props.href} className={props.classes.link} {...props}>
                    {btn}
                </a>
            }
        </MenuItem>
    );
}

export default withStyles(styles)(MenuItemLink);
