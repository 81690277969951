import { call, put, race, take } from 'redux-saga/effects'
import {
    AUTH_LOGOUT,
    GET_ANALYTICS_DATALAYER,
    GET_ANALYTICS_DATALAYER_FAIL,
    GET_ANALYTICS_DATALAYER_OK,
} from './constants'
import { apiClient } from '../apiClient'



export function* getAnalyticsDatalayerWorker() {
    try {
        return yield call(apiClient.get, 'analytics/datalayer');
    } catch (error) {
        yield put({type: GET_ANALYTICS_DATALAYER_FAIL, sending: false});
    }
}

export function* getAnalyticsDatalayer() {
    while (true) {
        yield take(GET_ANALYTICS_DATALAYER);
        const result = yield race({
            ok: call(getAnalyticsDatalayerWorker),
            no: take(AUTH_LOGOUT)
        });
        if (result.ok) {
            yield put({type: GET_ANALYTICS_DATALAYER_OK, payload: result.ok.data});
        }
    }
}

