import React, { Component } from 'react'
import { withTranslate } from 'react-redux-multilingual'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@material-ui/core'

class Alert extends Component {

    state = {
        open: true,
    }

    handleClose = () => {
        this.setState({ open: false });

        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    handleDismiss = () => {
        this.setState({ open: false });

        if (this.props.onDismiss) {
            this.props.onDismiss();
        }
    }

    render() {
        const { translate, title, message, closeBtn, disableEscapeKeyDown,
            disableBackdropClick, onClose } = this.props;

        return (
            <Dialog
                disableBackdropClick={disableBackdropClick || false}
                disableEscapeKeyDown={disableEscapeKeyDown || false}
                open={this.state.open}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose || this.handleDismiss} color="primary" autoFocus>
                        {closeBtn || translate('MISC.closeDialogBtn')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withTranslate(Alert);
