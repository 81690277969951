import { call, put, race, take } from 'redux-saga/effects'
import {
    AUTH_LOGOUT,
    GET_ASR_MODELS,
    GET_ASR_MODELS_FAIL,
    GET_ASR_MODELS_OK,
} from './constants'
import { apiClient } from '../apiClient'



export function* getAsrModelsWorker() {
    try {
        return yield call(apiClient.get, 'asrmodels/list');
    } catch (error) {
        yield put({type: GET_ASR_MODELS_FAIL, sending: false});
    }
}

export function* getAsrModels() {
    while (true) {
        yield take(GET_ASR_MODELS);
        const result = yield race({
            ok: call(getAsrModelsWorker),
            no: take(AUTH_LOGOUT)
        });
        if (result.ok) {
            yield put({type: GET_ASR_MODELS_OK, payload: result.ok.data});
        }
    }
}

