import React from 'react'
import { Tooltip } from '@material-ui/core'
import { withTranslate } from 'react-redux-multilingual'
import { DASHBOARD_URL } from '../settings';

function AccountBadge(props) {
    const { translate, user } = props;
    let label = props.label;
    let showPlainLabel = false;

    if (!label && user.data) {
        if (user.data['userSubscription']) {
            label = user.data['userSubscription']['planName'];
            showPlainLabel = true;
        } else {
            label = 'COSTS.noResultsPlan';
        }
    }

    function openPlans() {
        window.location.href = `${DASHBOARD_URL}/plan`;
    }

    if (label) {
        return (
            <Tooltip title={translate('PLAN.showPlans')} placement="bottom">
                <div className={props.className} onClick={openPlans}>
                    <span>{showPlainLabel ? label : translate(label)}</span>
                </div>
            </Tooltip>
        )
    } else {
        return (
            <div></div>
        );
    }
}

export default withTranslate(AccountBadge);

