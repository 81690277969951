import React from "react";
import PropTypes from 'prop-types';
import { Box } from "@material-ui/core";

import PanelDescription from "../export/exportSubtitles/panelDescription";
import LinkBox from "./LinkBox";

export default function RequestNewBurnIn(props) {
  const { translate, language } = props;

  return (
    <Box display="flex" flexDirection="row" gridColumnGap={4}>
      <PanelDescription
        text={translate("EDITOR.BURN_IN.notAvailableText")}
        testid="export-subtitles-dialog-burnin-notAvailable-text"
      />
      <LinkBox
        text={translate("EDITOR.BURN_IN.notAvailableLink")}
        testid="export-subtitles-dialog-burnin-notAvailable-link"
        language={language}
      />
    </Box>
  );
}

RequestNewBurnIn.propTypes = {
  translate: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
}
