import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { IntlActions } from 'react-redux-multilingual';
import rootSaga from './sagas';
import rootReducer from './reducers';
import { createLogger } from 'redux-logger';
import { DEBUG_REDUX } from './settings';

// import { createMiddleware as createGtm } from 'redux-gtm'
// import { eventsMap } from '.helpers/analytics'

const sagaMiddleware = createSagaMiddleware();

// const composeEnhancers = compose;
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middlewares = [
	sagaMiddleware,
	// createGtm(eventsMap),
	createLogger({ predicate: () => DEBUG_REDUX }),
].filter((m) => m);

const store = createStore(
	rootReducer,
	composeEnhancers(applyMiddleware(...middlewares)),
);

sagaMiddleware.run(rootSaga);
store.dispatch(IntlActions.setLocale('en'));

export default store;
