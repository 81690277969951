import React, { useEffect } from 'react';
import MachineTranslationsDialog from '@amberscript/amberscript-fe-components/dist/components/MachineMadeTranslations/machineTranslationsDialog';
import { useRecoilState } from 'recoil';
import { trackSentryErrorByTag } from '@amberscript/amberscript-fe-components/dist/sentry';

import { machineTranslationsAtom } from './store/machineTranslations';
import {
  createMachineTranslationsJob,
  getMachineTranslationLanguages,
  moveTranslationJobsToQueue,
} from './api/machineTranslations';
import { DASHBOARD_URL } from '../../settings';

export default function MachineTranslations(props) {
  const { isTranslateModalOpen, setTranslateModalOpen,currentJobId, translate} = props;
  const [machineTranslation, setMachineTranslation] = useRecoilState(
    machineTranslationsAtom,
  );

  const closeMachineMadeDialog = () => {
    setTranslateModalOpen(false)
  };

 
  // Get the supported and target languages for machine translations
  async function getAvailableLanguages() {
    try {
      const result = await getMachineTranslationLanguages();
      if (result && result.data) {
        const { sourceLanguages, targetLanguages } = result.data;
        setMachineTranslation({
          ...machineTranslation,
          jobId: currentJobId,
          open: true,
          sourceLanguages,
          targetLanguages,
        });
      }
    } catch (error) {
      trackSentryErrorByTag(error, 'auto-translation-languages');
    }
  }

  useEffect(() => {
     getAvailableLanguages();
  }, []);

  // Orders machine translations
  const handleOrder = async (languages) => {
    const costCenter = '';
    const { jobId } = machineTranslation;
    try {
      const result = await createMachineTranslationsJob(
        jobId,
        languages,
        costCenter,
      );
      if (result && result.data) {
        const { jobIds } = result.data;
        await moveTranslationJobsToQueue(jobIds);
        window.location.href = `${DASHBOARD_URL}`;
      }
    } catch (error) {
      trackSentryErrorByTag(error, 'auto-translation-job');
    } finally {
      closeMachineMadeDialog();
    }
  };

  if (!machineTranslation.open) {
    return null;
  }

  return (
    <MachineTranslationsDialog
      open={isTranslateModalOpen}
      handleClose={closeMachineMadeDialog}
      jobId={currentJobId}
      targetLanguages={machineTranslation.targetLanguages}
      sourceLanguage={machineTranslation.jobLanguage}
      handleOrder={handleOrder}
      translate={translate}
    />
  );
}
