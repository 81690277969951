import MachineMadeIcon from '@mui/icons-material/SettingsSuggest';
import HumanMadeIcon from '@mui/icons-material/BorderColor';

import { JOB_TYPE_DIRECT, JOB_TYPE_PERFECT } from './constants';
import { Box, Tooltip } from '@mui/material';

/**
 * Returns true if array has both manual and automatic jobs
 * @param {object[]} jobs
 */
export function hasAutoAndManualJobs(jobs) {
  const hasAuto = jobs.some((job) => job.jobType === JOB_TYPE_DIRECT);
  const hasManual = jobs.some((job) => job.jobType === JOB_TYPE_PERFECT);
  return hasAuto && hasManual;
}

/**
 * Returns the job type icon with a tooltip
 * @param {string} jobType 
 * @param {object} className 
 * @param {function} translate 
 */
export function getJobTypeIcon(jobType, className, translate) {
  const icon =
    jobType === JOB_TYPE_DIRECT ? (
      <MachineMadeIcon fontSize="small" />
    ) : (
      <HumanMadeIcon fontSize="small" />
    );
  return (
    <Box className={className}>
      <Tooltip
        title={translate(`MACHINE_TRANSLATIONS.${jobType}`)}
        placement="right"
      >
        {icon}
      </Tooltip>
    </Box>
  );
}

/**
 * Returns true if icons are showed and index is not 0
 * @param {boolean} shouldShowIcons 
 * @param {number} index 
 */
export function shouldShowJobTypeIcon(shouldShowIcons, index) {
  return shouldShowIcons && index !== 0;
}
