import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { storeFeedbackBySentry } from '../sentry/log';
import { Divider } from '@material-ui/core';

const maxLengthFeedbackInput = 250;

export default function ScrambledErrorDialog(props) {
	const { open, translate } = props;

	const [comment, setComment] = useState('');
	const [pageBusy, setPageBusy] = useState(false);

	function refreshPageWithTimeout(timeout) {
		setTimeout(() => {
			window.location.reload();
		}, timeout);
	}

	function shouldSendFeedback() {
		return comment.trim().length > 0;
	}

	function onClickOK() {
		setPageBusy(true);
		if (shouldSendFeedback()) {
			storeFeedbackBySentry(comment.trim());
		}
		refreshPageWithTimeout(300);
	}

	function getLabel() {
		const feedbackText = translate('EDITOR.feedbackTitle');
		if (comment.length > 0) {
			return `${feedbackText} ${comment.length}/${maxLengthFeedbackInput}`;
		} else {
			return `${feedbackText}`;
		}
	}

	return (
		<form>
			<Dialog
				open={open}
				disableEscapeKeyDown
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="form-dialog-title">
					{translate('SCRAMBLED_DIALOG.title')}
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						{translate('SCRAMBLED_DIALOG.description1')}
					</DialogContentText>
					<DialogContentText>
						{translate('SCRAMBLED_DIALOG.description2')}
					</DialogContentText>

					<Divider />

					<DialogContentText>
						<Box marginTop={2}>
							<Box
								component="span"
								fontSize={15}
								fontWeight={600}
								color="#000000de"
							>
								{translate('SCRAMBLED_DIALOG.titleFeedback')}
							</Box>
						</Box>
					</DialogContentText>

					<TextField
						id="feedback-scrambled-error"
						autoFocus
						margin="dense"
						label={getLabel()}
						type="text"
						value={comment}
						onChange={(e) => setComment(e.target.value)}
						fullWidth
						multiline
						rows={4}
						variant="outlined"
						inputProps={{ maxLength: maxLengthFeedbackInput }}
					/>
				</DialogContent>
				<DialogActions>
					<Box
						display="flex"
						alignItems="center"
						justifyContent="space-between"
						minHeight={40}
					>
						<Box component="span" fontSize={12}>
							{translate('SCRAMBLED_DIALOG.descriptionButton')}
						</Box>
						<Button onClick={onClickOK} color="primary" disabled={pageBusy}>
							{translate('EDITOR.feedbackVal3')}
						</Button>
					</Box>
				</DialogActions>
			</Dialog>
		</form>
	);
}
