import React from "react";
import PropTypes from "prop-types";
import { Box, styled } from "@material-ui/core";
import { toHHMMSS, toMMSS } from "../../../helpers/timeUtils";

const CustomBox = styled(Box)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  backgroundColor: "#FFFFFF",
  whiteSpace: "nowrap",
}));

export default function TimestampBox(props) {
  const { startTime } = props;

  const isStartTimeOverOneHour = () => startTime >= 3600;
  const getTimestamp = () =>
    isStartTimeOverOneHour() ? toHHMMSS(startTime) : toMMSS(startTime);

  return (
    <CustomBox pl={0.8} pr={0.8} lineHeight={2}>
      {getTimestamp()}
    </CustomBox>
  );
}

TimestampBox.propTypes = {
  startTime: PropTypes.number.isRequired,
};
