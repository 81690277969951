import React from 'react'
import classNames from 'classnames'
import { CircularProgress } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles';

const styles = {
    root: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
    },
    message: {
        color: 'black',
        marginLeft: 0,
        fontSize: 14
    },
    cover: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
    }
};

function Loading(props) {
    return (
        <div className={classNames({
            [props.classes.root]: true,
            [props.classes.cover]: props.cover
        })}>
            <CircularProgress style={{ "color": "#005A50" }} size={props.size} />
            {props.message && <span className={props.classes.message}>{props.message}</span>}
        </div>
    );
}

export default withStyles(styles)(Loading)
