import { apiClient } from "../../apiClient";

/**
 * Updates user activity time
 * @param {jobId} jobId
 * @param {seconds} seconds
 */
export function updateJobActiveSeconds(jobId, seconds) {
  return apiClient.put(
    `/jobs/metadata/editor/duration`,
    {},
    {
      params: {
        jobId,
        seconds,
      },
    }
  );
}
