import {
  availableOptionsByFormat,
  DEFAULT_VALUE_FOR_CHARS_PER_ROW,
  FORMATS,
  MAX_VALUE_FOR_CHARS_PER_ROW,
  MIN_VALUE_FOR_CHARS_PER_ROW,
  OPTIONS,
} from "./constants";

/**
 * Returns available formats
 * @param {boolean} isTranscriptionFile
 */
export function getFormats(isTranscriptionFile) {
  if (isTranscriptionFile()) {
    return FORMATS;
  } else {
    return [FORMATS[1], FORMATS[2]];
  }
}

/**
 * Returns available options by format
 * @param {string} format
 */
export function getAvailableOptionsByFormat(format) {
  const availableOptions = availableOptionsByFormat.filter(
    (option) => option.format === format
  )[0].options;
  return availableOptions.map((optionKey) => OPTIONS[optionKey - 1]);
}

/**
 * Returns max chars per row value according to max and min values
 * @param {number} val 
 */
export function handleMaxCharPerRowValue(val) {
  let value = Number.parseInt(val || DEFAULT_VALUE_FOR_CHARS_PER_ROW);

  if (value < MIN_VALUE_FOR_CHARS_PER_ROW) {
    value = MIN_VALUE_FOR_CHARS_PER_ROW;
  }

  if (value > MAX_VALUE_FOR_CHARS_PER_ROW) {
    value = MAX_VALUE_FOR_CHARS_PER_ROW;
  }

  return value;
}

export const openSurveyForYoutube = () => {
  window.open(
    'https://docs.google.com/forms/d/1PB32Y4CPDMeDWMdjoz7uVUkRvTGquLQ3c6yLF-SzA-0/viewform?pli=1&pli=1&edit_requested=true',
    '_blank',
  );
}
