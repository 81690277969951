import { FORMAT_LIST_AUDIO, FORMAT_LIST_VIDEO } from './constants';

/**
 * Returns available formats by media type
 * @param {boolean} isVideo
 */
export function getAvailableFormatsByDocumentType(isVideo) {
  if (isVideo) {
    return FORMAT_LIST_VIDEO;
  }
  return FORMAT_LIST_AUDIO;
}
