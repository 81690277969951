import React from 'react';
import { Box, DialogActions } from '@material-ui/core';
import PropTypes from 'prop-types';

function AmberDialogActions(props) {
  const { children, classes } = props;

  return (
    <Box p={2} marginTop={1}>
      <DialogActions {...(classes ? { classes } : {})} id="form-dialog-title">
        {children}
      </DialogActions>
    </Box>
  );
}
AmberDialogActions.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.objectOf(PropTypes.string),
};
AmberDialogActions.defaultProps = {
  classes: null,
};
export default AmberDialogActions;
