import React from "react";
import PropTypes from "prop-types";
import {
  FormControl,
  makeStyles,
  MenuItem,
  Select,
} from "@material-ui/core";

// Styles
const useStyles = makeStyles({
  menuPaper: {
    maxHeight: 250,
  },
});

export default function FormatSelectbox(props) {
  const classes = useStyles();
  const {
    value,
    onChange,
    options,
    testid,
  } = props;

  return (
    <FormControl
      data-testid={testid}
      className="formControl"
      style={{ minWidth: 210, margin: 0 }}
    >
      <Select
        variant="outlined"
        labelId="simple-select-helper-label"
        value={value}
        onChange={onChange}
        MenuProps={{ classes: { paper: classes.menuPaper } }}
        inputProps={{ "data-testid": `${testid}-select-test-id` }}
      >
        {options.map((option) => (
          <MenuItem
            key={option}
            value={option}
            data-testid={`${testid}-menuitem-${option}`}
          >
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

FormatSelectbox.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  emptyOption: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  helperText: PropTypes.string,
  testid: PropTypes.string,
};

FormatSelectbox.defaultProps = {
  testid: "",
};
