import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";

import {
  getAvailableOptionsByFormat,
  handleMaxCharPerRowValue,
} from "./helpers";
import {
  DEFAULT_VALUE_FOR_CHARS_PER_ROW,
  FORMAT_CSV,
  FORMAT_JSON,
  FORMAT_TEXT,
} from "./constants";
import AmberCheckbox from "../../../inputs/AmberCheckbox";
import { withTranslate } from "react-redux-multilingual";
import MaxCharsCheckbox from "./maxCharsCheckbox";
import FormatSelectbox from "./formatSelectbox";
import PanelTitle from "./panelTitle";
import CsvOptions from "./CsvOptions";

function ExportOptionCheckBox(props) {
  const { translate, checked, handleCheckBoxChange, data, testid } = props;
  const { translateKey, name } = data;

  return (
    <Box>
      <AmberCheckbox
        checked={checked}
        label={translate(`EDITOR.EXPORT.${translateKey}`)}
        value={name}
        handleChange={handleCheckBoxChange(name)}
        testid={testid}
      />
    </Box>
  );
}

function ExportTextContent(props) {
  const { translate, exportData, setExportData, formats } = props;

  const [optionCols, setOptionCols] = useState(
    setOptionColumns(exportData.format)
  );

  // Separate option checkboxes into 2 columns
  function setOptionColumns(format) {
    const availableOptions = getAvailableOptionsByFormat(format);

    const col1 = [],
      col2 = [];
    availableOptions.forEach((item, index) => {
      if (index % 2 === 0) {
        col1.push(item);
      } else {
        col2.push(item);
      }
    });
    return { col1, col2 };
  }

  const handleFormatChange = (event) => {
    const format = event.target.value;
    setExportData({ ...exportData, format });

    setOptionCols(setOptionColumns(format));
  };

  const handleCheckBoxChange = (name) => (event) => {
    if (name === "exportOnlyHighlights") {
      setExportData({
        ...exportData,
        [name]: event.target.checked,
        includeHighlights: event.target.checked
          ? false
          : exportData.includeHighlights,
      });
    } else if (name === "includeHighlights") {
      setExportData({
        ...exportData,
        [name]: event.target.checked,
        exportOnlyHighlights: event.target.checked
          ? false
          : exportData.exportOnlyHighlights,
      });
    } else {
      setExportData({
        ...exportData,
        [name]: event.target.checked,
      });
    }
  };

  const handleMaxCharPerRow = () => {
    setExportData({
      ...exportData,
      maxCharsPerRow: {
        active: !exportData.maxCharsPerRow.active,
        value: !exportData.maxCharsPerRow.active
          ? exportData.maxCharsPerRow.value
          : DEFAULT_VALUE_FOR_CHARS_PER_ROW,
      },
    });
  };

  const handleChangeMaxCharPerRowValue = (event) => {
    const { value } = event.target;
    const newValue = handleMaxCharPerRowValue(value);
    setExportData({
      ...exportData,
      maxCharsPerRow: {
        ...exportData.maxCharsPerRow,
        value: newValue,
      },
    });
  };

  const hasAvailableOptions = () => optionCols.col1.length !== 0;
  const isText = () => exportData.format === FORMAT_TEXT;
  const isJSON = () => exportData.format === FORMAT_JSON;
  const isCSV = () => exportData.format === FORMAT_CSV;

  return (
    <Box
      display="flex"
      flexDirection="column"
      gridRowGap={24}
      pt={2}
      pb={2}
      pl={4.5}
      pr={4.5}
    >
      <Box
        display="flex"
        flexDirection="column"
        gridRowGap={8}
        alignItems="start"
        mb={0}
      >
        <PanelTitle
          text={translate("EDITOR.EXPORT.format")}
          marginBottom={1}
          testid="export-text-dialog-format-title"
        />
        <FormatSelectbox
          label={translate("EDITOR.EXPORT.format")}
          value={exportData.format}
          onChange={handleFormatChange}
          options={formats}
          testid="export-text-dialog-format-selectbox"
        />
      </Box>
      {hasAvailableOptions() && (
        <Box display="flex" flexDirection="column">
          <PanelTitle
            text={translate("EDITOR.EXPORT.optionsTitle")}
            marginBottom={1}
            testid="export-text-dialog-options-title"
          />
          <>
            <Box display="flex" flexDirection="row" gridColumnGap={10}>
              <Box display="flex" flexDirection="column">
                {optionCols.col1.map((option) => (
                  <ExportOptionCheckBox
                    key={option.name}
                    handleCheckBoxChange={handleCheckBoxChange}
                    translate={translate}
                    checked={exportData[option.name]}
                    data={option}
                    testid={`export-text-dialog-${option.name}-checkbox`}
                  />
                ))}
              </Box>
              <Box display="flex" flexDirection="column">
                {optionCols.col2.map((option) => (
                  <ExportOptionCheckBox
                    key={option.name}
                    handleCheckBoxChange={handleCheckBoxChange}
                    translate={translate}
                    checked={exportData[option.name]}
                    data={option}
                    testid={`export-text-dialog-${option.name}-checkbox`}
                  />
                ))}
                {isText() && (
                  <Box>
                    <MaxCharsCheckbox
                      maxCharsPerRow={exportData.maxCharsPerRow}
                      handleMaxCharPerRow={handleMaxCharPerRow}
                      handleMaxCharPerRowValue={handleChangeMaxCharPerRowValue}
                      testid="export-text-dialog-maxCharsPerRow-checkbox"
                    />
                  </Box>
                )}
              </Box>
            </Box>
          </>
        </Box>
      )}
      {isJSON() && (
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          gridColumnGap={8}
          data-testid="export-text-dialog-json-desc"
        >
          <InfoIcon
            style={{
              width: 24,
              height: 24,
              color: "#2F424F",
            }}
            data-testid="export-text-dialog-json-icon"
          />
          <Box fontSize={14} data-testid="export-text-dialog-json-text">
            {translate("EDITOR.EXPORT.whatIsJson")}
          </Box>
        </Box>
      )}
      {isCSV() && (
        <CsvOptions
          exportData={exportData}
          setExportData={setExportData}
          maxCharsPerRow={exportData.maxCharsPerRow}
          handleMaxCharPerRow={handleMaxCharPerRow}
          handleMaxCharPerRowValue={handleChangeMaxCharPerRowValue}
        />
      )}
    </Box>
  );
}

ExportTextContent.propTypes = {
  exportData: PropTypes.shape({
    format: PropTypes.string.isRequired,
    includeTimestamps: PropTypes.bool.isRequired,
    includeSpeakers: PropTypes.bool.isRequired,
    includeHighlights: PropTypes.bool.isRequired,
    exportOnlyHighlights: PropTypes.bool.isRequired,
    maxCharsPerRow: PropTypes.shape({
      active: PropTypes.bool.isRequired,
      value: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
  setExportData: PropTypes.func.isRequired,
  formats: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default withTranslate(ExportTextContent);
