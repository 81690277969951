import React from 'react'
import { Link } from 'react-router-dom'
import { Tooltip } from '@material-ui/core'
import { withTranslate } from 'react-redux-multilingual'

import logo from '../../assets/images/logo_new_dark_green.svg'

import './Logo.scss'

function Logo(props) {
    const { to, href, tooltip, translate } = props;
    const img = <img className="Appbar_logo_image" src={logo} alt="Amberscript" />;

    if (to) {
        return (
            <Tooltip title={tooltip} placement="bottom">
                <Link to={to} className="Appbar_logo">
                    <span>
                        {img}
                    </span>
                    <span className="Appbar_logo_title">{translate('NAV.titleRecords')}</span>
                </Link>
            </Tooltip>
        )
    } else if (href) {
        return (
            <Tooltip title={tooltip} placement="bottom">
                <a href={href} className="Appbar_logo">
                    <span className="Appbar_logo_image">
                        {img}
                    </span>
                    <span className="Appbar_logo_title">{translate('NAV.titleRecords')}</span>
                </a>
            </Tooltip>
        )
    } else {
        return (
            <Tooltip title={tooltip} placement="bottom">
                <span className="Appbar_logo">
                    <span>
                        {img}
                    </span>
                    <span className="Appbar_logo_title">{translate('NAV.titleRecords')}</span>
                </span>
            </Tooltip>
        )
    }
}

export default withTranslate(Logo);
