import { List } from 'immutable';
import { Modifier, EditorState, SelectionState } from '@amberscript/amberscript-draftjs/lib/Draft';

// For old transcripts we do not have the "prisinte" property
// So for the old ones, no adjusments is required
// For the new ones, we need it
function shouldAdjust(editorState) {
    const contentState = editorState.getCurrentContent();
    const contentBlocks = contentState.getBlocksAsArray();
    const firstBlock = contentBlocks[0];
    const words = firstBlock.getIn(['data', 'words']);
    const firstWord = words.get(0);
    if(firstWord.get('pristine') === true || firstWord.get('pristine') === false) {
        return true;
    } else {
        return false;
    }
}

// Javascript has a lot of problem with calculations
// This function changes 1.80000000003 to 1.80
function twoDecimalPoint(number) {
    if(typeof number === "number") {
        return Math.trunc(number * 100) / 100;
    } else {
        return Math.trunc(parseFloat(number) * 100) / 100;
    }
}

// Get the pristine words of a ContentBlock
function getPristineWordsOfBlockWords(words) {
    return words.filter(word => word.get('pristine'));
}

// Get all the non-pristine words between two pristine words
// And in the next step we need to update their timestamps
function getNonPristineWordsBetweenTwoPristineWords(firstPristineWord, secondPristineWord, words) {
    if(firstPristineWord.equals(secondPristineWord)) {
        return new List();
    } else {
        const firstPristineWordIndex = words.findIndex(word => word.get("id") === firstPristineWord.get("id"));
        const secondPristineWordIndex = words.findIndex(word => word.get("id") === secondPristineWord.get("id"));
    
        if(secondPristineWordIndex - firstPristineWordIndex > 1) {
            return words.slice(firstPristineWordIndex + 1, secondPristineWordIndex);
        } else {
            return new List();
        }
    }        
}

// Update the timestamps of all the non-pristine words
// Based on startTime and endTime
// 1) Calculate the total length of all the words
// 2) Distribute the totalLength among the words
function updateTimestampsOfNonPristineWords(startTime, endTime, words) {
    if(words.size > 0) {
        let updatedWords = new List();

        // 1
        const totalLength = words.reduce((sum, word) => {
            return sum + word.get('text').length;
        }, 0);

        const timePerCharacter = (endTime - startTime) / totalLength;
        let currentStartTime = twoDecimalPoint(startTime);

        // 2
        for(const word of words) {
            const length = word.get('text').length;
            const end = twoDecimalPoint(currentStartTime + (timePerCharacter * length));
            
            const updatedStartTimeWord = word.set('start', currentStartTime);
            const updatedEndTimeWord = updatedStartTimeWord.set('end', end);
            updatedWords = updatedWords.push(updatedEndTimeWord);

            currentStartTime = end;
        }

        return updatedWords;   
    } else {
        return new List();
    }
}

function getNewWords(wordsNoFilter) {
    let newWords = new List();

    // Remove all the words with no text
    const words = wordsNoFilter.filter(w => w.get('text').trim() !== '');

    // If there are no words, return empty List();
    if(words.size === 0) return newWords;

    const startTimeOfTheBlock = words.first().get('start');
    const endTimeOfTheBlock = words.last().get('end');

    // Get all the pristineWords + firstWord and the lastWord of this block
    let pristineWords = getPristineWordsOfBlockWords(words);
    // If all the words are pristine, no adjustment is required!
    if(pristineWords.size === words.size) return words;

    if(pristineWords.size > 0) {
        // Update the timestamps of all the words from the first-word until the first-pristine-word
        // Update timestamps of the non-pristine words
        
        const updatedStartingNonPristineWords = updateTimestampsOfNonPristineWords(startTimeOfTheBlock, pristineWords.first().get('start'),
            words.slice(0, words.findIndex(word => word.get("id") === pristineWords.first().get("id"))));
        // Add non-pristine words to the newWords
        newWords = newWords.concat(updatedStartingNonPristineWords);

        // Loop over all the pristine words and correct the timestamps of all the non-pristine words
        for (let index = 0; index < pristineWords.size - 1; index += 1) {
            const firstPristineWord = pristineWords.get(index);
            const secondPristineWord = pristineWords.get(index + 1);

            // Get non-pristine words
            const nonPristineWords = getNonPristineWordsBetweenTwoPristineWords(firstPristineWord, secondPristineWord, words);
            // Add the first pristine word to the list
            newWords = newWords.push(firstPristineWord);
            if(nonPristineWords.size > 0) {
                // Update timestamps of the non-pristine words
                const updatedNonPristineWords = updateTimestampsOfNonPristineWords(firstPristineWord.get('end'), secondPristineWord.get('start'), nonPristineWords);
                // Add the words into the newWords
                newWords = newWords.concat(updatedNonPristineWords);
            }
        }
        // Add the last pristine word to the list
        newWords = newWords.push(pristineWords.last());

        // Update the timestamps of all the words from the last-pristine-word until the last-word
        // Update timestamps of the non-pristine words
        const updatedEndingNonPristineWords = updateTimestampsOfNonPristineWords(pristineWords.last().get('end'), endTimeOfTheBlock,
            words.slice(words.findIndex(word => word.get("id") === pristineWords.last().get("id")) + 1, words.size));
        // Add the words into the newWords
        newWords = newWords.concat(updatedEndingNonPristineWords);
    } else {
        // Update timestamps of all the words
        const updatedAllWords = updateTimestampsOfNonPristineWords(startTimeOfTheBlock, endTimeOfTheBlock, words);
        newWords = newWords.concat(updatedAllWords);
    }
    
    if(newWords.size === 0) {
        return words;
    } else {
        return newWords;
    }
}

function updateWordsDataInContentBlocks(contentState) {
    let newContentState = contentState;
    const contentBlocks = newContentState.getBlocksAsArray();
    
    for(const block of contentBlocks) {
        const selectionState = SelectionState.createEmpty(block.getKey());
        newContentState = Modifier.mergeBlockData(
            newContentState,
            selectionState, {
            words: getNewWords(block.getIn(['data', 'words'])),
        });
    }

    return newContentState;
}

function createNewEditorState(editorState, newContentState) {
    const newEditorStateInstance = EditorState.createWithContent(newContentState)
    const copyOfEditorState = EditorState.set(
        newEditorStateInstance,
        {   
            selection: editorState.getSelection(), 
            undoStack: editorState.getUndoStack(), 
            redoStack: editorState.getRedoStack(), 
            lastChangeType: editorState.getLastChangeType() 
        }
    );
    return copyOfEditorState;
}

// Check the newEditorState to make sure it has stored all the data correctly
// Including: (start, end, text, pristine) for all the words
function checkNewEditorState(editorState) {
    const contentState = editorState.getCurrentContent();
    const contentBlocks = contentState.getBlocksAsArray();
    for(const block of contentBlocks) {
        const words = block.getIn(['data', 'words']);
        if(!words) {
            console.log("No adjusting");
            return false;
        }
        for(let i = 0; i < words.size; i += 1) {
            const word = words.get(i);
            if(!(word.get('start') && word.get('end') && word.get('text') && word.get('pristine') !== undefined)) {
                console.log("No adjusting", word);
                return false;
            }
        }
    }
    return true;
}

// Update the timestamps of each non-pristine words
function adjustTimestamps(editorState) {
    if(shouldAdjust(editorState)) {
        const contentState = editorState.getCurrentContent();
        const newContentState = updateWordsDataInContentBlocks(contentState);
        const newEditorState = createNewEditorState(editorState, newContentState);
        if(checkNewEditorState(newEditorState)) {
            return newEditorState;
        } else {
            return editorState;
        }
    } else {
        return editorState;
    }
}

export {
    shouldAdjust,
    adjustTimestamps,
}