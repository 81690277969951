import { withTranslate } from 'react-redux-multilingual'
import Tour from '../ui/Tour'

class EditorTour extends Tour {

    constructor (props) {
        super(props);

        this.opts = {
            ...this.opts,
            scrollOffset: 182,
        }
        this.modalSteps = [{
            id: 'welcome',
            titleTranslate: 'TOUR.EDITOR.beginTitle',
            textTranslate: 'TOUR.EDITOR.begin',
        }, {
            id: 'end',
            textTranslate: 'TOUR.EDITOR.end',
        }];
    }

    getKey () {
        return 'amber.tour.editor';
    }

    getStepsOrder () {
        return [
            'welcome',
            'altClick',
            'play',
            'rewind',
            'highlight',
            'startTime',
            'shortcuts',
            'end'
        ]
    }
}

export default withTranslate(EditorTour);
