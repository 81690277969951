import React ,  {useState} from "react";
import { connect } from "react-redux";
import { withTranslate } from "react-redux-multilingual";

import LangMenu from "./LangMenu";
import ProfileMenu from "./ProfileMenu";
import TranscriptTitle from "./TranscriptTitle";
import CreateSubtitle from "./CreateSubtitle";

import Logo from "../ui/Logo";
import ExportButton from "../buttons/ExportButton";
import AmberOutlinedButton from "../buttons/AmberOutlinedButton";
import AmberPrimaryButton from "../buttons/AmberPrimaryButton";
import MediaFileControllers from "../buttons/MediaFileControllers";
import RequestFullTranscriptButton from "../buttons/RequestFullTranscriptButton";
import AccountBadge from "../AccountBadge";
import JobItemDialog from "../dialogs/job_item_dialog/jobItemDialog";
import JobCaptionSubmitDialog from "../dialogs/job_caption_submit_dialog/jobCaptionSubmitDialog";
import JobRatingDialog from "../dialogs/job_rating_dialog/jobRatingDialog";
import JobSubmitConfirmDialog from "../dialogs/job_submit_confirm_dialog/JobSubmitConfirmDialog";
import { canViewJobDetails, canSubmitCaptionJob, canRateJob } from "../../helpers/permissions";

import "./Header.scss";
import {
  UPDATE_JOB_DETAILS_DIALOG,
  UPDATE_CAPTION_JOB_SUBMIT_DIALOG,
  UPDATE_JOB_REVIEW_DIALOG,
  CAPTIONS_HIGH_QUALITY_STYLE,
  TRANSCRIPTION_TYPE_TRANSLATED_SUBTITLES,
  TRANSCRIPTION_TYPE_CAPTIONS,
  UPDATE_SUBMIT_CONFIRM_DIALOG,
} from '../../sagas/constants';
import AmberLoadingButton from "../buttons/AmberLoadingButton";

function Header(props) {

  const {
    transcript,
    translate,
    onRewind,
    onForward,
    account,
    title,
    addSteps,
    saveTranscript,
    uiSettings,
    realignTranscript,
    isTranscriptionFile,
    isCaptionFile,
    isTranscriber,
    transcriptId,
    job,
    submitQc,
    loadingQc,
    isExportModalOpen,
    setExportModalOpen,
    isPreQCAvailable

  } = props;

  const {
    dashboardHref,
    getFullPlan: enableFullPlan,
    exportFile: enableExport,
    langMenu: enableLangMenu,
    accountBadge: enableAccountBadge,
    profileMenu: enableProfileMenu,
    editName: enableEditName,
  } = uiSettings;

  const [submitConfirmIsOpen, setSubmitConfirmIsOpen] = useState(false)
  function shouldShowRequestPerfectBtn() {
    const { demoFile } = props.transcriptStatus.data;

    if (demoFile) {
      return false;
    }
    return true;
  }

  function isSubtitlesAndTranscriber() {
    return !isTranscriptionFile() && isTranscriber();
  }

  function getEmailValidated() {
    if (!isTranscriptionFile() && isTranscriber()) {
      return true;
    } else {
      return account.data.emailValidated;
    }
  }

  const handleJobDetailsDialogOpen = () => {
    props.dispatch({ type: UPDATE_JOB_DETAILS_DIALOG, isDetailsDialogOpen: true });
  }

  const handleCaptionJobSubmittingDialogOpen = () => {
    props.dispatch({ type: UPDATE_CAPTION_JOB_SUBMIT_DIALOG, isCaptionSubmitDialogOpen: true });
  }
  
  const handleJobRatingDialogOpen = () => {
    props.dispatch({ type: UPDATE_JOB_REVIEW_DIALOG, isRatingDialogOpen: true });
  }
  const confirmJob = () => {  
    if (isPreQCAvailable) {
      submitQc();
    } else {
      setSubmitConfirmIsOpen(true);
    }
  }
  

  const isJobDetailsViewingAllowed = canViewJobDetails(account.data);
  const isCaptionJobSubmittingAllowed = canSubmitCaptionJob(account.data, job.data, isCaptionFile());
  const isJobRatingAllowed = canRateJob(account.data, job.data, isTranscriptionFile());


  // Should show only if user is TRANSCRIBER and is assigned to this job
  // Hide if HQ and translation
  // Show for BASIC + FORMATTED captions and transcriptions
  const shouldShowSubmitButton = () => {
    if (!job.data || !isTranscriber() || job.data.jobStatus === "PERFECT_IN_REVIEW") {
      return false;
    }
   
    const { transcriptionType, workflowStyle } = job.data;
    if ((transcriptionType === TRANSCRIPTION_TYPE_CAPTIONS && workflowStyle === CAPTIONS_HIGH_QUALITY_STYLE) 
      || transcriptionType === TRANSCRIPTION_TYPE_TRANSLATED_SUBTITLES) {
        return false;
      }

    return true;
  }

  return (
    <header id="Header" className="AppBar">
      <div className="Appbar_left">
        <Logo
          href={dashboardHref}
          tooltip={translate("NAV.titleRecordsTooltip")}
        />
        {!transcript.loading && transcript.loaded && (
          <React.Fragment>
            {(enableExport || isSubtitlesAndTranscriber()) && (
              <ExportButton
                saveTranscript={saveTranscript}
                isEmailValidated={getEmailValidated()}
                realignTranscript={realignTranscript}
                isTranscriptionFile={isTranscriptionFile}
                transcriptId={transcriptId}
                isTranscriber={isTranscriber}
                isCaptionFile={isCaptionFile}
                isExportModalOpen={isExportModalOpen}
                setExportModalOpen={setExportModalOpen}
              />
            )}
            <MediaFileControllers
              addSteps={addSteps}
              onRewind={onRewind}
              onForward={onForward}
            />
          </React.Fragment>
        )}
      </div>
      {title && <h2 className="title">{title}</h2>}
      {!title && (
        <TranscriptTitle className="title" enableEditName={enableEditName} />
      )}
      <div className="Appbar_right">
        {isTranscriber && shouldShowSubmitButton() && (
          <AmberLoadingButton 
            text={translate("EDITOR.PRE_QC.submitBtn")}
            onClick={loadingQc ? () => {} : confirmJob} 
          />
        )}


        {
          isJobDetailsViewingAllowed &&
          <AmberOutlinedButton
            text={translate("EDITOR.btnDetails")}
            onClick={handleJobDetailsDialogOpen}>
          </AmberOutlinedButton>
        }
        
        {
          isCaptionJobSubmittingAllowed &&
          <AmberPrimaryButton
            text={translate("EDITOR.btnSubmit")}
            onClick={handleCaptionJobSubmittingDialogOpen}>
          </AmberPrimaryButton>
        }
        
        {
          isJobRatingAllowed &&
          <AmberPrimaryButton
            text={translate("EDITOR.btnApproveOrReject")}
            onClick={handleJobRatingDialogOpen}>
          </AmberPrimaryButton>
        }
        
        {isTranscriptionFile() &&
          enableFullPlan &&
          shouldShowRequestPerfectBtn() && (
            <RequestFullTranscriptButton className="request_full_transcription" />
          )}
        {isTranscriptionFile() && props.shouldShowCreateSubtitleBtn() && (
          <CreateSubtitle
            account={props.account}
            transcript={props.transcript}
            transcriptStatus={props.transcriptStatus}
            isTranscriber={isTranscriber}
            translate={translate}
          />
        )}
        {enableAccountBadge &&
          account.data.userSubscription &&
          account.data.userSubscription.active && (
            <AccountBadge user={account} className="account_badge" />
          )}
        {enableLangMenu && <LangMenu account={account} />}
        {enableProfileMenu && <ProfileMenu user={account} />}

        {isJobRatingAllowed && <JobRatingDialog translate={translate} />}

        {isCaptionJobSubmittingAllowed && <JobCaptionSubmitDialog translate={translate} />}

        {isJobDetailsViewingAllowed && <JobItemDialog translate={translate} />}
        {isTranscriber && shouldShowSubmitButton() && !isPreQCAvailable && ( <JobSubmitConfirmDialog translate={translate} submitQc={submitQc} setSubmitConfirmIsOpen={setSubmitConfirmIsOpen} submitConfirmIsOpen={submitConfirmIsOpen} />)}
      </div>
    </header>
  );
}

const mapStateToProps = ({ account, transcript, transcriptStatus, job }) => ({
  account,
  transcript,
  transcriptStatus,
  job
});

export default connect(mapStateToProps)(withTranslate(Header));
