import React, { PureComponent } from 'react'
import { Box } from '@material-ui/core'
import { PINKISH_RED } from '../../../helpers/colors'

import { getTimeFromSec } from '../../../helpers/timeUtils'
import { ADJUST_TIMESTAMP_START, ADJUST_TIMESTAMP_END } from '../../dialogs/dialogConstants';

class BlockStartEndTime extends PureComponent {
    render() {
        const { startTime, endTime, onTimestampClick, block, screenTimeLimitExceeded } = this.props;
        let start, end = '';

        if (startTime !== null && endTime !== null) {
            start = getTimeFromSec(startTime);
            end = getTimeFromSec(endTime);
        }
        return (
            <Box className="timeSubtitle" contentEditable={false} color={screenTimeLimitExceeded ? PINKISH_RED : 'unset'}>
                <Box component="span" className="start"
                    onClick={() => {
                        onTimestampClick({
                            mode: ADJUST_TIMESTAMP_START,
                            block: block,
                        })
                    }}>{start}</Box>
                <Box component="span"> - </Box>
                <Box className="end" component="span"
                    onClick={() => onTimestampClick({
                        mode: ADJUST_TIMESTAMP_END,
                        block: block,
                    })}>{end}</Box>
            </Box>
        )
    }
}

export default BlockStartEndTime;
