import { convertFromRaw } from '@amberscript/amberscript-draftjs/lib/Draft';
import { HIGHLIGHT } from '../settings';
import { isTimeInBlock, isPositionInBlock } from './wordsUtils';

const transcriptToContentState = (transcript) => {
    const rawContent = {
      blocks: [],
      entityMap: {}
    }

    // skips all empty and space symbol words
    rawContent.blocks = transcript.segments.map(segment => {
        const words = segment.get('words');
        return {
            text: words.reduce((result, word) => {
              const text = word.text;
              if (text.length > 0 && text !== ' ') {
                result.push(text);
              }
              return result;
            }, []).join(' '),
            inlineStyleRanges: [],
            data: {
              id: segment.get('id'),
              words: words,
              speaker: segment.get('speaker'),
              newLine: segment.get('newLine'),
            }
        }
    }).toArray();

    transcript.highlights.forEach((highlight, index) => {
      // const startBlockIndex = rawContent.blocks.findIndex(block => isTimeInBlock(highlight.start, block))
      // const endBlockIndex = rawContent.blocks.findIndex(block => isTimeInBlock(highlight.end, block))

      const startBlockIndex = rawContent.blocks.findIndex(block => isPositionInBlock(highlight.startPosition, block))
      const endBlockIndex = rawContent.blocks.findIndex(block => isPositionInBlock(highlight.endPosition, block))

      if (startBlockIndex < 0 || endBlockIndex < 0) {
        return
      }

      if (startBlockIndex === endBlockIndex) {
        rawContent.blocks[startBlockIndex].inlineStyleRanges.push({
          offset: highlight.startPosition - getPreviousBlocksLength(rawContent.blocks, startBlockIndex),
          length: highlight.endPosition - highlight.startPosition,
          style: HIGHLIGHT
        })
      } else {
        rawContent.blocks[startBlockIndex].inlineStyleRanges.push({
          offset: highlight.startPosition - getPreviousBlocksLength(rawContent.blocks, startBlockIndex),
          length: rawContent.blocks[startBlockIndex].text.length,
          style: HIGHLIGHT
        })

        rawContent.blocks[endBlockIndex].inlineStyleRanges.push({
          offset: 0,
          length: highlight.endPosition - getPreviousBlocksLength(rawContent.blocks, endBlockIndex),
          style: HIGHLIGHT
        })

        rawContent.blocks = rawContent.blocks.map((block, index) => {
          if (index > startBlockIndex && index < endBlockIndex) {
            block.inlineStyleRanges.push({
              offset: 0,
              length: block.text.length,
              style: HIGHLIGHT
            })

            return block;
          }
          return block;
        })
      }
    });

    return convertFromRaw(rawContent);
};

const getPreviousBlocksLength = (blocks, position) => {
  let length = 0;
  for (let i = 0; i < position; i += 1) {
    length += blocks[i].text.length + 1;
  }
  return length;
}

export default transcriptToContentState;
