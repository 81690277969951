import React from "react";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";

export default function PanelDescription(props) {
  const { text, marginBottom, testid } = props;

  return (
    <Box fontSize={14} fontWeight={400} mb={marginBottom} data-testid={testid}>
      {text}
    </Box>
  );
}

PanelDescription.propTypes = {
  text: PropTypes.string,
  testid: PropTypes.string,
  marginBottom: PropTypes.number,
};

PanelDescription.defaultProps = {
  marginBottom: 0,
  testid: "",
  text: "",
};
