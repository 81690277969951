import Immutable from 'immutable'

const TranscriptWord = new Immutable.Record({
    id: null,
    text: '',
    start: 0,
    end: 0,
    startPosition: 0,
    endPosition: 0,
    pristine: null,
    conf: null,
    newLine: false,
});

export default TranscriptWord;
