import { SET_JOB_EDITOR_MODE } from '../sagas/constants';

import { TRANSCRIBER_DASHBOARD, RECORDS_PAGE } from '../settings';

const DEFAULT_EDITOR_MODE = 'DEFAULT_EDITOR_MODE';
const JOB_EDITOR_MODE = 'JOB_EDITOR_MODE';

const initialState = {
  mode: DEFAULT_EDITOR_MODE,
  dashboardHref: RECORDS_PAGE,
  // Header
  exportFile: true,
  requestPerfect: true,
  getFullPlan: true,
  langMenu: true,
  accountBadge: true,
  profileMenu: true,
  editName: true,
  // Footer
  shortcuts: true,
  tour: true,
  // EditorControls
  editorControls: true
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_JOB_EDITOR_MODE: {
      return {
        ...state,
        dashboardHref: TRANSCRIBER_DASHBOARD,
        mode: JOB_EDITOR_MODE,
        // Header
        exportFile: false,
        requestPerfect: false,
        getFullPlan: false,
        langMenu: false,
        accountBadge: false,
        profileMenu: false,
        editName: false,
        // Footer
        shortcuts: true,
        tour: false,
        // EditorControls
        editorControls: true
      }
    }

    default: {
      return state;
    }
  }
}
