import React, { Component } from 'react'
import { withTranslate } from 'react-redux-multilingual'
import {
    Button, CircularProgress, Dialog, DialogTitle, DialogContent,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import './Footer.scss';

const styles = theme => ({
    root: {
        height: '100%',
        paddingLeft: 8,
    },
    btnRealign: {
        height: '100%',
        color: theme.palette.primary.default,
    },
    dialogContent: {
        display: 'flex',
        alignItems: 'center',
        color: '#424242'
    },
    progress: {
        marginLeft: 8
    }
});

function LoadingBarDialog({ open, translate, classes }) {
    return (
        <Dialog
            open={open}
            maxWidth="sm"
            aria-labelledby="Adjust-timestamps-dialog"
        >
            <DialogTitle>
                {translate('REALIGN.dialogTitle')}
            </DialogTitle>
            <DialogContent>
                <div className={classes.dialogContent}>
                    <span>
                        {translate('REALIGN.dialogContent')}
                    </span>
                    <CircularProgress className={classes.progress} />
                </div>
            </DialogContent>
        </Dialog>
    )
}

class RealignBtn extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { classes, translate, showRealigningLoadingBar, realignTranscript } = this.props;

        return (
            <React.Fragment>
                <LoadingBarDialog open={showRealigningLoadingBar} translate={translate} classes={classes} />
                <div className={classes.root}>
                    <Button className={classes.btnRealign} onClick={realignTranscript}>
                        {translate('REALIGN.btnName')}
                    </Button>
                </div>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(withTranslate(RealignBtn));

