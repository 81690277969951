export const API_REQUEST = 'API_REQUEST';

export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_LOGIN_OK = 'AUTH_LOGIN_OK';
export const AUTH_LOGIN_FAIL = 'AUTH_LOGIN_FAIL';
export const AUTH_LOGIN_FIREBASE = 'AUTH_LOGIN_FIREBASE';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_LOGOUT_OK = 'AUTH_LOGOUT_OK';

export const ADD_START_TIME_OFFSET = 'ADD_START_TIME_OFFSET';
export const FINISH_CONVERT_TO_SUBTITLES = 'FINISH_CONVERT_TO_SUBTITLES';
export const CONVERT_TO_SUBTITLES = 'CONVERT_TO_SUBTITLES';

export const GET_ACCOUNT = 'GET_ACCOUNT';
export const GET_ACCOUNT_OK = 'GET_ACCOUNT_OK';
export const GET_ACCOUNT_FAIL = 'GET_ACCOUNT_FAIL';

export const SET_ACCOUNT_LANGUAGE = 'SET_ACCOUNT_LANGUAGE';

export const SET_ACCOUNT_COUPON = 'SET_ACCOUNT_COUPON';
export const SET_ACCOUNT_COUPON_OK = 'SET_ACCOUNT_COUPON_OK';
export const SET_ACCOUNT_COUPON_FAIL = 'SET_ACCOUNT_COUPON_FAIL';

export const SEND_EMAIL_VALIDATION = 'SEND_EMAIL_VALIDATION';
export const EMAIL_VALIDATION_SENT = 'EMAIL_VALIDATION_SENT';

export const GET_TRANSCRIPT_LIST = 'GET_TRANSCRIPT_LIST';
export const GET_TRANSCRIPT_LIST_OK = 'GET_TRANSCRIPT_LIST_OK';
export const GET_TRANSCRIPT_LIST_FAIL = 'GET_TRANSCRIPT_LIST_FAIL';

export const GET_TRANSCRIPT = 'GET_TRANSCRIPT';
export const GET_TRANSCRIPT_OK = 'GET_TRANSCRIPT_OK';
export const GET_TRANSCRIPT_FAIL = 'GET_TRANSCRIPT_FAIL';

export const RELOAD_TRANSCRIPT = 'RELOAD_TRANSCRIPT';
export const RELOAD_TRANSCRIPT_FAIL = 'RELOAD_TRANSCRIPT_FAIL';
export const RELOAD_TRANSCRIPT_OK = 'RELOAD_TRANSCRIPT_OK';

export const SET_TRANSCRIPT = 'SET_TRANSCRIPT';
export const SAVE_TRANSCRIPT = 'SAVE_TRANSCRIPT';
export const SAVE_TRANSCRIPT_OK = 'SAVE_TRANSCRIPT_OK';
export const SAVE_TRANSCRIPT_FAIL = 'SAVE_TRANSCRIPT_FAIL';

export const SAVE_TRANSCRIPT_STATUS = 'SAVE_TRANSCRIPT_STATUS';
export const SAVE_TRANSCRIPT_STATUS_OK = 'SAVE_TRANSCRIPT_STATUS_OK';
export const SAVE_TRANSCRIPT_STATUS_FAIL = 'SAVE_TRANSCRIPT_STATUS_FAIL';

export const GET_TRANSCRIPT_STATUS = 'GET_TRANSCRIPT_STATUS';
export const GET_TRANSCRIPT_STATUS_OK = 'GET_TRANSCRIPT_STATUS_OK';
export const GET_TRANSCRIPT_STATUS_FAIL = 'GET_TRANSCRIPT_STATUS_FAIL';

export const SET_TRANSCRIPT_SPEAKER = 'SET_TRANSCRIPT_SPEAKER';
export const SET_TRANSCRIPT_TITLE = 'SET_TRANSCRIPT_TITLE';
export const SET_TRANSCRIPT_HIGHLIGHTS = 'SET_TRANSCRIPT_HIGHLIGHTS';

export const GET_AUDIO = 'GET_AUDIO';
export const GET_AUDIO_OK = 'GET_AUDIO_OK';
export const GET_AUDIO_FAIL = 'GET_AUDIO_FAIL';

export const SET_AUDIO_PLAY = 'SET_AUDIO_PLAY';
export const SET_AUDIO_PAUSE = 'SET_AUDIO_PAUSE';
export const SET_AUDIO_TOGGLE = 'SET_AUDIO_TOGGLE';

export const SET_AUDIO_TIME = 'SET_AUDIO_TIME';
export const SET_AUDIO_SPEED = 'SET_AUDIO_SPEED';
export const SET_AUDIO_POSITION = 'SET_AUDIO_POSITION';

export const RESIZE_VIDEO = 'RESIZE_VIDEO';

export const GET_ASR_MODELS = 'GET_ASR_MODELS';
export const GET_ASR_MODELS_OK = 'GET_ASR_MODELS_OK';
export const GET_ASR_MODELS_FAIL = 'GET_ASR_MODELS_FAIL';

// export const GET_ASRMODEL_LIST = "GET_TRANSCRIPT_LIST";
// export const GET_TRANSCRIPT_LIST_OK = "GET_TRANSCRIPT_LIST_OK";
// export const GET_TRANSCRIPT_LIST_FAIL = "GET_TRANSCRIPT_LIST_FAIL";

export const GET_TRANSACTION_BALANCE = 'GET_TRANSACTION_BALANCE';
export const GET_TRANSACTION_BALANCE_OK = 'GET_TRANSACTION_BALANCE_OK';
export const GET_TRANSACTION_BALANCE_FAIL = 'GET_TRANSACTION_BALANCE_FAIL';

export const GET_ANALYTICS_DATALAYER = 'GET_ANALYTICS_DATALAYER';
export const GET_ANALYTICS_DATALAYER_OK = 'GET_ANALYTICS_DATALAYER_OK';
export const GET_ANALYTICS_DATALAYER_FAIL = 'GET_ANALYTICS_DATALAYER_FAIL';

export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';

export const DISABLE_TRANSCRIPT_EDITOR = 'DISABLE_TRANSCRIPT_EDITOR';
export const ENABLE_TRANSCRIPT_EDITOR = 'ENABLE_TRANSCRIPT_EDITOR';

export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';

export const SHOW_SNACKBAR = 'SHOW_SNACKBAR';
export const HIDE_SNACKBAR = 'HIDE_SNACKBAR';

export const REFERRAL_SEND_INVITATION = 'REFERRAL_SEND_INVITATION';
export const REFERRAL_SEND_INVITATION_OK = 'REFERRAL_SEND_INVITATION_OK';
export const REFERRAL_SEND_INVITATION_FAIL = 'REFERRAL_SEND_INVITATION_FAIL';

export const REFERRAL_GET_CREDIT = 'REFERRAL_GET_CREDIT';
export const REFERRAL_GET_CREDIT_OK = 'REFERRAL_GET_CREDIT_OK';
export const REFERRAL_GET_CREDIT_FAIL = 'REFERRAL_GET_CREDIT_FAIL';

export const REFERRAL_GET_DETAILS = 'REFERRAL_GET_DETAILS';
export const REFERRAL_GET_DETAILS_OK = 'REFERRAL_GET_DETAILS_OK';
export const REFERRAL_GET_DETAILS_FAIL = 'REFERRAL_GET_DETAILS_FAIL';

export const SET_JOB_EDITOR_MODE = 'SET_JOB_EDITOR_MODE';

// OTHER MESSAGES
export const INVALID_API_KEY = 'Invalid apiKey given';
export const NO_API_GIVEN = "Required String parameter 'apiKey' is not present";

// DOCUMENT TYPES
export const DOCUMENT_TYPE_SUBTITLES_JSON = 'SUBTITLES_JSON';
export const DOCUMENT_TYPE_TRANSCRIPT = 'TRANSCRIPT';

export const RATING_COMPONENT_ID = 'RATING_COMPONENT_ID';
export const TEXT_INPUT_ID = 'TEXT_INPUT_ID';

export const RATING_PERFECT_DONE_STATE = 'ACCEPTED';
export const RATING_PERFECT_REVIEW_NOT_PASSED_STATE = 'REJECTED';

// JOBS
export const GET_JOB = 'GET_JOB';
export const GET_JOB_OK = 'GET_JOB_OK';
export const GET_JOB_FAIL = 'GET_JOB_FAIL';

export const SAVE_JOB_REVIEW = 'SAVE_JOB_REVIEW';
export const SAVE_JOB_REVIEW_OK = 'SAVE_JOB_REVIEW_OK';
export const SAVE_JOB_REVIEW_FAIL = 'SAVE_JOB_REVIEW_FAIL';

export const SUBMIT_CAPTION_JOB = 'SUBMIT_CAPTION_JOB';
export const SUBMIT_CAPTION_JOB_OK = "SUBMIT_CAPTION_JOB_OK";
export const SUBMIT_CAPTION_JOB_FAIL = "SUBMIT_CAPTION_JOB_FAIL";

export const GET_QUALITY_CHECKS = 'GET_QUALITY_CHECKS';
export const GET_QUALITY_CHECKS_OK = 'GET_QUALITY_CHECKS_OK';
export const GET_QUALITY_CHECKS_FAIL = 'GET_QUALITY_CHECKS_FAIL';

export const PERFECT_REVIEW_NOT_PASSED = 'PERFECT_REVIEW_NOT_PASSED';
export const PERFECT_EDITING = 'PERFECT_EDITING';
export const PERFECT_DONE = 'PERFECT_DONE';
export const DONE = 'DONE';
export const PERFECT_IN_REVIEW = 'PERFECT_IN_REVIEW';

export const SET_JOB_MESSAGE = 'SET_JOB_MESSAGE';
export const UPDATE_JOB_REVIEW_DIALOG = 'UPDATE_JOB_REVIEW_DIALOG';
export const UPDATE_JOB_DETAILS_DIALOG = 'UPDATE_JOB_DETAILS_DIALOG';
export const UPDATE_CAPTION_JOB_SUBMIT_DIALOG = 'UPDATE_CAPTION_JOB_SUBMIT_DIALOG'

export const TRANSCRIBER = 'TRANSCRIBER';
export const PERFECT_JOB_MANAGER = 'PERFECT_JOB_MANAGER';
export const QUALITY_CONTROLLER = 'QUALITY_CONTROLLER';
export const ADMIN = 'ADMIN';

export const WORK_MODE_QUERY_PARAM = 'workMode';
export const PERFECT_PLATFORM_QUERY_PARAM_VALUE = 'perfectPlatform';

export const CAPTIONS_HIGH_QUALITY_STYLE = 'HQ';
export const TRANSLATIONS_HIGH_QUALITY_STYLE = 'TRANSLATION - HQ';
export const BASIC_STYLE = 'BASIC';
export const FORMATTED_STYLE = 'FORMATTED';

export const TRANSCRIPTION_TYPE_TRANSCRIPTION = "transcription";
export const TRANSCRIPTION_TYPE_CAPTIONS = "captions";
export const TRANSCRIPTION_TYPE_TRANSLATED_SUBTITLES = "translatedSubtitles";

export const RIGHT_PANEL_TAB_VALUE_GLOSSARY = 0;
export const RIGHT_PANEL_TAB_VALUE_PREQC = 1;

export const PRE_QC_ISSUE_TYPE_SPELLING = "spelling";
export const PRE_QC_ISSUE_TYPE_GRAMMAR = "grammar";
export const PRE_QC_ISSUE_TYPE_INAUDIBLE = "inaudible";
export const PRE_QC_ISSUE_TYPE_CLEANREAD = "cleanread";
export const PRE_QC_ISSUE_TYPE_FORMATTING = "formatting";

//FIND TEXT
export const SET_SEARCH_TEXT = 'SET_SEARCH_TEXT';
export const SET_FOUND_WORDS = 'SET_FOUND_WORDS';
export const ADD_FOUND_WORDS = 'ADD_FOUND_WORDS';
export const SET_SEARCH_INDEX = 'SET_SEARCH_INDEX';
export const SET_CASE_MATCH = 'SET_CASE_MATCH';