import React from "react";
import { Box } from "@material-ui/core";
import PropTypes from "prop-types";

import AmberPrimaryButton from "../../../buttons/AmberPrimaryButton";
import FormatSelectbox from "./formatSelectBox";
import PanelDescription from "./panelDescription";
import PanelTitle from "./panelTitle";

export default function FileExport(props) {
  const {
    translate,
    exportData,
    handleFormatChange,
    formats,
    handleExportSubtitles,
  } = props;

  return (
    <Box>
      <PanelTitle
        text={translate("EDITOR.EXPORT.asAFile")}
        marginBottom={2}
        testid="export-subtitles-dialog-asAFile-title"
      />
      <PanelDescription
        text={translate("EDITOR.EXPORT.asAFileDesc")}
        marginBottom={3}
        testid="export-subtitles-dialog-asAFile-description"
      />
      <Box display="flex" flexDirection="row" mt={1} alignItems="center">
        <FormatSelectbox
          label={translate("EDITOR.EXPORT.format")}
          value={exportData.format}
          onChange={handleFormatChange}
          options={formats}
          testid="export-subtitles-dialog-asAFile-selectbox"
        />
        <Box height={40}>
          <AmberPrimaryButton
            text={translate("EDITOR.EXPORT.download")}
            onClick={handleExportSubtitles}
            testid="export-subtitles-dialog-asAFile-btn"
          />
        </Box>
      </Box>
    </Box>
  );
}

FileExport.propTypes = {
  translate: PropTypes.func.isRequired,
  exportData: PropTypes.shape({
    format: PropTypes.string.isRequired,
    selectedRule: PropTypes.string.isRequired,
    selectedRealignment: PropTypes.string.isRequired,
    customMaxNrRows: PropTypes.number.isRequired,
    customMaxCharPerRow: PropTypes.number.isRequired,
  }),
  formats: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  handleFormatChange: PropTypes.func.isRequired,
  handleExportSubtitles: PropTypes.func.isRequired,
};
