import { DEBUG } from "../../settings";

function checkForWhiteSpace(text) {
  return /\s/.test(text);
}

/**
 * Check if any white spaces exist within a word
 * For instance 'Amber ' or ' Amber' or 'Amb er' are not acceptable.
 *
 * @param {ContentBlock} block
 * @returns {Boolean} result if there is a white space, returns true
 */
function checkWordsForAnyWhiteSpace(block) {
  const words = block.data.get("words");
  for (const word of words) {
    const text = word.get("text");
    // Check for white space
    if (checkForWhiteSpace(text)) {
      return {
        error: true,
        word: text,
      };
    }
    if (text.trim() !== text) {
      return {
        error: true,
        word: text,
      };
    }
    // Check if the whole word is a space
    if (text.trim() === "") {
      return {
        error: true,
        word: text,
      };
    }
  }
  return {
    error: false,
    word: null,
  };
}

//
function getWhatUserSees(block) {
  return block.text.replace(/\s\s+/g, " ").trim();
}

function getWhatAmberscriptSees(block) {
  return block.data
    .get("words")
    .map((w) => w.get("text"))
    .join(" ");
}

//
export default function checkForScrambledError(editorState) {
  let scrambledChecker = {
    hasError: false,
    reason: null,
    word: null,
  };

  try {
    const contentState = editorState.getCurrentContent();
    for (const block of contentState.getBlocksAsArray()) {
      if (block.text && block.data && block.data.get("words")) {
        const userText = getWhatUserSees(block);
        const whatAmberScriptSee = getWhatAmberscriptSees(block);

        // 1) Check for any white spaces
        const { error: whiteSpaceError, word: whiteSpaceWord } =
          checkWordsForAnyWhiteSpace(block);
        if (whiteSpaceError) {
          scrambledChecker.hasError = true;
          scrambledChecker.reason = "White space in a word";
          scrambledChecker.word = whiteSpaceWord;
          break;
        }

        // 2) Check to see if both versions are equal
        if (userText !== whatAmberScriptSee) {
          scrambledChecker.hasError = true;
          scrambledChecker.reason = "Version diff";
          break;
        }
      }
    }
  } catch (error) {
    if (DEBUG) {
      console.log("Error in checkForScrambledError function", error);
    }
  }

  return scrambledChecker;
}

/**
 * Returns true if language is included in sourceLanguages
 * @param {string} language
 * @param {string[]} sourceLanguages
 */
export function isSourceLanguageSupportedForMachineMade(
  language,
  sourceLanguages
) {
  return sourceLanguages.some((lang) => lang === language);
}
