import { apiClient } from "../apiClient";
import { convertSegmentsToSubtitles, createSubtitlesFromSegments } from './subtitleUtil'

const newLine = '\r\n';
const speakerColors = [
    "ffffff", "ffff00", "00ffff", "00ff00"
]

function combineSubtitlesToFullSrt(subtitles, speakersColor) {
    let fullSrt = "";
    let index = 1;
    for (let subtitle of subtitles) {
      fullSrt += index++;
      fullSrt += newLine;
      fullSrt += getSrtSubtitleWithColor(subtitle, speakersColor);
      fullSrt += newLine + newLine;
    }
    return fullSrt;
}

function getSrtSubtitleWithColor(subtitle, speakersColor) {
    let frame = `${subtitle.startFormatted(',')} --> ${subtitle.endFormatted(',')}` + newLine;
    frame += speakersColor.get(subtitle.speaker);
    for(let line of subtitle.lines) {
        frame += line + newLine;
    }
    return frame + newLine;
}

function setColorToSpeakers(speakers) {
    const colorToSpeakers = new Map();
    for (let index = 0; index < speakers.length; index++) {
        colorToSpeakers.set(speakers[index].id, speakerColors[index % speakerColors.length]);
    }
    return colorToSpeakers;
}

function exportStl(transcript, transcriptStatus, subtitleDurationMax) {
    const { audioDisplayFileName } = transcriptStatus.data;
    const segments = transcript.data.segments.valueSeq().toArray();
    const speakersColor = setColorToSpeakers(transcript.data.speakers.toArray());
    let subtitles;
    if (subtitleDurationMax === undefined) {
        subtitles = createSubtitlesFromSegments(segments)
    } else {
        subtitles = convertSegmentsToSubtitles(segments, subtitleDurationMax);
    }

    //subtitle
    let fullSrtString = combineSubtitlesToFullSrt(subtitles, speakersColor);
    //file name
    const fileName = _getExportFilename(audioDisplayFileName, 'srt');
    //alphabet [Latin, Cyrillic, Arabic, Greek, Hebrew]
    const languageCode = (transcriptStatus.data.language) ? transcriptStatus.data.language : "en-us";

    exportFile(fullSrtString, fileName, languageCode);
}

function exportFile(subtitle, fileName, languageCode) {
    const blob = new Blob(
        [subtitle],
        {type: 'text/plain'}
    )
    var formData = new FormData();
    formData.append("file", blob, fileName);
    formData.append("languageCode", languageCode);
    apiClient.post('transcription/exportstl', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }).then(response => {
        window.open(response.data.downloadUrl, "_self");
    }).catch(error => {
        console.log({error});
    })
}

function _getExportFilename(fileName, ext) {
    return fileName.split('.').slice(0, -1).join('.') + '.' + ext;
}

export default exportStl;