import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { DialogContent } from '@material-ui/core';

import AmberOutlinedButton from '../../buttons/AmberOutlinedButton';
import AmberLoadingButton from '../../buttons/AmberLoadingButton';

import AmberDialog from '../AmberDialog';
import AmberDialogActions from '../AmberDialogActions';
import JobRatingContents from './jobRatingContents';
import { convertToJSON } from '../../../helpers/exportTranscript';
import {
	RATING_COMPONENT_ID,
	TEXT_INPUT_ID,
	RATING_PERFECT_DONE_STATE,
	RATING_PERFECT_REVIEW_NOT_PASSED_STATE,
	PERFECT_DONE,
	PERFECT_REVIEW_NOT_PASSED,
	SAVE_JOB_REVIEW,
	SET_JOB_MESSAGE,
	UPDATE_JOB_REVIEW_DIALOG,
	TRANSCRIPTION_TYPE_CAPTIONS,
	TRANSCRIPTION_TYPE_TRANSLATED_SUBTITLES,
} from '../../../sagas/constants';
import {
	getRatingDefaults,
} from '../../../constants/ratingCategories';

const useStyles = makeStyles((theme) => ({
	dialogActions: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	buttonReject: {
		color: theme.palette.custom.pinkishRed,
		border: `2px solid ${theme.palette.custom.pinkishRed}`,
		'&:hover': {
			border: `1px solid ${theme.palette.custom.pinkishRed}`,
			backgroundColor: 'rgba(50, 150, 120, 0.04)',
		},
	},
	actionButtons: {
		display: 'flex',
		flexDirection: 'row',
	},
	spinnerColour: {
		color: theme.palette.error.main,
	},
	errorMessage: {
		color: theme.palette.error.main,
		paddingLeft: '24px',
		paddingBottom: '24px',
	},
}));

const validateRatings = (ratings, worflowStyle, transcriptionType) => {
	if (!ratings) {
		return false;
	}

	const ratingDefaults = getRatingDefaults(
		null,
		worflowStyle,
		transcriptionType,
	);

	const ratingDefaultsKeys = Object.keys(ratingDefaults);
	return ratingDefaultsKeys.every(
		(key) => ratings[key] && ratings[key].selected,
	);
};

const validateNotes = (notes) => {
	if (!notes) {
		return false;
	}
	return notes.trim().length > 0;
};

const getProcessedRatings = (ratings) => {
	const ratingsKeys = Object.keys(ratings);
	return ratingsKeys.reduce((acc, key) => {
		acc[key] = ratings[key].selected;
		return acc;
	}, {});
};

function JobRatingDialog(props) {
	const { job, transcript, translate } = props;

	const classes = useStyles();
	const [notes, setNotes] = useState(undefined);
	const [ratings, setRatings] = useState(undefined);

	const [canSubmit, setCanSubmit] = useState(false);
	const [canReject, setCanReject] = useState(false);

	useEffect(() => {
		if (validateRatings(ratings, job.data.workflowStyle, job.data.transcriptionType ) && validateNotes(notes)) {
			setCanReject(true);
			setCanSubmit(true);
			return;
		}
		setCanReject(false);
		setCanSubmit(false);
	}, [notes, ratings]);


	const handleClickReject = () => {
		const processedRatings = getProcessedRatings(ratings);
		const data = {
			ratings: processedRatings,
			notes,
		};
		handleReview(RATING_PERFECT_REVIEW_NOT_PASSED_STATE, data);
	};

	const handleClickAccept = () => {
		const processedRatings = getProcessedRatings(ratings);
		const data = {
			ratings: processedRatings,
			notes,
		};
		handleReview(RATING_PERFECT_DONE_STATE, data);
	};

	const handleChange = (event) => {
		const { id, value } = event;
		switch (id) {
			case TEXT_INPUT_ID:
				setNotes(value);
				break;
			case RATING_COMPONENT_ID:
				setRatings({ ...value });
				break;
			default:
				break;
		}
	};

	const setJobStatus = (jobToUpdate, status) => {
		const job = jobToUpdate;
		switch (status) {
			case RATING_PERFECT_DONE_STATE:
				job.status = PERFECT_DONE;
				break;
			case RATING_PERFECT_REVIEW_NOT_PASSED_STATE:
				job.status = PERFECT_REVIEW_NOT_PASSED;
				break;
			default:
				break;
		}
	};

	const handleJobRatingDialogClose = () => {
		setNotes(undefined);
		setRatings(undefined);
		props.dispatch({
			type: UPDATE_JOB_REVIEW_DIALOG,
			isRatingDialogOpen: false,
			message: null,
		});
	};

	const getReviewTranscript = (status) => {
		if(status === RATING_PERFECT_REVIEW_NOT_PASSED_STATE){
			return null;
		}

		if(job.data.transcriptionType === TRANSCRIPTION_TYPE_CAPTIONS || job.data.transcriptionType === TRANSCRIPTION_TYPE_TRANSLATED_SUBTITLES) {
			const convertOptions = {
				includeTimestamps: true,
				includeSpeakers: true,
				includeHighlights: true,
				highlightsOnly: false,
				includeIsConvertedToSubtitles: true,
			};

			return convertToJSON(transcript, convertOptions, true);
		}

		return null;
	};

	const handleReview = async (status, data) => {
		const { ratings, notes } = data;
		try {
			const modifiedJob = { ...job.data };

			setJobStatus(modifiedJob, status);

			props.dispatch({
				type: SAVE_JOB_REVIEW,
				jobId: modifiedJob.jobId,
                workflowStyle: modifiedJob.workflowStyle,
                transcriptionType: modifiedJob.transcriptionType,
				ratings,
				notes,
				status,
				transcript: getReviewTranscript(status),
			});
		} catch (error) {
			props.dispatch({ type: SET_JOB_MESSAGE, message: error.message });
		}
	};

	return (
		<AmberDialog
			open={job.isRatingDialogOpen}
			title={translate('EDITOR.dialogJobFeedback.title')}
			fullWidth
			maxWidth="sm"
			handleClose={handleJobRatingDialogClose}
			classes={{ paper: classes.dialogPaper }}
		>
			<DialogContent>
				<JobRatingContents
					handleChange={handleChange}
					notes={notes}
					ratings={ratings}
					job={job.data}
					translate={translate}
				/>
			</DialogContent>

			<AmberDialogActions classes={{ root: classes.dialogActions }}>
				<AmberOutlinedButton
					text={translate('MISC.closeDialogBtn')}
					onClick={handleJobRatingDialogClose}
				/>
				<div className={classes.actionButtons}>
					<AmberLoadingButton
						text={translate('EDITOR.dialogJobFeedback.btnReject')}
						onClick={handleClickReject}
						classes={{ root: classes.buttonReject }}
						spinnerClass={classes.spinnerColour}
						disabled={!canReject || job.loading}
						loading={job.rejecting}
						variant="outlined"
					/>
					<AmberLoadingButton
						text={translate('EDITOR.dialogJobFeedback.btnApprove')}
						onClick={handleClickAccept}
						loading={job.approving}
						disabled={!canSubmit || job.loading}
					/>
				</div>
			</AmberDialogActions>

			{job.message && <div className={classes.errorMessage}>{job.message}</div>}
		</AmberDialog>
	);
}

JobRatingDialog.propTypes = {
	translate: PropTypes.func.isRequired,
};

const mapStateToProps = ({ job, transcript }) => ({
	job,
	transcript,
});

export default connect(mapStateToProps)(JobRatingDialog);
