import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, Tooltip } from '@material-ui/core'

import { PauseIcon, PlayIcon } from '../ui/Icons'

import { SET_AUDIO_TOGGLE } from '../../sagas/constants'

import '../ui/HeaderButton.scss'

class PlayButton extends Component {

    toggleAudio = () => {
        this.props.dispatch({ type: SET_AUDIO_TOGGLE });
    }

    componentDidMount() {
        this.props.addSteps({
            id: 'play',
            textTranslate: 'TOUR.EDITOR.play',
            selector: '#PlayButton',
            position: 'bottom',
            type: 'hover',
            isFixed: true,
            allowClicksThruHole: true,
            style: {
                hole: {
                    borderRadius: '100%'
                }
            }
        });
    }

    render() {
        const { playing } = this.props.transcriptAudio;

        if (playing) {
            return (
                <Tooltip title="Shortcut: (ctrl+space)" placement="bottom">
                    <Button id="PlayButton" className="PlayButton HeaderButton" aria-label="Pause" color="primary" onClick={this.toggleAudio}>
                        <i><PauseIcon style={{ color: "white" }} /></i>
                    </Button>
                </Tooltip>
            )
        } else {
            return (
                <Tooltip title="Shortcut: (ctrl+space)" placement="bottom">
                    <Button id="PlayButton" className="PlayButton HeaderButton" aria-label="Play" color="primary" onClick={this.toggleAudio}>
                        <i><PlayIcon style={{ color: "white" }} /></i>
                    </Button>
                </Tooltip>
            )
        }
    }
}

const mapStateToProps = ({ transcriptAudio }) => ({ transcriptAudio });

export default connect(mapStateToProps)(PlayButton)
