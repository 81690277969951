import {
  BURN_IN_JOB_STATUS_BUSY,
  BURN_IN_JOB_STATUS_DONE,
  BURN_IN_JOB_STATUS_OPEN,
} from "./constants";

/**
 * Checks if status of job is OPEN
 * @param {object} burnInJob
 */
export function isBurnInOpen(burnInJob) {
  if (burnInJob) {
    return burnInJob.status === BURN_IN_JOB_STATUS_OPEN;
  }
  return false;
}

/**
 * Checks if status of job is DONE
 * @param {object} burnInJob
 */
export function isBurnInDone(burnInJob) {
  if (burnInJob) {
    return burnInJob.status === BURN_IN_JOB_STATUS_DONE;
  }
  return false;
}

/**
 * Checks if status of job is BUSY
 * @param {object} burnInJob
 */
export function isBurnInBusy(burnInJob) {
  if (burnInJob) {
    return burnInJob.status === BURN_IN_JOB_STATUS_BUSY;
  }
  return false;
}
