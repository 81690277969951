import zenscroll from 'zenscroll';

let topOfNode;

export const scrollToNode = (node, ms, offset) => {
	if (node) {
			const newTopOfNode = zenscroll.getTopOf(node).toFixed();
			if (newTopOfNode !== topOfNode) {
				topOfNode = newTopOfNode;
				zenscroll.center(node, ms, offset);
			}
	}
}