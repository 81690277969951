import React from 'react';
import PropTypes from 'prop-types';
import { Box, CircularProgress, makeStyles } from '@material-ui/core';
import InfoIcon from '@mui/icons-material/Info';

import Title from './title';
import SpellingItem from './SpellingItem';
import AmberTooltip from '../../tooltips/AmberTooltip';

const useStyles = makeStyles((theme) => ({
  boxColor: {
    color: theme.palette.custom.charcoalGray,
  },
}));
export default function SpellingPanel(props) {
  const { spellingIssues, translate, loading, handleOpenReportDialog } = props;
  const classes = useStyles();

  const adaptedMessage = () =>
    spellingIssues.length > 1
      ? 'EDITOR.PRE_QC.spellingIssuesFound'
      : 'EDITOR.PRE_QC.spellingIssueFound';

  function Loading() {
    return (
      <Box
        display="flex"
        gridColumnGap={16}
        alignItems="center"
        paddingLeft={2.75}
        marginTop={3}
      >
        <CircularProgress size={20} />
        <Title text={translate('EDITOR.PRE_QC.loadingSuggestions')} />
      </Box>
    );
  }

  function GetContent() {
    return (
      <>
        <Box
          display="flex"
          alignItems="center"
          paddingLeft={2.75}
          marginTop={3}
          flexDirection="row"
          gridRowGap={2}
        >
          <Title
            text={`${spellingIssues.length}${translate(adaptedMessage())}`}
          />
          <AmberTooltip
            tooltip={translate('EDITOR.PRE_QC.spellingInfoTooltip')}
            testid="pre-qc-spelling-info-tooltip"
          >
            <InfoIcon
              sx={{ color: 'custom.gray', pl: 1 }}
              data-testid="pre-qc-spelling-info-icon"
            />
          </AmberTooltip>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          paddingLeft={2.75}
          paddingRight={2.75}
          className={classes.boxColor}
          marginTop={2}
          gridRowGap={8}
        >
          {spellingIssues.length !== 0 &&
            spellingIssues.map((item, index) => (
              <Box
                key={`pre-qc-spelling-suggestion-${index}`}
                display="flex"
                fontSize={{ xs: 10, sm: 12, md: 12, lg: 14, xl: 14 }}
              >
                <SpellingItem
                  spellingItem={item}
                  translate={translate}
                  index={index}
                  handleOpenReportDialog={handleOpenReportDialog}
                />
              </Box>
            ))}
        </Box>
      </>
    );
  }

  if (!spellingIssues?.length && !loading) {
    return null;
  }

  return (
    <Box data-testid="pre-qc-box-spelling-panel">
      {loading ? <Loading /> : <GetContent />}
    </Box>
  );
}

SpellingPanel.propTypes = {
  spellingIssues: PropTypes.arrayOf(
    PropTypes.shape({
      original_text: PropTypes.string.isRequired,
      start_time: PropTypes.number.isRequired,
      suggestion: PropTypes.string,
    })
  ),
  translate: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  handleOpenReportDialog: PropTypes.func.isRequired,
};

SpellingPanel.defaultProps = {
  spellingIssues: [],
};
