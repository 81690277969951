import React from "react";
import PropTypes from "prop-types";
import { DialogActions, styled } from "@material-ui/core";
import AmberOutlinedButton from "../../../buttons/AmberOutlinedButton";
import AmberPrimaryButton from "../../../buttons/AmberPrimaryButton";

const CustomDialogActions = styled(DialogActions)(({ theme }) => ({
  padding: theme.spacing(3),
}));

export default function ReportSpellingDialogActions(props) {
  const { handleClose, handleReport, translate } = props;

  return (
    <CustomDialogActions  data-testid="pre-qc-report-false-positive-dialog-actions">
      <AmberOutlinedButton
        text={translate("EDITOR.PRE_QC.cancel")}
        onClick={handleClose}
        testid="pre-qc-report-false-positive-dialog-cancel-btn"
      />
      <AmberPrimaryButton
        text={translate("EDITOR.PRE_QC.reportBtn")}
        onClick={handleReport}
        testid="pre-qc-report-false-positive-dialog-report-btn"
      />
    </CustomDialogActions>
  );
}

ReportSpellingDialogActions.propTypes = {
  handleClose: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
};
